@media (min-width:1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1220px
    }
}

@media (min-width:992px) {
    .row.g-lg-0 {
        margin-right: 0;
        margin-left: 0
    }

    .row.g-lg-0>* {
        padding-right: 0;
        padding-left: 0
    }
    header .navbar-brand img {
        max-height:70px
    }
}

@media (max-width:1600px) {
    section {
        padding-top: 60px;
        padding-bottom: 60px
    }

    section.big-section {
        padding-top: 120px;
        padding-bottom: 120px
    }

    section.extra-big-section {
        padding-top: 160px;
        padding-bottom: 160px
    }
}

@media (max-width:991px) {
    html {
        font-size: 14px
    }

    section {
        padding-top: 75px;
        padding-bottom: 75px
    }

    section.half-section {
        padding-top: 50px;
        padding-bottom: 50px
    }

    section.big-section {
        padding-top: 95px;
        padding-bottom: 95px
    }

    section.extra-big-section {
        padding-top: 110px;
        padding-bottom: 110px
    }

    .row.md-gutter-very-small {
        margin-left: -5px;
        margin-right: -5px
    }

    .row.md-gutter-very-small>* {
        padding-right: 5px;
        padding-left: 5px
    }

    .row.md-gutter-small {
        margin-left: -10px;
        margin-right: -10px
    }

    .row.md-gutter-small>* {
        padding-right: 10px;
        padding-left: 10px
    }
}

@media (max-width:767px) {
    html {
        font-size: 12px
    }

    section {
        padding-top: 50px;
        padding-bottom: 50px
    }

    section.half-section {
        padding-top: 40px;
        padding-bottom: 40px
    }

    section.big-section,
    section.extra-big-section {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .row.sm-gutter-very-small {
        margin-left: -5px;
        margin-right: -5px
    }

    .row.sm-gutter-very-small>* {
        padding-right: 5px;
        padding-left: 5px
    }

    .row.sm-gutter-small {
        margin-left: -10px;
        margin-right: -10px
    }

    .row.sm-gutter-small>* {
        padding-right: 10px;
        padding-left: 10px
    }
    
}

@media (max-width:1600px) {
    .xxl-fs-100 {
        font-size: 6.25rem;
        line-height: 6.25rem
    }

    .xxl-fs-110 {
        font-size: 6.875rem;
        line-height: 6.875rem
    }

    .xxl-fs-120 {
        font-size: 7.5rem;
        line-height: 7.5rem
    }

    .xxl-fs-130 {
        font-size: 8.125rem;
        line-height: 8.125rem
    }

    .xxl-fs-140 {
        font-size: 8.75rem;
        line-height: 8.75rem
    }

    .xxl-fs-150 {
        font-size: 9.375rem;
        line-height: 9.375rem
    }

    .xxl-fs-160 {
        font-size: 10rem;
        line-height: 10rem
    }

    .xxl-fs-170 {
        font-size: 10.625rem;
        line-height: 10.625rem
    }

    .xxl-fs-180 {
        font-size: 11.25rem;
        line-height: 11.25rem
    }

    .xxl-fs-190 {
        font-size: 11.875rem;
        line-height: 11.875rem
    }

    .xxl-fs-200 {
        font-size: 12.5rem;
        line-height: 12.5rem
    }

    .xxl-fs-250 {
        font-size: 15.625rem;
        line-height: 15.625rem
    }

    .xxl-fs-275 {
        font-size: 17.188rem;
        line-height: 17.188rem
    }

    .xxl-fs-300 {
        font-size: 18.75rem;
        line-height: 18.75rem
    }

    .xxl-w-100 {
        width: 100% !important
    }

    .xxl-outside-box-right-7 {
        margin-right: -7vw
    }
}

@media (max-width:1399px) {
    .xl-fs-0 {
        font-size: 0
    }

    .xl-fs-10 {
        font-size: 10px
    }

    .xl-fs-11 {
        font-size: 11px
    }

    .xl-fs-12 {
        font-size: 12px
    }

    .xl-fs-13 {
        font-size: 13px
    }

    .xl-fs-14 {
        font-size: 14px
    }

    .xl-fs-15 {
        font-size: 15px
    }

    .xl-fs-16 {
        font-size: 16px
    }

    .xl-fs-17 {
        font-size: 17px
    }

    .xl-fs-18 {
        font-size: 18px
    }

    .xl-fs-19 {
        font-size: 19px
    }

    .xl-fs-20 {
        font-size: 20px
    }

    .xl-fs-22 {
        font-size: 22px
    }

    .xl-fs-24 {
        font-size: 24px;
        line-height: 32px
    }

    .xl-fs-26 {
        font-size: 26px;
        line-height: 38px
    }

    .xl-fs-28 {
        font-size: 1.75rem;
        line-height: 2.6rem
    }

    .xl-fs-30 {
        font-size: 1.875rem;
        line-height: 2.8rem
    }

    .xl-fs-32 {
        font-size: 2rem;
        line-height: 2.5rem
    }

    .xl-fs-40 {
        font-size: 2.5rem;
        line-height: 2.5rem
    }

    .xl-fs-45 {
        font-size: 2.813rem;
        line-height: 3rem
    }

    .xl-fs-50 {
        font-size: 3.125rem;
        line-height: 3.25rem
    }

    .xl-fs-55 {
        font-size: 3.438rem;
        line-height: 3.5rem
    }

    .xl-fs-60 {
        font-size: 3.75rem;
        line-height: 3.75rem
    }

    .xl-fs-65 {
        font-size: 4.063rem;
        line-height: 4.688rem
    }

    .xl-fs-70 {
        font-size: 4.375rem;
        line-height: 4.375rem
    }

    .xl-fs-75 {
        font-size: 4.668rem;
        line-height: 4.668rem
    }

    .xl-fs-80 {
        font-size: 5rem;
        line-height: 5rem
    }

    .xl-fs-85 {
        font-size: 5.313rem;
        line-height: 5.313rem
    }

    .xl-fs-90 {
        font-size: 5.625rem;
        line-height: 5.625rem
    }

    .xl-fs-95 {
        font-size: 5.938rem;
        line-height: 5.938rem
    }

    .xl-fs-100 {
        font-size: 6.25rem;
        line-height: 6.25rem
    }

    .xl-fs-110 {
        font-size: 6.875rem;
        line-height: 6.875rem
    }

    .xl-fs-120 {
        font-size: 7.5rem;
        line-height: 7.5rem
    }

    .xl-fs-130 {
        font-size: 8.125rem;
        line-height: 8.125rem
    }

    .xl-fs-140 {
        font-size: 8.75rem;
        line-height: 8.75rem
    }

    .xl-fs-150 {
        font-size: 9.375rem;
        line-height: 9.375rem
    }

    .xl-fs-160 {
        font-size: 10rem;
        line-height: 10rem
    }

    .xl-fs-170 {
        font-size: 10.625rem;
        line-height: 10.625rem
    }

    .xl-fs-180 {
        font-size: 11.25rem;
        line-height: 11.25rem
    }

    .xl-fs-190 {
        font-size: 11.875rem;
        line-height: 11.875rem
    }

    .xl-fs-200 {
        font-size: 12.5rem;
        line-height: 12.5rem
    }

    .xl-fs-250 {
        font-size: 15.625rem;
        line-height: 15.625rem
    }

    .xl-fs-275 {
        font-size: 17.188rem;
        line-height: 17.188rem
    }

    .xl-fs-300 {
        font-size: 18.75rem;
        line-height: 18.75rem
    }

    .xl-lh-20 {
        line-height: 20px
    }

    .xl-lh-22 {
        line-height: 22px
    }

    .xl-lh-24 {
        line-height: 24px
    }

    .xl-lh-26 {
        line-height: 26px
    }

    .xl-lh-28 {
        line-height: 28px
    }

    .xl-lh-30 {
        line-height: 30px
    }

    .xl-lh-32 {
        line-height: 2rem
    }

    .xl-lh-34 {
        line-height: 2.125rem
    }

    .xl-lh-36 {
        line-height: 2.25rem
    }

    .xl-lh-38 {
        line-height: 2.375rem
    }

    .xl-lh-40 {
        line-height: 2.5rem
    }

    .xl-lh-42 {
        line-height: 2.625rem
    }

    .xl-lh-44 {
        line-height: 2.75rem
    }

    .xl-lh-46 {
        line-height: 2.875rem
    }

    .xl-lh-48 {
        line-height: 3rem
    }

    .xl-lh-50 {
        line-height: 3.125rem
    }

    .xl-lh-55 {
        line-height: 3.438rem
    }

    .xl-lh-60 {
        line-height: 3.75rem
    }

    .xl-lh-65 {
        line-height: 4.063rem
    }

    .xl-lh-70 {
        line-height: 4.375rem
    }

    .xl-lh-75 {
        line-height: 4.688rem
    }

    .xl-lh-80 {
        line-height: 5rem
    }

    .xl-lh-85 {
        line-height: 5.313rem
    }

    .xl-lh-90 {
        line-height: 5.625rem
    }

    .xl-lh-95 {
        line-height: 5.938rem
    }

    .xl-lh-100 {
        line-height: 6.25rem
    }

    .xl-lh-normal {
        line-height: normal
    }

    .xl-lh-initial {
        line-height: initial
    }

    .xl-w-220px {
        width: 220px !important
    }

    .xl-w-230px {
        width: 230px !important
    }

    .xl-w-40 {
        width: 40% !important
    }

    .xl-w-45 {
        width: 45% !important
    }

    .xl-w-50 {
        width: 50% !important
    }

    .xl-w-55 {
        width: 55% !important
    }

    .xl-w-60 {
        width: 60% !important
    }

    .xl-w-65 {
        width: 65% !important
    }

    .xl-w-70 {
        width: 70% !important
    }

    .xl-w-75 {
        width: 75% !important
    }

    .xl-w-80 {
        width: 80% !important
    }

    .xl-w-85 {
        width: 85% !important
    }

    .xl-w-90 {
        width: 90% !important
    }

    .xl-w-95 {
        width: 95% !important
    }

    .xl-w-100 {
        width: 100% !important
    }

    .xl-w-auto {
        width: auto !important
    }

    .xl-h-300px {
        height: 300px !important
    }

    .xl-h-350px {
        height: 350px !important
    }

    .xl-h-400px {
        height: 400px !important
    }

    .xl-h-450px {
        height: 450px !important
    }

    .xl-h-auto {
        height: auto !important
    }

    .xl-h-100 {
        height: 100% !important
    }

    .xl-border-top-0 {
        border-top: 0 !important
    }

    .xl-border-bottom-0 {
        border-bottom: 0 !important
    }

    .xl-border-end-0 {
        border-right: 0 !important
    }

    .xl-border-start-0 {
        border-left: 0 !important
    }

    .xl-ls-minus-05px {
        letter-spacing: -.5px !important
    }

    .xl-ls-minus-1px {
        letter-spacing: -1px !important
    }

    .xl-ls-minus-2px {
        letter-spacing: -2px !important
    }

    .xl-ls-minus-3px {
        letter-spacing: -3px !important
    }

    .xl-ls-minus-4px {
        letter-spacing: -4px !important
    }

    .xl-position-relative {
        position: relative !important
    }

    .xl-position-absolute {
        position: absolute !important
    }

    .xl-position-inherit {
        position: inherit !important
    }

    .xl-position-initial {
        position: initial !important
    }

    .xl-outside-box-left-10 {
        margin-left: -10vw
    }

    .xl-outside-box-left-15 {
        margin-left: -15vw
    }

    .xl-outside-box-left-20 {
        margin-left: -20vw
    }

    .xl-outside-box-left-30 {
        margin-left: -30vw
    }

    .xl-outside-box-right-5 {
        margin-right: -5vw
    }

    .xl-outside-box-right-10 {
        margin-right: -10vw
    }

    .xl-outside-box-right-15 {
        margin-right: -15vw
    }

    .xl-outside-box-right-20 {
        margin-right: -20vw
    }

    .xl-outside-box-right-25 {
        margin-right: -25vw
    }

    .xl-outside-box-right-30 {
        margin-right: -30vw
    }

    .theme-demos .demos-wrapper .demos-wrapper-inner {
        padding-left: 0;
        padding-right: 0
    }
}

@media (max-width:1199px) {
    .lg-fs-0 {
        font-size: 0
    }

    .lg-fs-10 {
        font-size: 10px
    }

    .lg-fs-11 {
        font-size: 11px
    }

    .lg-fs-12 {
        font-size: 12px
    }

    .lg-fs-13 {
        font-size: 13px
    }

    .lg-fs-14 {
        font-size: 14px
    }

    .lg-fs-15 {
        font-size: 15px
    }

    .lg-fs-16 {
        font-size: 16px
    }

    .lg-fs-17 {
        font-size: 17px
    }

    .lg-fs-18 {
        font-size: 18px
    }

    .lg-fs-19 {
        font-size: 19px
    }

    .lg-fs-20 {
        font-size: 20px
    }

    .lg-fs-22 {
        font-size: 22px
    }

    .lg-fs-24 {
        font-size: 24px;
        line-height: 32px
    }

    .lg-fs-26 {
        font-size: 26px;
        line-height: 38px
    }

    .lg-fs-28 {
        font-size: 1.75rem;
        line-height: 2.6rem
    }

    .lg-fs-30 {
        font-size: 1.875rem;
        line-height: 2.8rem
    }

    .lg-fs-32 {
        font-size: 2rem;
        line-height: 2.5rem
    }

    .lg-fs-40 {
        font-size: 2.5rem;
        line-height: 2.5rem
    }

    .lg-fs-45 {
        font-size: 2.813rem;
        line-height: 3rem
    }

    .lg-fs-50 {
        font-size: 3.125rem;
        line-height: 3.25rem
    }

    .lg-fs-55 {
        font-size: 3.438rem;
        line-height: 3.5rem
    }

    .lg-fs-60 {
        font-size: 3.75rem;
        line-height: 3.75rem
    }

    .lg-fs-65 {
        font-size: 4.063rem;
        line-height: 4.688rem
    }

    .lg-fs-70 {
        font-size: 4.375rem;
        line-height: 4.375rem
    }

    .lg-fs-75 {
        font-size: 4.668rem;
        line-height: 4.668rem
    }

    .lg-fs-80 {
        font-size: 5rem;
        line-height: 5rem
    }

    .lg-fs-85 {
        font-size: 5.313rem;
        line-height: 5.313rem
    }

    .lg-fs-90 {
        font-size: 5.625rem;
        line-height: 5.625rem
    }

    .lg-fs-95 {
        font-size: 5.938rem;
        line-height: 5.938rem
    }

    .lg-fs-100 {
        font-size: 6.25rem;
        line-height: 6.25rem
    }

    .lg-fs-110 {
        font-size: 6.875rem;
        line-height: 6.875rem
    }

    .lg-fs-120 {
        font-size: 7.5rem;
        line-height: 7.5rem
    }

    .lg-fs-130 {
        font-size: 8.125rem;
        line-height: 8.125rem
    }

    .lg-fs-140 {
        font-size: 8.75rem;
        line-height: 8.75rem
    }

    .lg-fs-150 {
        font-size: 9.375rem;
        line-height: 9.375rem
    }

    .lg-fs-160 {
        font-size: 10rem;
        line-height: 10rem
    }

    .lg-fs-170 {
        font-size: 10.625rem;
        line-height: 10.625rem
    }

    .lg-fs-180 {
        font-size: 11.25rem;
        line-height: 11.25rem
    }

    .lg-fs-190 {
        font-size: 11.875rem;
        line-height: 11.875rem
    }

    .lg-fs-200 {
        font-size: 12.5rem;
        line-height: 12.5rem
    }

    .lg-fs-250 {
        font-size: 15.625rem;
        line-height: 15.625rem
    }

    .lg-fs-275 {
        font-size: 17.188rem;
        line-height: 17.188rem
    }

    .lg-fs-300 {
        font-size: 18.75rem;
        line-height: 18.75rem
    }

    .lg-lh-20 {
        line-height: 20px
    }

    .lg-lh-22 {
        line-height: 22px
    }

    .lg-lh-24 {
        line-height: 24px
    }

    .lg-lh-26 {
        line-height: 26px
    }

    .lg-lh-28 {
        line-height: 28px
    }

    .lg-lh-30 {
        line-height: 30px
    }

    .lg-lh-32 {
        line-height: 2rem
    }

    .lg-lh-34 {
        line-height: 2.125rem
    }

    .lg-lh-36 {
        line-height: 2.25rem
    }

    .lg-lh-38 {
        line-height: 2.375rem
    }

    .lg-lh-40 {
        line-height: 2.5rem
    }

    .lg-lh-42 {
        line-height: 2.625rem
    }

    .lg-lh-44 {
        line-height: 2.75rem
    }

    .lg-lh-46 {
        line-height: 2.875rem
    }

    .lg-lh-48 {
        line-height: 3rem
    }

    .lg-lh-50 {
        line-height: 3.125rem
    }

    .lg-lh-55 {
        line-height: 3.438rem
    }

    .lg-lh-60 {
        line-height: 3.75rem
    }

    .lg-lh-65 {
        line-height: 4.063rem
    }

    .lg-lh-70 {
        line-height: 4.375rem
    }

    .lg-lh-75 {
        line-height: 4.688rem
    }

    .lg-lh-80 {
        line-height: 5rem
    }

    .lg-lh-85 {
        line-height: 5.313rem
    }

    .lg-lh-90 {
        line-height: 5.625rem
    }

    .lg-lh-95 {
        line-height: 5.938rem
    }

    .lg-lh-100 {
        line-height: 6.25rem
    }

    .lg-lh-normal {
        line-height: normal
    }

    .lg-lh-initial {
        line-height: initial
    }
}

@media (max-width:991px) {
    .md-fs-0 {
        font-size: 0
    }

    .md-fs-10 {
        font-size: 10px
    }

    .md-fs-11 {
        font-size: 11px
    }

    .md-fs-12 {
        font-size: 12px
    }

    .md-fs-13 {
        font-size: 13px
    }

    .md-fs-14 {
        font-size: 14px
    }

    .md-fs-15 {
        font-size: 15px
    }

    .md-fs-16 {
        font-size: 16px
    }

    .md-fs-17 {
        font-size: 17px
    }

    .md-fs-18 {
        font-size: 18px
    }

    .md-fs-19 {
        font-size: 19px
    }

    .md-fs-20 {
        font-size: 20px
    }

    .md-fs-22 {
        font-size: 22px
    }

    .md-fs-24 {
        font-size: 24px;
        line-height: 32px
    }

    .md-fs-26 {
        font-size: 26px;
        line-height: 38px
    }

    .md-fs-28 {
        font-size: 1.75rem;
        line-height: 2.6rem
    }

    .md-fs-30 {
        font-size: 1.875rem;
        line-height: 2.8rem
    }

    .md-fs-32 {
        font-size: 2rem;
        line-height: 2.5rem
    }

    .md-fs-40 {
        font-size: 2.5rem;
        line-height: 2.5rem
    }

    .md-fs-45 {
        font-size: 2.813rem;
        line-height: 3rem
    }

    .md-fs-50 {
        font-size: 3.125rem;
        line-height: 3.25rem
    }

    .md-fs-55 {
        font-size: 3.438rem;
        line-height: 3.5rem
    }

    .md-fs-60 {
        font-size: 3.75rem;
        line-height: 3.75rem
    }

    .md-fs-65 {
        font-size: 4.063rem;
        line-height: 4.688rem
    }

    .md-fs-70 {
        font-size: 4.375rem;
        line-height: 4.375rem
    }

    .md-fs-75 {
        font-size: 4.668rem;
        line-height: 4.668rem
    }

    .md-fs-80 {
        font-size: 5rem;
        line-height: 5rem
    }

    .md-fs-85 {
        font-size: 5.313rem;
        line-height: 5.313rem
    }

    .md-fs-90 {
        font-size: 5.625rem;
        line-height: 5.625rem
    }

    .md-fs-95 {
        font-size: 5.938rem;
        line-height: 5.938rem
    }

    .md-fs-100 {
        font-size: 6.25rem;
        line-height: 6.25rem
    }

    .md-fs-110 {
        font-size: 6.875rem;
        line-height: 6.875rem
    }

    .md-fs-120 {
        font-size: 7.5rem;
        line-height: 7.5rem
    }

    .md-fs-130 {
        font-size: 8.125rem;
        line-height: 8.125rem
    }

    .md-fs-140 {
        font-size: 8.75rem;
        line-height: 8.75rem
    }

    .md-fs-150 {
        font-size: 9.375rem;
        line-height: 9.375rem
    }

    .md-fs-160 {
        font-size: 10rem;
        line-height: 10rem
    }

    .md-fs-170 {
        font-size: 10.625rem;
        line-height: 10.625rem
    }

    .md-fs-180 {
        font-size: 11.25rem;
        line-height: 11.25rem
    }

    .md-fs-190 {
        font-size: 11.875rem;
        line-height: 11.875rem
    }

    .md-fs-200 {
        font-size: 12.5rem;
        line-height: 12.5rem
    }

    .md-fs-250 {
        font-size: 15.625rem;
        line-height: 15.625rem
    }

    .md-fs-275 {
        font-size: 17.188rem;
        line-height: 17.188rem
    }

    .md-fs-300 {
        font-size: 18.75rem;
        line-height: 18.75rem
    }

    .md-lh-20 {
        line-height: 20px
    }

    .md-lh-22 {
        line-height: 22px
    }

    .md-lh-24 {
        line-height: 24px
    }

    .md-lh-26 {
        line-height: 26px
    }

    .md-lh-28 {
        line-height: 28px
    }

    .md-lh-30 {
        line-height: 30px
    }

    .md-lh-32 {
        line-height: 2rem
    }

    .md-lh-34 {
        line-height: 2.125rem
    }

    .md-lh-36 {
        line-height: 2.25rem
    }

    .md-lh-38 {
        line-height: 2.375rem
    }

    .md-lh-40 {
        line-height: 2.5rem
    }

    .md-lh-42 {
        line-height: 2.625rem
    }

    .md-lh-44 {
        line-height: 2.75rem
    }

    .md-lh-46 {
        line-height: 2.875rem
    }

    .md-lh-48 {
        line-height: 3rem
    }

    .md-lh-50 {
        line-height: 3.125rem
    }

    .md-lh-55 {
        line-height: 3.438rem
    }

    .md-lh-60 {
        line-height: 3.75rem
    }

    .md-lh-65 {
        line-height: 4.063rem
    }

    .md-lh-70 {
        line-height: 4.375rem
    }

    .md-lh-75 {
        line-height: 4.688rem
    }

    .md-lh-80 {
        line-height: 5rem
    }

    .md-lh-85 {
        line-height: 5.313rem
    }

    .md-lh-90 {
        line-height: 5.625rem
    }

    .md-lh-95 {
        line-height: 5.938rem
    }

    .md-lh-100 {
        line-height: 6.25rem
    }

    .md-lh-normal {
        line-height: normal
    }

    .md-lh-initial {
        line-height: initial
    }
}

@media (max-width:767px) {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 25px
    }

    .sm-fs-0 {
        font-size: 0
    }

    .sm-fs-10 {
        font-size: 10px
    }

    .sm-fs-11 {
        font-size: 11px
    }

    .sm-fs-12 {
        font-size: 12px
    }

    .sm-fs-13 {
        font-size: 13px
    }

    .sm-fs-14 {
        font-size: 14px
    }

    .sm-fs-15 {
        font-size: 15px
    }

    .sm-fs-16 {
        font-size: 16px
    }

    .sm-fs-17 {
        font-size: 17px
    }

    .sm-fs-18 {
        font-size: 18px
    }

    .sm-fs-19 {
        font-size: 19px
    }

    .sm-fs-20 {
        font-size: 20px
    }

    .sm-fs-22 {
        font-size: 22px
    }

    .sm-fs-24 {
        font-size: 24px;
        line-height: 32px
    }

    .sm-fs-26 {
        font-size: 26px;
        line-height: 38px
    }

    .sm-fs-28 {
        font-size: 1.75rem;
        line-height: 2.6rem
    }

    .sm-fs-30 {
        font-size: 1.875rem;
        line-height: 2.8rem
    }

    .sm-fs-32 {
        font-size: 2rem;
        line-height: 2.5rem
    }

    .sm-fs-40 {
        font-size: 2.5rem;
        line-height: 2.5rem
    }

    .sm-fs-45 {
        font-size: 2.813rem;
        line-height: 2.813rem
    }

    .sm-fs-50 {
        font-size: 3.125rem;
        line-height: 3.125rem
    }

    .sm-fs-55 {
        font-size: 3.438rem;
        line-height: 3.438rem
    }

    .sm-fs-60 {
        font-size: 3.75rem;
        line-height: 3.75rem
    }

    .sm-fs-65 {
        font-size: 4.063rem;
        line-height: 4.063rem
    }

    .sm-fs-70 {
        font-size: 4.375rem;
        line-height: 4.375rem
    }

    .sm-fs-75 {
        font-size: 4.668rem;
        line-height: 4.668rem
    }

    .sm-fs-80 {
        font-size: 5rem;
        line-height: 5rem
    }

    .sm-fs-85 {
        font-size: 5.313rem;
        line-height: 5.313rem
    }

    .sm-fs-90 {
        font-size: 5.625rem;
        line-height: 5.625rem
    }

    .sm-fs-95 {
        font-size: 5.938rem;
        line-height: 5.938rem
    }

    .sm-fs-100 {
        font-size: 6.25rem;
        line-height: 6.25rem
    }

    .sm-fs-110 {
        font-size: 6.875rem;
        line-height: 6.875rem
    }

    .sm-fs-120 {
        font-size: 7.5rem;
        line-height: 7.5rem
    }

    .sm-fs-130 {
        font-size: 8.125rem;
        line-height: 8.125rem
    }

    .sm-fs-140 {
        font-size: 8.75rem;
        line-height: 8.75rem
    }

    .sm-fs-150 {
        font-size: 9.375rem;
        line-height: 9.375rem
    }

    .sm-fs-160 {
        font-size: 10rem;
        line-height: 10rem
    }

    .sm-fs-170 {
        font-size: 10.625rem;
        line-height: 10.625rem
    }

    .sm-fs-180 {
        font-size: 11.25rem;
        line-height: 11.25rem
    }

    .sm-fs-190 {
        font-size: 11.875rem;
        line-height: 11.875rem
    }

    .sm-fs-200 {
        font-size: 12.5rem;
        line-height: 12.5rem
    }

    .sm-fs-250 {
        font-size: 15.625rem;
        line-height: 15.625rem
    }

    .sm-fs-275 {
        font-size: 17.188rem;
        line-height: 17.188rem
    }

    .sm-fs-300 {
        font-size: 18.75rem;
        line-height: 18.75rem
    }

    .sm-lh-20 {
        line-height: 20px
    }

    .sm-lh-22 {
        line-height: 22px
    }

    .sm-lh-24 {
        line-height: 24px
    }

    .sm-lh-26 {
        line-height: 26px
    }

    .sm-lh-28 {
        line-height: 28px
    }

    .sm-lh-30 {
        line-height: 30px
    }

    .sm-lh-32 {
        line-height: 2rem
    }

    .sm-lh-34 {
        line-height: 2.125rem
    }

    .sm-lh-36 {
        line-height: 2.25rem
    }

    .sm-lh-38 {
        line-height: 2.375rem
    }

    .sm-lh-40 {
        line-height: 2.5rem
    }

    .sm-lh-42 {
        line-height: 2.625rem
    }

    .sm-lh-44 {
        line-height: 2.75rem
    }

    .sm-lh-46 {
        line-height: 2.875rem
    }

    .sm-lh-48 {
        line-height: 3rem
    }

    .sm-lh-50 {
        line-height: 3.125rem
    }

    .sm-lh-55 {
        line-height: 3.438rem
    }

    .sm-lh-60 {
        line-height: 3.75rem
    }

    .sm-lh-65 {
        line-height: 4.063rem
    }

    .sm-lh-70 {
        line-height: 4.375rem
    }

    .sm-lh-75 {
        line-height: 4.688rem
    }

    .sm-lh-80 {
        line-height: 5rem
    }

    .sm-lh-85 {
        line-height: 5.313rem
    }

    .sm-lh-90 {
        line-height: 5.625rem
    }

    .sm-lh-95 {
        line-height: 5.938rem
    }

    .sm-lh-100 {
        line-height: 6.25rem
    }

    .sm-lh-normal {
        line-height: normal
    }

    .sm-lh-initial {
        line-height: initial
    }
}

@media (max-width:575px) {
    .xs-fs-0 {
        font-size: 0
    }

    .xs-fs-10 {
        font-size: 10px
    }

    .xs-fs-11 {
        font-size: 11px
    }

    .xs-fs-12 {
        font-size: 12px
    }

    .xs-fs-13 {
        font-size: 13px
    }

    .xs-fs-14 {
        font-size: 14px
    }

    .xs-fs-15 {
        font-size: 15px
    }

    .xs-fs-16 {
        font-size: 16px
    }

    .xs-fs-17 {
        font-size: 17px
    }

    .xs-fs-18 {
        font-size: 18px
    }

    .xs-fs-19 {
        font-size: 19px
    }

    .xs-fs-20 {
        font-size: 20px
    }

    .xs-fs-22 {
        font-size: 22px
    }

    .xs-fs-24 {
        font-size: 24px;
        line-height: 32px
    }

    .xs-fs-26 {
        font-size: 26px;
        line-height: 38px
    }

    .xs-fs-28 {
        font-size: 1.75rem;
        line-height: 2.6rem
    }

    .xs-fs-30 {
        font-size: 1.875rem;
        line-height: 2.8rem
    }

    .xs-fs-32 {
        font-size: 2rem;
        line-height: 2.5rem
    }

    .xs-fs-40 {
        font-size: 2.5rem;
        line-height: 2.5rem
    }

    .xs-fs-45 {
        font-size: 2.813rem;
        line-height: 3rem
    }

    .xs-fs-50 {
        font-size: 3.125rem;
        line-height: 3.125rem
    }

    .xs-fs-55 {
        font-size: 3.438rem;
        line-height: 3.438rem
    }

    .xs-fs-60 {
        font-size: 3.75rem;
        line-height: 3.75rem
    }

    .xs-fs-65 {
        font-size: 4.063rem;
        line-height: 4.688rem
    }

    .xs-fs-70 {
        font-size: 4.375rem;
        line-height: 4.375rem
    }

    .xs-fs-75 {
        font-size: 4.668rem;
        line-height: 4.668rem
    }

    .xs-fs-80 {
        font-size: 5rem;
        line-height: 5rem
    }

    .xs-fs-85 {
        font-size: 5.313rem;
        line-height: 5.313rem
    }

    .xs-fs-90 {
        font-size: 5.625rem;
        line-height: 5.625rem
    }

    .xs-fs-95 {
        font-size: 5.938rem;
        line-height: 5.938rem
    }

    .xs-fs-100 {
        font-size: 6.25rem;
        line-height: 6.25rem
    }

    .xs-fs-110 {
        font-size: 6.875rem;
        line-height: 6.875rem
    }

    .xs-fs-120 {
        font-size: 7.5rem;
        line-height: 7.5rem
    }

    .xs-fs-130 {
        font-size: 8.125rem;
        line-height: 8.125rem
    }

    .xs-fs-140 {
        font-size: 8.75rem;
        line-height: 8.75rem
    }

    .xs-fs-150 {
        font-size: 9.375rem;
        line-height: 9.375rem
    }

    .xs-fs-160 {
        font-size: 10rem;
        line-height: 10rem
    }

    .xs-fs-170 {
        font-size: 10.625rem;
        line-height: 10.625rem
    }

    .xs-fs-180 {
        font-size: 11.25rem;
        line-height: 11.25rem
    }

    .xs-fs-190 {
        font-size: 11.875rem;
        line-height: 11.875rem
    }

    .xs-fs-200 {
        font-size: 12.5rem;
        line-height: 12.5rem
    }

    .xs-fs-250 {
        font-size: 15.625rem;
        line-height: 15.625rem
    }

    .xs-fs-275 {
        font-size: 17.188rem;
        line-height: 17.188rem
    }

    .xs-fs-300 {
        font-size: 18.75rem;
        line-height: 18.75rem
    }

    .xs-lh-20 {
        line-height: 20px
    }

    .xs-lh-22 {
        line-height: 22px
    }

    .xs-lh-24 {
        line-height: 24px
    }

    .xs-lh-26 {
        line-height: 26px
    }

    .xs-lh-28 {
        line-height: 28px
    }

    .xs-lh-30 {
        line-height: 30px
    }

    .xs-lh-32 {
        line-height: 2rem
    }

    .xs-lh-34 {
        line-height: 2.125rem
    }

    .xs-lh-36 {
        line-height: 2.25rem
    }

    .xs-lh-38 {
        line-height: 2.375rem
    }

    .xs-lh-40 {
        line-height: 2.5rem
    }

    .xs-lh-42 {
        line-height: 2.625rem
    }

    .xs-lh-44 {
        line-height: 2.75rem
    }

    .xs-lh-46 {
        line-height: 2.875rem
    }

    .xs-lh-48 {
        line-height: 3rem
    }

    .xs-lh-50 {
        line-height: 3.125rem
    }

    .xs-lh-55 {
        line-height: 3.438rem
    }

    .xs-lh-60 {
        line-height: 3.75rem
    }

    .xs-lh-65 {
        line-height: 4.063rem
    }

    .xs-lh-70 {
        line-height: 4.375rem
    }

    .xs-lh-75 {
        line-height: 4.688rem
    }

    .xs-lh-80 {
        line-height: 5rem
    }

    .xs-lh-85 {
        line-height: 5.313rem
    }

    .xs-lh-90 {
        line-height: 5.625rem
    }

    .xs-lh-95 {
        line-height: 5.938rem
    }

    .xs-lh-100 {
        line-height: 6.25rem
    }

    .xs-lh-normal {
        line-height: normal
    }

    .xs-lh-initial {
        line-height: initial
    }
}

@media (max-width:1199px) {
    .one-fifth-screen {
        height: 750px !important
    }

    .lg-w-10px {
        width: 10px !important
    }

    .lg-w-15px {
        width: 15px !important
    }

    .lg-w-20px {
        width: 20px !important
    }

    .lg-w-25px {
        width: 25px !important
    }

    .lg-w-30px {
        width: 30px !important
    }

    .lg-w-35px {
        width: 35px !important
    }

    .lg-w-40px {
        width: 40px !important
    }

    .lg-w-45px {
        width: 45px !important
    }

    .lg-w-50px {
        width: 50px !important
    }

    .lg-w-55px {
        width: 55px !important
    }

    .lg-w-60px {
        width: 60px !important
    }

    .lg-w-65px {
        width: 65px !important
    }

    .lg-w-70px {
        width: 70px !important
    }

    .lg-w-75px {
        width: 75px !important
    }

    .lg-w-80px {
        width: 80px !important
    }

    .lg-w-85px {
        width: 85px !important
    }

    .lg-w-90px {
        width: 90px !important
    }

    .lg-w-95px {
        width: 95px !important
    }

    .lg-w-100px {
        width: 100px !important
    }

    .lg-w-110px {
        width: 110px !important
    }

    .lg-w-120px {
        width: 120px !important
    }

    .lg-w-130px {
        width: 130px !important
    }

    .lg-w-140px {
        width: 140px !important
    }

    .lg-w-150px {
        width: 150px !important
    }

    .lg-w-160px {
        width: 160px !important
    }

    .lg-w-170px {
        width: 170px !important
    }

    .lg-w-180px {
        width: 180px !important
    }

    .lg-w-190px {
        width: 190px !important
    }

    .lg-w-200px {
        width: 200px !important
    }

    .lg-w-250px {
        width: 250px !important
    }

    .lg-w-300px {
        width: 300px !important
    }

    .lg-w-350px {
        width: 350px !important
    }

    .lg-w-400px {
        width: 400px !important
    }

    .lg-w-450px {
        width: 450px !important
    }

    .lg-w-500px {
        width: 500px !important
    }

    .lg-w-550px {
        width: 550px !important
    }

    .lg-w-600px {
        width: 600px !important
    }

    .lg-w-650px {
        width: 650px !important
    }

    .lg-w-700px {
        width: 700px !important
    }

    .lg-w-750px {
        width: 750px !important
    }

    .lg-w-800px {
        width: 800px !important
    }

    .lg-w-850px {
        width: 850px !important
    }

    .lg-w-900px {
        width: 900px !important
    }

    .lg-w-950px {
        width: 950px !important
    }

    .lg-w-1000px {
        width: 1000px !important
    }

    .lg-w-10 {
        width: 10% !important
    }

    .lg-w-15 {
        width: 15% !important
    }

    .lg-w-20 {
        width: 20% !important
    }

    .lg-w-25 {
        width: 25% !important
    }

    .lg-w-30 {
        width: 30% !important
    }

    .lg-w-35 {
        width: 35% !important
    }

    .lg-w-40 {
        width: 40% !important
    }

    .lg-w-45 {
        width: 45% !important
    }

    .lg-w-50 {
        width: 50% !important
    }

    .lg-w-55 {
        width: 55% !important
    }

    .lg-w-60 {
        width: 60% !important
    }

    .lg-w-65 {
        width: 65% !important
    }

    .lg-w-70 {
        width: 70% !important
    }

    .lg-w-75 {
        width: 75% !important
    }

    .lg-w-80 {
        width: 80% !important
    }

    .lg-w-85 {
        width: 85% !important
    }

    .lg-w-90 {
        width: 90% !important
    }

    .lg-w-95 {
        width: 95% !important
    }

    .lg-w-100 {
        width: 100% !important
    }

    .lg-w-auto {
        width: auto !important
    }

    .lg-h-10px {
        height: 10px !important
    }

    .lg-h-15px {
        height: 15px !important
    }

    .lg-h-20px {
        height: 20px !important
    }

    .lg-h-25px {
        height: 25px !important
    }

    .lg-h-30px {
        height: 30px !important
    }

    .lg-h-35px {
        height: 35px !important
    }

    .lg-h-40px {
        height: 40px !important
    }

    .lg-h-45px {
        height: 45px !important
    }

    .lg-h-50px {
        height: 50px !important
    }

    .lg-h-55px {
        height: 55px !important
    }

    .lg-h-60px {
        height: 60px !important
    }

    .lg-h-65px {
        height: 65px !important
    }

    .lg-h-70px {
        height: 70px !important
    }

    .lg-h-75px {
        height: 75px !important
    }

    .lg-h-80px {
        height: 80px !important
    }

    .lg-h-85px {
        height: 85px !important
    }

    .lg-h-90px {
        height: 90px !important
    }

    .lg-h-95px {
        height: 95px !important
    }

    .lg-h-100px {
        height: 100px !important
    }

    .lg-h-110px {
        height: 110px !important
    }

    .lg-h-120px {
        height: 120px !important
    }

    .lg-h-130px {
        height: 130px !important
    }

    .lg-h-140px {
        height: 140px !important
    }

    .lg-h-150px {
        height: 150px !important
    }

    .lg-h-160px {
        height: 160px !important
    }

    .lg-h-170px {
        height: 170px !important
    }

    .lg-h-180px {
        height: 180px !important
    }

    .lg-h-190px {
        height: 190px !important
    }

    .lg-h-200px {
        height: 200px !important
    }

    .lg-h-250px {
        height: 250px !important
    }

    .lg-h-300px {
        height: 300px !important
    }

    .lg-h-350px {
        height: 350px !important
    }

    .lg-h-400px {
        height: 400px !important
    }

    .lg-h-450px {
        height: 450px !important
    }

    .lg-h-500px {
        height: 500px !important
    }

    .lg-h-550px {
        height: 550px !important
    }

    .lg-h-600px {
        height: 600px !important
    }

    .lg-h-650px {
        height: 650px !important
    }

    .lg-h-700px {
        height: 700px !important
    }

    .lg-h-750px {
        height: 750px !important
    }

    .lg-h-800px {
        height: 800px !important
    }

    .lg-h-850px {
        height: 850px !important
    }

    .lg-h-auto {
        height: auto !important
    }

    .lg-h-100 {
        height: 100% !important
    }

    .lg-ls-0px {
        letter-spacing: 0 !important
    }

    .lg-ls-minus-05px {
        letter-spacing: -.5px !important
    }

    .lg-ls-minus-1px {
        letter-spacing: -1px !important
    }

    .lg-ls-minus-2px {
        letter-spacing: -2px !important
    }

    .lg-ls-minus-3px {
        letter-spacing: -3px !important
    }

    .lg-ls-minus-4px {
        letter-spacing: -4px !important
    }

    .lg-ls-minus-5px {
        letter-spacing: -5px !important
    }

    .lg-border {
        border: 1px solid
    }

    .lg-border-top {
        border-top: 1px solid
    }

    .lg-border-bottom {
        border-bottom: 1px solid
    }

    .lg-border-start {
        border-left: 1px solid
    }

    .lg-border-end {
        border-right: 1px solid
    }

    .lg-border-top-0 {
        border-top: 0 !important
    }

    .lg-border-bottom-0 {
        border-bottom: 0 !important
    }

    .lg-border-end-0 {
        border-right: 0 !important
    }

    .lg-border-start-0 {
        border-left: 0 !important
    }

    .lg-no-border-radius {
        border-radius: 0 !important
    }

    .lg-position-relative {
        position: relative !important
    }

    .lg-position-absolute {
        position: absolute !important
    }

    .lg-position-inherit {
        position: inherit !important
    }

    .lg-position-initial {
        position: initial !important
    }

    .lg-slider-shadow-none {
        mask-image: none !important;
        -webkit-mask-image: none !important
    }

    .lg-background-image-none {
        background-image: none !important
    }

    .outside-box-top-15 {
        margin-top: -8vw
    }

    .lg-outside-box-left-0 {
        margin-left: 0
    }

    .lg-outside-box-left-2 {
        margin-left: -2vw
    }

    .lg-outside-box-left-5 {
        margin-left: -5vw
    }

    .lg-outside-box-left-10 {
        margin-left: -10vw
    }

    .lg-outside-box-left-20 {
        margin-left: -20vw
    }

    .lg-outside-box-left-30 {
        margin-left: -30vw
    }

    .lg-outside-box-left-40 {
        margin-left: -40vw
    }

    .lg-outside-box-left-50 {
        margin-left: -50vw
    }

    .lg-outside-box-right-0 {
        margin-right: 0
    }

    .lg-outside-box-right-2 {
        margin-right: -2vw
    }

    .lg-outside-box-right-5 {
        margin-right: -5vw
    }

    .lg-outside-box-right-7 {
        margin-right: -7vw
    }

    .lg-outside-box-right-10 {
        margin-right: -10vw
    }

    .lg-outside-box-right-20 {
        margin-right: -20vw
    }

    .lg-outside-box-right-30 {
        margin-right: -30vw
    }

    .lg-outside-box-right-40 {
        margin-right: -40vw
    }

    .lg-outside-box-right-50 {
        margin-right: -50vw
    }

    .lg-outside-box-right-60 {
        margin-right: -60vw
    }

    .lg-outside-box-right-65 {
        margin-right: -65vw
    }

    .lg-transform-none {
        transform: none !important
    }

    [data-parallax-liquid] .liquid-parallax {
        top: 0
    }

    .swiper-number-pagination-style-02 .swiper-number {
        right: 0;
        left: 0;
        width: 100%;
        text-align: center;
        top: inherit;
        transform: translate3d(0, 0, 0);
        bottom: 50px
    }

    .swiper-number-pagination-style-02 .swiper-number .swiper-pagination-bullet {
        margin: 0 10px !important;
        display: inline-block
    }

    .slider-navigation-style-03 {
        left: -15px
    }

    .slider-navigation-style-03.swiper-button-next {
        right: -15px
    }

    .stack-box {
        height: auto
    }

    .stack-box .stack-box-contain {
        height: auto;
        position: relative
    }

    .stack-box .stack-item {
        height: auto;
        position: relative;
        display: block
    }

    .stack-box .stack-item .stack-item-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        height: auto
    }

    .transform-3d {
        transform: translate3d(0, 0, 100px)
    }

    .lg-no-parallax [data-swiper-parallax] {
        transform: none !important
    }

    .theme-demos {
        display: none !important
    }
}

@media (max-width:991px) {
    .md-extra-small-screen {
        height: 300px !important
    }

    .md-small-screen {
        height: 400px !important
    }

    .md-one-half-screen {
        height: 600px !important
    }

    .md-one-third-screen {
        height: 700px !important
    }

    .md-one-fourth-screen {
        height: 800px !important
    }

    .md-one-fifth-screen {
        height: 900px !important
    }

    .one-third-screen {
        height: 550px !important
    }

    .one-fourth-screen {
        height: 650px !important
    }

    .md-w-5px {
        width: 5px !important
    }

    .md-w-10px {
        width: 10px !important
    }

    .md-w-15px {
        width: 15px !important
    }

    .md-w-20px {
        width: 20px !important
    }

    .md-w-25px {
        width: 25px !important
    }

    .md-w-30px {
        width: 30px !important
    }

    .md-w-35px {
        width: 35px !important
    }

    .md-w-40px {
        width: 40px !important
    }

    .md-w-45px {
        width: 45px !important
    }

    .md-w-50px {
        width: 50px !important
    }

    .md-w-55px {
        width: 55px !important
    }

    .md-w-60px {
        width: 60px !important
    }

    .md-w-65px {
        width: 65px !important
    }

    .md-w-70px {
        width: 70px !important
    }

    .md-w-75px {
        width: 75px !important
    }

    .md-w-80px {
        width: 80px !important
    }

    .md-w-85px {
        width: 85px !important
    }

    .md-w-90px {
        width: 90px !important
    }

    .md-w-95px {
        width: 95px !important
    }

    .md-w-100px {
        width: 100px !important
    }

    .md-w-110px {
        width: 110px !important
    }

    .md-w-120px {
        width: 120px !important
    }

    .md-w-130px {
        width: 130px !important
    }

    .md-w-140px {
        width: 140px !important
    }

    .md-w-150px {
        width: 150px !important
    }

    .md-w-160px {
        width: 160px !important
    }

    .md-w-170px {
        width: 170px !important
    }

    .md-w-180px {
        width: 180px !important
    }

    .md-w-190px {
        width: 190px !important
    }

    .md-w-200px {
        width: 200px !important
    }

    .md-w-210px {
        width: 210px !important
    }

    .md-w-220px {
        width: 220px !important
    }

    .md-w-230px {
        width: 230px !important
    }

    .md-w-240px {
        width: 240px !important
    }

    .md-w-250px {
        width: 250px !important
    }

    .md-w-260px {
        width: 260px !important
    }

    .md-w-270px {
        width: 270px !important
    }

    .md-w-280px {
        width: 280px !important
    }

    .md-w-290px {
        width: 290px !important
    }

    .md-w-300px {
        width: 300px !important
    }

    .md-w-350px {
        width: 350px !important
    }

    .md-w-400px {
        width: 400px !important
    }

    .md-w-450px {
        width: 450px !important
    }

    .md-w-500px {
        width: 500px !important
    }

    .md-w-550px {
        width: 550px !important
    }

    .md-w-600px {
        width: 600px !important
    }

    .md-w-650px {
        width: 650px !important
    }

    .md-w-700px {
        width: 700px !important
    }

    .md-w-750px {
        width: 750px !important
    }

    .md-w-800px {
        width: 800px !important
    }

    .md-w-850px {
        width: 850px !important
    }

    .md-w-900px {
        width: 900px !important
    }

    .md-w-950px {
        width: 950px !important
    }

    .md-w-1000px {
        width: 1000px !important
    }

    .md-w-10 {
        width: 10% !important
    }

    .md-w-15 {
        width: 15% !important
    }

    .md-w-20 {
        width: 20% !important
    }

    .md-w-25 {
        width: 25% !important
    }

    .md-w-30 {
        width: 30% !important
    }

    .md-w-35 {
        width: 35% !important
    }

    .md-w-40 {
        width: 40% !important
    }

    .md-w-45 {
        width: 45% !important
    }

    .md-w-50 {
        width: 50% !important
    }

    .md-w-55 {
        width: 55% !important
    }

    .md-w-60 {
        width: 60% !important
    }

    .md-w-65 {
        width: 65% !important
    }

    .md-w-70 {
        width: 70% !important
    }

    .md-w-75 {
        width: 75% !important
    }

    .md-w-80 {
        width: 80% !important
    }

    .md-w-85 {
        width: 85% !important
    }

    .md-w-90 {
        width: 90% !important
    }

    .md-w-95 {
        width: 95% !important
    }

    .md-w-100 {
        width: 100% !important
    }

    .md-w-auto {
        width: auto !important
    }

    .md-h-1px {
        height: 1px !important
    }

    .md-h-5px {
        height: 5px !important
    }

    .md-h-10px {
        height: 10px !important
    }

    .md-h-15px {
        height: 15px !important
    }

    .md-h-20px {
        height: 20px !important
    }

    .md-h-25px {
        height: 25px !important
    }

    .md-h-30px {
        height: 30px !important
    }

    .md-h-35px {
        height: 35px !important
    }

    .md-h-40px {
        height: 40px !important
    }

    .md-h-42px {
        height: 42px !important
    }

    .md-h-45px {
        height: 45px !important
    }

    .md-h-50px {
        height: 50px !important
    }

    .md-h-55px {
        height: 55px !important
    }

    .md-h-60px {
        height: 60px !important
    }

    .md-h-65px {
        height: 65px !important
    }

    .md-h-70px {
        height: 70px !important
    }

    .md-h-75px {
        height: 75px !important
    }

    .md-h-80px {
        height: 80px !important
    }

    .md-h-85px {
        height: 85px !important
    }

    .md-h-90px {
        height: 90px !important
    }

    .md-h-95px {
        height: 95px !important
    }

    .md-h-100px {
        height: 100px !important
    }

    .md-h-110px {
        height: 110px !important
    }

    .md-h-120px {
        height: 120px !important
    }

    .md-h-130px {
        height: 130px !important
    }

    .md-h-140px {
        height: 140px !important
    }

    .md-h-150px {
        height: 150px !important
    }

    .md-h-160px {
        height: 160px !important
    }

    .md-h-170px {
        height: 170px !important
    }

    .md-h-180px {
        height: 180px !important
    }

    .md-h-190px {
        height: 190px !important
    }

    .md-h-200px {
        height: 200px !important
    }

    .md-h-250px {
        height: 250px !important
    }

    .md-h-300px {
        height: 300px !important
    }

    .md-h-350px {
        height: 350px !important
    }

    .md-h-400px {
        height: 400px !important
    }

    .md-h-450px {
        height: 450px !important
    }

    .md-h-500px {
        height: 500px !important
    }

    .md-h-550px {
        height: 550px !important
    }

    .md-h-600px {
        height: 600px !important
    }

    .md-h-650px {
        height: 650px !important
    }

    .md-h-700px {
        height: 700px !important
    }

    .md-h-750px {
        height: 750px !important
    }

    .md-h-800px {
        height: 800px !important
    }

    .md-h-850px {
        height: 850px !important
    }

    .md-h-auto {
        height: auto !important
    }

    .md-h-100 {
        height: 100% !important
    }

    .md-ls-05px {
        letter-spacing: .5px !important
    }

    .md-ls-0px {
        letter-spacing: 0 !important
    }

    .md-ls-minus-05px {
        letter-spacing: -.5px !important
    }

    .md-ls-minus-1px {
        letter-spacing: -1px !important
    }

    .md-ls-minus-2px {
        letter-spacing: -2px !important
    }

    .md-ls-minus-3px {
        letter-spacing: -3px !important
    }

    .md-ls-minus-4px {
        letter-spacing: -4px !important
    }

    .md-ls-minus-5px {
        letter-spacing: -5px !important
    }

    .md-border {
        border: 1px solid
    }

    .md-border-top {
        border-top: 1px solid
    }

    .md-border-bottom {
        border-bottom: 1px solid
    }

    .md-border-start {
        border-left: 1px solid
    }

    .md-border-end {
        border-right: 1px solid
    }

    .md-border-top-0 {
        border-top: 0 !important
    }

    .md-border-bottom-0 {
        border-bottom: 0 !important
    }

    .md-border-end-0 {
        border-right: 0 !important
    }

    .md-border-start-0 {
        border-left: 0 !important
    }

    .outside-box-top-15 {
        margin-top: -8vw
    }

    .md-outside-box-left-2 {
        margin-left: -2vw
    }

    .md-outside-box-left-5 {
        margin-left: -5vw
    }

    .md-outside-box-left-7 {
        margin-left: -7vw
    }

    .md-outside-box-left-8 {
        margin-left: -8vw
    }

    .md-outside-box-left-10 {
        margin-left: -10vw
    }

    .md-outside-box-left-15 {
        margin-left: -15vw
    }

    .md-outside-box-left-20 {
        margin-left: -20vw
    }

    .md-outside-box-left-25 {
        margin-left: -25vw
    }

    .md-outside-box-left-30 {
        margin-left: -30vw
    }

    .md-outside-box-left-35 {
        margin-left: -35vw
    }

    .md-outside-box-left-40 {
        margin-left: -40vw
    }

    .md-outside-box-left-50 {
        margin-left: -50vw
    }

    .md-outside-box-left-60 {
        margin-left: -60vw
    }

    .md-outside-box-left-65 {
        margin-left: -65vw
    }

    .md-outside-box-right-2 {
        margin-right: -2vw
    }

    .md-outside-box-right-5 {
        margin-right: -5vw
    }

    .md-outside-box-right-7 {
        margin-right: -7vw
    }

    .md-outside-box-right-10 {
        margin-right: -10vw
    }

    .md-outside-box-right-15 {
        margin-right: -15vw
    }

    .md-outside-box-right-20 {
        margin-right: -20vw
    }

    .md-outside-box-right-25 {
        margin-right: -25vw
    }

    .md-outside-box-right-30 {
        margin-right: -30vw
    }

    .md-outside-box-right-35 {
        margin-right: -35vw
    }

    .md-outside-box-right-40 {
        margin-right: -40vw
    }

    .md-outside-box-right-50 {
        margin-right: -50vw
    }

    .md-outside-box-right-60 {
        margin-right: -60vw
    }

    .md-outside-box-right-65 {
        margin-right: -65vw
    }

    .md-border-radius-6px {
        border-radius: 6px !important
    }

    .md-background-image-none {
        background-image: none !important
    }

    .md-background-position-left-center {
        background-position: left center !important
    }

    .md-outside-box-right-0 {
        margin-right: 0
    }

    .md-outside-box-left-0 {
        margin-left: 0
    }

    .md-transform-none {
        transform: none !important
    }

    .md-position-relative {
        position: relative !important
    }

    .md-position-absolute {
        position: absolute !important
    }

    .md-position-inherit {
        position: inherit !important
    }

    .md-position-initial {
        position: initial !important
    }

    .md-slider-shadow-none {
        mask-image: none !important;
        -webkit-mask-image: none !important
    }

    .slider-navigation-style-01 {
        left: 30px
    }

    .slider-navigation-style-01.swiper-button-next {
        right: 30px
    }

    .slider-navigation-style-05 {
        left: -15%
    }

    .slider-navigation-style-05.swiper-button-next {
        right: -15%
    }

    .swiper-number-pagination-style-01 .swiper-number {
        bottom: 30px
    }

    .swiper-number-pagination-progress-vertical .swiper-pagination-wrapper {
        right: 0;
        top: inherit;
        transform: none;
        z-index: 99;
        bottom: 20px;
        left: 0
    }

    .swiper-number-pagination-progress-vertical .swiper-pagination-wrapper .swiper-progress {
        height: 2px;
        width: var(--swiper-progress, 0)
    }

    .swiper-number-pagination-progress-vertical .swiper-pagination-wrapper .pagination-progress-vertical {
        transform: none;
        writing-mode: inherit
    }

    .swiper-number-pagination-progress-vertical .swiper-pagination-wrapper .pagination-progress-vertical .swiper-pagination-progress {
        width: 200px;
        height: 2px
    }

    .swiper-number-pagination-progress-vertical .swiper-pagination-wrapper .number-prev {
        margin: 0 15px 0 0
    }

    .swiper-number-pagination-progress-vertical .swiper-pagination-wrapper .number-next {
        margin: 0 0 0 15px
    }

    .swiper-horizontal>.swiper-pagination-bullets {
        bottom: 28px
    }

    .md-absolute-middle-center {
        left: 50%;
        right: auto;
        top: 50%;
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%)
    }

    .slider-navigation-style-06 {
        left: 30px
    }

    .slider-navigation-style-06.swiper-button-next,
    .swiper-number-pagination-style-02 .swiper-number {
        right: 30px
    }

    .slider-navigation-style-07 {
        -webkit-transform: translateX(-190px);
        transform: translateX(-190px)
    }

    .slider-navigation-style-07.swiper-button-next {
        -webkit-transform: translate(-190px, -100%);
        transform: translate(-190px, -100%)
    }

    .md-no-parallax [data-swiper-parallax] {
        transform: none !important
    }

    .md-text-white-space-nowrap {
        white-space: nowrap
    }

    .md-text-white-space-normal {
        white-space: normal
    }

    .md-icon-extra-double-large {
        font-size: 80px
    }

    .md-icon-double-large {
        font-size: 60px
    }

    .md-icon-extra-large {
        font-size: 50px
    }

    .md-icon-large {
        font-size: 42px
    }

    .md-icon-medium {
        font-size: 34px
    }

    .md-icon-very-medium {
        font-size: 28px
    }

    .md-icon-extra-medium {
        font-size: 24px
    }

    .md-vertical-align-top {
        vertical-align: top
    }

    .swiper-button-prev.slider-custom-text-prev {
        padding-right: 30px
    }

    .swiper-button-next.slider-custom-text-next {
        padding-left: 30px
    }

    .looping-wrapper .el {
        height: 18vh;
        margin-top: -20vh
    }
}

@media (max-width:767px) {
    .sm-extra-small-screen {
        height: 300px !important
    }

    .sm-small-screen {
        height: 400px !important
    }

    .sm-one-half-screen {
        height: 600px !important
    }

    .sm-one-third-screen {
        height: 700px !important
    }

    .sm-one-fourth-screen {
        height: 800px !important
    }

    .sm-one-fifth-screen {
        height: 900px !important
    }

    .small-screen {
        height: 300px !important
    }

    .extra-small-screen {
        height: 250px !important
    }

    .one-third-screen {
        height: 450px !important
    }

    .one-fourth-screen {
        height: 500px !important
    }

    .one-fifth-screen {
        height: 650px !important
    }

    .sm-w-5px {
        width: 5px !important
    }

    .sm-w-10px {
        width: 10px !important
    }

    .sm-w-15px {
        width: 15px !important
    }

    .sm-w-20px {
        width: 20px !important
    }

    .sm-w-25px {
        width: 25px !important
    }

    .sm-w-30px {
        width: 30px !important
    }

    .sm-w-35px {
        width: 35px !important
    }

    .sm-w-40px {
        width: 40px !important
    }

    .sm-w-45px {
        width: 45px !important
    }

    .sm-w-50px {
        width: 50px !important
    }

    .sm-w-55px {
        width: 55px !important
    }

    .sm-w-60px {
        width: 60px !important
    }

    .sm-w-65px {
        width: 65px !important
    }

    .sm-w-70px {
        width: 70px !important
    }

    .sm-w-75px {
        width: 75px !important
    }

    .sm-w-80px {
        width: 80px !important
    }

    .sm-w-85px {
        width: 85px !important
    }

    .sm-w-90px {
        width: 90px !important
    }

    .sm-w-95px {
        width: 95px !important
    }

    .sm-w-100px {
        width: 100px !important
    }

    .sm-w-110px {
        width: 110px !important
    }

    .sm-w-120px {
        width: 120px !important
    }

    .sm-w-130px {
        width: 130px !important
    }

    .sm-w-140px {
        width: 140px !important
    }

    .sm-w-150px {
        width: 150px !important
    }

    .sm-w-160px {
        width: 160px !important
    }

    .sm-w-170px {
        width: 170px !important
    }

    .sm-w-180px {
        width: 180px !important
    }

    .sm-w-190px {
        width: 190px !important
    }

    .sm-w-200px {
        width: 200px !important
    }

    .sm-w-210px {
        width: 210px !important
    }

    .sm-w-220px {
        width: 220px !important
    }

    .sm-w-230px {
        width: 230px !important
    }

    .sm-w-240px {
        width: 240px !important
    }

    .sm-w-250px {
        width: 250px !important
    }

    .sm-w-260px {
        width: 260px !important
    }

    .sm-w-270px {
        width: 270px !important
    }

    .sm-w-280px {
        width: 280px !important
    }

    .sm-w-290px {
        width: 290px !important
    }

    .sm-w-300px {
        width: 300px !important
    }

    .sm-w-350px {
        width: 350px !important
    }

    .sm-w-400px {
        width: 400px !important
    }

    .sm-w-450px {
        width: 450px !important
    }

    .sm-w-500px {
        width: 500px !important
    }

    .sm-w-550px {
        width: 550px !important
    }

    .sm-w-600px {
        width: 600px !important
    }

    .sm-w-650px {
        width: 650px !important
    }

    .sm-w-700px {
        width: 700px !important
    }

    .sm-w-750px {
        width: 750px !important
    }

    .sm-w-800px {
        width: 800px !important
    }

    .sm-w-850px {
        width: 850px !important
    }

    .sm-w-900px {
        width: 900px !important
    }

    .sm-w-950px {
        width: 950px !important
    }

    .sm-w-1000px {
        width: 1000px !important
    }

    .sm-w-10 {
        width: 10% !important
    }

    .sm-w-15 {
        width: 15% !important
    }

    .sm-w-20 {
        width: 20% !important
    }

    .sm-w-25 {
        width: 25% !important
    }

    .sm-w-30 {
        width: 30% !important
    }

    .sm-w-35 {
        width: 35% !important
    }

    .sm-w-40 {
        width: 40% !important
    }

    .sm-w-45 {
        width: 45% !important
    }

    .sm-w-50 {
        width: 50% !important
    }

    .sm-w-55 {
        width: 55% !important
    }

    .sm-w-60 {
        width: 60% !important
    }

    .sm-w-65 {
        width: 65% !important
    }

    .sm-w-70 {
        width: 70% !important
    }

    .sm-w-75 {
        width: 75% !important
    }

    .sm-w-80 {
        width: 80% !important
    }

    .sm-w-85 {
        width: 85% !important
    }

    .sm-w-90 {
        width: 90% !important
    }

    .sm-w-95 {
        width: 95% !important
    }

    .sm-w-100 {
        width: 100% !important
    }

    .sm-w-auto {
        width: auto !important
    }

    .sm-h-5px {
        height: 5px !important
    }

    .sm-h-6px {
        height: 6px !important
    }

    .sm-h-7px {
        height: 7px !important
    }

    .sm-h-8px {
        height: 8px !important
    }

    .sm-h-9px {
        height: 9px !important
    }

    .sm-h-10px {
        height: 10px !important
    }

    .sm-h-15px {
        height: 15px !important
    }

    .sm-h-20px {
        height: 20px !important
    }

    .sm-h-25px {
        height: 25px !important
    }

    .sm-h-30px {
        height: 30px !important
    }

    .sm-h-35px {
        height: 35px !important
    }

    .sm-h-40px {
        height: 40px !important
    }

    .sm-h-42px {
        height: 42px !important
    }

    .sm-h-45px {
        height: 45px !important
    }

    .sm-h-50px {
        height: 50px !important
    }

    .sm-h-55px {
        height: 55px !important
    }

    .sm-h-60px {
        height: 60px !important
    }

    .sm-h-65px {
        height: 65px !important
    }

    .sm-h-70px {
        height: 70px !important
    }

    .sm-h-75px {
        height: 75px !important
    }

    .sm-h-80px {
        height: 80px !important
    }

    .sm-h-85px {
        height: 85px !important
    }

    .sm-h-90px {
        height: 90px !important
    }

    .sm-h-95px {
        height: 95px !important
    }

    .sm-h-100px {
        height: 100px !important
    }

    .sm-h-110px {
        height: 110px !important
    }

    .sm-h-120px {
        height: 120px !important
    }

    .sm-h-130px {
        height: 130px !important
    }

    .sm-h-140px {
        height: 140px !important
    }

    .sm-h-150px {
        height: 150px !important
    }

    .sm-h-160px {
        height: 160px !important
    }

    .sm-h-170px {
        height: 170px !important
    }

    .sm-h-180px {
        height: 180px !important
    }

    .sm-h-190px {
        height: 190px !important
    }

    .sm-h-200px {
        height: 200px !important
    }

    .sm-h-250px {
        height: 250px !important
    }

    .sm-h-300px {
        height: 300px !important
    }

    .sm-h-350px {
        height: 350px !important
    }

    .sm-h-400px {
        height: 400px !important
    }

    .sm-h-450px {
        height: 450px !important
    }

    .sm-h-500px {
        height: 500px !important
    }

    .sm-h-550px {
        height: 550px !important
    }

    .sm-h-600px {
        height: 600px !important
    }

    .sm-h-650px {
        height: 650px !important
    }

    .sm-h-700px {
        height: 700px !important
    }

    .sm-h-750px {
        height: 750px !important
    }

    .sm-h-800px {
        height: 800px !important
    }

    .sm-h-850px {
        height: 850px !important
    }

    .sm-h-auto {
        height: auto !important
    }

    .sm-h-100 {
        height: 100% !important
    }

    .sm-border {
        border: 1px solid
    }

    .sm-border-top {
        border-top: 1px solid
    }

    .sm-border-bottom {
        border-bottom: 1px solid
    }

    .sm-border-start {
        border-left: 1px solid
    }

    .sm-border-end {
        border-right: 1px solid
    }

    .sm-border-top-0 {
        border-top: 0 !important
    }

    .sm-border-bottom-0 {
        border-bottom: 0 !important
    }

    .sm-border-end-0 {
        border-right: 0 !important
    }

    .sm-border-start-0 {
        border-left: 0 !important
    }

    .sm-ls-05px {
        letter-spacing: .5px !important
    }

    .sm-ls-0px {
        letter-spacing: 0 !important
    }

    .sm-ls-1px {
        letter-spacing: 1px !important
    }

    .sm-ls-2px {
        letter-spacing: 2px !important
    }

    .sm-ls-3px {
        letter-spacing: 3px !important
    }

    .sm-ls-4px {
        letter-spacing: 4px !important
    }

    .sm-ls-5px {
        letter-spacing: 5px !important
    }

    .sm-ls-minus-05px {
        letter-spacing: -.5px !important
    }

    .sm-ls-minus-1px {
        letter-spacing: -1px !important
    }

    .sm-ls-minus-2px {
        letter-spacing: -2px !important
    }

    .sm-ls-minus-3px {
        letter-spacing: -3px !important
    }

    .sm-ls-minus-4px {
        letter-spacing: -4px !important
    }

    .sm-ls-minus-5px {
        letter-spacing: -5px !important
    }

    .sm-border-radius-0px {
        border-radius: 0 !important
    }

    .sm-border-radius-6px {
        border-radius: 6px !important
    }

    .sm-position-relative {
        position: relative !important
    }

    .sm-position-absolute {
        position: absolute !important
    }

    .sm-position-inherit {
        position: inherit !important
    }

    .sm-position-initial {
        position: initial !important
    }

    .sm-slider-shadow-none {
        mask-image: none !important;
        -webkit-mask-image: none !important
    }

    .slider-navigation-style-01 {
        left: 15px
    }

    .slider-navigation-style-03,
    .slider-navigation-style-05 {
        left: 25px
    }

    .slider-navigation-style-01.swiper-button-next {
        right: 15px
    }

    .slider-navigation-style-03.swiper-button-next,
    .slider-navigation-style-05.swiper-button-next {
        right: 25px
    }

    .slider-navigation-style-06 {
        left: 15px
    }

    .slider-navigation-style-06.swiper-button-next {
        right: 15px
    }

    .slider-navigation-style-07 {
        left: 0;
        bottom: initial;
        top: 50%;
        width: 50px;
        height: 50px;
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-transform: translateY(-13px);
        transform: translateY(-13px)
    }

    .slider-navigation-style-07.swiper-button-next {
        bottom: initial;
        right: 0;
        left: initial;
        -webkit-transform: translate(0, -100%);
        transform: translate(0, -100%)
    }

    .sm-no-parallax [data-swiper-parallax],
    .sm-transform-none {
        transform: none !important
    }

    .sm-background-image-none {
        background-image: none !important
    }

    .sm-background-position-left-center {
        background-position: left center !important
    }

    .sm-outside-box-left-2 {
        margin-left: -2vw
    }

    .sm-outside-box-left-5 {
        margin-left: -5vw
    }

    .sm-outside-box-left-7 {
        margin-left: -7vw
    }

    .sm-outside-box-left-8 {
        margin-left: -8vw
    }

    .sm-outside-box-left-10 {
        margin-left: -10vw
    }

    .sm-outside-box-left-15 {
        margin-left: -15vw
    }

    .sm-outside-box-left-20 {
        margin-left: -20vw
    }

    .sm-outside-box-left-25 {
        margin-left: -25vw
    }

    .sm-outside-box-left-30 {
        margin-left: -30vw
    }

    .sm-outside-box-left-35 {
        margin-left: -35vw
    }

    .sm-outside-box-left-40 {
        margin-left: -40vw
    }

    .sm-outside-box-left-50 {
        margin-left: -50vw
    }

    .sm-outside-box-left-60 {
        margin-left: -60vw
    }

    .sm-outside-box-left-65 {
        margin-left: -65vw
    }

    .sm-outside-box-right-2 {
        margin-right: -2vw
    }

    .sm-outside-box-right-5 {
        margin-right: -5vw
    }

    .sm-outside-box-right-7 {
        margin-right: -7vw
    }

    .sm-outside-box-right-10 {
        margin-right: -10vw
    }

    .sm-outside-box-right-15 {
        margin-right: -15vw
    }

    .sm-outside-box-right-20 {
        margin-right: -20vw
    }

    .sm-outside-box-right-25 {
        margin-right: -25vw
    }

    .sm-outside-box-right-30 {
        margin-right: -30vw
    }

    .sm-outside-box-right-35 {
        margin-right: -35vw
    }

    .sm-outside-box-right-40 {
        margin-right: -40vw
    }

    .sm-outside-box-right-50 {
        margin-right: -50vw
    }

    .sm-outside-box-right-60 {
        margin-right: -60vw
    }

    .sm-outside-box-right-65 {
        margin-right: -65vw
    }

    .sm-outside-box-right-0 {
        margin-right: 0
    }

    .sm-outside-box-left-0 {
        margin-left: 0
    }

    .sm-outside-box-top-0 {
        margin-top: 0
    }

    .video-icon-extra-large .video-icon {
        width: 100px;
        height: 100px;
        font-size: 28px
    }

    .video-icon-extra-large .video-icon .video-icon-sonar .video-icon-sonar-bfr {
        width: 150px;
        height: 150px
    }

    .video-icon-extra-large .video-icon .video-icon-sonar .video-icon-sonar-afr {
        width: 120px;
        height: 120px
    }

    .swiper-number-pagination-style-02 .swiper-number {
        right: 15px
    }

    .slider-navigation-style-07 {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }

    .sm-text-white-space-nowrap {
        white-space: nowrap
    }

    .sm-text-white-space-normal {
        white-space: normal
    }

    .sm-icon-extra-double-large {
        font-size: 80px
    }

    .sm-icon-double-large {
        font-size: 60px
    }

    .sm-icon-extra-large {
        font-size: 50px
    }

    .sm-icon-large {
        font-size: 42px
    }

    .sm-icon-medium {
        font-size: 34px
    }

    .sm-icon-very-medium {
        font-size: 28px
    }

    .sm-icon-extra-medium {
        font-size: 24px
    }

    .sm-vertical-align-top {
        vertical-align: top
    }

    .sm-vertical-title-inherit .title {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        writing-mode: inherit
    }

    .sm-feather-shadow-none {
        mask-image: none;
        -webkit-mask-image: none
    }

    .looping-wrapper .el {
        height: 145px;
        margin-top: -145px
    }

    .cookie-message {
        right: 15px;
        max-width: 330px
    }
}

@media (max-width:575px) {
    .xs-extra-small-screen {
        height: 300px !important
    }

    .xs-small-screen {
        height: 400px !important
    }

    .xs-one-half-screen {
        height: 600px !important
    }

    .xs-one-third-screen {
        height: 700px !important
    }

    .xs-one-fourth-screen {
        height: 800px !important
    }

    .xs-one-fifth-screen {
        height: 900px !important
    }

    .xs-w-5px {
        width: 5px !important
    }

    .xs-w-10px {
        width: 10px !important
    }

    .xs-w-15px {
        width: 15px !important
    }

    .xs-w-20px {
        width: 20px !important
    }

    .xs-w-25px {
        width: 25px !important
    }

    .xs-w-30px {
        width: 30px !important
    }

    .xs-w-35px {
        width: 35px !important
    }

    .xs-w-40px {
        width: 40px !important
    }

    .xs-w-45px {
        width: 45px !important
    }

    .xs-w-50px {
        width: 50px !important
    }

    .xs-w-55px {
        width: 55px !important
    }

    .xs-w-60px {
        width: 60px !important
    }

    .xs-w-65px {
        width: 65px !important
    }

    .xs-w-70px {
        width: 70px !important
    }

    .xs-w-75px {
        width: 75px !important
    }

    .xs-w-80px {
        width: 80px !important
    }

    .xs-w-85px {
        width: 85px !important
    }

    .xs-w-90px {
        width: 90px !important
    }

    .xs-w-95px {
        width: 95px !important
    }

    .xs-w-100px {
        width: 100px !important
    }

    .xs-w-110px {
        width: 110px !important
    }

    .xs-w-120px {
        width: 120px !important
    }

    .xs-w-130px {
        width: 130px !important
    }

    .xs-w-140px {
        width: 140px !important
    }

    .xs-w-150px {
        width: 150px !important
    }

    .xs-w-160px {
        width: 160px !important
    }

    .xs-w-170px {
        width: 170px !important
    }

    .xs-w-180px {
        width: 180px !important
    }

    .xs-w-190px {
        width: 190px !important
    }

    .xs-w-200px {
        width: 200px !important
    }

    .xs-w-210px {
        width: 210px !important
    }

    .xs-w-220px {
        width: 220px !important
    }

    .xs-w-230px {
        width: 230px !important
    }

    .xs-w-240px {
        width: 240px !important
    }

    .xs-w-250px {
        width: 250px !important
    }

    .xs-w-260px {
        width: 260px !important
    }

    .xs-w-270px {
        width: 270px !important
    }

    .xs-w-280px {
        width: 280px !important
    }

    .xs-w-290px {
        width: 290px !important
    }

    .xs-w-300px {
        width: 300px !important
    }

    .xs-w-350px {
        width: 350px !important
    }

    .xs-w-400px {
        width: 400px !important
    }

    .xs-w-450px {
        width: 450px !important
    }

    .xs-w-500px {
        width: 500px !important
    }

    .xs-w-550px {
        width: 550px !important
    }

    .xs-w-600px {
        width: 600px !important
    }

    .xs-w-650px {
        width: 650px !important
    }

    .xs-w-700px {
        width: 700px !important
    }

    .xs-w-750px {
        width: 750px !important
    }

    .xs-w-800px {
        width: 800px !important
    }

    .xs-w-850px {
        width: 850px !important
    }

    .xs-w-900px {
        width: 900px !important
    }

    .xs-w-950px {
        width: 950px !important
    }

    .xs-w-1000px {
        width: 1000px !important
    }

    .xs-w-10 {
        width: 10% !important
    }

    .xs-w-15 {
        width: 15% !important
    }

    .xs-w-20 {
        width: 20% !important
    }

    .xs-w-25 {
        width: 25% !important
    }

    .xs-w-30 {
        width: 30% !important
    }

    .xs-w-35 {
        width: 35% !important
    }

    .xs-w-40 {
        width: 40% !important
    }

    .xs-w-45 {
        width: 45% !important
    }

    .xs-w-50 {
        width: 50% !important
    }

    .xs-w-55 {
        width: 55% !important
    }

    .xs-w-60 {
        width: 60% !important
    }

    .xs-w-65 {
        width: 65% !important
    }

    .xs-w-70 {
        width: 70% !important
    }

    .xs-w-75 {
        width: 75% !important
    }

    .xs-w-80 {
        width: 80% !important
    }

    .xs-w-85 {
        width: 85% !important
    }

    .xs-w-90 {
        width: 90% !important
    }

    .xs-w-95 {
        width: 95% !important
    }

    .xs-w-100 {
        width: 100% !important
    }

    .xs-w-auto {
        width: auto !important
    }

    .xs-h-1px {
        height: 1px !important
    }

    .xs-h-2px {
        height: 2px !important
    }

    .xs-h-3px {
        height: 3px !important
    }

    .xs-h-4px {
        height: 4px !important
    }

    .xs-h-5px {
        height: 5px !important
    }

    .xs-h-6px {
        height: 6px !important
    }

    .xs-h-7px {
        height: 7px !important
    }

    .xs-h-8px {
        height: 8px !important
    }

    .xs-h-9px {
        height: 9px !important
    }

    .xs-h-10px {
        height: 10px !important
    }

    .xs-h-15px {
        height: 15px !important
    }

    .xs-h-20px {
        height: 20px !important
    }

    .xs-h-25px {
        height: 25px !important
    }

    .xs-h-30px {
        height: 30px !important
    }

    .xs-h-35px {
        height: 35px !important
    }

    .xs-h-40px {
        height: 40px !important
    }

    .xs-h-42px {
        height: 42px !important
    }

    .xs-h-45px {
        height: 45px !important
    }

    .xs-h-50px {
        height: 50px !important
    }

    .xs-h-55px {
        height: 55px !important
    }

    .xs-h-60px {
        height: 60px !important
    }

    .xs-h-65px {
        height: 65px !important
    }

    .xs-h-70px {
        height: 70px !important
    }

    .xs-h-75px {
        height: 75px !important
    }

    .xs-h-80px {
        height: 80px !important
    }

    .xs-h-85px {
        height: 85px !important
    }

    .xs-h-90px {
        height: 90px !important
    }

    .xs-h-95px {
        height: 95px !important
    }

    .xs-h-100px {
        height: 100px !important
    }

    .xs-h-110px {
        height: 110px !important
    }

    .xs-h-120px {
        height: 120px !important
    }

    .xs-h-130px {
        height: 130px !important
    }

    .xs-h-140px {
        height: 140px !important
    }

    .xs-h-150px {
        height: 150px !important
    }

    .xs-h-160px {
        height: 160px !important
    }

    .xs-h-170px {
        height: 170px !important
    }

    .xs-h-180px {
        height: 180px !important
    }

    .xs-h-190px {
        height: 190px !important
    }

    .xs-h-200px {
        height: 200px !important
    }

    .xs-h-250px {
        height: 250px !important
    }

    .xs-h-300px {
        height: 300px !important
    }

    .xs-h-350px {
        height: 350px !important
    }

    .xs-h-400px {
        height: 400px !important
    }

    .xs-h-450px {
        height: 450px !important
    }

    .xs-h-480px {
        height: 480px !important
    }

    .xs-h-500px {
        height: 500px !important
    }

    .xs-h-550px {
        height: 550px !important
    }

    .xs-h-600px {
        height: 600px !important
    }

    .xs-h-650px {
        height: 650px !important
    }

    .xs-h-700px {
        height: 700px !important
    }

    .xs-h-750px {
        height: 750px !important
    }

    .xs-h-800px {
        height: 800px !important
    }

    .xs-h-850px {
        height: 850px !important
    }

    .xs-h-auto {
        height: auto !important
    }

    .xs-h-100 {
        height: 100% !important
    }

    .xs-ls-05px {
        letter-spacing: .5px !important
    }

    .xs-ls-0px {
        letter-spacing: 0 !important
    }

    .xs-ls-1px {
        letter-spacing: 1px !important
    }

    .xs-ls-2px {
        letter-spacing: 2px !important
    }

    .xs-ls-3px {
        letter-spacing: 3px !important
    }

    .xs-ls-4px {
        letter-spacing: 4px !important
    }

    .xs-ls-5px {
        letter-spacing: 5px !important
    }

    .xs-ls-6px {
        letter-spacing: 6px !important
    }

    .xs-ls-7px {
        letter-spacing: 7px !important
    }

    .xs-ls-8px {
        letter-spacing: 8px !important
    }

    .xs-ls-9px {
        letter-spacing: 9px !important
    }

    .xs-ls-10px {
        letter-spacing: 10px !important
    }

    .xs-ls-minus-05px {
        letter-spacing: -.5px !important
    }

    .xs-ls-minus-1px {
        letter-spacing: -1px !important
    }

    .xs-ls-minus-2px {
        letter-spacing: -2px !important
    }

    .xs-ls-minus-3px {
        letter-spacing: -3px !important
    }

    .xs-ls-minus-4px {
        letter-spacing: -4px !important
    }

    .xs-ls-minus-5px {
        letter-spacing: -5px !important
    }

    .xs-ls-minus-6px {
        letter-spacing: -6px !important
    }

    .xs-ls-minus-7px {
        letter-spacing: -7px !important
    }

    .xs-ls-minus-8px {
        letter-spacing: -8px !important
    }

    .xs-ls-minus-9px {
        letter-spacing: -9px !important
    }

    .xs-ls-minus-10px {
        letter-spacing: -10px !important
    }

    .xs-border {
        border: 1px solid
    }

    .xs-border-radius-0,
    .xs-border-radius-0px {
        border-radius: 0 !important
    }

    .xs-border-top {
        border-top: 1px solid
    }

    .xs-border-bottom {
        border-bottom: 1px solid
    }

    .xs-border-start {
        border-left: 1px solid
    }

    .xs-border-end {
        border-right: 1px solid
    }

    .xs-border-top-0 {
        border-top: 0 !important
    }

    .xs-border-bottom-0 {
        border-bottom: 0 !important
    }

    .xs-border-end-0 {
        border-right: 0 !important
    }

    .xs-border-start-0 {
        border-left: 0 !important
    }

    .xs-position-relative {
        position: relative !important
    }

    .xs-position-absolute {
        position: absolute !important
    }

    .xs-position-inherit {
        position: inherit !important
    }

    .xs-position-initial {
        position: initial !important
    }

    .xs-background-image-none {
        background-image: none !important
    }

    .xs-background-position-left-center {
        background-position: left center !important
    }

    .xs-outside-box-right-0 {
        margin-right: 0
    }

    .xs-outside-box-left-0 {
        margin-left: 0
    }

    .xs-outside-box-top-0 {
        margin-top: 0
    }

    .xs-transform-none {
        transform: none !important
    }

    .xs-slider-shadow-none {
        mask-image: none !important;
        -webkit-mask-image: none !important
    }

    .slider-custom-image .slider-custom-image-pagination .swiper-pagination-bullet {
        width: 70px;
        height: 70px;
        margin: 0 10px !important
    }

    .swiper-number-pagination-style-02 .swiper-number {
        bottom: 25px
    }

    .swiper-number-pagination-style-02 .swiper-number .swiper-pagination-bullet {
        margin: 0 5px !important
    }

    .xs-text-white-space-nowrap {
        white-space: nowrap
    }

    .xs-text-white-space-normal {
        white-space: normal
    }

    .xs-icon-extra-double-large {
        font-size: 80px
    }

    .xs-icon-double-large {
        font-size: 60px
    }

    .xs-icon-extra-large {
        font-size: 50px
    }

    .xs-icon-large {
        font-size: 42px
    }

    .xs-icon-medium {
        font-size: 34px
    }

    .xs-icon-very-medium {
        font-size: 28px
    }

    .xs-icon-extra-medium {
        font-size: 24px
    }

    .xs-vertical-align-top {
        vertical-align: top
    }

    .xs-border-radius-30px {
        border-radius: 30px !important
    }

    .xs-no-border-radius {
        border-radius: 0 !important
    }

    .xs-vertical-title-inherit .title {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        writing-mode: inherit
    }

    .looping-wrapper .el {
        height: 105px;
        margin-top: -105px
    }
}

@media (max-width:1600px) {
    .xxl-p-5px {
        padding: 5px !important
    }

    .xxl-p-10px {
        padding: 10px !important
    }

    .xxl-p-15px {
        padding: 15px !important
    }

    .xxl-p-20px {
        padding: 20px !important
    }

    .xxl-p-25px {
        padding: 25px !important
    }

    .xxl-p-30px {
        padding: 30px !important
    }

    .xxl-p-35px {
        padding: 35px !important
    }

    .xxl-p-40px {
        padding: 40px !important
    }

    .xxl-p-45px {
        padding: 45px !important
    }

    .xxl-p-50px {
        padding: 50px !important
    }

    .xxl-p-55px {
        padding: 55px !important
    }

    .xxl-p-60px {
        padding: 60px !important
    }

    .xxl-p-65px {
        padding: 65px !important
    }

    .xxl-p-70px {
        padding: 70px !important
    }

    .xxl-p-1 {
        padding: 1% !important
    }

    .xxl-p-2 {
        padding: 2% !important
    }

    .xxl-p-3 {
        padding: 3% !important
    }

    .xxl-p-4 {
        padding: 4% !important
    }

    .xxl-p-5 {
        padding: 5% !important
    }

    .xxl-p-6 {
        padding: 6% !important
    }

    .xxl-p-7 {
        padding: 7% !important
    }

    .xxl-p-8 {
        padding: 8% !important
    }

    .xxl-p-9 {
        padding: 9% !important
    }

    .xxl-p-10 {
        padding: 10% !important
    }

    .xxl-p-11 {
        padding: 11% !important
    }

    .xxl-p-12 {
        padding: 12% !important
    }

    .xxl-p-13 {
        padding: 13% !important
    }

    .xxl-p-14 {
        padding: 14% !important
    }

    .xxl-p-15 {
        padding: 15% !important
    }

    .xxl-p-16 {
        padding: 16% !important
    }

    .xxl-p-17 {
        padding: 17% !important
    }

    .xxl-p-18 {
        padding: 18% !important
    }

    .xxl-p-19 {
        padding: 19% !important
    }

    .xxl-p-20 {
        padding: 20% !important
    }

    .xxl-p-21 {
        padding: 21% !important
    }

    .xxl-p-22 {
        padding: 22% !important
    }

    .xxl-p-23 {
        padding: 23% !important
    }

    .xxl-p-24 {
        padding: 24% !important
    }

    .xxl-p-25 {
        padding: 25% !important
    }

    .xxl-p-26 {
        padding: 26% !important
    }

    .xxl-p-27 {
        padding: 27% !important
    }

    .xxl-p-28 {
        padding: 28% !important
    }

    .xxl-p-29 {
        padding: 29% !important
    }

    .xxl-p-30 {
        padding: 30% !important
    }

    .xxl-pt-5px {
        padding-top: 5px !important
    }

    .xxl-pt-10px {
        padding-top: 10px !important
    }

    .xxl-pt-15px {
        padding-top: 15px !important
    }

    .xxl-pt-20px {
        padding-top: 20px !important
    }

    .xxl-pt-25px {
        padding-top: 25px !important
    }

    .xxl-pt-30px {
        padding-top: 30px !important
    }

    .xxl-pt-35px {
        padding-top: 35px !important
    }

    .xxl-pt-40px {
        padding-top: 40px !important
    }

    .xxl-pt-45px {
        padding-top: 45px !important
    }

    .xxl-pt-50px {
        padding-top: 50px !important
    }

    .xxl-pt-55px {
        padding-top: 55px !important
    }

    .xxl-pt-60px {
        padding-top: 60px !important
    }

    .xxl-pt-65px {
        padding-top: 65px !important
    }

    .xxl-pt-70px {
        padding-top: 70px !important
    }

    .xxl-pt-1 {
        padding-top: 1% !important
    }

    .xxl-pt-2 {
        padding-top: 2% !important
    }

    .xxl-pt-3 {
        padding-top: 3% !important
    }

    .xxl-pt-4 {
        padding-top: 4% !important
    }

    .xxl-pt-5 {
        padding-top: 5% !important
    }

    .xxl-pt-6 {
        padding-top: 6% !important
    }

    .xxl-pt-7 {
        padding-top: 7% !important
    }

    .xxl-pt-8 {
        padding-top: 8% !important
    }

    .xxl-pt-9 {
        padding-top: 9% !important
    }

    .xxl-pt-10 {
        padding-top: 10% !important
    }

    .xxl-pt-11 {
        padding-top: 11% !important
    }

    .xxl-pt-12 {
        padding-top: 12% !important
    }

    .xxl-pt-13 {
        padding-top: 13% !important
    }

    .xxl-pt-14 {
        padding-top: 14% !important
    }

    .xxl-pt-15 {
        padding-top: 15% !important
    }

    .xxl-pt-16 {
        padding-top: 16% !important
    }

    .xxl-pt-17 {
        padding-top: 17% !important
    }

    .xxl-pt-18 {
        padding-top: 18% !important
    }

    .xxl-pt-19 {
        padding-top: 19% !important
    }

    .xxl-pt-20 {
        padding-top: 20% !important
    }

    .xxl-pt-21 {
        padding-top: 21% !important
    }

    .xxl-pt-22 {
        padding-top: 22% !important
    }

    .xxl-pt-23 {
        padding-top: 23% !important
    }

    .xxl-pt-24 {
        padding-top: 24% !important
    }

    .xxl-pt-25 {
        padding-top: 25% !important
    }

    .xxl-pt-26 {
        padding-top: 26% !important
    }

    .xxl-pt-27 {
        padding-top: 27% !important
    }

    .xxl-pt-28 {
        padding-top: 28% !important
    }

    .xxl-pt-29 {
        padding-top: 29% !important
    }

    .xxl-pt-30 {
        padding-top: 30% !important
    }

    .xxl-pe-5px {
        padding-right: 5px !important
    }

    .xxl-pe-10px {
        padding-right: 10px !important
    }

    .xxl-pe-15px {
        padding-right: 15px !important
    }

    .xxl-pe-20px {
        padding-right: 20px !important
    }

    .xxl-pe-25px {
        padding-right: 25px !important
    }

    .xxl-pe-30px {
        padding-right: 30px !important
    }

    .xxl-pe-35px {
        padding-right: 35px !important
    }

    .xxl-pe-40px {
        padding-right: 40px !important
    }

    .xxl-pe-45px {
        padding-right: 45px !important
    }

    .xxl-pe-50px {
        padding-right: 50px !important
    }

    .xxl-pe-55px {
        padding-right: 55px !important
    }

    .xxl-pe-60px {
        padding-right: 60px !important
    }

    .xxl-pe-65px {
        padding-right: 65px !important
    }

    .xxl-pe-70px {
        padding-right: 70px !important
    }

    .xxl-pe-1 {
        padding-right: 1% !important
    }

    .xxl-pe-2 {
        padding-right: 2% !important
    }

    .xxl-pe-3 {
        padding-right: 3% !important
    }

    .xxl-pe-4 {
        padding-right: 4% !important
    }

    .xxl-pe-5 {
        padding-right: 5% !important
    }

    .xxl-pe-6 {
        padding-right: 6% !important
    }

    .xxl-pe-7 {
        padding-right: 7% !important
    }

    .xxl-pe-8 {
        padding-right: 8% !important
    }

    .xxl-pe-9 {
        padding-right: 9% !important
    }

    .xxl-pe-10 {
        padding-right: 10% !important
    }

    .xxl-pe-11 {
        padding-right: 11% !important
    }

    .xxl-pe-12 {
        padding-right: 12% !important
    }

    .xxl-pe-13 {
        padding-right: 13% !important
    }

    .xxl-pe-14 {
        padding-right: 14% !important
    }

    .xxl-pe-15 {
        padding-right: 15% !important
    }

    .xxl-pe-16 {
        padding-right: 16% !important
    }

    .xxl-pe-17 {
        padding-right: 17% !important
    }

    .xxl-pe-18 {
        padding-right: 18% !important
    }

    .xxl-pe-19 {
        padding-right: 19% !important
    }

    .xxl-pe-20 {
        padding-right: 20% !important
    }

    .xxl-pe-21 {
        padding-right: 21% !important
    }

    .xxl-pe-22 {
        padding-right: 22% !important
    }

    .xxl-pe-23 {
        padding-right: 23% !important
    }

    .xxl-pe-24 {
        padding-right: 24% !important
    }

    .xxl-pe-25 {
        padding-right: 25% !important
    }

    .xxl-pe-26 {
        padding-right: 26% !important
    }

    .xxl-pe-27 {
        padding-right: 27% !important
    }

    .xxl-pe-28 {
        padding-right: 28% !important
    }

    .xxl-pe-29 {
        padding-right: 29% !important
    }

    .xxl-pe-30 {
        padding-right: 30% !important
    }

    .xxl-pb-5px {
        padding-bottom: 5px !important
    }

    .xxl-pb-10px {
        padding-bottom: 10px !important
    }

    .xxl-pb-15px {
        padding-bottom: 15px !important
    }

    .xxl-pb-20px {
        padding-bottom: 20px !important
    }

    .xxl-pb-25px {
        padding-bottom: 25px !important
    }

    .xxl-pb-30px {
        padding-bottom: 30px !important
    }

    .xxl-pb-35px {
        padding-bottom: 35px !important
    }

    .xxl-pb-40px {
        padding-bottom: 40px !important
    }

    .xxl-pb-45px {
        padding-bottom: 45px !important
    }

    .xxl-pb-50px {
        padding-bottom: 50px !important
    }

    .xxl-pb-55px {
        padding-bottom: 55px !important
    }

    .xxl-pb-60px {
        padding-bottom: 60px !important
    }

    .xxl-pb-65px {
        padding-bottom: 65px !important
    }

    .xxl-pb-70px {
        padding-bottom: 70px !important
    }

    .xxl-pb-1 {
        padding-bottom: 1% !important
    }

    .xxl-pb-2 {
        padding-bottom: 2% !important
    }

    .xxl-pb-3 {
        padding-bottom: 3% !important
    }

    .xxl-pb-4 {
        padding-bottom: 4% !important
    }

    .xxl-pb-5 {
        padding-bottom: 5% !important
    }

    .xxl-pb-6 {
        padding-bottom: 6% !important
    }

    .xxl-pb-7 {
        padding-bottom: 7% !important
    }

    .xxl-pb-8 {
        padding-bottom: 8% !important
    }

    .xxl-pb-9 {
        padding-bottom: 9% !important
    }

    .xxl-pb-10 {
        padding-bottom: 10% !important
    }

    .xxl-pb-11 {
        padding-bottom: 11% !important
    }

    .xxl-pb-12 {
        padding-bottom: 12% !important
    }

    .xxl-pb-13 {
        padding-bottom: 13% !important
    }

    .xxl-pb-14 {
        padding-bottom: 14% !important
    }

    .xxl-pb-15 {
        padding-bottom: 15% !important
    }

    .xxl-pb-16 {
        padding-bottom: 16% !important
    }

    .xxl-pb-17 {
        padding-bottom: 17% !important
    }

    .xxl-pb-18 {
        padding-bottom: 18% !important
    }

    .xxl-pb-19 {
        padding-bottom: 19% !important
    }

    .xxl-pb-20 {
        padding-bottom: 20% !important
    }

    .xxl-pb-21 {
        padding-bottom: 21% !important
    }

    .xxl-pb-22 {
        padding-bottom: 22% !important
    }

    .xxl-pb-23 {
        padding-bottom: 23% !important
    }

    .xxl-pb-24 {
        padding-bottom: 24% !important
    }

    .xxl-pb-25 {
        padding-bottom: 25% !important
    }

    .xxl-pb-26 {
        padding-bottom: 26% !important
    }

    .xxl-pb-27 {
        padding-bottom: 27% !important
    }

    .xxl-pb-28 {
        padding-bottom: 28% !important
    }

    .xxl-pb-29 {
        padding-bottom: 29% !important
    }

    .xxl-pb-30 {
        padding-bottom: 30% !important
    }

    .xxl-ps-5px {
        padding-left: 5px !important
    }

    .xxl-ps-10px {
        padding-left: 10px !important
    }

    .xxl-ps-15px {
        padding-left: 15px !important
    }

    .xxl-ps-20px {
        padding-left: 20px !important
    }

    .xxl-ps-25px {
        padding-left: 25px !important
    }

    .xxl-ps-30px {
        padding-left: 30px !important
    }

    .xxl-ps-35px {
        padding-left: 35px !important
    }

    .xxl-ps-40px {
        padding-left: 40px !important
    }

    .xxl-ps-45px {
        padding-left: 45px !important
    }

    .xxl-ps-50px {
        padding-left: 50px !important
    }

    .xxl-ps-55px {
        padding-left: 55px !important
    }

    .xxl-ps-60px {
        padding-left: 60px !important
    }

    .xxl-ps-65px {
        padding-left: 65px !important
    }

    .xxl-ps-70px {
        padding-left: 70px !important
    }

    .xxl-ps-1 {
        padding-left: 1% !important
    }

    .xxl-ps-2 {
        padding-left: 2% !important
    }

    .xxl-ps-3 {
        padding-left: 3% !important
    }

    .xxl-ps-4 {
        padding-left: 4% !important
    }

    .xxl-ps-5 {
        padding-left: 5% !important
    }

    .xxl-ps-6 {
        padding-left: 6% !important
    }

    .xxl-ps-7 {
        padding-left: 7% !important
    }

    .xxl-ps-8 {
        padding-left: 8% !important
    }

    .xxl-ps-9 {
        padding-left: 9% !important
    }

    .xxl-ps-10 {
        padding-left: 10% !important
    }

    .xxl-ps-11 {
        padding-left: 11% !important
    }

    .xxl-ps-12 {
        padding-left: 12% !important
    }

    .xxl-ps-13 {
        padding-left: 13% !important
    }

    .xxl-ps-14 {
        padding-left: 14% !important
    }

    .xxl-ps-15 {
        padding-left: 15% !important
    }

    .xxl-ps-16 {
        padding-left: 16% !important
    }

    .xxl-ps-17 {
        padding-left: 17% !important
    }

    .xxl-ps-18 {
        padding-left: 18% !important
    }

    .xxl-ps-19 {
        padding-left: 19% !important
    }

    .xxl-ps-20 {
        padding-left: 20% !important
    }

    .xxl-ps-21 {
        padding-left: 21% !important
    }

    .xxl-ps-22 {
        padding-left: 22% !important
    }

    .xxl-ps-23 {
        padding-left: 23% !important
    }

    .xxl-ps-24 {
        padding-left: 24% !important
    }

    .xxl-ps-25 {
        padding-left: 25% !important
    }

    .xxl-ps-26 {
        padding-left: 26% !important
    }

    .xxl-ps-27 {
        padding-left: 27% !important
    }

    .xxl-ps-28 {
        padding-left: 28% !important
    }

    .xxl-ps-29 {
        padding-left: 29% !important
    }

    .xxl-ps-30 {
        padding-left: 30% !important
    }

    .xxl-m-5px {
        margin: 5px !important
    }

    .xxl-m-10px {
        margin: 10px !important
    }

    .xxl-m-15px {
        margin: 15px !important
    }

    .xxl-m-20px {
        margin: 20px !important
    }

    .xxl-m-25px {
        margin: 25px !important
    }

    .xxl-m-30px {
        margin: 30px !important
    }

    .xxl-m-35px {
        margin: 35px !important
    }

    .xxl-m-40px {
        margin: 40px !important
    }

    .xxl-m-45px {
        margin: 45px !important
    }

    .xxl-m-50px {
        margin: 50px !important
    }

    .xxl-m-55px {
        margin: 55px !important
    }

    .xxl-m-60px {
        margin: 60px !important
    }

    .xxl-m-65px {
        margin: 65px !important
    }

    .xxl-m-70px {
        margin: 70px !important
    }

    .xxl-m-1 {
        margin: 1% !important
    }

    .xxl-m-2 {
        margin: 2% !important
    }

    .xxl-m-3 {
        margin: 3% !important
    }

    .xxl-m-4 {
        margin: 4% !important
    }

    .xxl-m-5 {
        margin: 5% !important
    }

    .xxl-m-6 {
        margin: 6% !important
    }

    .xxl-m-7 {
        margin: 7% !important
    }

    .xxl-m-8 {
        margin: 8% !important
    }

    .xxl-m-9 {
        margin: 9% !important
    }

    .xxl-m-10 {
        margin: 10% !important
    }

    .xxl-m-11 {
        margin: 11% !important
    }

    .xxl-m-12 {
        margin: 12% !important
    }

    .xxl-m-13 {
        margin: 13% !important
    }

    .xxl-m-14 {
        margin: 14% !important
    }

    .xxl-m-15 {
        margin: 15% !important
    }

    .xxl-m-16 {
        margin: 16% !important
    }

    .xxl-m-17 {
        margin: 17% !important
    }

    .xxl-m-18 {
        margin: 18% !important
    }

    .xxl-m-19 {
        margin: 19% !important
    }

    .xxl-m-20 {
        margin: 20% !important
    }

    .xxl-m-21 {
        margin: 21% !important
    }

    .xxl-m-22 {
        margin: 22% !important
    }

    .xxl-m-23 {
        margin: 23% !important
    }

    .xxl-m-24 {
        margin: 24% !important
    }

    .xxl-m-25 {
        margin: 25% !important
    }

    .xxl-m-26 {
        margin: 26% !important
    }

    .xxl-m-27 {
        margin: 27% !important
    }

    .xxl-m-28 {
        margin: 28% !important
    }

    .xxl-m-29 {
        margin: 29% !important
    }

    .xxl-m-30 {
        margin: 30% !important
    }

    .xxl-mt-5px {
        margin-top: 5px !important
    }

    .xxl-mt-10px {
        margin-top: 10px !important
    }

    .xxl-mt-15px {
        margin-top: 15px !important
    }

    .xxl-mt-20px {
        margin-top: 20px !important
    }

    .xxl-mt-25px {
        margin-top: 25px !important
    }

    .xxl-mt-30px {
        margin-top: 30px !important
    }

    .xxl-mt-35px {
        margin-top: 35px !important
    }

    .xxl-mt-40px {
        margin-top: 40px !important
    }

    .xxl-mt-45px {
        margin-top: 45px !important
    }

    .xxl-mt-50px {
        margin-top: 50px !important
    }

    .xxl-mt-55px {
        margin-top: 55px !important
    }

    .xxl-mt-60px {
        margin-top: 60px !important
    }

    .xxl-mt-65px {
        margin-top: 65px !important
    }

    .xxl-mt-70px {
        margin-top: 70px !important
    }

    .xxl-mt-1 {
        margin-top: 1% !important
    }

    .xxl-mt-2 {
        margin-top: 2% !important
    }

    .xxl-mt-3 {
        margin-top: 3% !important
    }

    .xxl-mt-4 {
        margin-top: 4% !important
    }

    .xxl-mt-5 {
        margin-top: 5% !important
    }

    .xxl-mt-6 {
        margin-top: 6% !important
    }

    .xxl-mt-7 {
        margin-top: 7% !important
    }

    .xxl-mt-8 {
        margin-top: 8% !important
    }

    .xxl-mt-9 {
        margin-top: 9% !important
    }

    .xxl-mt-10 {
        margin-top: 10% !important
    }

    .xxl-mt-11 {
        margin-top: 11% !important
    }

    .xxl-mt-12 {
        margin-top: 12% !important
    }

    .xxl-mt-13 {
        margin-top: 13% !important
    }

    .xxl-mt-14 {
        margin-top: 14% !important
    }

    .xxl-mt-15 {
        margin-top: 15% !important
    }

    .xxl-mt-16 {
        margin-top: 16% !important
    }

    .xxl-mt-17 {
        margin-top: 17% !important
    }

    .xxl-mt-18 {
        margin-top: 18% !important
    }

    .xxl-mt-19 {
        margin-top: 19% !important
    }

    .xxl-mt-20 {
        margin-top: 20% !important
    }

    .xxl-mt-21 {
        margin-top: 21% !important
    }

    .xxl-mt-22 {
        margin-top: 22% !important
    }

    .xxl-mt-23 {
        margin-top: 23% !important
    }

    .xxl-mt-24 {
        margin-top: 24% !important
    }

    .xxl-mt-25 {
        margin-top: 25% !important
    }

    .xxl-mt-26 {
        margin-top: 26% !important
    }

    .xxl-mt-27 {
        margin-top: 27% !important
    }

    .xxl-mt-28 {
        margin-top: 28% !important
    }

    .xxl-mt-29 {
        margin-top: 29% !important
    }

    .xxl-mt-30 {
        margin-top: 30% !important
    }

    .xxl-me-5px {
        margin-right: 5px !important
    }

    .xxl-me-10px {
        margin-right: 10px !important
    }

    .xxl-me-15px {
        margin-right: 15px !important
    }

    .xxl-me-20px {
        margin-right: 20px !important
    }

    .xxl-me-25px {
        margin-right: 25px !important
    }

    .xxl-me-30px {
        margin-right: 30px !important
    }

    .xxl-me-35px {
        margin-right: 35px !important
    }

    .xxl-me-40px {
        margin-right: 40px !important
    }

    .xxl-me-45px {
        margin-right: 45px !important
    }

    .xxl-me-50px {
        margin-right: 50px !important
    }

    .xxl-me-55px {
        margin-right: 55px !important
    }

    .xxl-me-60px {
        margin-right: 60px !important
    }

    .xxl-me-65px {
        margin-right: 65px !important
    }

    .xxl-me-70px {
        margin-right: 70px !important
    }

    .xxl-me-1 {
        margin-right: 1% !important
    }

    .xxl-me-2 {
        margin-right: 2% !important
    }

    .xxl-me-3 {
        margin-right: 3% !important
    }

    .xxl-me-4 {
        margin-right: 4% !important
    }

    .xxl-me-5 {
        margin-right: 5% !important
    }

    .xxl-me-6 {
        margin-right: 6% !important
    }

    .xxl-me-7 {
        margin-right: 7% !important
    }

    .xxl-me-8 {
        margin-right: 8% !important
    }

    .xxl-me-9 {
        margin-right: 9% !important
    }

    .xxl-me-10 {
        margin-right: 10% !important
    }

    .xxl-me-11 {
        margin-right: 11% !important
    }

    .xxl-me-12 {
        margin-right: 12% !important
    }

    .xxl-me-13 {
        margin-right: 13% !important
    }

    .xxl-me-14 {
        margin-right: 14% !important
    }

    .xxl-me-15 {
        margin-right: 15% !important
    }

    .xxl-me-16 {
        margin-right: 16% !important
    }

    .xxl-me-17 {
        margin-right: 17% !important
    }

    .xxl-me-18 {
        margin-right: 18% !important
    }

    .xxl-me-19 {
        margin-right: 19% !important
    }

    .xxl-me-20 {
        margin-right: 20% !important
    }

    .xxl-me-21 {
        margin-right: 21% !important
    }

    .xxl-me-22 {
        margin-right: 22% !important
    }

    .xxl-me-23 {
        margin-right: 23% !important
    }

    .xxl-me-24 {
        margin-right: 24% !important
    }

    .xxl-me-25 {
        margin-right: 25% !important
    }

    .xxl-me-26 {
        margin-right: 26% !important
    }

    .xxl-me-27 {
        margin-right: 27% !important
    }

    .xxl-me-28 {
        margin-right: 28% !important
    }

    .xxl-me-29 {
        margin-right: 29% !important
    }

    .xxl-me-30 {
        margin-right: 30% !important
    }

    .xxl-mb-5px {
        margin-bottom: 5px !important
    }

    .xxl-mb-10px {
        margin-bottom: 10px !important
    }

    .xxl-mb-15px {
        margin-bottom: 15px !important
    }

    .xxl-mb-20px {
        margin-bottom: 20px !important
    }

    .xxl-mb-25px {
        margin-bottom: 25px !important
    }

    .xxl-mb-30px {
        margin-bottom: 30px !important
    }

    .xxl-mb-35px {
        margin-bottom: 35px !important
    }

    .xxl-mb-40px {
        margin-bottom: 40px !important
    }

    .xxl-mb-45px {
        margin-bottom: 45px !important
    }

    .xxl-mb-50px {
        margin-bottom: 50px !important
    }

    .xxl-mb-55px {
        margin-bottom: 55px !important
    }

    .xxl-mb-60px {
        margin-bottom: 60px !important
    }

    .xxl-mb-65px {
        margin-bottom: 65px !important
    }

    .xxl-mb-70px {
        margin-bottom: 70px !important
    }

    .xxl-mb-1 {
        margin-bottom: 1% !important
    }

    .xxl-mb-2 {
        margin-bottom: 2% !important
    }

    .xxl-mb-3 {
        margin-bottom: 3% !important
    }

    .xxl-mb-4 {
        margin-bottom: 4% !important
    }

    .xxl-mb-5 {
        margin-bottom: 5% !important
    }

    .xxl-mb-6 {
        margin-bottom: 6% !important
    }

    .xxl-mb-7 {
        margin-bottom: 7% !important
    }

    .xxl-mb-8 {
        margin-bottom: 8% !important
    }

    .xxl-mb-9 {
        margin-bottom: 9% !important
    }

    .xxl-mb-10 {
        margin-bottom: 10% !important
    }

    .xxl-mb-11 {
        margin-bottom: 11% !important
    }

    .xxl-mb-12 {
        margin-bottom: 12% !important
    }

    .xxl-mb-13 {
        margin-bottom: 13% !important
    }

    .xxl-mb-14 {
        margin-bottom: 14% !important
    }

    .xxl-mb-15 {
        margin-bottom: 15% !important
    }

    .xxl-mb-16 {
        margin-bottom: 16% !important
    }

    .xxl-mb-17 {
        margin-bottom: 17% !important
    }

    .xxl-mb-18 {
        margin-bottom: 18% !important
    }

    .xxl-mb-19 {
        margin-bottom: 19% !important
    }

    .xxl-mb-20 {
        margin-bottom: 20% !important
    }

    .xxl-mb-21 {
        margin-bottom: 21% !important
    }

    .xxl-mb-22 {
        margin-bottom: 22% !important
    }

    .xxl-mb-23 {
        margin-bottom: 23% !important
    }

    .xxl-mb-24 {
        margin-bottom: 24% !important
    }

    .xxl-mb-25 {
        margin-bottom: 25% !important
    }

    .xxl-mb-26 {
        margin-bottom: 26% !important
    }

    .xxl-mb-27 {
        margin-bottom: 27% !important
    }

    .xxl-mb-28 {
        margin-bottom: 28% !important
    }

    .xxl-mb-29 {
        margin-bottom: 29% !important
    }

    .xxl-mb-30 {
        margin-bottom: 30% !important
    }

    .xxl-ms-5px {
        margin-left: 5px !important
    }

    .xxl-ms-10px {
        margin-left: 10px !important
    }

    .xxl-ms-15px {
        margin-left: 15px !important
    }

    .xxl-ms-20px {
        margin-left: 20px !important
    }

    .xxl-ms-25px {
        margin-left: 25px !important
    }

    .xxl-ms-30px {
        margin-left: 30px !important
    }

    .xxl-ms-35px {
        margin-left: 35px !important
    }

    .xxl-ms-40px {
        margin-left: 40px !important
    }

    .xxl-ms-45px {
        margin-left: 45px !important
    }

    .xxl-ms-50px {
        margin-left: 50px !important
    }

    .xxl-ms-55px {
        margin-left: 55px !important
    }

    .xxl-ms-60px {
        margin-left: 60px !important
    }

    .xxl-ms-65px {
        margin-left: 65px !important
    }

    .xxl-ms-70px {
        margin-left: 70px !important
    }

    .xxl-ms-1 {
        margin-left: 1% !important
    }

    .xxl-ms-2 {
        margin-left: 2% !important
    }

    .xxl-ms-3 {
        margin-left: 3% !important
    }

    .xxl-ms-4 {
        margin-left: 4% !important
    }

    .xxl-ms-5 {
        margin-left: 5% !important
    }

    .xxl-ms-6 {
        margin-left: 6% !important
    }

    .xxl-ms-7 {
        margin-left: 7% !important
    }

    .xxl-ms-8 {
        margin-left: 8% !important
    }

    .xxl-ms-9 {
        margin-left: 9% !important
    }

    .xxl-ms-10 {
        margin-left: 10% !important
    }

    .xxl-ms-11 {
        margin-left: 11% !important
    }

    .xxl-ms-12 {
        margin-left: 12% !important
    }

    .xxl-ms-13 {
        margin-left: 13% !important
    }

    .xxl-ms-14 {
        margin-left: 14% !important
    }

    .xxl-ms-15 {
        margin-left: 15% !important
    }

    .xxl-ms-16 {
        margin-left: 16% !important
    }

    .xxl-ms-17 {
        margin-left: 17% !important
    }

    .xxl-ms-18 {
        margin-left: 18% !important
    }

    .xxl-ms-19 {
        margin-left: 19% !important
    }

    .xxl-ms-20 {
        margin-left: 20% !important
    }

    .xxl-ms-21 {
        margin-left: 21% !important
    }

    .xxl-ms-22 {
        margin-left: 22% !important
    }

    .xxl-ms-23 {
        margin-left: 23% !important
    }

    .xxl-ms-24 {
        margin-left: 24% !important
    }

    .xxl-ms-25 {
        margin-left: 25% !important
    }

    .xxl-ms-26 {
        margin-left: 26% !important
    }

    .xxl-ms-27 {
        margin-left: 27% !important
    }

    .xxl-ms-28 {
        margin-left: 28% !important
    }

    .xxl-ms-29 {
        margin-left: 29% !important
    }

    .xxl-ms-30 {
        margin-left: 30% !important
    }

    .xxl-top-0px {
        top: 0 !important
    }

    .xxl-top-1px {
        top: 1px !important
    }

    .xxl-top-2px {
        top: 2px !important
    }

    .xxl-top-3px {
        top: 3px !important
    }

    .xxl-top-4px {
        top: 4px !important
    }

    .xxl-top-5px {
        top: 5px !important
    }

    .xxl-top-6px {
        top: 6px !important
    }

    .xxl-top-7px {
        top: 7px !important
    }

    .xxl-top-8px {
        top: 8px !important
    }

    .xxl-top-9px {
        top: 9px !important
    }

    .xxl-top-10px {
        top: 10px !important
    }

    .xxl-top-11px {
        top: 11px !important
    }

    .xxl-top-12px {
        top: 12px !important
    }

    .xxl-top-13px {
        top: 13px !important
    }

    .xxl-top-14px {
        top: 14px !important
    }

    .xxl-top-15px {
        top: 15px !important
    }

    .xxl-top-16px {
        top: 16px !important
    }

    .xxl-top-17px {
        top: 17px !important
    }

    .xxl-top-18px {
        top: 18px !important
    }

    .xxl-top-19px {
        top: 19px !important
    }

    .xxl-top-20px {
        top: 20px !important
    }

    .xxl-top-21px {
        top: 21px !important
    }

    .xxl-top-22px {
        top: 22px !important
    }

    .xxl-top-23px {
        top: 23px !important
    }

    .xxl-top-24px {
        top: 24px !important
    }

    .xxl-top-25px {
        top: 25px !important
    }

    .xxl-top-26px {
        top: 26px !important
    }

    .xxl-top-27px {
        top: 27px !important
    }

    .xxl-top-28px {
        top: 28px !important
    }

    .xxl-top-29px {
        top: 29px !important
    }

    .xxl-top-30px {
        top: 30px !important
    }

    .xxl-top-40px {
        top: 40px !important
    }

    .xxl-top-50px {
        top: 50px !important
    }

    .xxl-top-60px {
        top: 60px !important
    }

    .xxl-top-70px {
        top: 70px !important
    }

    .xxl-top-80px {
        top: 80px !important
    }

    .xxl-top-90px {
        top: 90px !important
    }

    .xxl-top-100px {
        top: 100px !important
    }

    .xxl-top-110px {
        top: 110px !important
    }

    .xxl-top-120px {
        top: 120px !important
    }

    .xxl-top-130px {
        top: 130px !important
    }

    .xxl-top-140px {
        top: 140px !important
    }

    .xxl-top-150px {
        top: 150px !important
    }

    .xxl-right-0px {
        right: 0 !important
    }

    .xxl-right-1px {
        right: 1px !important
    }

    .xxl-right-2px {
        right: 2px !important
    }

    .xxl-right-3px {
        right: 3px !important
    }

    .xxl-right-4px {
        right: 4px !important
    }

    .xxl-right-5px {
        right: 5px !important
    }

    .xxl-right-6px {
        right: 6px !important
    }

    .xxl-right-7px {
        right: 7px !important
    }

    .xxl-right-8px {
        right: 8px !important
    }

    .xxl-right-9px {
        right: 9px !important
    }

    .xxl-right-10px {
        right: 10px !important
    }

    .xxl-right-11px {
        right: 11px !important
    }

    .xxl-right-12px {
        right: 12px !important
    }

    .xxl-right-13px {
        right: 13px !important
    }

    .xxl-right-14px {
        right: 14px !important
    }

    .xxl-right-15px {
        right: 15px !important
    }

    .xxl-right-16px {
        right: 16px !important
    }

    .xxl-right-17px {
        right: 17px !important
    }

    .xxl-right-18px {
        right: 18px !important
    }

    .xxl-right-19px {
        right: 19px !important
    }

    .xxl-right-20px {
        right: 20px !important
    }

    .xxl-right-21px {
        right: 21px !important
    }

    .xxl-right-22px {
        right: 22px !important
    }

    .xxl-right-23px {
        right: 23px !important
    }

    .xxl-right-24px {
        right: 24px !important
    }

    .xxl-right-25px {
        right: 25px !important
    }

    .xxl-right-26px {
        right: 26px !important
    }

    .xxl-right-27px {
        right: 27px !important
    }

    .xxl-right-28px {
        right: 28px !important
    }

    .xxl-right-29px {
        right: 29px !important
    }

    .xxl-right-30px {
        right: 30px !important
    }

    .xxl-right-40px {
        right: 40px !important
    }

    .xxl-right-50px {
        right: 50px !important
    }

    .xxl-right-60px {
        right: 60px !important
    }

    .xxl-right-70px {
        right: 70px !important
    }

    .xxl-right-80px {
        right: 80px !important
    }

    .xxl-right-90px {
        right: 90px !important
    }

    .xxl-right-100px {
        right: 100px !important
    }

    .xxl-right-110px {
        right: 110px !important
    }

    .xxl-right-120px {
        right: 120px !important
    }

    .xxl-right-130px {
        right: 130px !important
    }

    .xxl-right-140px {
        right: 140px !important
    }

    .xxl-right-150px {
        right: 150px !important
    }

    .xxl-bottom-0px {
        bottom: 0 !important
    }

    .xxl-bottom-1px {
        bottom: 1px !important
    }

    .xxl-bottom-2px {
        bottom: 2px !important
    }

    .xxl-bottom-3px {
        bottom: 3px !important
    }

    .xxl-bottom-4px {
        bottom: 4px !important
    }

    .xxl-bottom-5px {
        bottom: 5px !important
    }

    .xxl-bottom-6px {
        bottom: 6px !important
    }

    .xxl-bottom-7px {
        bottom: 7px !important
    }

    .xxl-bottom-8px {
        bottom: 8px !important
    }

    .xxl-bottom-9px {
        bottom: 9px !important
    }

    .xxl-bottom-10px {
        bottom: 10px !important
    }

    .xxl-bottom-11px {
        bottom: 11px !important
    }

    .xxl-bottom-12px {
        bottom: 12px !important
    }

    .xxl-bottom-13px {
        bottom: 13px !important
    }

    .xxl-bottom-14px {
        bottom: 14px !important
    }

    .xxl-bottom-15px {
        bottom: 15px !important
    }

    .xxl-bottom-16px {
        bottom: 16px !important
    }

    .xxl-bottom-17px {
        bottom: 17px !important
    }

    .xxl-bottom-18px {
        bottom: 18px !important
    }

    .xxl-bottom-19px {
        bottom: 19px !important
    }

    .xxl-bottom-20px {
        bottom: 20px !important
    }

    .xxl-bottom-21px {
        bottom: 21px !important
    }

    .xxl-bottom-22px {
        bottom: 22px !important
    }

    .xxl-bottom-23px {
        bottom: 23px !important
    }

    .xxl-bottom-24px {
        bottom: 24px !important
    }

    .xxl-bottom-25px {
        bottom: 25px !important
    }

    .xxl-bottom-26px {
        bottom: 26px !important
    }

    .xxl-bottom-27px {
        bottom: 27px !important
    }

    .xxl-bottom-28px {
        bottom: 28px !important
    }

    .xxl-bottom-29px {
        bottom: 29px !important
    }

    .xxl-bottom-30px {
        bottom: 30px !important
    }

    .xxl-bottom-40px {
        bottom: 40px !important
    }

    .xxl-bottom-50px {
        bottom: 50px !important
    }

    .xxl-bottom-60px {
        bottom: 60px !important
    }

    .xxl-bottom-70px {
        bottom: 70px !important
    }

    .xxl-bottom-80px {
        bottom: 80px !important
    }

    .xxl-bottom-90px {
        bottom: 90px !important
    }

    .xxl-bottom-100px {
        bottom: 100px !important
    }

    .xxl-bottom-110px {
        bottom: 110px !important
    }

    .xxl-bottom-120px {
        bottom: 120px !important
    }

    .xxl-bottom-130px {
        bottom: 130px !important
    }

    .xxl-bottom-140px {
        bottom: 140px !important
    }

    .xxl-bottom-150px {
        bottom: 150px !important
    }

    .xxl-left-0px {
        left: 0 !important
    }

    .xxl-left-1px {
        left: 1px !important
    }

    .xxl-left-2px {
        left: 2px !important
    }

    .xxl-left-3px {
        left: 3px !important
    }

    .xxl-left-4px {
        left: 4px !important
    }

    .xxl-left-5px {
        left: 5px !important
    }

    .xxl-left-6px {
        left: 6px !important
    }

    .xxl-left-7px {
        left: 7px !important
    }

    .xxl-left-8px {
        left: 8px !important
    }

    .xxl-left-9px {
        left: 9px !important
    }

    .xxl-left-10px {
        left: 10px !important
    }

    .xxl-left-11px {
        left: 11px !important
    }

    .xxl-left-12px {
        left: 12px !important
    }

    .xxl-left-13px {
        left: 13px !important
    }

    .xxl-left-14px {
        left: 14px !important
    }

    .xxl-left-15px {
        left: 15px !important
    }

    .xxl-left-16px {
        left: 16px !important
    }

    .xxl-left-17px {
        left: 17px !important
    }

    .xxl-left-18px {
        left: 18px !important
    }

    .xxl-left-19px {
        left: 19px !important
    }

    .xxl-left-20px {
        left: 20px !important
    }

    .xxl-left-21px {
        left: 21px !important
    }

    .xxl-left-22px {
        left: 22px !important
    }

    .xxl-left-23px {
        left: 23px !important
    }

    .xxl-left-24px {
        left: 24px !important
    }

    .xxl-left-25px {
        left: 25px !important
    }

    .xxl-left-26px {
        left: 26px !important
    }

    .xxl-left-27px {
        left: 27px !important
    }

    .xxl-left-28px {
        left: 28px !important
    }

    .xxl-left-29px {
        left: 29px !important
    }

    .xxl-left-30px {
        left: 30px !important
    }

    .xxl-left-40px {
        left: 40px !important
    }

    .xxl-left-50px {
        left: 50px !important
    }

    .xxl-left-60px {
        left: 60px !important
    }

    .xxl-left-70px {
        left: 70px !important
    }

    .xxl-left-80px {
        left: 80px !important
    }

    .xxl-left-90px {
        left: 90px !important
    }

    .xxl-left-100px {
        left: 100px !important
    }

    .xxl-left-110px {
        left: 110px !important
    }

    .xxl-left-120px {
        left: 120px !important
    }

    .xxl-left-130px {
        left: 130px !important
    }

    .xxl-left-140px {
        left: 140px !important
    }

    .xxl-left-150px {
        left: 150px !important
    }

    .xxl-top-minus-0px {
        top: 0 !important
    }

    .xxl-top-minus-1px {
        top: -1px !important
    }

    .xxl-top-minus-2px {
        top: -2px !important
    }

    .xxl-top-minus-3px {
        top: -3px !important
    }

    .xxl-top-minus-4px {
        top: -4px !important
    }

    .xxl-top-minus-5px {
        top: -5px !important
    }

    .xxl-top-minus-6px {
        top: -6px !important
    }

    .xxl-top-minus-7px {
        top: -7px !important
    }

    .xxl-top-minus-8px {
        top: -8px !important
    }

    .xxl-top-minus-9px {
        top: -9px !important
    }

    .xxl-top-minus-10px {
        top: -10px !important
    }

    .xxl-top-minus-11px {
        top: -11px !important
    }

    .xxl-top-minus-12px {
        top: -12px !important
    }

    .xxl-top-minus-13px {
        top: -13px !important
    }

    .xxl-top-minus-14px {
        top: -14px !important
    }

    .xxl-top-minus-15px {
        top: -15px !important
    }

    .xxl-top-minus-16px {
        top: -16px !important
    }

    .xxl-top-minus-17px {
        top: -17px !important
    }

    .xxl-top-minus-18px {
        top: -18px !important
    }

    .xxl-top-minus-19px {
        top: -19px !important
    }

    .xxl-top-minus-20px {
        top: -20px !important
    }

    .xxl-top-minus-21px {
        top: -21px !important
    }

    .xxl-top-minus-22px {
        top: -22px !important
    }

    .xxl-top-minus-23px {
        top: -23px !important
    }

    .xxl-top-minus-24px {
        top: -24px !important
    }

    .xxl-top-minus-25px {
        top: -25px !important
    }

    .xxl-top-minus-26px {
        top: -26px !important
    }

    .xxl-top-minus-27px {
        top: -27px !important
    }

    .xxl-top-minus-28px {
        top: -28px !important
    }

    .xxl-top-minus-29px {
        top: -29px !important
    }

    .xxl-top-minus-30px {
        top: -30px !important
    }

    .xxl-top-minus-40px {
        top: -40px !important
    }

    .xxl-top-minus-50px {
        top: -50px !important
    }

    .xxl-top-minus-60px {
        top: -60px !important
    }

    .xxl-top-minus-70px {
        top: -70px !important
    }

    .xxl-top-minus-80px {
        top: -80px !important
    }

    .xxl-top-minus-90px {
        top: -90px !important
    }

    .xxl-top-minus-100px {
        top: -100px !important
    }

    .xxl-top-minus-110px {
        top: -110px !important
    }

    .xxl-top-minus-120px {
        top: -120px !important
    }

    .xxl-top-minus-130px {
        top: -130px !important
    }

    .xxl-top-minus-140px {
        top: -140px !important
    }

    .xxl-top-minus-150px {
        top: -150px !important
    }

    .xxl-right-minus-0px {
        right: 0 !important
    }

    .xxl-right-minus-1px {
        right: -1px !important
    }

    .xxl-right-minus-2px {
        right: -2px !important
    }

    .xxl-right-minus-3px {
        right: -3px !important
    }

    .xxl-right-minus-4px {
        right: -4px !important
    }

    .xxl-right-minus-5px {
        right: -5px !important
    }

    .xxl-right-minus-6px {
        right: -6px !important
    }

    .xxl-right-minus-7px {
        right: -7px !important
    }

    .xxl-right-minus-8px {
        right: -8px !important
    }

    .xxl-right-minus-9px {
        right: -9px !important
    }

    .xxl-right-minus-10px {
        right: -10px !important
    }

    .xxl-right-minus-11px {
        right: -11px !important
    }

    .xxl-right-minus-12px {
        right: -12px !important
    }

    .xxl-right-minus-13px {
        right: -13px !important
    }

    .xxl-right-minus-14px {
        right: -14px !important
    }

    .xxl-right-minus-15px {
        right: -15px !important
    }

    .xxl-right-minus-16px {
        right: -16px !important
    }

    .xxl-right-minus-17px {
        right: -17px !important
    }

    .xxl-right-minus-18px {
        right: -18px !important
    }

    .xxl-right-minus-19px {
        right: -19px !important
    }

    .xxl-right-minus-20px {
        right: -20px !important
    }

    .xxl-right-minus-21px {
        right: -21px !important
    }

    .xxl-right-minus-22px {
        right: -22px !important
    }

    .xxl-right-minus-23px {
        right: -23px !important
    }

    .xxl-right-minus-24px {
        right: -24px !important
    }

    .xxl-right-minus-25px {
        right: -25px !important
    }

    .xxl-right-minus-26px {
        right: -26px !important
    }

    .xxl-right-minus-27px {
        right: -27px !important
    }

    .xxl-right-minus-28px {
        right: -28px !important
    }

    .xxl-right-minus-29px {
        right: -29px !important
    }

    .xxl-right-minus-30px {
        right: -30px !important
    }

    .xxl-right-minus-40px {
        right: -40px !important
    }

    .xxl-right-minus-50px {
        right: -50px !important
    }

    .xxl-right-minus-60px {
        right: -60px !important
    }

    .xxl-right-minus-70px {
        right: -70px !important
    }

    .xxl-right-minus-80px {
        right: -80px !important
    }

    .xxl-right-minus-90px {
        right: -90px !important
    }

    .xxl-right-minus-100px {
        right: -100px !important
    }

    .xxl-right-minus-110px {
        right: -110px !important
    }

    .xxl-right-minus-120px {
        right: -120px !important
    }

    .xxl-right-minus-130px {
        right: -130px !important
    }

    .xxl-right-minus-140px {
        right: -140px !important
    }

    .xxl-right-minus-150px {
        right: -150px !important
    }

    .xxl-bottom-minus-0px {
        bottom: 0 !important
    }

    .xxl-bottom-minus-1px {
        bottom: -1px !important
    }

    .xxl-bottom-minus-2px {
        bottom: -2px !important
    }

    .xxl-bottom-minus-3px {
        bottom: -3px !important
    }

    .xxl-bottom-minus-4px {
        bottom: -4px !important
    }

    .xxl-bottom-minus-5px {
        bottom: -5px !important
    }

    .xxl-bottom-minus-6px {
        bottom: -6px !important
    }

    .xxl-bottom-minus-7px {
        bottom: -7px !important
    }

    .xxl-bottom-minus-8px {
        bottom: -8px !important
    }

    .xxl-bottom-minus-9px {
        bottom: -9px !important
    }

    .xxl-bottom-minus-10px {
        bottom: -10px !important
    }

    .xxl-bottom-minus-11px {
        bottom: -11px !important
    }

    .xxl-bottom-minus-12px {
        bottom: -12px !important
    }

    .xxl-bottom-minus-13px {
        bottom: -13px !important
    }

    .xxl-bottom-minus-14px {
        bottom: -14px !important
    }

    .xxl-bottom-minus-15px {
        bottom: -15px !important
    }

    .xxl-bottom-minus-16px {
        bottom: -16px !important
    }

    .xxl-bottom-minus-17px {
        bottom: -17px !important
    }

    .xxl-bottom-minus-18px {
        bottom: -18px !important
    }

    .xxl-bottom-minus-19px {
        bottom: -19px !important
    }

    .xxl-bottom-minus-20px {
        bottom: -20px !important
    }

    .xxl-bottom-minus-21px {
        bottom: -21px !important
    }

    .xxl-bottom-minus-22px {
        bottom: -22px !important
    }

    .xxl-bottom-minus-23px {
        bottom: -23px !important
    }

    .xxl-bottom-minus-24px {
        bottom: -24px !important
    }

    .xxl-bottom-minus-25px {
        bottom: -25px !important
    }

    .xxl-bottom-minus-26px {
        bottom: -26px !important
    }

    .xxl-bottom-minus-27px {
        bottom: -27px !important
    }

    .xxl-bottom-minus-28px {
        bottom: -28px !important
    }

    .xxl-bottom-minus-29px {
        bottom: -29px !important
    }

    .xxl-bottom-minus-30px {
        bottom: -30px !important
    }

    .xxl-bottom-minus-40px {
        bottom: -40px !important
    }

    .xxl-bottom-minus-50px {
        bottom: -50px !important
    }

    .xxl-bottom-minus-60px {
        bottom: -60px !important
    }

    .xxl-bottom-minus-70px {
        bottom: -70px !important
    }

    .xxl-bottom-minus-80px {
        bottom: -80px !important
    }

    .xxl-bottom-minus-90px {
        bottom: -90px !important
    }

    .xxl-bottom-minus-100px {
        bottom: -100px !important
    }

    .xxl-bottom-minus-110px {
        bottom: -110px !important
    }

    .xxl-bottom-minus-120px {
        bottom: -120px !important
    }

    .xxl-bottom-minus-130px {
        bottom: -130px !important
    }

    .xxl-bottom-minus-140px {
        bottom: -140px !important
    }

    .xxl-bottom-minus-150px {
        bottom: -150px !important
    }

    .xxl-left-minus-0px {
        left: 0 !important
    }

    .xxl-left-minus-1px {
        left: -1px !important
    }

    .xxl-left-minus-2px {
        left: -2px !important
    }

    .xxl-left-minus-3px {
        left: -3px !important
    }

    .xxl-left-minus-4px {
        left: -4px !important
    }

    .xxl-left-minus-5px {
        left: -5px !important
    }

    .xxl-left-minus-6px {
        left: -6px !important
    }

    .xxl-left-minus-7px {
        left: -7px !important
    }

    .xxl-left-minus-8px {
        left: -8px !important
    }

    .xxl-left-minus-9px {
        left: -9px !important
    }

    .xxl-left-minus-10px {
        left: -10px !important
    }

    .xxl-left-minus-11px {
        left: -11px !important
    }

    .xxl-left-minus-12px {
        left: -12px !important
    }

    .xxl-left-minus-13px {
        left: -13px !important
    }

    .xxl-left-minus-14px {
        left: -14px !important
    }

    .xxl-left-minus-15px {
        left: -15px !important
    }

    .xxl-left-minus-16px {
        left: -16px !important
    }

    .xxl-left-minus-17px {
        left: -17px !important
    }

    .xxl-left-minus-18px {
        left: -18px !important
    }

    .xxl-left-minus-19px {
        left: -19px !important
    }

    .xxl-left-minus-20px {
        left: -20px !important
    }

    .xxl-left-minus-21px {
        left: -21px !important
    }

    .xxl-left-minus-22px {
        left: -22px !important
    }

    .xxl-left-minus-23px {
        left: -23px !important
    }

    .xxl-left-minus-24px {
        left: -24px !important
    }

    .xxl-left-minus-25px {
        left: -25px !important
    }

    .xxl-left-minus-26px {
        left: -26px !important
    }

    .xxl-left-minus-27px {
        left: -27px !important
    }

    .xxl-left-minus-28px {
        left: -28px !important
    }

    .xxl-left-minus-29px {
        left: -29px !important
    }

    .xxl-left-minus-30px {
        left: -30px !important
    }

    .xxl-left-minus-40px {
        left: -40px !important
    }

    .xxl-left-minus-50px {
        left: -50px !important
    }

    .xxl-left-minus-60px {
        left: -60px !important
    }

    .xxl-left-minus-70px {
        left: -70px !important
    }

    .xxl-left-minus-80px {
        left: -80px !important
    }

    .xxl-left-minus-90px {
        left: -90px !important
    }

    .xxl-left-minus-100px {
        left: -100px !important
    }

    .xxl-left-minus-110px {
        left: -110px !important
    }

    .xxl-left-minus-120px {
        left: -120px !important
    }

    .xxl-left-minus-130px {
        left: -130px !important
    }

    .xxl-left-minus-140px {
        left: -140px !important
    }

    .xxl-left-minus-150px {
        left: -150px !important
    }

    .xxl-border-width-0px {
        border-width: 0 !important
    }

    .xxl-border-width-1px {
        border-width: 1px !important
    }

    .xxl-border-width-2px {
        border-width: 2px !important
    }

    .xxl-border-width-3px {
        border-width: 3px !important
    }

    .xxl-border-width-4px {
        border-width: 4px !important
    }

    .xxl-border-width-5px {
        border-width: 5px !important
    }

    .xxl-border-width-6px {
        border-width: 6px !important
    }

    .xxl-border-width-7px {
        border-width: 7px !important
    }

    .xxl-border-width-8px {
        border-width: 8px !important
    }

    .xxl-border-width-9px {
        border-width: 9px !important
    }

    .xxl-border-width-10px {
        border-width: 10px !important
    }
}

@media (max-width:1399px) {
    .xl-p-5px {
        padding: 5px !important
    }

    .xl-p-10px {
        padding: 10px !important
    }

    .xl-p-15px {
        padding: 15px !important
    }

    .xl-p-20px {
        padding: 20px !important
    }

    .xl-p-25px {
        padding: 25px !important
    }

    .xl-p-30px {
        padding: 30px !important
    }

    .xl-p-35px {
        padding: 35px !important
    }

    .xl-p-40px {
        padding: 40px !important
    }

    .xl-p-45px {
        padding: 45px !important
    }

    .xl-p-50px {
        padding: 50px !important
    }

    .xl-p-55px {
        padding: 55px !important
    }

    .xl-p-60px {
        padding: 60px !important
    }

    .xl-p-65px {
        padding: 65px !important
    }

    .xl-p-70px {
        padding: 70px !important
    }

    .xl-p-1 {
        padding: 1% !important
    }

    .xl-p-2 {
        padding: 2% !important
    }

    .xl-p-3 {
        padding: 3% !important
    }

    .xl-p-4 {
        padding: 4% !important
    }

    .xl-p-5 {
        padding: 5% !important
    }

    .xl-p-6 {
        padding: 6% !important
    }

    .xl-p-7 {
        padding: 7% !important
    }

    .xl-p-8 {
        padding: 8% !important
    }

    .xl-p-9 {
        padding: 9% !important
    }

    .xl-p-10 {
        padding: 10% !important
    }

    .xl-p-11 {
        padding: 11% !important
    }

    .xl-p-12 {
        padding: 12% !important
    }

    .xl-p-13 {
        padding: 13% !important
    }

    .xl-p-14 {
        padding: 14% !important
    }

    .xl-p-15 {
        padding: 15% !important
    }

    .xl-p-16 {
        padding: 16% !important
    }

    .xl-p-17 {
        padding: 17% !important
    }

    .xl-p-18 {
        padding: 18% !important
    }

    .xl-p-19 {
        padding: 19% !important
    }

    .xl-p-20 {
        padding: 20% !important
    }

    .xl-p-21 {
        padding: 21% !important
    }

    .xl-p-22 {
        padding: 22% !important
    }

    .xl-p-23 {
        padding: 23% !important
    }

    .xl-p-24 {
        padding: 24% !important
    }

    .xl-p-25 {
        padding: 25% !important
    }

    .xl-p-26 {
        padding: 26% !important
    }

    .xl-p-27 {
        padding: 27% !important
    }

    .xl-p-28 {
        padding: 28% !important
    }

    .xl-p-29 {
        padding: 29% !important
    }

    .xl-p-30 {
        padding: 30% !important
    }

    .xl-pt-5px {
        padding-top: 5px !important
    }

    .xl-pt-10px {
        padding-top: 10px !important
    }

    .xl-pt-15px {
        padding-top: 15px !important
    }

    .xl-pt-20px {
        padding-top: 20px !important
    }

    .xl-pt-25px {
        padding-top: 25px !important
    }

    .xl-pt-30px {
        padding-top: 30px !important
    }

    .xl-pt-35px {
        padding-top: 35px !important
    }

    .xl-pt-40px {
        padding-top: 40px !important
    }

    .xl-pt-45px {
        padding-top: 45px !important
    }

    .xl-pt-50px {
        padding-top: 50px !important
    }

    .xl-pt-55px {
        padding-top: 55px !important
    }

    .xl-pt-60px {
        padding-top: 60px !important
    }

    .xl-pt-65px {
        padding-top: 65px !important
    }

    .xl-pt-70px {
        padding-top: 70px !important
    }

    .xl-pt-1 {
        padding-top: 1% !important
    }

    .xl-pt-2 {
        padding-top: 2% !important
    }

    .xl-pt-3 {
        padding-top: 3% !important
    }

    .xl-pt-4 {
        padding-top: 4% !important
    }

    .xl-pt-5 {
        padding-top: 5% !important
    }

    .xl-pt-6 {
        padding-top: 6% !important
    }

    .xl-pt-7 {
        padding-top: 7% !important
    }

    .xl-pt-8 {
        padding-top: 8% !important
    }

    .xl-pt-9 {
        padding-top: 9% !important
    }

    .xl-pt-10 {
        padding-top: 10% !important
    }

    .xl-pt-11 {
        padding-top: 11% !important
    }

    .xl-pt-12 {
        padding-top: 12% !important
    }

    .xl-pt-13 {
        padding-top: 13% !important
    }

    .xl-pt-14 {
        padding-top: 14% !important
    }

    .xl-pt-15 {
        padding-top: 15% !important
    }

    .xl-pt-16 {
        padding-top: 16% !important
    }

    .xl-pt-17 {
        padding-top: 17% !important
    }

    .xl-pt-18 {
        padding-top: 18% !important
    }

    .xl-pt-19 {
        padding-top: 19% !important
    }

    .xl-pt-20 {
        padding-top: 20% !important
    }

    .xl-pt-21 {
        padding-top: 21% !important
    }

    .xl-pt-22 {
        padding-top: 22% !important
    }

    .xl-pt-23 {
        padding-top: 23% !important
    }

    .xl-pt-24 {
        padding-top: 24% !important
    }

    .xl-pt-25 {
        padding-top: 25% !important
    }

    .xl-pt-26 {
        padding-top: 26% !important
    }

    .xl-pt-27 {
        padding-top: 27% !important
    }

    .xl-pt-28 {
        padding-top: 28% !important
    }

    .xl-pt-29 {
        padding-top: 29% !important
    }

    .xl-pt-30 {
        padding-top: 30% !important
    }

    .xl-pe-5px {
        padding-right: 5px !important
    }

    .xl-pe-10px {
        padding-right: 10px !important
    }

    .xl-pe-15px {
        padding-right: 15px !important
    }

    .xl-pe-20px {
        padding-right: 20px !important
    }

    .xl-pe-25px {
        padding-right: 25px !important
    }

    .xl-pe-30px {
        padding-right: 30px !important
    }

    .xl-pe-35px {
        padding-right: 35px !important
    }

    .xl-pe-40px {
        padding-right: 40px !important
    }

    .xl-pe-45px {
        padding-right: 45px !important
    }

    .xl-pe-50px {
        padding-right: 50px !important
    }

    .xl-pe-55px {
        padding-right: 55px !important
    }

    .xl-pe-60px {
        padding-right: 60px !important
    }

    .xl-pe-65px {
        padding-right: 65px !important
    }

    .xl-pe-70px {
        padding-right: 70px !important
    }

    .xl-pe-1 {
        padding-right: 1% !important
    }

    .xl-pe-2 {
        padding-right: 2% !important
    }

    .xl-pe-3 {
        padding-right: 3% !important
    }

    .xl-pe-4 {
        padding-right: 4% !important
    }

    .xl-pe-5 {
        padding-right: 5% !important
    }

    .xl-pe-6 {
        padding-right: 6% !important
    }

    .xl-pe-7 {
        padding-right: 7% !important
    }

    .xl-pe-8 {
        padding-right: 8% !important
    }

    .xl-pe-9 {
        padding-right: 9% !important
    }

    .xl-pe-10 {
        padding-right: 10% !important
    }

    .xl-pe-11 {
        padding-right: 11% !important
    }

    .xl-pe-12 {
        padding-right: 12% !important
    }

    .xl-pe-13 {
        padding-right: 13% !important
    }

    .xl-pe-14 {
        padding-right: 14% !important
    }

    .xl-pe-15 {
        padding-right: 15% !important
    }

    .xl-pe-16 {
        padding-right: 16% !important
    }

    .xl-pe-17 {
        padding-right: 17% !important
    }

    .xl-pe-18 {
        padding-right: 18% !important
    }

    .xl-pe-19 {
        padding-right: 19% !important
    }

    .xl-pe-20 {
        padding-right: 20% !important
    }

    .xl-pe-21 {
        padding-right: 21% !important
    }

    .xl-pe-22 {
        padding-right: 22% !important
    }

    .xl-pe-23 {
        padding-right: 23% !important
    }

    .xl-pe-24 {
        padding-right: 24% !important
    }

    .xl-pe-25 {
        padding-right: 25% !important
    }

    .xl-pe-26 {
        padding-right: 26% !important
    }

    .xl-pe-27 {
        padding-right: 27% !important
    }

    .xl-pe-28 {
        padding-right: 28% !important
    }

    .xl-pe-29 {
        padding-right: 29% !important
    }

    .xl-pe-30 {
        padding-right: 30% !important
    }

    .xl-pb-5px {
        padding-bottom: 5px !important
    }

    .xl-pb-10px {
        padding-bottom: 10px !important
    }

    .xl-pb-15px {
        padding-bottom: 15px !important
    }

    .xl-pb-20px {
        padding-bottom: 20px !important
    }

    .xl-pb-25px {
        padding-bottom: 25px !important
    }

    .xl-pb-30px {
        padding-bottom: 30px !important
    }

    .xl-pb-35px {
        padding-bottom: 35px !important
    }

    .xl-pb-40px {
        padding-bottom: 40px !important
    }

    .xl-pb-45px {
        padding-bottom: 45px !important
    }

    .xl-pb-50px {
        padding-bottom: 50px !important
    }

    .xl-pb-55px {
        padding-bottom: 55px !important
    }

    .xl-pb-60px {
        padding-bottom: 60px !important
    }

    .xl-pb-65px {
        padding-bottom: 65px !important
    }

    .xl-pb-70px {
        padding-bottom: 70px !important
    }

    .xl-pb-1 {
        padding-bottom: 1% !important
    }

    .xl-pb-2 {
        padding-bottom: 2% !important
    }

    .xl-pb-3 {
        padding-bottom: 3% !important
    }

    .xl-pb-4 {
        padding-bottom: 4% !important
    }

    .xl-pb-5 {
        padding-bottom: 5% !important
    }

    .xl-pb-6 {
        padding-bottom: 6% !important
    }

    .xl-pb-7 {
        padding-bottom: 7% !important
    }

    .xl-pb-8 {
        padding-bottom: 8% !important
    }

    .xl-pb-9 {
        padding-bottom: 9% !important
    }

    .xl-pb-10 {
        padding-bottom: 10% !important
    }

    .xl-pb-11 {
        padding-bottom: 11% !important
    }

    .xl-pb-12 {
        padding-bottom: 12% !important
    }

    .xl-pb-13 {
        padding-bottom: 13% !important
    }

    .xl-pb-14 {
        padding-bottom: 14% !important
    }

    .xl-pb-15 {
        padding-bottom: 15% !important
    }

    .xl-pb-16 {
        padding-bottom: 16% !important
    }

    .xl-pb-17 {
        padding-bottom: 17% !important
    }

    .xl-pb-18 {
        padding-bottom: 18% !important
    }

    .xl-pb-19 {
        padding-bottom: 19% !important
    }

    .xl-pb-20 {
        padding-bottom: 20% !important
    }

    .xl-pb-21 {
        padding-bottom: 21% !important
    }

    .xl-pb-22 {
        padding-bottom: 22% !important
    }

    .xl-pb-23 {
        padding-bottom: 23% !important
    }

    .xl-pb-24 {
        padding-bottom: 24% !important
    }

    .xl-pb-25 {
        padding-bottom: 25% !important
    }

    .xl-pb-26 {
        padding-bottom: 26% !important
    }

    .xl-pb-27 {
        padding-bottom: 27% !important
    }

    .xl-pb-28 {
        padding-bottom: 28% !important
    }

    .xl-pb-29 {
        padding-bottom: 29% !important
    }

    .xl-pb-30 {
        padding-bottom: 30% !important
    }

    .xl-ps-5px {
        padding-left: 5px !important
    }

    .xl-ps-10px {
        padding-left: 10px !important
    }

    .xl-ps-15px {
        padding-left: 15px !important
    }

    .xl-ps-20px {
        padding-left: 20px !important
    }

    .xl-ps-25px {
        padding-left: 25px !important
    }

    .xl-ps-30px {
        padding-left: 30px !important
    }

    .xl-ps-35px {
        padding-left: 35px !important
    }

    .xl-ps-40px {
        padding-left: 40px !important
    }

    .xl-ps-45px {
        padding-left: 45px !important
    }

    .xl-ps-50px {
        padding-left: 50px !important
    }

    .xl-ps-55px {
        padding-left: 55px !important
    }

    .xl-ps-60px {
        padding-left: 60px !important
    }

    .xl-ps-65px {
        padding-left: 65px !important
    }

    .xl-ps-70px {
        padding-left: 70px !important
    }

    .xl-ps-1 {
        padding-left: 1% !important
    }

    .xl-ps-2 {
        padding-left: 2% !important
    }

    .xl-ps-3 {
        padding-left: 3% !important
    }

    .xl-ps-4 {
        padding-left: 4% !important
    }

    .xl-ps-5 {
        padding-left: 5% !important
    }

    .xl-ps-6 {
        padding-left: 6% !important
    }

    .xl-ps-7 {
        padding-left: 7% !important
    }

    .xl-ps-8 {
        padding-left: 8% !important
    }

    .xl-ps-9 {
        padding-left: 9% !important
    }

    .xl-ps-10 {
        padding-left: 10% !important
    }

    .xl-ps-11 {
        padding-left: 11% !important
    }

    .xl-ps-12 {
        padding-left: 12% !important
    }

    .xl-ps-13 {
        padding-left: 13% !important
    }

    .xl-ps-14 {
        padding-left: 14% !important
    }

    .xl-ps-15 {
        padding-left: 15% !important
    }

    .xl-ps-16 {
        padding-left: 16% !important
    }

    .xl-ps-17 {
        padding-left: 17% !important
    }

    .xl-ps-18 {
        padding-left: 18% !important
    }

    .xl-ps-19 {
        padding-left: 19% !important
    }

    .xl-ps-20 {
        padding-left: 20% !important
    }

    .xl-ps-21 {
        padding-left: 21% !important
    }

    .xl-ps-22 {
        padding-left: 22% !important
    }

    .xl-ps-23 {
        padding-left: 23% !important
    }

    .xl-ps-24 {
        padding-left: 24% !important
    }

    .xl-ps-25 {
        padding-left: 25% !important
    }

    .xl-ps-26 {
        padding-left: 26% !important
    }

    .xl-ps-27 {
        padding-left: 27% !important
    }

    .xl-ps-28 {
        padding-left: 28% !important
    }

    .xl-ps-29 {
        padding-left: 29% !important
    }

    .xl-ps-30 {
        padding-left: 30% !important
    }

    .xl-m-5px {
        margin: 5px !important
    }

    .xl-m-10px {
        margin: 10px !important
    }

    .xl-m-15px {
        margin: 15px !important
    }

    .xl-m-20px {
        margin: 20px !important
    }

    .xl-m-25px {
        margin: 25px !important
    }

    .xl-m-30px {
        margin: 30px !important
    }

    .xl-m-35px {
        margin: 35px !important
    }

    .xl-m-40px {
        margin: 40px !important
    }

    .xl-m-45px {
        margin: 45px !important
    }

    .xl-m-50px {
        margin: 50px !important
    }

    .xl-m-55px {
        margin: 55px !important
    }

    .xl-m-60px {
        margin: 60px !important
    }

    .xl-m-65px {
        margin: 65px !important
    }

    .xl-m-70px {
        margin: 70px !important
    }

    .xl-m-1 {
        margin: 1% !important
    }

    .xl-m-2 {
        margin: 2% !important
    }

    .xl-m-3 {
        margin: 3% !important
    }

    .xl-m-4 {
        margin: 4% !important
    }

    .xl-m-5 {
        margin: 5% !important
    }

    .xl-m-6 {
        margin: 6% !important
    }

    .xl-m-7 {
        margin: 7% !important
    }

    .xl-m-8 {
        margin: 8% !important
    }

    .xl-m-9 {
        margin: 9% !important
    }

    .xl-m-10 {
        margin: 10% !important
    }

    .xl-m-11 {
        margin: 11% !important
    }

    .xl-m-12 {
        margin: 12% !important
    }

    .xl-m-13 {
        margin: 13% !important
    }

    .xl-m-14 {
        margin: 14% !important
    }

    .xl-m-15 {
        margin: 15% !important
    }

    .xl-m-16 {
        margin: 16% !important
    }

    .xl-m-17 {
        margin: 17% !important
    }

    .xl-m-18 {
        margin: 18% !important
    }

    .xl-m-19 {
        margin: 19% !important
    }

    .xl-m-20 {
        margin: 20% !important
    }

    .xl-m-21 {
        margin: 21% !important
    }

    .xl-m-22 {
        margin: 22% !important
    }

    .xl-m-23 {
        margin: 23% !important
    }

    .xl-m-24 {
        margin: 24% !important
    }

    .xl-m-25 {
        margin: 25% !important
    }

    .xl-m-26 {
        margin: 26% !important
    }

    .xl-m-27 {
        margin: 27% !important
    }

    .xl-m-28 {
        margin: 28% !important
    }

    .xl-m-29 {
        margin: 29% !important
    }

    .xl-m-30 {
        margin: 30% !important
    }

    .xl-mt-5px {
        margin-top: 5px !important
    }

    .xl-mt-10px {
        margin-top: 10px !important
    }

    .xl-mt-15px {
        margin-top: 15px !important
    }

    .xl-mt-20px {
        margin-top: 20px !important
    }

    .xl-mt-25px {
        margin-top: 25px !important
    }

    .xl-mt-30px {
        margin-top: 30px !important
    }

    .xl-mt-35px {
        margin-top: 35px !important
    }

    .xl-mt-40px {
        margin-top: 40px !important
    }

    .xl-mt-45px {
        margin-top: 45px !important
    }

    .xl-mt-50px {
        margin-top: 50px !important
    }

    .xl-mt-55px {
        margin-top: 55px !important
    }

    .xl-mt-60px {
        margin-top: 60px !important
    }

    .xl-mt-65px {
        margin-top: 65px !important
    }

    .xl-mt-70px {
        margin-top: 70px !important
    }

    .xl-mt-1 {
        margin-top: 1% !important
    }

    .xl-mt-2 {
        margin-top: 2% !important
    }

    .xl-mt-3 {
        margin-top: 3% !important
    }

    .xl-mt-4 {
        margin-top: 4% !important
    }

    .xl-mt-5 {
        margin-top: 5% !important
    }

    .xl-mt-6 {
        margin-top: 6% !important
    }

    .xl-mt-7 {
        margin-top: 7% !important
    }

    .xl-mt-8 {
        margin-top: 8% !important
    }

    .xl-mt-9 {
        margin-top: 9% !important
    }

    .xl-mt-10 {
        margin-top: 10% !important
    }

    .xl-mt-11 {
        margin-top: 11% !important
    }

    .xl-mt-12 {
        margin-top: 12% !important
    }

    .xl-mt-13 {
        margin-top: 13% !important
    }

    .xl-mt-14 {
        margin-top: 14% !important
    }

    .xl-mt-15 {
        margin-top: 15% !important
    }

    .xl-mt-16 {
        margin-top: 16% !important
    }

    .xl-mt-17 {
        margin-top: 17% !important
    }

    .xl-mt-18 {
        margin-top: 18% !important
    }

    .xl-mt-19 {
        margin-top: 19% !important
    }

    .xl-mt-20 {
        margin-top: 20% !important
    }

    .xl-mt-21 {
        margin-top: 21% !important
    }

    .xl-mt-22 {
        margin-top: 22% !important
    }

    .xl-mt-23 {
        margin-top: 23% !important
    }

    .xl-mt-24 {
        margin-top: 24% !important
    }

    .xl-mt-25 {
        margin-top: 25% !important
    }

    .xl-mt-26 {
        margin-top: 26% !important
    }

    .xl-mt-27 {
        margin-top: 27% !important
    }

    .xl-mt-28 {
        margin-top: 28% !important
    }

    .xl-mt-29 {
        margin-top: 29% !important
    }

    .xl-mt-30 {
        margin-top: 30% !important
    }

    .xl-me-5px {
        margin-right: 5px !important
    }

    .xl-me-10px {
        margin-right: 10px !important
    }

    .xl-me-15px {
        margin-right: 15px !important
    }

    .xl-me-20px {
        margin-right: 20px !important
    }

    .xl-me-25px {
        margin-right: 25px !important
    }

    .xl-me-30px {
        margin-right: 30px !important
    }

    .xl-me-35px {
        margin-right: 35px !important
    }

    .xl-me-40px {
        margin-right: 40px !important
    }

    .xl-me-45px {
        margin-right: 45px !important
    }

    .xl-me-50px {
        margin-right: 50px !important
    }

    .xl-me-55px {
        margin-right: 55px !important
    }

    .xl-me-60px {
        margin-right: 60px !important
    }

    .xl-me-65px {
        margin-right: 65px !important
    }

    .xl-me-70px {
        margin-right: 70px !important
    }

    .xl-me-1 {
        margin-right: 1% !important
    }

    .xl-me-2 {
        margin-right: 2% !important
    }

    .xl-me-3 {
        margin-right: 3% !important
    }

    .xl-me-4 {
        margin-right: 4% !important
    }

    .xl-me-5 {
        margin-right: 5% !important
    }

    .xl-me-6 {
        margin-right: 6% !important
    }

    .xl-me-7 {
        margin-right: 7% !important
    }

    .xl-me-8 {
        margin-right: 8% !important
    }

    .xl-me-9 {
        margin-right: 9% !important
    }

    .xl-me-10 {
        margin-right: 10% !important
    }

    .xl-me-11 {
        margin-right: 11% !important
    }

    .xl-me-12 {
        margin-right: 12% !important
    }

    .xl-me-13 {
        margin-right: 13% !important
    }

    .xl-me-14 {
        margin-right: 14% !important
    }

    .xl-me-15 {
        margin-right: 15% !important
    }

    .xl-me-16 {
        margin-right: 16% !important
    }

    .xl-me-17 {
        margin-right: 17% !important
    }

    .xl-me-18 {
        margin-right: 18% !important
    }

    .xl-me-19 {
        margin-right: 19% !important
    }

    .xl-me-20 {
        margin-right: 20% !important
    }

    .xl-me-21 {
        margin-right: 21% !important
    }

    .xl-me-22 {
        margin-right: 22% !important
    }

    .xl-me-23 {
        margin-right: 23% !important
    }

    .xl-me-24 {
        margin-right: 24% !important
    }

    .xl-me-25 {
        margin-right: 25% !important
    }

    .xl-me-26 {
        margin-right: 26% !important
    }

    .xl-me-27 {
        margin-right: 27% !important
    }

    .xl-me-28 {
        margin-right: 28% !important
    }

    .xl-me-29 {
        margin-right: 29% !important
    }

    .xl-me-30 {
        margin-right: 30% !important
    }

    .xl-mb-5px {
        margin-bottom: 5px !important
    }

    .xl-mb-10px {
        margin-bottom: 10px !important
    }

    .xl-mb-15px {
        margin-bottom: 15px !important
    }

    .xl-mb-20px {
        margin-bottom: 20px !important
    }

    .xl-mb-25px {
        margin-bottom: 25px !important
    }

    .xl-mb-30px {
        margin-bottom: 30px !important
    }

    .xl-mb-35px {
        margin-bottom: 35px !important
    }

    .xl-mb-40px {
        margin-bottom: 40px !important
    }

    .xl-mb-45px {
        margin-bottom: 45px !important
    }

    .xl-mb-50px {
        margin-bottom: 50px !important
    }

    .xl-mb-55px {
        margin-bottom: 55px !important
    }

    .xl-mb-60px {
        margin-bottom: 60px !important
    }

    .xl-mb-65px {
        margin-bottom: 65px !important
    }

    .xl-mb-70px {
        margin-bottom: 70px !important
    }

    .xl-mb-1 {
        margin-bottom: 1% !important
    }

    .xl-mb-2 {
        margin-bottom: 2% !important
    }

    .xl-mb-3 {
        margin-bottom: 3% !important
    }

    .xl-mb-4 {
        margin-bottom: 4% !important
    }

    .xl-mb-5 {
        margin-bottom: 5% !important
    }

    .xl-mb-6 {
        margin-bottom: 6% !important
    }

    .xl-mb-7 {
        margin-bottom: 7% !important
    }

    .xl-mb-8 {
        margin-bottom: 8% !important
    }

    .xl-mb-9 {
        margin-bottom: 9% !important
    }

    .xl-mb-10 {
        margin-bottom: 10% !important
    }

    .xl-mb-11 {
        margin-bottom: 11% !important
    }

    .xl-mb-12 {
        margin-bottom: 12% !important
    }

    .xl-mb-13 {
        margin-bottom: 13% !important
    }

    .xl-mb-14 {
        margin-bottom: 14% !important
    }

    .xl-mb-15 {
        margin-bottom: 15% !important
    }

    .xl-mb-16 {
        margin-bottom: 16% !important
    }

    .xl-mb-17 {
        margin-bottom: 17% !important
    }

    .xl-mb-18 {
        margin-bottom: 18% !important
    }

    .xl-mb-19 {
        margin-bottom: 19% !important
    }

    .xl-mb-20 {
        margin-bottom: 20% !important
    }

    .xl-mb-21 {
        margin-bottom: 21% !important
    }

    .xl-mb-22 {
        margin-bottom: 22% !important
    }

    .xl-mb-23 {
        margin-bottom: 23% !important
    }

    .xl-mb-24 {
        margin-bottom: 24% !important
    }

    .xl-mb-25 {
        margin-bottom: 25% !important
    }

    .xl-mb-26 {
        margin-bottom: 26% !important
    }

    .xl-mb-27 {
        margin-bottom: 27% !important
    }

    .xl-mb-28 {
        margin-bottom: 28% !important
    }

    .xl-mb-29 {
        margin-bottom: 29% !important
    }

    .xl-mb-30 {
        margin-bottom: 30% !important
    }

    .xl-ms-5px {
        margin-left: 5px !important
    }

    .xl-ms-10px {
        margin-left: 10px !important
    }

    .xl-ms-15px {
        margin-left: 15px !important
    }

    .xl-ms-20px {
        margin-left: 20px !important
    }

    .xl-ms-25px {
        margin-left: 25px !important
    }

    .xl-ms-30px {
        margin-left: 30px !important
    }

    .xl-ms-35px {
        margin-left: 35px !important
    }

    .xl-ms-40px {
        margin-left: 40px !important
    }

    .xl-ms-45px {
        margin-left: 45px !important
    }

    .xl-ms-50px {
        margin-left: 50px !important
    }

    .xl-ms-55px {
        margin-left: 55px !important
    }

    .xl-ms-60px {
        margin-left: 60px !important
    }

    .xl-ms-65px {
        margin-left: 65px !important
    }

    .xl-ms-70px {
        margin-left: 70px !important
    }

    .xl-ms-1 {
        margin-left: 1% !important
    }

    .xl-ms-2 {
        margin-left: 2% !important
    }

    .xl-ms-3 {
        margin-left: 3% !important
    }

    .xl-ms-4 {
        margin-left: 4% !important
    }

    .xl-ms-5 {
        margin-left: 5% !important
    }

    .xl-ms-6 {
        margin-left: 6% !important
    }

    .xl-ms-7 {
        margin-left: 7% !important
    }

    .xl-ms-8 {
        margin-left: 8% !important
    }

    .xl-ms-9 {
        margin-left: 9% !important
    }

    .xl-ms-10 {
        margin-left: 10% !important
    }

    .xl-ms-11 {
        margin-left: 11% !important
    }

    .xl-ms-12 {
        margin-left: 12% !important
    }

    .xl-ms-13 {
        margin-left: 13% !important
    }

    .xl-ms-14 {
        margin-left: 14% !important
    }

    .xl-ms-15 {
        margin-left: 15% !important
    }

    .xl-ms-16 {
        margin-left: 16% !important
    }

    .xl-ms-17 {
        margin-left: 17% !important
    }

    .xl-ms-18 {
        margin-left: 18% !important
    }

    .xl-ms-19 {
        margin-left: 19% !important
    }

    .xl-ms-20 {
        margin-left: 20% !important
    }

    .xl-ms-21 {
        margin-left: 21% !important
    }

    .xl-ms-22 {
        margin-left: 22% !important
    }

    .xl-ms-23 {
        margin-left: 23% !important
    }

    .xl-ms-24 {
        margin-left: 24% !important
    }

    .xl-ms-25 {
        margin-left: 25% !important
    }

    .xl-ms-26 {
        margin-left: 26% !important
    }

    .xl-ms-27 {
        margin-left: 27% !important
    }

    .xl-ms-28 {
        margin-left: 28% !important
    }

    .xl-ms-29 {
        margin-left: 29% !important
    }

    .xl-ms-30 {
        margin-left: 30% !important
    }

    .xl-top-0px {
        top: 0 !important
    }

    .xl-top-1px {
        top: 1px !important
    }

    .xl-top-2px {
        top: 2px !important
    }

    .xl-top-3px {
        top: 3px !important
    }

    .xl-top-4px {
        top: 4px !important
    }

    .xl-top-5px {
        top: 5px !important
    }

    .xl-top-6px {
        top: 6px !important
    }

    .xl-top-7px {
        top: 7px !important
    }

    .xl-top-8px {
        top: 8px !important
    }

    .xl-top-9px {
        top: 9px !important
    }

    .xl-top-10px {
        top: 10px !important
    }

    .xl-top-11px {
        top: 11px !important
    }

    .xl-top-12px {
        top: 12px !important
    }

    .xl-top-13px {
        top: 13px !important
    }

    .xl-top-14px {
        top: 14px !important
    }

    .xl-top-15px {
        top: 15px !important
    }

    .xl-top-16px {
        top: 16px !important
    }

    .xl-top-17px {
        top: 17px !important
    }

    .xl-top-18px {
        top: 18px !important
    }

    .xl-top-19px {
        top: 19px !important
    }

    .xl-top-20px {
        top: 20px !important
    }

    .xl-top-21px {
        top: 21px !important
    }

    .xl-top-22px {
        top: 22px !important
    }

    .xl-top-23px {
        top: 23px !important
    }

    .xl-top-24px {
        top: 24px !important
    }

    .xl-top-25px {
        top: 25px !important
    }

    .xl-top-26px {
        top: 26px !important
    }

    .xl-top-27px {
        top: 27px !important
    }

    .xl-top-28px {
        top: 28px !important
    }

    .xl-top-29px {
        top: 29px !important
    }

    .xl-top-30px {
        top: 30px !important
    }

    .xl-top-40px {
        top: 40px !important
    }

    .xl-top-50px {
        top: 50px !important
    }

    .xl-top-60px {
        top: 60px !important
    }

    .xl-top-70px {
        top: 70px !important
    }

    .xl-top-80px {
        top: 80px !important
    }

    .xl-top-90px {
        top: 90px !important
    }

    .xl-top-100px {
        top: 100px !important
    }

    .xl-top-110px {
        top: 110px !important
    }

    .xl-top-120px {
        top: 120px !important
    }

    .xl-top-130px {
        top: 130px !important
    }

    .xl-top-140px {
        top: 140px !important
    }

    .xl-top-150px {
        top: 150px !important
    }

    .xl-right-0px {
        right: 0 !important
    }

    .xl-right-1px {
        right: 1px !important
    }

    .xl-right-2px {
        right: 2px !important
    }

    .xl-right-3px {
        right: 3px !important
    }

    .xl-right-4px {
        right: 4px !important
    }

    .xl-right-5px {
        right: 5px !important
    }

    .xl-right-6px {
        right: 6px !important
    }

    .xl-right-7px {
        right: 7px !important
    }

    .xl-right-8px {
        right: 8px !important
    }

    .xl-right-9px {
        right: 9px !important
    }

    .xl-right-10px {
        right: 10px !important
    }

    .xl-right-11px {
        right: 11px !important
    }

    .xl-right-12px {
        right: 12px !important
    }

    .xl-right-13px {
        right: 13px !important
    }

    .xl-right-14px {
        right: 14px !important
    }

    .xl-right-15px {
        right: 15px !important
    }

    .xl-right-16px {
        right: 16px !important
    }

    .xl-right-17px {
        right: 17px !important
    }

    .xl-right-18px {
        right: 18px !important
    }

    .xl-right-19px {
        right: 19px !important
    }

    .xl-right-20px {
        right: 20px !important
    }

    .xl-right-21px {
        right: 21px !important
    }

    .xl-right-22px {
        right: 22px !important
    }

    .xl-right-23px {
        right: 23px !important
    }

    .xl-right-24px {
        right: 24px !important
    }

    .xl-right-25px {
        right: 25px !important
    }

    .xl-right-26px {
        right: 26px !important
    }

    .xl-right-27px {
        right: 27px !important
    }

    .xl-right-28px {
        right: 28px !important
    }

    .xl-right-29px {
        right: 29px !important
    }

    .xl-right-30px {
        right: 30px !important
    }

    .xl-right-40px {
        right: 40px !important
    }

    .xl-right-50px {
        right: 50px !important
    }

    .xl-right-60px {
        right: 60px !important
    }

    .xl-right-70px {
        right: 70px !important
    }

    .xl-right-80px {
        right: 80px !important
    }

    .xl-right-90px {
        right: 90px !important
    }

    .xl-right-100px {
        right: 100px !important
    }

    .xl-right-110px {
        right: 110px !important
    }

    .xl-right-120px {
        right: 120px !important
    }

    .xl-right-130px {
        right: 130px !important
    }

    .xl-right-140px {
        right: 140px !important
    }

    .xl-right-150px {
        right: 150px !important
    }

    .xl-bottom-0px {
        bottom: 0 !important
    }

    .xl-bottom-1px {
        bottom: 1px !important
    }

    .xl-bottom-2px {
        bottom: 2px !important
    }

    .xl-bottom-3px {
        bottom: 3px !important
    }

    .xl-bottom-4px {
        bottom: 4px !important
    }

    .xl-bottom-5px {
        bottom: 5px !important
    }

    .xl-bottom-6px {
        bottom: 6px !important
    }

    .xl-bottom-7px {
        bottom: 7px !important
    }

    .xl-bottom-8px {
        bottom: 8px !important
    }

    .xl-bottom-9px {
        bottom: 9px !important
    }

    .xl-bottom-10px {
        bottom: 10px !important
    }

    .xl-bottom-11px {
        bottom: 11px !important
    }

    .xl-bottom-12px {
        bottom: 12px !important
    }

    .xl-bottom-13px {
        bottom: 13px !important
    }

    .xl-bottom-14px {
        bottom: 14px !important
    }

    .xl-bottom-15px {
        bottom: 15px !important
    }

    .xl-bottom-16px {
        bottom: 16px !important
    }

    .xl-bottom-17px {
        bottom: 17px !important
    }

    .xl-bottom-18px {
        bottom: 18px !important
    }

    .xl-bottom-19px {
        bottom: 19px !important
    }

    .xl-bottom-20px {
        bottom: 20px !important
    }

    .xl-bottom-21px {
        bottom: 21px !important
    }

    .xl-bottom-22px {
        bottom: 22px !important
    }

    .xl-bottom-23px {
        bottom: 23px !important
    }

    .xl-bottom-24px {
        bottom: 24px !important
    }

    .xl-bottom-25px {
        bottom: 25px !important
    }

    .xl-bottom-26px {
        bottom: 26px !important
    }

    .xl-bottom-27px {
        bottom: 27px !important
    }

    .xl-bottom-28px {
        bottom: 28px !important
    }

    .xl-bottom-29px {
        bottom: 29px !important
    }

    .xl-bottom-30px {
        bottom: 30px !important
    }

    .xl-bottom-40px {
        bottom: 40px !important
    }

    .xl-bottom-50px {
        bottom: 50px !important
    }

    .xl-bottom-60px {
        bottom: 60px !important
    }

    .xl-bottom-70px {
        bottom: 70px !important
    }

    .xl-bottom-80px {
        bottom: 80px !important
    }

    .xl-bottom-90px {
        bottom: 90px !important
    }

    .xl-bottom-100px {
        bottom: 100px !important
    }

    .xl-bottom-110px {
        bottom: 110px !important
    }

    .xl-bottom-120px {
        bottom: 120px !important
    }

    .xl-bottom-130px {
        bottom: 130px !important
    }

    .xl-bottom-140px {
        bottom: 140px !important
    }

    .xl-bottom-150px {
        bottom: 150px !important
    }

    .xl-left-0px {
        left: 0 !important
    }

    .xl-left-1px {
        left: 1px !important
    }

    .xl-left-2px {
        left: 2px !important
    }

    .xl-left-3px {
        left: 3px !important
    }

    .xl-left-4px {
        left: 4px !important
    }

    .xl-left-5px {
        left: 5px !important
    }

    .xl-left-6px {
        left: 6px !important
    }

    .xl-left-7px {
        left: 7px !important
    }

    .xl-left-8px {
        left: 8px !important
    }

    .xl-left-9px {
        left: 9px !important
    }

    .xl-left-10px {
        left: 10px !important
    }

    .xl-left-11px {
        left: 11px !important
    }

    .xl-left-12px {
        left: 12px !important
    }

    .xl-left-13px {
        left: 13px !important
    }

    .xl-left-14px {
        left: 14px !important
    }

    .xl-left-15px {
        left: 15px !important
    }

    .xl-left-16px {
        left: 16px !important
    }

    .xl-left-17px {
        left: 17px !important
    }

    .xl-left-18px {
        left: 18px !important
    }

    .xl-left-19px {
        left: 19px !important
    }

    .xl-left-20px {
        left: 20px !important
    }

    .xl-left-21px {
        left: 21px !important
    }

    .xl-left-22px {
        left: 22px !important
    }

    .xl-left-23px {
        left: 23px !important
    }

    .xl-left-24px {
        left: 24px !important
    }

    .xl-left-25px {
        left: 25px !important
    }

    .xl-left-26px {
        left: 26px !important
    }

    .xl-left-27px {
        left: 27px !important
    }

    .xl-left-28px {
        left: 28px !important
    }

    .xl-left-29px {
        left: 29px !important
    }

    .xl-left-30px {
        left: 30px !important
    }

    .xl-left-40px {
        left: 40px !important
    }

    .xl-left-50px {
        left: 50px !important
    }

    .xl-left-60px {
        left: 60px !important
    }

    .xl-left-70px {
        left: 70px !important
    }

    .xl-left-80px {
        left: 80px !important
    }

    .xl-left-90px {
        left: 90px !important
    }

    .xl-left-100px {
        left: 100px !important
    }

    .xl-left-110px {
        left: 110px !important
    }

    .xl-left-120px {
        left: 120px !important
    }

    .xl-left-130px {
        left: 130px !important
    }

    .xl-left-140px {
        left: 140px !important
    }

    .xl-left-150px {
        left: 150px !important
    }

    .xl-top-minus-0px {
        top: 0 !important
    }

    .xl-top-minus-1px {
        top: -1px !important
    }

    .xl-top-minus-2px {
        top: -2px !important
    }

    .xl-top-minus-3px {
        top: -3px !important
    }

    .xl-top-minus-4px {
        top: -4px !important
    }

    .xl-top-minus-5px {
        top: -5px !important
    }

    .xl-top-minus-6px {
        top: -6px !important
    }

    .xl-top-minus-7px {
        top: -7px !important
    }

    .xl-top-minus-8px {
        top: -8px !important
    }

    .xl-top-minus-9px {
        top: -9px !important
    }

    .xl-top-minus-10px {
        top: -10px !important
    }

    .xl-top-minus-11px {
        top: -11px !important
    }

    .xl-top-minus-12px {
        top: -12px !important
    }

    .xl-top-minus-13px {
        top: -13px !important
    }

    .xl-top-minus-14px {
        top: -14px !important
    }

    .xl-top-minus-15px {
        top: -15px !important
    }

    .xl-top-minus-16px {
        top: -16px !important
    }

    .xl-top-minus-17px {
        top: -17px !important
    }

    .xl-top-minus-18px {
        top: -18px !important
    }

    .xl-top-minus-19px {
        top: -19px !important
    }

    .xl-top-minus-20px {
        top: -20px !important
    }

    .xl-top-minus-21px {
        top: -21px !important
    }

    .xl-top-minus-22px {
        top: -22px !important
    }

    .xl-top-minus-23px {
        top: -23px !important
    }

    .xl-top-minus-24px {
        top: -24px !important
    }

    .xl-top-minus-25px {
        top: -25px !important
    }

    .xl-top-minus-26px {
        top: -26px !important
    }

    .xl-top-minus-27px {
        top: -27px !important
    }

    .xl-top-minus-28px {
        top: -28px !important
    }

    .xl-top-minus-29px {
        top: -29px !important
    }

    .xl-top-minus-30px {
        top: -30px !important
    }

    .xl-top-minus-40px {
        top: -40px !important
    }

    .xl-top-minus-50px {
        top: -50px !important
    }

    .xl-top-minus-60px {
        top: -60px !important
    }

    .xl-top-minus-70px {
        top: -70px !important
    }

    .xl-top-minus-80px {
        top: -80px !important
    }

    .xl-top-minus-90px {
        top: -90px !important
    }

    .xl-top-minus-100px {
        top: -100px !important
    }

    .xl-top-minus-110px {
        top: -110px !important
    }

    .xl-top-minus-120px {
        top: -120px !important
    }

    .xl-top-minus-130px {
        top: -130px !important
    }

    .xl-top-minus-140px {
        top: -140px !important
    }

    .xl-top-minus-150px {
        top: -150px !important
    }

    .xl-right-minus-0px {
        right: 0 !important
    }

    .xl-right-minus-1px {
        right: -1px !important
    }

    .xl-right-minus-2px {
        right: -2px !important
    }

    .xl-right-minus-3px {
        right: -3px !important
    }

    .xl-right-minus-4px {
        right: -4px !important
    }

    .xl-right-minus-5px {
        right: -5px !important
    }

    .xl-right-minus-6px {
        right: -6px !important
    }

    .xl-right-minus-7px {
        right: -7px !important
    }

    .xl-right-minus-8px {
        right: -8px !important
    }

    .xl-right-minus-9px {
        right: -9px !important
    }

    .xl-right-minus-10px {
        right: -10px !important
    }

    .xl-right-minus-11px {
        right: -11px !important
    }

    .xl-right-minus-12px {
        right: -12px !important
    }

    .xl-right-minus-13px {
        right: -13px !important
    }

    .xl-right-minus-14px {
        right: -14px !important
    }

    .xl-right-minus-15px {
        right: -15px !important
    }

    .xl-right-minus-16px {
        right: -16px !important
    }

    .xl-right-minus-17px {
        right: -17px !important
    }

    .xl-right-minus-18px {
        right: -18px !important
    }

    .xl-right-minus-19px {
        right: -19px !important
    }

    .xl-right-minus-20px {
        right: -20px !important
    }

    .xl-right-minus-21px {
        right: -21px !important
    }

    .xl-right-minus-22px {
        right: -22px !important
    }

    .xl-right-minus-23px {
        right: -23px !important
    }

    .xl-right-minus-24px {
        right: -24px !important
    }

    .xl-right-minus-25px {
        right: -25px !important
    }

    .xl-right-minus-26px {
        right: -26px !important
    }

    .xl-right-minus-27px {
        right: -27px !important
    }

    .xl-right-minus-28px {
        right: -28px !important
    }

    .xl-right-minus-29px {
        right: -29px !important
    }

    .xl-right-minus-30px {
        right: -30px !important
    }

    .xl-right-minus-40px {
        right: -40px !important
    }

    .xl-right-minus-50px {
        right: -50px !important
    }

    .xl-right-minus-60px {
        right: -60px !important
    }

    .xl-right-minus-70px {
        right: -70px !important
    }

    .xl-right-minus-80px {
        right: -80px !important
    }

    .xl-right-minus-90px {
        right: -90px !important
    }

    .xl-right-minus-100px {
        right: -100px !important
    }

    .xl-right-minus-110px {
        right: -110px !important
    }

    .xl-right-minus-120px {
        right: -120px !important
    }

    .xl-right-minus-130px {
        right: -130px !important
    }

    .xl-right-minus-140px {
        right: -140px !important
    }

    .xl-right-minus-150px {
        right: -150px !important
    }

    .xl-bottom-minus-0px {
        bottom: 0 !important
    }

    .xl-bottom-minus-1px {
        bottom: -1px !important
    }

    .xl-bottom-minus-2px {
        bottom: -2px !important
    }

    .xl-bottom-minus-3px {
        bottom: -3px !important
    }

    .xl-bottom-minus-4px {
        bottom: -4px !important
    }

    .xl-bottom-minus-5px {
        bottom: -5px !important
    }

    .xl-bottom-minus-6px {
        bottom: -6px !important
    }

    .xl-bottom-minus-7px {
        bottom: -7px !important
    }

    .xl-bottom-minus-8px {
        bottom: -8px !important
    }

    .xl-bottom-minus-9px {
        bottom: -9px !important
    }

    .xl-bottom-minus-10px {
        bottom: -10px !important
    }

    .xl-bottom-minus-11px {
        bottom: -11px !important
    }

    .xl-bottom-minus-12px {
        bottom: -12px !important
    }

    .xl-bottom-minus-13px {
        bottom: -13px !important
    }

    .xl-bottom-minus-14px {
        bottom: -14px !important
    }

    .xl-bottom-minus-15px {
        bottom: -15px !important
    }

    .xl-bottom-minus-16px {
        bottom: -16px !important
    }

    .xl-bottom-minus-17px {
        bottom: -17px !important
    }

    .xl-bottom-minus-18px {
        bottom: -18px !important
    }

    .xl-bottom-minus-19px {
        bottom: -19px !important
    }

    .xl-bottom-minus-20px {
        bottom: -20px !important
    }

    .xl-bottom-minus-21px {
        bottom: -21px !important
    }

    .xl-bottom-minus-22px {
        bottom: -22px !important
    }

    .xl-bottom-minus-23px {
        bottom: -23px !important
    }

    .xl-bottom-minus-24px {
        bottom: -24px !important
    }

    .xl-bottom-minus-25px {
        bottom: -25px !important
    }

    .xl-bottom-minus-26px {
        bottom: -26px !important
    }

    .xl-bottom-minus-27px {
        bottom: -27px !important
    }

    .xl-bottom-minus-28px {
        bottom: -28px !important
    }

    .xl-bottom-minus-29px {
        bottom: -29px !important
    }

    .xl-bottom-minus-30px {
        bottom: -30px !important
    }

    .xl-bottom-minus-40px {
        bottom: -40px !important
    }

    .xl-bottom-minus-50px {
        bottom: -50px !important
    }

    .xl-bottom-minus-60px {
        bottom: -60px !important
    }

    .xl-bottom-minus-70px {
        bottom: -70px !important
    }

    .xl-bottom-minus-80px {
        bottom: -80px !important
    }

    .xl-bottom-minus-90px {
        bottom: -90px !important
    }

    .xl-bottom-minus-100px {
        bottom: -100px !important
    }

    .xl-bottom-minus-110px {
        bottom: -110px !important
    }

    .xl-bottom-minus-120px {
        bottom: -120px !important
    }

    .xl-bottom-minus-130px {
        bottom: -130px !important
    }

    .xl-bottom-minus-140px {
        bottom: -140px !important
    }

    .xl-bottom-minus-150px {
        bottom: -150px !important
    }

    .xl-left-minus-0px {
        left: 0 !important
    }

    .xl-left-minus-1px {
        left: -1px !important
    }

    .xl-left-minus-2px {
        left: -2px !important
    }

    .xl-left-minus-3px {
        left: -3px !important
    }

    .xl-left-minus-4px {
        left: -4px !important
    }

    .xl-left-minus-5px {
        left: -5px !important
    }

    .xl-left-minus-6px {
        left: -6px !important
    }

    .xl-left-minus-7px {
        left: -7px !important
    }

    .xl-left-minus-8px {
        left: -8px !important
    }

    .xl-left-minus-9px {
        left: -9px !important
    }

    .xl-left-minus-10px {
        left: -10px !important
    }

    .xl-left-minus-11px {
        left: -11px !important
    }

    .xl-left-minus-12px {
        left: -12px !important
    }

    .xl-left-minus-13px {
        left: -13px !important
    }

    .xl-left-minus-14px {
        left: -14px !important
    }

    .xl-left-minus-15px {
        left: -15px !important
    }

    .xl-left-minus-16px {
        left: -16px !important
    }

    .xl-left-minus-17px {
        left: -17px !important
    }

    .xl-left-minus-18px {
        left: -18px !important
    }

    .xl-left-minus-19px {
        left: -19px !important
    }

    .xl-left-minus-20px {
        left: -20px !important
    }

    .xl-left-minus-21px {
        left: -21px !important
    }

    .xl-left-minus-22px {
        left: -22px !important
    }

    .xl-left-minus-23px {
        left: -23px !important
    }

    .xl-left-minus-24px {
        left: -24px !important
    }

    .xl-left-minus-25px {
        left: -25px !important
    }

    .xl-left-minus-26px {
        left: -26px !important
    }

    .xl-left-minus-27px {
        left: -27px !important
    }

    .xl-left-minus-28px {
        left: -28px !important
    }

    .xl-left-minus-29px {
        left: -29px !important
    }

    .xl-left-minus-30px {
        left: -30px !important
    }

    .xl-left-minus-40px {
        left: -40px !important
    }

    .xl-left-minus-50px {
        left: -50px !important
    }

    .xl-left-minus-60px {
        left: -60px !important
    }

    .xl-left-minus-70px {
        left: -70px !important
    }

    .xl-left-minus-80px {
        left: -80px !important
    }

    .xl-left-minus-90px {
        left: -90px !important
    }

    .xl-left-minus-100px {
        left: -100px !important
    }

    .xl-left-minus-110px {
        left: -110px !important
    }

    .xl-left-minus-120px {
        left: -120px !important
    }

    .xl-left-minus-130px {
        left: -130px !important
    }

    .xl-left-minus-140px {
        left: -140px !important
    }

    .xl-left-minus-150px {
        left: -150px !important
    }

    .xl-border-width-0px {
        border-width: 0 !important
    }

    .xl-border-width-1px {
        border-width: 1px !important
    }

    .xl-border-width-2px {
        border-width: 2px !important
    }

    .xl-border-width-3px {
        border-width: 3px !important
    }

    .xl-border-width-4px {
        border-width: 4px !important
    }

    .xl-border-width-5px {
        border-width: 5px !important
    }

    .xl-border-width-6px {
        border-width: 6px !important
    }

    .xl-border-width-7px {
        border-width: 7px !important
    }

    .xl-border-width-8px {
        border-width: 8px !important
    }

    .xl-border-width-9px {
        border-width: 9px !important
    }

    .xl-border-width-10px {
        border-width: 10px !important
    }
}

@media (max-width:1199px) {
    .lg-p-5px {
        padding: 5px !important
    }

    .lg-p-10px {
        padding: 10px !important
    }

    .lg-p-15px {
        padding: 15px !important
    }

    .lg-p-20px {
        padding: 20px !important
    }

    .lg-p-25px {
        padding: 25px !important
    }

    .lg-p-30px {
        padding: 30px !important
    }

    .lg-p-35px {
        padding: 35px !important
    }

    .lg-p-40px {
        padding: 40px !important
    }

    .lg-p-45px {
        padding: 45px !important
    }

    .lg-p-50px {
        padding: 50px !important
    }

    .lg-p-55px {
        padding: 55px !important
    }

    .lg-p-60px {
        padding: 60px !important
    }

    .lg-p-65px {
        padding: 65px !important
    }

    .lg-p-70px {
        padding: 70px !important
    }

    .lg-p-1 {
        padding: 1% !important
    }

    .lg-p-2 {
        padding: 2% !important
    }

    .lg-p-3 {
        padding: 3% !important
    }

    .lg-p-4 {
        padding: 4% !important
    }

    .lg-p-5 {
        padding: 5% !important
    }

    .lg-p-6 {
        padding: 6% !important
    }

    .lg-p-7 {
        padding: 7% !important
    }

    .lg-p-8 {
        padding: 8% !important
    }

    .lg-p-9 {
        padding: 9% !important
    }

    .lg-p-10 {
        padding: 10% !important
    }

    .lg-p-11 {
        padding: 11% !important
    }

    .lg-p-12 {
        padding: 12% !important
    }

    .lg-p-13 {
        padding: 13% !important
    }

    .lg-p-14 {
        padding: 14% !important
    }

    .lg-p-15 {
        padding: 15% !important
    }

    .lg-p-16 {
        padding: 16% !important
    }

    .lg-p-17 {
        padding: 17% !important
    }

    .lg-p-18 {
        padding: 18% !important
    }

    .lg-p-19 {
        padding: 19% !important
    }

    .lg-p-20 {
        padding: 20% !important
    }

    .lg-p-21 {
        padding: 21% !important
    }

    .lg-p-22 {
        padding: 22% !important
    }

    .lg-p-23 {
        padding: 23% !important
    }

    .lg-p-24 {
        padding: 24% !important
    }

    .lg-p-25 {
        padding: 25% !important
    }

    .lg-p-26 {
        padding: 26% !important
    }

    .lg-p-27 {
        padding: 27% !important
    }

    .lg-p-28 {
        padding: 28% !important
    }

    .lg-p-29 {
        padding: 29% !important
    }

    .lg-p-30 {
        padding: 30% !important
    }

    .lg-pt-5px {
        padding-top: 5px !important
    }

    .lg-pt-10px {
        padding-top: 10px !important
    }

    .lg-pt-15px {
        padding-top: 15px !important
    }

    .lg-pt-20px {
        padding-top: 20px !important
    }

    .lg-pt-25px {
        padding-top: 25px !important
    }

    .lg-pt-30px {
        padding-top: 30px !important
    }

    .lg-pt-35px {
        padding-top: 35px !important
    }

    .lg-pt-40px {
        padding-top: 40px !important
    }

    .lg-pt-45px {
        padding-top: 45px !important
    }

    .lg-pt-50px {
        padding-top: 50px !important
    }

    .lg-pt-55px {
        padding-top: 55px !important
    }

    .lg-pt-60px {
        padding-top: 60px !important
    }

    .lg-pt-65px {
        padding-top: 65px !important
    }

    .lg-pt-70px {
        padding-top: 70px !important
    }

    .lg-pt-1 {
        padding-top: 1% !important
    }

    .lg-pt-2 {
        padding-top: 2% !important
    }

    .lg-pt-3 {
        padding-top: 3% !important
    }

    .lg-pt-4 {
        padding-top: 4% !important
    }

    .lg-pt-5 {
        padding-top: 5% !important
    }

    .lg-pt-6 {
        padding-top: 6% !important
    }

    .lg-pt-7 {
        padding-top: 7% !important
    }

    .lg-pt-8 {
        padding-top: 8% !important
    }

    .lg-pt-9 {
        padding-top: 9% !important
    }

    .lg-pt-10 {
        padding-top: 10% !important
    }

    .lg-pt-11 {
        padding-top: 11% !important
    }

    .lg-pt-12 {
        padding-top: 12% !important
    }

    .lg-pt-13 {
        padding-top: 13% !important
    }

    .lg-pt-14 {
        padding-top: 14% !important
    }

    .lg-pt-15 {
        padding-top: 15% !important
    }

    .lg-pt-16 {
        padding-top: 16% !important
    }

    .lg-pt-17 {
        padding-top: 17% !important
    }

    .lg-pt-18 {
        padding-top: 18% !important
    }

    .lg-pt-19 {
        padding-top: 19% !important
    }

    .lg-pt-20 {
        padding-top: 20% !important
    }

    .lg-pt-21 {
        padding-top: 21% !important
    }

    .lg-pt-22 {
        padding-top: 22% !important
    }

    .lg-pt-23 {
        padding-top: 23% !important
    }

    .lg-pt-24 {
        padding-top: 24% !important
    }

    .lg-pt-25 {
        padding-top: 25% !important
    }

    .lg-pt-26 {
        padding-top: 26% !important
    }

    .lg-pt-27 {
        padding-top: 27% !important
    }

    .lg-pt-28 {
        padding-top: 28% !important
    }

    .lg-pt-29 {
        padding-top: 29% !important
    }

    .lg-pt-30 {
        padding-top: 30% !important
    }

    .lg-pe-5px {
        padding-right: 5px !important
    }

    .lg-pe-10px {
        padding-right: 10px !important
    }

    .lg-pe-15px {
        padding-right: 15px !important
    }

    .lg-pe-20px {
        padding-right: 20px !important
    }

    .lg-pe-25px {
        padding-right: 25px !important
    }

    .lg-pe-30px {
        padding-right: 30px !important
    }

    .lg-pe-35px {
        padding-right: 35px !important
    }

    .lg-pe-40px {
        padding-right: 40px !important
    }

    .lg-pe-45px {
        padding-right: 45px !important
    }

    .lg-pe-50px {
        padding-right: 50px !important
    }

    .lg-pe-55px {
        padding-right: 55px !important
    }

    .lg-pe-60px {
        padding-right: 60px !important
    }

    .lg-pe-65px {
        padding-right: 65px !important
    }

    .lg-pe-70px {
        padding-right: 70px !important
    }

    .lg-pe-1 {
        padding-right: 1% !important
    }

    .lg-pe-2 {
        padding-right: 2% !important
    }

    .lg-pe-3 {
        padding-right: 3% !important
    }

    .lg-pe-4 {
        padding-right: 4% !important
    }

    .lg-pe-5 {
        padding-right: 5% !important
    }

    .lg-pe-6 {
        padding-right: 6% !important
    }

    .lg-pe-7 {
        padding-right: 7% !important
    }

    .lg-pe-8 {
        padding-right: 8% !important
    }

    .lg-pe-9 {
        padding-right: 9% !important
    }

    .lg-pe-10 {
        padding-right: 10% !important
    }

    .lg-pe-11 {
        padding-right: 11% !important
    }

    .lg-pe-12 {
        padding-right: 12% !important
    }

    .lg-pe-13 {
        padding-right: 13% !important
    }

    .lg-pe-14 {
        padding-right: 14% !important
    }

    .lg-pe-15 {
        padding-right: 15% !important
    }

    .lg-pe-16 {
        padding-right: 16% !important
    }

    .lg-pe-17 {
        padding-right: 17% !important
    }

    .lg-pe-18 {
        padding-right: 18% !important
    }

    .lg-pe-19 {
        padding-right: 19% !important
    }

    .lg-pe-20 {
        padding-right: 20% !important
    }

    .lg-pe-21 {
        padding-right: 21% !important
    }

    .lg-pe-22 {
        padding-right: 22% !important
    }

    .lg-pe-23 {
        padding-right: 23% !important
    }

    .lg-pe-24 {
        padding-right: 24% !important
    }

    .lg-pe-25 {
        padding-right: 25% !important
    }

    .lg-pe-26 {
        padding-right: 26% !important
    }

    .lg-pe-27 {
        padding-right: 27% !important
    }

    .lg-pe-28 {
        padding-right: 28% !important
    }

    .lg-pe-29 {
        padding-right: 29% !important
    }

    .lg-pe-30 {
        padding-right: 30% !important
    }

    .lg-pb-5px {
        padding-bottom: 5px !important
    }

    .lg-pb-10px {
        padding-bottom: 10px !important
    }

    .lg-pb-15px {
        padding-bottom: 15px !important
    }

    .lg-pb-20px {
        padding-bottom: 20px !important
    }

    .lg-pb-25px {
        padding-bottom: 25px !important
    }

    .lg-pb-30px {
        padding-bottom: 30px !important
    }

    .lg-pb-35px {
        padding-bottom: 35px !important
    }

    .lg-pb-40px {
        padding-bottom: 40px !important
    }

    .lg-pb-45px {
        padding-bottom: 45px !important
    }

    .lg-pb-50px {
        padding-bottom: 50px !important
    }

    .lg-pb-55px {
        padding-bottom: 55px !important
    }

    .lg-pb-60px {
        padding-bottom: 60px !important
    }

    .lg-pb-65px {
        padding-bottom: 65px !important
    }

    .lg-pb-70px {
        padding-bottom: 70px !important
    }

    .lg-pb-1 {
        padding-bottom: 1% !important
    }

    .lg-pb-2 {
        padding-bottom: 2% !important
    }

    .lg-pb-3 {
        padding-bottom: 3% !important
    }

    .lg-pb-4 {
        padding-bottom: 4% !important
    }

    .lg-pb-5 {
        padding-bottom: 5% !important
    }

    .lg-pb-6 {
        padding-bottom: 6% !important
    }

    .lg-pb-7 {
        padding-bottom: 7% !important
    }

    .lg-pb-8 {
        padding-bottom: 8% !important
    }

    .lg-pb-9 {
        padding-bottom: 9% !important
    }

    .lg-pb-10 {
        padding-bottom: 10% !important
    }

    .lg-pb-11 {
        padding-bottom: 11% !important
    }

    .lg-pb-12 {
        padding-bottom: 12% !important
    }

    .lg-pb-13 {
        padding-bottom: 13% !important
    }

    .lg-pb-14 {
        padding-bottom: 14% !important
    }

    .lg-pb-15 {
        padding-bottom: 15% !important
    }

    .lg-pb-16 {
        padding-bottom: 16% !important
    }

    .lg-pb-17 {
        padding-bottom: 17% !important
    }

    .lg-pb-18 {
        padding-bottom: 18% !important
    }

    .lg-pb-19 {
        padding-bottom: 19% !important
    }

    .lg-pb-20 {
        padding-bottom: 20% !important
    }

    .lg-pb-21 {
        padding-bottom: 21% !important
    }

    .lg-pb-22 {
        padding-bottom: 22% !important
    }

    .lg-pb-23 {
        padding-bottom: 23% !important
    }

    .lg-pb-24 {
        padding-bottom: 24% !important
    }

    .lg-pb-25 {
        padding-bottom: 25% !important
    }

    .lg-pb-26 {
        padding-bottom: 26% !important
    }

    .lg-pb-27 {
        padding-bottom: 27% !important
    }

    .lg-pb-28 {
        padding-bottom: 28% !important
    }

    .lg-pb-29 {
        padding-bottom: 29% !important
    }

    .lg-pb-30 {
        padding-bottom: 30% !important
    }

    .lg-ps-5px {
        padding-left: 5px !important
    }

    .lg-ps-10px {
        padding-left: 10px !important
    }

    .lg-ps-15px {
        padding-left: 15px !important
    }

    .lg-ps-20px {
        padding-left: 20px !important
    }

    .lg-ps-25px {
        padding-left: 25px !important
    }

    .lg-ps-30px {
        padding-left: 30px !important
    }

    .lg-ps-35px {
        padding-left: 35px !important
    }

    .lg-ps-40px {
        padding-left: 40px !important
    }

    .lg-ps-45px {
        padding-left: 45px !important
    }

    .lg-ps-50px {
        padding-left: 50px !important
    }

    .lg-ps-55px {
        padding-left: 55px !important
    }

    .lg-ps-60px {
        padding-left: 60px !important
    }

    .lg-ps-65px {
        padding-left: 65px !important
    }

    .lg-ps-70px {
        padding-left: 70px !important
    }

    .lg-ps-1 {
        padding-left: 1% !important
    }

    .lg-ps-2 {
        padding-left: 2% !important
    }

    .lg-ps-3 {
        padding-left: 3% !important
    }

    .lg-ps-4 {
        padding-left: 4% !important
    }

    .lg-ps-5 {
        padding-left: 5% !important
    }

    .lg-ps-6 {
        padding-left: 6% !important
    }

    .lg-ps-7 {
        padding-left: 7% !important
    }

    .lg-ps-8 {
        padding-left: 8% !important
    }

    .lg-ps-9 {
        padding-left: 9% !important
    }

    .lg-ps-10 {
        padding-left: 10% !important
    }

    .lg-ps-11 {
        padding-left: 11% !important
    }

    .lg-ps-12 {
        padding-left: 12% !important
    }

    .lg-ps-13 {
        padding-left: 13% !important
    }

    .lg-ps-14 {
        padding-left: 14% !important
    }

    .lg-ps-15 {
        padding-left: 15% !important
    }

    .lg-ps-16 {
        padding-left: 16% !important
    }

    .lg-ps-17 {
        padding-left: 17% !important
    }

    .lg-ps-18 {
        padding-left: 18% !important
    }

    .lg-ps-19 {
        padding-left: 19% !important
    }

    .lg-ps-20 {
        padding-left: 20% !important
    }

    .lg-ps-21 {
        padding-left: 21% !important
    }

    .lg-ps-22 {
        padding-left: 22% !important
    }

    .lg-ps-23 {
        padding-left: 23% !important
    }

    .lg-ps-24 {
        padding-left: 24% !important
    }

    .lg-ps-25 {
        padding-left: 25% !important
    }

    .lg-ps-26 {
        padding-left: 26% !important
    }

    .lg-ps-27 {
        padding-left: 27% !important
    }

    .lg-ps-28 {
        padding-left: 28% !important
    }

    .lg-ps-29 {
        padding-left: 29% !important
    }

    .lg-ps-30 {
        padding-left: 30% !important
    }

    .lg-m-5px {
        margin: 5px !important
    }

    .lg-m-10px {
        margin: 10px !important
    }

    .lg-m-15px {
        margin: 15px !important
    }

    .lg-m-20px {
        margin: 20px !important
    }

    .lg-m-25px {
        margin: 25px !important
    }

    .lg-m-30px {
        margin: 30px !important
    }

    .lg-m-35px {
        margin: 35px !important
    }

    .lg-m-40px {
        margin: 40px !important
    }

    .lg-m-45px {
        margin: 45px !important
    }

    .lg-m-50px {
        margin: 50px !important
    }

    .lg-m-55px {
        margin: 55px !important
    }

    .lg-m-60px {
        margin: 60px !important
    }

    .lg-m-65px {
        margin: 65px !important
    }

    .lg-m-70px {
        margin: 70px !important
    }

    .lg-m-1 {
        margin: 1% !important
    }

    .lg-m-2 {
        margin: 2% !important
    }

    .lg-m-3 {
        margin: 3% !important
    }

    .lg-m-4 {
        margin: 4% !important
    }

    .lg-m-5 {
        margin: 5% !important
    }

    .lg-m-6 {
        margin: 6% !important
    }

    .lg-m-7 {
        margin: 7% !important
    }

    .lg-m-8 {
        margin: 8% !important
    }

    .lg-m-9 {
        margin: 9% !important
    }

    .lg-m-10 {
        margin: 10% !important
    }

    .lg-m-11 {
        margin: 11% !important
    }

    .lg-m-12 {
        margin: 12% !important
    }

    .lg-m-13 {
        margin: 13% !important
    }

    .lg-m-14 {
        margin: 14% !important
    }

    .lg-m-15 {
        margin: 15% !important
    }

    .lg-m-16 {
        margin: 16% !important
    }

    .lg-m-17 {
        margin: 17% !important
    }

    .lg-m-18 {
        margin: 18% !important
    }

    .lg-m-19 {
        margin: 19% !important
    }

    .lg-m-20 {
        margin: 20% !important
    }

    .lg-m-21 {
        margin: 21% !important
    }

    .lg-m-22 {
        margin: 22% !important
    }

    .lg-m-23 {
        margin: 23% !important
    }

    .lg-m-24 {
        margin: 24% !important
    }

    .lg-m-25 {
        margin: 25% !important
    }

    .lg-m-26 {
        margin: 26% !important
    }

    .lg-m-27 {
        margin: 27% !important
    }

    .lg-m-28 {
        margin: 28% !important
    }

    .lg-m-29 {
        margin: 29% !important
    }

    .lg-m-30 {
        margin: 30% !important
    }

    .lg-mt-5px {
        margin-top: 5px !important
    }

    .lg-mt-10px {
        margin-top: 10px !important
    }

    .lg-mt-15px {
        margin-top: 15px !important
    }

    .lg-mt-20px {
        margin-top: 20px !important
    }

    .lg-mt-25px {
        margin-top: 25px !important
    }

    .lg-mt-30px {
        margin-top: 30px !important
    }

    .lg-mt-35px {
        margin-top: 35px !important
    }

    .lg-mt-40px {
        margin-top: 40px !important
    }

    .lg-mt-45px {
        margin-top: 45px !important
    }

    .lg-mt-50px {
        margin-top: 50px !important
    }

    .lg-mt-55px {
        margin-top: 55px !important
    }

    .lg-mt-60px {
        margin-top: 60px !important
    }

    .lg-mt-65px {
        margin-top: 65px !important
    }

    .lg-mt-70px {
        margin-top: 70px !important
    }

    .lg-mt-1 {
        margin-top: 1% !important
    }

    .lg-mt-2 {
        margin-top: 2% !important
    }

    .lg-mt-3 {
        margin-top: 3% !important
    }

    .lg-mt-4 {
        margin-top: 4% !important
    }

    .lg-mt-5 {
        margin-top: 5% !important
    }

    .lg-mt-6 {
        margin-top: 6% !important
    }

    .lg-mt-7 {
        margin-top: 7% !important
    }

    .lg-mt-8 {
        margin-top: 8% !important
    }

    .lg-mt-9 {
        margin-top: 9% !important
    }

    .lg-mt-10 {
        margin-top: 10% !important
    }

    .lg-mt-11 {
        margin-top: 11% !important
    }

    .lg-mt-12 {
        margin-top: 12% !important
    }

    .lg-mt-13 {
        margin-top: 13% !important
    }

    .lg-mt-14 {
        margin-top: 14% !important
    }

    .lg-mt-15 {
        margin-top: 15% !important
    }

    .lg-mt-16 {
        margin-top: 16% !important
    }

    .lg-mt-17 {
        margin-top: 17% !important
    }

    .lg-mt-18 {
        margin-top: 18% !important
    }

    .lg-mt-19 {
        margin-top: 19% !important
    }

    .lg-mt-20 {
        margin-top: 20% !important
    }

    .lg-mt-21 {
        margin-top: 21% !important
    }

    .lg-mt-22 {
        margin-top: 22% !important
    }

    .lg-mt-23 {
        margin-top: 23% !important
    }

    .lg-mt-24 {
        margin-top: 24% !important
    }

    .lg-mt-25 {
        margin-top: 25% !important
    }

    .lg-mt-26 {
        margin-top: 26% !important
    }

    .lg-mt-27 {
        margin-top: 27% !important
    }

    .lg-mt-28 {
        margin-top: 28% !important
    }

    .lg-mt-29 {
        margin-top: 29% !important
    }

    .lg-mt-30 {
        margin-top: 30% !important
    }

    .lg-me-5px {
        margin-right: 5px !important
    }

    .lg-me-10px {
        margin-right: 10px !important
    }

    .lg-me-15px {
        margin-right: 15px !important
    }

    .lg-me-20px {
        margin-right: 20px !important
    }

    .lg-me-25px {
        margin-right: 25px !important
    }

    .lg-me-30px {
        margin-right: 30px !important
    }

    .lg-me-35px {
        margin-right: 35px !important
    }

    .lg-me-40px {
        margin-right: 40px !important
    }

    .lg-me-45px {
        margin-right: 45px !important
    }

    .lg-me-50px {
        margin-right: 50px !important
    }

    .lg-me-55px {
        margin-right: 55px !important
    }

    .lg-me-60px {
        margin-right: 60px !important
    }

    .lg-me-65px {
        margin-right: 65px !important
    }

    .lg-me-70px {
        margin-right: 70px !important
    }

    .lg-me-1 {
        margin-right: 1% !important
    }

    .lg-me-2 {
        margin-right: 2% !important
    }

    .lg-me-3 {
        margin-right: 3% !important
    }

    .lg-me-4 {
        margin-right: 4% !important
    }

    .lg-me-5 {
        margin-right: 5% !important
    }

    .lg-me-6 {
        margin-right: 6% !important
    }

    .lg-me-7 {
        margin-right: 7% !important
    }

    .lg-me-8 {
        margin-right: 8% !important
    }

    .lg-me-9 {
        margin-right: 9% !important
    }

    .lg-me-10 {
        margin-right: 10% !important
    }

    .lg-me-11 {
        margin-right: 11% !important
    }

    .lg-me-12 {
        margin-right: 12% !important
    }

    .lg-me-13 {
        margin-right: 13% !important
    }

    .lg-me-14 {
        margin-right: 14% !important
    }

    .lg-me-15 {
        margin-right: 15% !important
    }

    .lg-me-16 {
        margin-right: 16% !important
    }

    .lg-me-17 {
        margin-right: 17% !important
    }

    .lg-me-18 {
        margin-right: 18% !important
    }

    .lg-me-19 {
        margin-right: 19% !important
    }

    .lg-me-20 {
        margin-right: 20% !important
    }

    .lg-me-21 {
        margin-right: 21% !important
    }

    .lg-me-22 {
        margin-right: 22% !important
    }

    .lg-me-23 {
        margin-right: 23% !important
    }

    .lg-me-24 {
        margin-right: 24% !important
    }

    .lg-me-25 {
        margin-right: 25% !important
    }

    .lg-me-26 {
        margin-right: 26% !important
    }

    .lg-me-27 {
        margin-right: 27% !important
    }

    .lg-me-28 {
        margin-right: 28% !important
    }

    .lg-me-29 {
        margin-right: 29% !important
    }

    .lg-me-30 {
        margin-right: 30% !important
    }

    .lg-mb-5px {
        margin-bottom: 5px !important
    }

    .lg-mb-10px {
        margin-bottom: 10px !important
    }

    .lg-mb-15px {
        margin-bottom: 15px !important
    }

    .lg-mb-20px {
        margin-bottom: 20px !important
    }

    .lg-mb-25px {
        margin-bottom: 25px !important
    }

    .lg-mb-30px {
        margin-bottom: 30px !important
    }

    .lg-mb-35px {
        margin-bottom: 35px !important
    }

    .lg-mb-40px {
        margin-bottom: 40px !important
    }

    .lg-mb-45px {
        margin-bottom: 45px !important
    }

    .lg-mb-50px {
        margin-bottom: 50px !important
    }

    .lg-mb-55px {
        margin-bottom: 55px !important
    }

    .lg-mb-60px {
        margin-bottom: 60px !important
    }

    .lg-mb-65px {
        margin-bottom: 65px !important
    }

    .lg-mb-70px {
        margin-bottom: 70px !important
    }

    .lg-mb-1 {
        margin-bottom: 1% !important
    }

    .lg-mb-2 {
        margin-bottom: 2% !important
    }

    .lg-mb-3 {
        margin-bottom: 3% !important
    }

    .lg-mb-4 {
        margin-bottom: 4% !important
    }

    .lg-mb-5 {
        margin-bottom: 5% !important
    }

    .lg-mb-6 {
        margin-bottom: 6% !important
    }

    .lg-mb-7 {
        margin-bottom: 7% !important
    }

    .lg-mb-8 {
        margin-bottom: 8% !important
    }

    .lg-mb-9 {
        margin-bottom: 9% !important
    }

    .lg-mb-10 {
        margin-bottom: 10% !important
    }

    .lg-mb-11 {
        margin-bottom: 11% !important
    }

    .lg-mb-12 {
        margin-bottom: 12% !important
    }

    .lg-mb-13 {
        margin-bottom: 13% !important
    }

    .lg-mb-14 {
        margin-bottom: 14% !important
    }

    .lg-mb-15 {
        margin-bottom: 15% !important
    }

    .lg-mb-16 {
        margin-bottom: 16% !important
    }

    .lg-mb-17 {
        margin-bottom: 17% !important
    }

    .lg-mb-18 {
        margin-bottom: 18% !important
    }

    .lg-mb-19 {
        margin-bottom: 19% !important
    }

    .lg-mb-20 {
        margin-bottom: 20% !important
    }

    .lg-mb-21 {
        margin-bottom: 21% !important
    }

    .lg-mb-22 {
        margin-bottom: 22% !important
    }

    .lg-mb-23 {
        margin-bottom: 23% !important
    }

    .lg-mb-24 {
        margin-bottom: 24% !important
    }

    .lg-mb-25 {
        margin-bottom: 25% !important
    }

    .lg-mb-26 {
        margin-bottom: 26% !important
    }

    .lg-mb-27 {
        margin-bottom: 27% !important
    }

    .lg-mb-28 {
        margin-bottom: 28% !important
    }

    .lg-mb-29 {
        margin-bottom: 29% !important
    }

    .lg-mb-30 {
        margin-bottom: 30% !important
    }

    .lg-ms-5px {
        margin-left: 5px !important
    }

    .lg-ms-10px {
        margin-left: 10px !important
    }

    .lg-ms-15px {
        margin-left: 15px !important
    }

    .lg-ms-20px {
        margin-left: 20px !important
    }

    .lg-ms-25px {
        margin-left: 25px !important
    }

    .lg-ms-30px {
        margin-left: 30px !important
    }

    .lg-ms-35px {
        margin-left: 35px !important
    }

    .lg-ms-40px {
        margin-left: 40px !important
    }

    .lg-ms-45px {
        margin-left: 45px !important
    }

    .lg-ms-50px {
        margin-left: 50px !important
    }

    .lg-ms-55px {
        margin-left: 55px !important
    }

    .lg-ms-60px {
        margin-left: 60px !important
    }

    .lg-ms-65px {
        margin-left: 65px !important
    }

    .lg-ms-70px {
        margin-left: 70px !important
    }

    .lg-ms-1 {
        margin-left: 1% !important
    }

    .lg-ms-2 {
        margin-left: 2% !important
    }

    .lg-ms-3 {
        margin-left: 3% !important
    }

    .lg-ms-4 {
        margin-left: 4% !important
    }

    .lg-ms-5 {
        margin-left: 5% !important
    }

    .lg-ms-6 {
        margin-left: 6% !important
    }

    .lg-ms-7 {
        margin-left: 7% !important
    }

    .lg-ms-8 {
        margin-left: 8% !important
    }

    .lg-ms-9 {
        margin-left: 9% !important
    }

    .lg-ms-10 {
        margin-left: 10% !important
    }

    .lg-ms-11 {
        margin-left: 11% !important
    }

    .lg-ms-12 {
        margin-left: 12% !important
    }

    .lg-ms-13 {
        margin-left: 13% !important
    }

    .lg-ms-14 {
        margin-left: 14% !important
    }

    .lg-ms-15 {
        margin-left: 15% !important
    }

    .lg-ms-16 {
        margin-left: 16% !important
    }

    .lg-ms-17 {
        margin-left: 17% !important
    }

    .lg-ms-18 {
        margin-left: 18% !important
    }

    .lg-ms-19 {
        margin-left: 19% !important
    }

    .lg-ms-20 {
        margin-left: 20% !important
    }

    .lg-ms-21 {
        margin-left: 21% !important
    }

    .lg-ms-22 {
        margin-left: 22% !important
    }

    .lg-ms-23 {
        margin-left: 23% !important
    }

    .lg-ms-24 {
        margin-left: 24% !important
    }

    .lg-ms-25 {
        margin-left: 25% !important
    }

    .lg-ms-26 {
        margin-left: 26% !important
    }

    .lg-ms-27 {
        margin-left: 27% !important
    }

    .lg-ms-28 {
        margin-left: 28% !important
    }

    .lg-ms-29 {
        margin-left: 29% !important
    }

    .lg-ms-30 {
        margin-left: 30% !important
    }

    .lg-top-0px {
        top: 0 !important
    }

    .lg-top-1px {
        top: 1px !important
    }

    .lg-top-2px {
        top: 2px !important
    }

    .lg-top-3px {
        top: 3px !important
    }

    .lg-top-4px {
        top: 4px !important
    }

    .lg-top-5px {
        top: 5px !important
    }

    .lg-top-6px {
        top: 6px !important
    }

    .lg-top-7px {
        top: 7px !important
    }

    .lg-top-8px {
        top: 8px !important
    }

    .lg-top-9px {
        top: 9px !important
    }

    .lg-top-10px {
        top: 10px !important
    }

    .lg-top-11px {
        top: 11px !important
    }

    .lg-top-12px {
        top: 12px !important
    }

    .lg-top-13px {
        top: 13px !important
    }

    .lg-top-14px {
        top: 14px !important
    }

    .lg-top-15px {
        top: 15px !important
    }

    .lg-top-16px {
        top: 16px !important
    }

    .lg-top-17px {
        top: 17px !important
    }

    .lg-top-18px {
        top: 18px !important
    }

    .lg-top-19px {
        top: 19px !important
    }

    .lg-top-20px {
        top: 20px !important
    }

    .lg-top-21px {
        top: 21px !important
    }

    .lg-top-22px {
        top: 22px !important
    }

    .lg-top-23px {
        top: 23px !important
    }

    .lg-top-24px {
        top: 24px !important
    }

    .lg-top-25px {
        top: 25px !important
    }

    .lg-top-26px {
        top: 26px !important
    }

    .lg-top-27px {
        top: 27px !important
    }

    .lg-top-28px {
        top: 28px !important
    }

    .lg-top-29px {
        top: 29px !important
    }

    .lg-top-30px {
        top: 30px !important
    }

    .lg-top-40px {
        top: 40px !important
    }

    .lg-top-50px {
        top: 50px !important
    }

    .lg-top-60px {
        top: 60px !important
    }

    .lg-top-70px {
        top: 70px !important
    }

    .lg-top-80px {
        top: 80px !important
    }

    .lg-top-90px {
        top: 90px !important
    }

    .lg-top-100px {
        top: 100px !important
    }

    .lg-top-110px {
        top: 110px !important
    }

    .lg-top-120px {
        top: 120px !important
    }

    .lg-top-130px {
        top: 130px !important
    }

    .lg-top-140px {
        top: 140px !important
    }

    .lg-top-150px {
        top: 150px !important
    }

    .lg-right-0px {
        right: 0 !important
    }

    .lg-right-1px {
        right: 1px !important
    }

    .lg-right-2px {
        right: 2px !important
    }

    .lg-right-3px {
        right: 3px !important
    }

    .lg-right-4px {
        right: 4px !important
    }

    .lg-right-5px {
        right: 5px !important
    }

    .lg-right-6px {
        right: 6px !important
    }

    .lg-right-7px {
        right: 7px !important
    }

    .lg-right-8px {
        right: 8px !important
    }

    .lg-right-9px {
        right: 9px !important
    }

    .lg-right-10px {
        right: 10px !important
    }

    .lg-right-11px {
        right: 11px !important
    }

    .lg-right-12px {
        right: 12px !important
    }

    .lg-right-13px {
        right: 13px !important
    }

    .lg-right-14px {
        right: 14px !important
    }

    .lg-right-15px {
        right: 15px !important
    }

    .lg-right-16px {
        right: 16px !important
    }

    .lg-right-17px {
        right: 17px !important
    }

    .lg-right-18px {
        right: 18px !important
    }

    .lg-right-19px {
        right: 19px !important
    }

    .lg-right-20px {
        right: 20px !important
    }

    .lg-right-21px {
        right: 21px !important
    }

    .lg-right-22px {
        right: 22px !important
    }

    .lg-right-23px {
        right: 23px !important
    }

    .lg-right-24px {
        right: 24px !important
    }

    .lg-right-25px {
        right: 25px !important
    }

    .lg-right-26px {
        right: 26px !important
    }

    .lg-right-27px {
        right: 27px !important
    }

    .lg-right-28px {
        right: 28px !important
    }

    .lg-right-29px {
        right: 29px !important
    }

    .lg-right-30px {
        right: 30px !important
    }

    .lg-right-40px {
        right: 40px !important
    }

    .lg-right-50px {
        right: 50px !important
    }

    .lg-right-60px {
        right: 60px !important
    }

    .lg-right-70px {
        right: 70px !important
    }

    .lg-right-80px {
        right: 80px !important
    }

    .lg-right-90px {
        right: 90px !important
    }

    .lg-right-100px {
        right: 100px !important
    }

    .lg-right-110px {
        right: 110px !important
    }

    .lg-right-120px {
        right: 120px !important
    }

    .lg-right-130px {
        right: 130px !important
    }

    .lg-right-140px {
        right: 140px !important
    }

    .lg-right-150px {
        right: 150px !important
    }

    .lg-bottom-0px {
        bottom: 0 !important
    }

    .lg-bottom-1px {
        bottom: 1px !important
    }

    .lg-bottom-2px {
        bottom: 2px !important
    }

    .lg-bottom-3px {
        bottom: 3px !important
    }

    .lg-bottom-4px {
        bottom: 4px !important
    }

    .lg-bottom-5px {
        bottom: 5px !important
    }

    .lg-bottom-6px {
        bottom: 6px !important
    }

    .lg-bottom-7px {
        bottom: 7px !important
    }

    .lg-bottom-8px {
        bottom: 8px !important
    }

    .lg-bottom-9px {
        bottom: 9px !important
    }

    .lg-bottom-10px {
        bottom: 10px !important
    }

    .lg-bottom-11px {
        bottom: 11px !important
    }

    .lg-bottom-12px {
        bottom: 12px !important
    }

    .lg-bottom-13px {
        bottom: 13px !important
    }

    .lg-bottom-14px {
        bottom: 14px !important
    }

    .lg-bottom-15px {
        bottom: 15px !important
    }

    .lg-bottom-16px {
        bottom: 16px !important
    }

    .lg-bottom-17px {
        bottom: 17px !important
    }

    .lg-bottom-18px {
        bottom: 18px !important
    }

    .lg-bottom-19px {
        bottom: 19px !important
    }

    .lg-bottom-20px {
        bottom: 20px !important
    }

    .lg-bottom-21px {
        bottom: 21px !important
    }

    .lg-bottom-22px {
        bottom: 22px !important
    }

    .lg-bottom-23px {
        bottom: 23px !important
    }

    .lg-bottom-24px {
        bottom: 24px !important
    }

    .lg-bottom-25px {
        bottom: 25px !important
    }

    .lg-bottom-26px {
        bottom: 26px !important
    }

    .lg-bottom-27px {
        bottom: 27px !important
    }

    .lg-bottom-28px {
        bottom: 28px !important
    }

    .lg-bottom-29px {
        bottom: 29px !important
    }

    .lg-bottom-30px {
        bottom: 30px !important
    }

    .lg-bottom-40px {
        bottom: 40px !important
    }

    .lg-bottom-50px {
        bottom: 50px !important
    }

    .lg-bottom-60px {
        bottom: 60px !important
    }

    .lg-bottom-70px {
        bottom: 70px !important
    }

    .lg-bottom-80px {
        bottom: 80px !important
    }

    .lg-bottom-90px {
        bottom: 90px !important
    }

    .lg-bottom-100px {
        bottom: 100px !important
    }

    .lg-bottom-110px {
        bottom: 110px !important
    }

    .lg-bottom-120px {
        bottom: 120px !important
    }

    .lg-bottom-130px {
        bottom: 130px !important
    }

    .lg-bottom-140px {
        bottom: 140px !important
    }

    .lg-bottom-150px {
        bottom: 150px !important
    }

    .lg-left-0px {
        left: 0 !important
    }

    .lg-left-1px {
        left: 1px !important
    }

    .lg-left-2px {
        left: 2px !important
    }

    .lg-left-3px {
        left: 3px !important
    }

    .lg-left-4px {
        left: 4px !important
    }

    .lg-left-5px {
        left: 5px !important
    }

    .lg-left-6px {
        left: 6px !important
    }

    .lg-left-7px {
        left: 7px !important
    }

    .lg-left-8px {
        left: 8px !important
    }

    .lg-left-9px {
        left: 9px !important
    }

    .lg-left-10px {
        left: 10px !important
    }

    .lg-left-11px {
        left: 11px !important
    }

    .lg-left-12px {
        left: 12px !important
    }

    .lg-left-13px {
        left: 13px !important
    }

    .lg-left-14px {
        left: 14px !important
    }

    .lg-left-15px {
        left: 15px !important
    }

    .lg-left-16px {
        left: 16px !important
    }

    .lg-left-17px {
        left: 17px !important
    }

    .lg-left-18px {
        left: 18px !important
    }

    .lg-left-19px {
        left: 19px !important
    }

    .lg-left-20px {
        left: 20px !important
    }

    .lg-left-21px {
        left: 21px !important
    }

    .lg-left-22px {
        left: 22px !important
    }

    .lg-left-23px {
        left: 23px !important
    }

    .lg-left-24px {
        left: 24px !important
    }

    .lg-left-25px {
        left: 25px !important
    }

    .lg-left-26px {
        left: 26px !important
    }

    .lg-left-27px {
        left: 27px !important
    }

    .lg-left-28px {
        left: 28px !important
    }

    .lg-left-29px {
        left: 29px !important
    }

    .lg-left-30px {
        left: 30px !important
    }

    .lg-left-40px {
        left: 40px !important
    }

    .lg-left-50px {
        left: 50px !important
    }

    .lg-left-60px {
        left: 60px !important
    }

    .lg-left-70px {
        left: 70px !important
    }

    .lg-left-80px {
        left: 80px !important
    }

    .lg-left-90px {
        left: 90px !important
    }

    .lg-left-100px {
        left: 100px !important
    }

    .lg-left-110px {
        left: 110px !important
    }

    .lg-left-120px {
        left: 120px !important
    }

    .lg-left-130px {
        left: 130px !important
    }

    .lg-left-140px {
        left: 140px !important
    }

    .lg-left-150px {
        left: 150px !important
    }

    .lg-top-minus-0px {
        top: 0 !important
    }

    .lg-top-minus-1px {
        top: -1px !important
    }

    .lg-top-minus-2px {
        top: -2px !important
    }

    .lg-top-minus-3px {
        top: -3px !important
    }

    .lg-top-minus-4px {
        top: -4px !important
    }

    .lg-top-minus-5px {
        top: -5px !important
    }

    .lg-top-minus-6px {
        top: -6px !important
    }

    .lg-top-minus-7px {
        top: -7px !important
    }

    .lg-top-minus-8px {
        top: -8px !important
    }

    .lg-top-minus-9px {
        top: -9px !important
    }

    .lg-top-minus-10px {
        top: -10px !important
    }

    .lg-top-minus-11px {
        top: -11px !important
    }

    .lg-top-minus-12px {
        top: -12px !important
    }

    .lg-top-minus-13px {
        top: -13px !important
    }

    .lg-top-minus-14px {
        top: -14px !important
    }

    .lg-top-minus-15px {
        top: -15px !important
    }

    .lg-top-minus-16px {
        top: -16px !important
    }

    .lg-top-minus-17px {
        top: -17px !important
    }

    .lg-top-minus-18px {
        top: -18px !important
    }

    .lg-top-minus-19px {
        top: -19px !important
    }

    .lg-top-minus-20px {
        top: -20px !important
    }

    .lg-top-minus-21px {
        top: -21px !important
    }

    .lg-top-minus-22px {
        top: -22px !important
    }

    .lg-top-minus-23px {
        top: -23px !important
    }

    .lg-top-minus-24px {
        top: -24px !important
    }

    .lg-top-minus-25px {
        top: -25px !important
    }

    .lg-top-minus-26px {
        top: -26px !important
    }

    .lg-top-minus-27px {
        top: -27px !important
    }

    .lg-top-minus-28px {
        top: -28px !important
    }

    .lg-top-minus-29px {
        top: -29px !important
    }

    .lg-top-minus-30px {
        top: -30px !important
    }

    .lg-top-minus-40px {
        top: -40px !important
    }

    .lg-top-minus-50px {
        top: -50px !important
    }

    .lg-top-minus-60px {
        top: -60px !important
    }

    .lg-top-minus-70px {
        top: -70px !important
    }

    .lg-top-minus-80px {
        top: -80px !important
    }

    .lg-top-minus-90px {
        top: -90px !important
    }

    .lg-top-minus-100px {
        top: -100px !important
    }

    .lg-top-minus-110px {
        top: -110px !important
    }

    .lg-top-minus-120px {
        top: -120px !important
    }

    .lg-top-minus-130px {
        top: -130px !important
    }

    .lg-top-minus-140px {
        top: -140px !important
    }

    .lg-top-minus-150px {
        top: -150px !important
    }

    .lg-right-minus-0px {
        right: 0 !important
    }

    .lg-right-minus-1px {
        right: -1px !important
    }

    .lg-right-minus-2px {
        right: -2px !important
    }

    .lg-right-minus-3px {
        right: -3px !important
    }

    .lg-right-minus-4px {
        right: -4px !important
    }

    .lg-right-minus-5px {
        right: -5px !important
    }

    .lg-right-minus-6px {
        right: -6px !important
    }

    .lg-right-minus-7px {
        right: -7px !important
    }

    .lg-right-minus-8px {
        right: -8px !important
    }

    .lg-right-minus-9px {
        right: -9px !important
    }

    .lg-right-minus-10px {
        right: -10px !important
    }

    .lg-right-minus-11px {
        right: -11px !important
    }

    .lg-right-minus-12px {
        right: -12px !important
    }

    .lg-right-minus-13px {
        right: -13px !important
    }

    .lg-right-minus-14px {
        right: -14px !important
    }

    .lg-right-minus-15px {
        right: -15px !important
    }

    .lg-right-minus-16px {
        right: -16px !important
    }

    .lg-right-minus-17px {
        right: -17px !important
    }

    .lg-right-minus-18px {
        right: -18px !important
    }

    .lg-right-minus-19px {
        right: -19px !important
    }

    .lg-right-minus-20px {
        right: -20px !important
    }

    .lg-right-minus-21px {
        right: -21px !important
    }

    .lg-right-minus-22px {
        right: -22px !important
    }

    .lg-right-minus-23px {
        right: -23px !important
    }

    .lg-right-minus-24px {
        right: -24px !important
    }

    .lg-right-minus-25px {
        right: -25px !important
    }

    .lg-right-minus-26px {
        right: -26px !important
    }

    .lg-right-minus-27px {
        right: -27px !important
    }

    .lg-right-minus-28px {
        right: -28px !important
    }

    .lg-right-minus-29px {
        right: -29px !important
    }

    .lg-right-minus-30px {
        right: -30px !important
    }

    .lg-right-minus-40px {
        right: -40px !important
    }

    .lg-right-minus-50px {
        right: -50px !important
    }

    .lg-right-minus-60px {
        right: -60px !important
    }

    .lg-right-minus-70px {
        right: -70px !important
    }

    .lg-right-minus-80px {
        right: -80px !important
    }

    .lg-right-minus-90px {
        right: -90px !important
    }

    .lg-right-minus-100px {
        right: -100px !important
    }

    .lg-right-minus-110px {
        right: -110px !important
    }

    .lg-right-minus-120px {
        right: -120px !important
    }

    .lg-right-minus-130px {
        right: -130px !important
    }

    .lg-right-minus-140px {
        right: -140px !important
    }

    .lg-right-minus-150px {
        right: -150px !important
    }

    .lg-bottom-minus-0px {
        bottom: 0 !important
    }

    .lg-bottom-minus-1px {
        bottom: -1px !important
    }

    .lg-bottom-minus-2px {
        bottom: -2px !important
    }

    .lg-bottom-minus-3px {
        bottom: -3px !important
    }

    .lg-bottom-minus-4px {
        bottom: -4px !important
    }

    .lg-bottom-minus-5px {
        bottom: -5px !important
    }

    .lg-bottom-minus-6px {
        bottom: -6px !important
    }

    .lg-bottom-minus-7px {
        bottom: -7px !important
    }

    .lg-bottom-minus-8px {
        bottom: -8px !important
    }

    .lg-bottom-minus-9px {
        bottom: -9px !important
    }

    .lg-bottom-minus-10px {
        bottom: -10px !important
    }

    .lg-bottom-minus-11px {
        bottom: -11px !important
    }

    .lg-bottom-minus-12px {
        bottom: -12px !important
    }

    .lg-bottom-minus-13px {
        bottom: -13px !important
    }

    .lg-bottom-minus-14px {
        bottom: -14px !important
    }

    .lg-bottom-minus-15px {
        bottom: -15px !important
    }

    .lg-bottom-minus-16px {
        bottom: -16px !important
    }

    .lg-bottom-minus-17px {
        bottom: -17px !important
    }

    .lg-bottom-minus-18px {
        bottom: -18px !important
    }

    .lg-bottom-minus-19px {
        bottom: -19px !important
    }

    .lg-bottom-minus-20px {
        bottom: -20px !important
    }

    .lg-bottom-minus-21px {
        bottom: -21px !important
    }

    .lg-bottom-minus-22px {
        bottom: -22px !important
    }

    .lg-bottom-minus-23px {
        bottom: -23px !important
    }

    .lg-bottom-minus-24px {
        bottom: -24px !important
    }

    .lg-bottom-minus-25px {
        bottom: -25px !important
    }

    .lg-bottom-minus-26px {
        bottom: -26px !important
    }

    .lg-bottom-minus-27px {
        bottom: -27px !important
    }

    .lg-bottom-minus-28px {
        bottom: -28px !important
    }

    .lg-bottom-minus-29px {
        bottom: -29px !important
    }

    .lg-bottom-minus-30px {
        bottom: -30px !important
    }

    .lg-bottom-minus-40px {
        bottom: -40px !important
    }

    .lg-bottom-minus-50px {
        bottom: -50px !important
    }

    .lg-bottom-minus-60px {
        bottom: -60px !important
    }

    .lg-bottom-minus-70px {
        bottom: -70px !important
    }

    .lg-bottom-minus-80px {
        bottom: -80px !important
    }

    .lg-bottom-minus-90px {
        bottom: -90px !important
    }

    .lg-bottom-minus-100px {
        bottom: -100px !important
    }

    .lg-bottom-minus-110px {
        bottom: -110px !important
    }

    .lg-bottom-minus-120px {
        bottom: -120px !important
    }

    .lg-bottom-minus-130px {
        bottom: -130px !important
    }

    .lg-bottom-minus-140px {
        bottom: -140px !important
    }

    .lg-bottom-minus-150px {
        bottom: -150px !important
    }

    .lg-left-minus-0px {
        left: 0 !important
    }

    .lg-left-minus-1px {
        left: -1px !important
    }

    .lg-left-minus-2px {
        left: -2px !important
    }

    .lg-left-minus-3px {
        left: -3px !important
    }

    .lg-left-minus-4px {
        left: -4px !important
    }

    .lg-left-minus-5px {
        left: -5px !important
    }

    .lg-left-minus-6px {
        left: -6px !important
    }

    .lg-left-minus-7px {
        left: -7px !important
    }

    .lg-left-minus-8px {
        left: -8px !important
    }

    .lg-left-minus-9px {
        left: -9px !important
    }

    .lg-left-minus-10px {
        left: -10px !important
    }

    .lg-left-minus-11px {
        left: -11px !important
    }

    .lg-left-minus-12px {
        left: -12px !important
    }

    .lg-left-minus-13px {
        left: -13px !important
    }

    .lg-left-minus-14px {
        left: -14px !important
    }

    .lg-left-minus-15px {
        left: -15px !important
    }

    .lg-left-minus-16px {
        left: -16px !important
    }

    .lg-left-minus-17px {
        left: -17px !important
    }

    .lg-left-minus-18px {
        left: -18px !important
    }

    .lg-left-minus-19px {
        left: -19px !important
    }

    .lg-left-minus-20px {
        left: -20px !important
    }

    .lg-left-minus-21px {
        left: -21px !important
    }

    .lg-left-minus-22px {
        left: -22px !important
    }

    .lg-left-minus-23px {
        left: -23px !important
    }

    .lg-left-minus-24px {
        left: -24px !important
    }

    .lg-left-minus-25px {
        left: -25px !important
    }

    .lg-left-minus-26px {
        left: -26px !important
    }

    .lg-left-minus-27px {
        left: -27px !important
    }

    .lg-left-minus-28px {
        left: -28px !important
    }

    .lg-left-minus-29px {
        left: -29px !important
    }

    .lg-left-minus-30px {
        left: -30px !important
    }

    .lg-left-minus-40px {
        left: -40px !important
    }

    .lg-left-minus-50px {
        left: -50px !important
    }

    .lg-left-minus-60px {
        left: -60px !important
    }

    .lg-left-minus-70px {
        left: -70px !important
    }

    .lg-left-minus-80px {
        left: -80px !important
    }

    .lg-left-minus-90px {
        left: -90px !important
    }

    .lg-left-minus-100px {
        left: -100px !important
    }

    .lg-left-minus-110px {
        left: -110px !important
    }

    .lg-left-minus-120px {
        left: -120px !important
    }

    .lg-left-minus-130px {
        left: -130px !important
    }

    .lg-left-minus-140px {
        left: -140px !important
    }

    .lg-left-minus-150px {
        left: -150px !important
    }

    .lg-border-width-0px {
        border-width: 0 !important
    }

    .lg-border-width-1px {
        border-width: 1px !important
    }

    .lg-border-width-2px {
        border-width: 2px !important
    }

    .lg-border-width-3px {
        border-width: 3px !important
    }

    .lg-border-width-4px {
        border-width: 4px !important
    }

    .lg-border-width-5px {
        border-width: 5px !important
    }

    .lg-border-width-6px {
        border-width: 6px !important
    }

    .lg-border-width-7px {
        border-width: 7px !important
    }

    .lg-border-width-8px {
        border-width: 8px !important
    }

    .lg-border-width-9px {
        border-width: 9px !important
    }

    .lg-border-width-10px {
        border-width: 10px !important
    }
}

@media (max-width:991px) {
    .md-p-5px {
        padding: 5px !important
    }

    .md-p-10px {
        padding: 10px !important
    }

    .md-p-15px {
        padding: 15px !important
    }

    .md-p-20px {
        padding: 20px !important
    }

    .md-p-25px {
        padding: 25px !important
    }

    .md-p-30px {
        padding: 30px !important
    }

    .md-p-35px {
        padding: 35px !important
    }

    .md-p-40px {
        padding: 40px !important
    }

    .md-p-45px {
        padding: 45px !important
    }

    .md-p-50px {
        padding: 50px !important
    }

    .md-p-55px {
        padding: 55px !important
    }

    .md-p-60px {
        padding: 60px !important
    }

    .md-p-65px {
        padding: 65px !important
    }

    .md-p-70px {
        padding: 70px !important
    }

    .md-p-1 {
        padding: 1% !important
    }

    .md-p-2 {
        padding: 2% !important
    }

    .md-p-3 {
        padding: 3% !important
    }

    .md-p-4 {
        padding: 4% !important
    }

    .md-p-5 {
        padding: 5% !important
    }

    .md-p-6 {
        padding: 6% !important
    }

    .md-p-7 {
        padding: 7% !important
    }

    .md-p-8 {
        padding: 8% !important
    }

    .md-p-9 {
        padding: 9% !important
    }

    .md-p-10 {
        padding: 10% !important
    }

    .md-p-11 {
        padding: 11% !important
    }

    .md-p-12 {
        padding: 12% !important
    }

    .md-p-13 {
        padding: 13% !important
    }

    .md-p-14 {
        padding: 14% !important
    }

    .md-p-15 {
        padding: 15% !important
    }

    .md-p-16 {
        padding: 16% !important
    }

    .md-p-17 {
        padding: 17% !important
    }

    .md-p-18 {
        padding: 18% !important
    }

    .md-p-19 {
        padding: 19% !important
    }

    .md-p-20 {
        padding: 20% !important
    }

    .md-p-21 {
        padding: 21% !important
    }

    .md-p-22 {
        padding: 22% !important
    }

    .md-p-23 {
        padding: 23% !important
    }

    .md-p-24 {
        padding: 24% !important
    }

    .md-p-25 {
        padding: 25% !important
    }

    .md-p-26 {
        padding: 26% !important
    }

    .md-p-27 {
        padding: 27% !important
    }

    .md-p-28 {
        padding: 28% !important
    }

    .md-p-29 {
        padding: 29% !important
    }

    .md-p-30 {
        padding: 30% !important
    }

    .md-pt-5px {
        padding-top: 5px !important
    }

    .md-pt-10px {
        padding-top: 10px !important
    }

    .md-pt-15px {
        padding-top: 15px !important
    }

    .md-pt-20px {
        padding-top: 20px !important
    }

    .md-pt-25px {
        padding-top: 25px !important
    }

    .md-pt-30px {
        padding-top: 30px !important
    }

    .md-pt-35px {
        padding-top: 35px !important
    }

    .md-pt-40px {
        padding-top: 40px !important
    }

    .md-pt-45px {
        padding-top: 45px !important
    }

    .md-pt-50px {
        padding-top: 50px !important
    }

    .md-pt-55px {
        padding-top: 55px !important
    }

    .md-pt-60px {
        padding-top: 60px !important
    }

    .md-pt-65px {
        padding-top: 65px !important
    }

    .md-pt-70px {
        padding-top: 70px !important
    }

    .md-pt-1 {
        padding-top: 1% !important
    }

    .md-pt-2 {
        padding-top: 2% !important
    }

    .md-pt-3 {
        padding-top: 3% !important
    }

    .md-pt-4 {
        padding-top: 4% !important
    }

    .md-pt-5 {
        padding-top: 5% !important
    }

    .md-pt-6 {
        padding-top: 6% !important
    }

    .md-pt-7 {
        padding-top: 7% !important
    }

    .md-pt-8 {
        padding-top: 8% !important
    }

    .md-pt-9 {
        padding-top: 9% !important
    }

    .md-pt-10 {
        padding-top: 10% !important
    }

    .md-pt-11 {
        padding-top: 11% !important
    }

    .md-pt-12 {
        padding-top: 12% !important
    }

    .md-pt-13 {
        padding-top: 13% !important
    }

    .md-pt-14 {
        padding-top: 14% !important
    }

    .md-pt-15 {
        padding-top: 15% !important
    }

    .md-pt-16 {
        padding-top: 16% !important
    }

    .md-pt-17 {
        padding-top: 17% !important
    }

    .md-pt-18 {
        padding-top: 18% !important
    }

    .md-pt-19 {
        padding-top: 19% !important
    }

    .md-pt-20 {
        padding-top: 20% !important
    }

    .md-pt-21 {
        padding-top: 21% !important
    }

    .md-pt-22 {
        padding-top: 22% !important
    }

    .md-pt-23 {
        padding-top: 23% !important
    }

    .md-pt-24 {
        padding-top: 24% !important
    }

    .md-pt-25 {
        padding-top: 25% !important
    }

    .md-pt-26 {
        padding-top: 26% !important
    }

    .md-pt-27 {
        padding-top: 27% !important
    }

    .md-pt-28 {
        padding-top: 28% !important
    }

    .md-pt-29 {
        padding-top: 29% !important
    }

    .md-pt-30 {
        padding-top: 30% !important
    }

    .md-pe-5px {
        padding-right: 5px !important
    }

    .md-pe-10px {
        padding-right: 10px !important
    }

    .md-pe-15px {
        padding-right: 15px !important
    }

    .md-pe-20px {
        padding-right: 20px !important
    }

    .md-pe-25px {
        padding-right: 25px !important
    }

    .md-pe-30px {
        padding-right: 30px !important
    }

    .md-pe-35px {
        padding-right: 35px !important
    }

    .md-pe-40px {
        padding-right: 40px !important
    }

    .md-pe-45px {
        padding-right: 45px !important
    }

    .md-pe-50px {
        padding-right: 50px !important
    }

    .md-pe-55px {
        padding-right: 55px !important
    }

    .md-pe-60px {
        padding-right: 60px !important
    }

    .md-pe-65px {
        padding-right: 65px !important
    }

    .md-pe-70px {
        padding-right: 70px !important
    }

    .md-pe-1 {
        padding-right: 1% !important
    }

    .md-pe-2 {
        padding-right: 2% !important
    }

    .md-pe-3 {
        padding-right: 3% !important
    }

    .md-pe-4 {
        padding-right: 4% !important
    }

    .md-pe-5 {
        padding-right: 5% !important
    }

    .md-pe-6 {
        padding-right: 6% !important
    }

    .md-pe-7 {
        padding-right: 7% !important
    }

    .md-pe-8 {
        padding-right: 8% !important
    }

    .md-pe-9 {
        padding-right: 9% !important
    }

    .md-pe-10 {
        padding-right: 10% !important
    }

    .md-pe-11 {
        padding-right: 11% !important
    }

    .md-pe-12 {
        padding-right: 12% !important
    }

    .md-pe-13 {
        padding-right: 13% !important
    }

    .md-pe-14 {
        padding-right: 14% !important
    }

    .md-pe-15 {
        padding-right: 15% !important
    }

    .md-pe-16 {
        padding-right: 16% !important
    }

    .md-pe-17 {
        padding-right: 17% !important
    }

    .md-pe-18 {
        padding-right: 18% !important
    }

    .md-pe-19 {
        padding-right: 19% !important
    }

    .md-pe-20 {
        padding-right: 20% !important
    }

    .md-pe-21 {
        padding-right: 21% !important
    }

    .md-pe-22 {
        padding-right: 22% !important
    }

    .md-pe-23 {
        padding-right: 23% !important
    }

    .md-pe-24 {
        padding-right: 24% !important
    }

    .md-pe-25 {
        padding-right: 25% !important
    }

    .md-pe-26 {
        padding-right: 26% !important
    }

    .md-pe-27 {
        padding-right: 27% !important
    }

    .md-pe-28 {
        padding-right: 28% !important
    }

    .md-pe-29 {
        padding-right: 29% !important
    }

    .md-pe-30 {
        padding-right: 30% !important
    }

    .md-pb-5px {
        padding-bottom: 5px !important
    }

    .md-pb-10px {
        padding-bottom: 10px !important
    }

    .md-pb-15px {
        padding-bottom: 15px !important
    }

    .md-pb-20px {
        padding-bottom: 20px !important
    }

    .md-pb-25px {
        padding-bottom: 25px !important
    }

    .md-pb-30px {
        padding-bottom: 30px !important
    }

    .md-pb-35px {
        padding-bottom: 35px !important
    }

    .md-pb-40px {
        padding-bottom: 40px !important
    }

    .md-pb-45px {
        padding-bottom: 45px !important
    }

    .md-pb-50px {
        padding-bottom: 50px !important
    }

    .md-pb-55px {
        padding-bottom: 55px !important
    }

    .md-pb-60px {
        padding-bottom: 60px !important
    }

    .md-pb-65px {
        padding-bottom: 65px !important
    }

    .md-pb-70px {
        padding-bottom: 70px !important
    }

    .md-pb-1 {
        padding-bottom: 1% !important
    }

    .md-pb-2 {
        padding-bottom: 2% !important
    }

    .md-pb-3 {
        padding-bottom: 3% !important
    }

    .md-pb-4 {
        padding-bottom: 4% !important
    }

    .md-pb-5 {
        padding-bottom: 5% !important
    }

    .md-pb-6 {
        padding-bottom: 6% !important
    }

    .md-pb-7 {
        padding-bottom: 7% !important
    }

    .md-pb-8 {
        padding-bottom: 8% !important
    }

    .md-pb-9 {
        padding-bottom: 9% !important
    }

    .md-pb-10 {
        padding-bottom: 10% !important
    }

    .md-pb-11 {
        padding-bottom: 11% !important
    }

    .md-pb-12 {
        padding-bottom: 12% !important
    }

    .md-pb-13 {
        padding-bottom: 13% !important
    }

    .md-pb-14 {
        padding-bottom: 14% !important
    }

    .md-pb-15 {
        padding-bottom: 15% !important
    }

    .md-pb-16 {
        padding-bottom: 16% !important
    }

    .md-pb-17 {
        padding-bottom: 17% !important
    }

    .md-pb-18 {
        padding-bottom: 18% !important
    }

    .md-pb-19 {
        padding-bottom: 19% !important
    }

    .md-pb-20 {
        padding-bottom: 20% !important
    }

    .md-pb-21 {
        padding-bottom: 21% !important
    }

    .md-pb-22 {
        padding-bottom: 22% !important
    }

    .md-pb-23 {
        padding-bottom: 23% !important
    }

    .md-pb-24 {
        padding-bottom: 24% !important
    }

    .md-pb-25 {
        padding-bottom: 25% !important
    }

    .md-pb-26 {
        padding-bottom: 26% !important
    }

    .md-pb-27 {
        padding-bottom: 27% !important
    }

    .md-pb-28 {
        padding-bottom: 28% !important
    }

    .md-pb-29 {
        padding-bottom: 29% !important
    }

    .md-pb-30 {
        padding-bottom: 30% !important
    }

    .md-ps-5px {
        padding-left: 5px !important
    }

    .md-ps-10px {
        padding-left: 10px !important
    }

    .md-ps-15px {
        padding-left: 15px !important
    }

    .md-ps-20px {
        padding-left: 20px !important
    }

    .md-ps-25px {
        padding-left: 25px !important
    }

    .md-ps-30px {
        padding-left: 30px !important
    }

    .md-ps-35px {
        padding-left: 35px !important
    }

    .md-ps-40px {
        padding-left: 40px !important
    }

    .md-ps-45px {
        padding-left: 45px !important
    }

    .md-ps-50px {
        padding-left: 50px !important
    }

    .md-ps-55px {
        padding-left: 55px !important
    }

    .md-ps-60px {
        padding-left: 60px !important
    }

    .md-ps-65px {
        padding-left: 65px !important
    }

    .md-ps-70px {
        padding-left: 70px !important
    }

    .md-ps-1 {
        padding-left: 1% !important
    }

    .md-ps-2 {
        padding-left: 2% !important
    }

    .md-ps-3 {
        padding-left: 3% !important
    }

    .md-ps-4 {
        padding-left: 4% !important
    }

    .md-ps-5 {
        padding-left: 5% !important
    }

    .md-ps-6 {
        padding-left: 6% !important
    }

    .md-ps-7 {
        padding-left: 7% !important
    }

    .md-ps-8 {
        padding-left: 8% !important
    }

    .md-ps-9 {
        padding-left: 9% !important
    }

    .md-ps-10 {
        padding-left: 10% !important
    }

    .md-ps-11 {
        padding-left: 11% !important
    }

    .md-ps-12 {
        padding-left: 12% !important
    }

    .md-ps-13 {
        padding-left: 13% !important
    }

    .md-ps-14 {
        padding-left: 14% !important
    }

    .md-ps-15 {
        padding-left: 15% !important
    }

    .md-ps-16 {
        padding-left: 16% !important
    }

    .md-ps-17 {
        padding-left: 17% !important
    }

    .md-ps-18 {
        padding-left: 18% !important
    }

    .md-ps-19 {
        padding-left: 19% !important
    }

    .md-ps-20 {
        padding-left: 20% !important
    }

    .md-ps-21 {
        padding-left: 21% !important
    }

    .md-ps-22 {
        padding-left: 22% !important
    }

    .md-ps-23 {
        padding-left: 23% !important
    }

    .md-ps-24 {
        padding-left: 24% !important
    }

    .md-ps-25 {
        padding-left: 25% !important
    }

    .md-ps-26 {
        padding-left: 26% !important
    }

    .md-ps-27 {
        padding-left: 27% !important
    }

    .md-ps-28 {
        padding-left: 28% !important
    }

    .md-ps-29 {
        padding-left: 29% !important
    }

    .md-ps-30 {
        padding-left: 30% !important
    }

    .md-m-5px {
        margin: 5px !important
    }

    .md-m-10px {
        margin: 10px !important
    }

    .md-m-15px {
        margin: 15px !important
    }

    .md-m-20px {
        margin: 20px !important
    }

    .md-m-25px {
        margin: 25px !important
    }

    .md-m-30px {
        margin: 30px !important
    }

    .md-m-35px {
        margin: 35px !important
    }

    .md-m-40px {
        margin: 40px !important
    }

    .md-m-45px {
        margin: 45px !important
    }

    .md-m-50px {
        margin: 50px !important
    }

    .md-m-55px {
        margin: 55px !important
    }

    .md-m-60px {
        margin: 60px !important
    }

    .md-m-65px {
        margin: 65px !important
    }

    .md-m-70px {
        margin: 70px !important
    }

    .md-m-1 {
        margin: 1% !important
    }

    .md-m-2 {
        margin: 2% !important
    }

    .md-m-3 {
        margin: 3% !important
    }

    .md-m-4 {
        margin: 4% !important
    }

    .md-m-5 {
        margin: 5% !important
    }

    .md-m-6 {
        margin: 6% !important
    }

    .md-m-7 {
        margin: 7% !important
    }

    .md-m-8 {
        margin: 8% !important
    }

    .md-m-9 {
        margin: 9% !important
    }

    .md-m-10 {
        margin: 10% !important
    }

    .md-m-11 {
        margin: 11% !important
    }

    .md-m-12 {
        margin: 12% !important
    }

    .md-m-13 {
        margin: 13% !important
    }

    .md-m-14 {
        margin: 14% !important
    }

    .md-m-15 {
        margin: 15% !important
    }

    .md-m-16 {
        margin: 16% !important
    }

    .md-m-17 {
        margin: 17% !important
    }

    .md-m-18 {
        margin: 18% !important
    }

    .md-m-19 {
        margin: 19% !important
    }

    .md-m-20 {
        margin: 20% !important
    }

    .md-m-21 {
        margin: 21% !important
    }

    .md-m-22 {
        margin: 22% !important
    }

    .md-m-23 {
        margin: 23% !important
    }

    .md-m-24 {
        margin: 24% !important
    }

    .md-m-25 {
        margin: 25% !important
    }

    .md-m-26 {
        margin: 26% !important
    }

    .md-m-27 {
        margin: 27% !important
    }

    .md-m-28 {
        margin: 28% !important
    }

    .md-m-29 {
        margin: 29% !important
    }

    .md-m-30 {
        margin: 30% !important
    }

    .md-mt-5px {
        margin-top: 5px !important
    }

    .md-mt-10px {
        margin-top: 10px !important
    }

    .md-mt-15px {
        margin-top: 15px !important
    }

    .md-mt-20px {
        margin-top: 20px !important
    }

    .md-mt-25px {
        margin-top: 25px !important
    }

    .md-mt-30px {
        margin-top: 30px !important
    }

    .md-mt-35px {
        margin-top: 35px !important
    }

    .md-mt-40px {
        margin-top: 40px !important
    }

    .md-mt-45px {
        margin-top: 45px !important
    }

    .md-mt-50px {
        margin-top: 50px !important
    }

    .md-mt-55px {
        margin-top: 55px !important
    }

    .md-mt-60px {
        margin-top: 60px !important
    }

    .md-mt-65px {
        margin-top: 65px !important
    }

    .md-mt-70px {
        margin-top: 70px !important
    }

    .md-mt-1 {
        margin-top: 1% !important
    }

    .md-mt-2 {
        margin-top: 2% !important
    }

    .md-mt-3 {
        margin-top: 3% !important
    }

    .md-mt-4 {
        margin-top: 4% !important
    }

    .md-mt-5 {
        margin-top: 5% !important
    }

    .md-mt-6 {
        margin-top: 6% !important
    }

    .md-mt-7 {
        margin-top: 7% !important
    }

    .md-mt-8 {
        margin-top: 8% !important
    }

    .md-mt-9 {
        margin-top: 9% !important
    }

    .md-mt-10 {
        margin-top: 10% !important
    }

    .md-mt-11 {
        margin-top: 11% !important
    }

    .md-mt-12 {
        margin-top: 12% !important
    }

    .md-mt-13 {
        margin-top: 13% !important
    }

    .md-mt-14 {
        margin-top: 14% !important
    }

    .md-mt-15 {
        margin-top: 15% !important
    }

    .md-mt-16 {
        margin-top: 16% !important
    }

    .md-mt-17 {
        margin-top: 17% !important
    }

    .md-mt-18 {
        margin-top: 18% !important
    }

    .md-mt-19 {
        margin-top: 19% !important
    }

    .md-mt-20 {
        margin-top: 20% !important
    }

    .md-mt-21 {
        margin-top: 21% !important
    }

    .md-mt-22 {
        margin-top: 22% !important
    }

    .md-mt-23 {
        margin-top: 23% !important
    }

    .md-mt-24 {
        margin-top: 24% !important
    }

    .md-mt-25 {
        margin-top: 25% !important
    }

    .md-mt-26 {
        margin-top: 26% !important
    }

    .md-mt-27 {
        margin-top: 27% !important
    }

    .md-mt-28 {
        margin-top: 28% !important
    }

    .md-mt-29 {
        margin-top: 29% !important
    }

    .md-mt-30 {
        margin-top: 30% !important
    }

    .md-me-5px {
        margin-right: 5px !important
    }

    .md-me-10px {
        margin-right: 10px !important
    }

    .md-me-15px {
        margin-right: 15px !important
    }

    .md-me-20px {
        margin-right: 20px !important
    }

    .md-me-25px {
        margin-right: 25px !important
    }

    .md-me-30px {
        margin-right: 30px !important
    }

    .md-me-35px {
        margin-right: 35px !important
    }

    .md-me-40px {
        margin-right: 40px !important
    }

    .md-me-45px {
        margin-right: 45px !important
    }

    .md-me-50px {
        margin-right: 50px !important
    }

    .md-me-55px {
        margin-right: 55px !important
    }

    .md-me-60px {
        margin-right: 60px !important
    }

    .md-me-65px {
        margin-right: 65px !important
    }

    .md-me-70px {
        margin-right: 70px !important
    }

    .md-me-1 {
        margin-right: 1% !important
    }

    .md-me-2 {
        margin-right: 2% !important
    }

    .md-me-3 {
        margin-right: 3% !important
    }

    .md-me-4 {
        margin-right: 4% !important
    }

    .md-me-5 {
        margin-right: 5% !important
    }

    .md-me-6 {
        margin-right: 6% !important
    }

    .md-me-7 {
        margin-right: 7% !important
    }

    .md-me-8 {
        margin-right: 8% !important
    }

    .md-me-9 {
        margin-right: 9% !important
    }

    .md-me-10 {
        margin-right: 10% !important
    }

    .md-me-11 {
        margin-right: 11% !important
    }

    .md-me-12 {
        margin-right: 12% !important
    }

    .md-me-13 {
        margin-right: 13% !important
    }

    .md-me-14 {
        margin-right: 14% !important
    }

    .md-me-15 {
        margin-right: 15% !important
    }

    .md-me-16 {
        margin-right: 16% !important
    }

    .md-me-17 {
        margin-right: 17% !important
    }

    .md-me-18 {
        margin-right: 18% !important
    }

    .md-me-19 {
        margin-right: 19% !important
    }

    .md-me-20 {
        margin-right: 20% !important
    }

    .md-me-21 {
        margin-right: 21% !important
    }

    .md-me-22 {
        margin-right: 22% !important
    }

    .md-me-23 {
        margin-right: 23% !important
    }

    .md-me-24 {
        margin-right: 24% !important
    }

    .md-me-25 {
        margin-right: 25% !important
    }

    .md-me-26 {
        margin-right: 26% !important
    }

    .md-me-27 {
        margin-right: 27% !important
    }

    .md-me-28 {
        margin-right: 28% !important
    }

    .md-me-29 {
        margin-right: 29% !important
    }

    .md-me-30 {
        margin-right: 30% !important
    }

    .md-mb-5px {
        margin-bottom: 5px !important
    }

    .md-mb-10px {
        margin-bottom: 10px !important
    }

    .md-mb-15px {
        margin-bottom: 15px !important
    }

    .md-mb-20px {
        margin-bottom: 20px !important
    }

    .md-mb-25px {
        margin-bottom: 25px !important
    }

    .md-mb-30px {
        margin-bottom: 30px !important
    }

    .md-mb-35px {
        margin-bottom: 35px !important
    }

    .md-mb-40px {
        margin-bottom: 40px !important
    }

    .md-mb-45px {
        margin-bottom: 45px !important
    }

    .md-mb-50px {
        margin-bottom: 50px !important
    }

    .md-mb-55px {
        margin-bottom: 55px !important
    }

    .md-mb-60px {
        margin-bottom: 60px !important
    }

    .md-mb-65px {
        margin-bottom: 65px !important
    }

    .md-mb-70px {
        margin-bottom: 70px !important
    }

    .md-mb-1 {
        margin-bottom: 1% !important
    }

    .md-mb-2 {
        margin-bottom: 2% !important
    }

    .md-mb-3 {
        margin-bottom: 3% !important
    }

    .md-mb-4 {
        margin-bottom: 4% !important
    }

    .md-mb-5 {
        margin-bottom: 5% !important
    }

    .md-mb-6 {
        margin-bottom: 6% !important
    }

    .md-mb-7 {
        margin-bottom: 7% !important
    }

    .md-mb-8 {
        margin-bottom: 8% !important
    }

    .md-mb-9 {
        margin-bottom: 9% !important
    }

    .md-mb-10 {
        margin-bottom: 10% !important
    }

    .md-mb-11 {
        margin-bottom: 11% !important
    }

    .md-mb-12 {
        margin-bottom: 12% !important
    }

    .md-mb-13 {
        margin-bottom: 13% !important
    }

    .md-mb-14 {
        margin-bottom: 14% !important
    }

    .md-mb-15 {
        margin-bottom: 15% !important
    }

    .md-mb-16 {
        margin-bottom: 16% !important
    }

    .md-mb-17 {
        margin-bottom: 17% !important
    }

    .md-mb-18 {
        margin-bottom: 18% !important
    }

    .md-mb-19 {
        margin-bottom: 19% !important
    }

    .md-mb-20 {
        margin-bottom: 20% !important
    }

    .md-mb-21 {
        margin-bottom: 21% !important
    }

    .md-mb-22 {
        margin-bottom: 22% !important
    }

    .md-mb-23 {
        margin-bottom: 23% !important
    }

    .md-mb-24 {
        margin-bottom: 24% !important
    }

    .md-mb-25 {
        margin-bottom: 25% !important
    }

    .md-mb-26 {
        margin-bottom: 26% !important
    }

    .md-mb-27 {
        margin-bottom: 27% !important
    }

    .md-mb-28 {
        margin-bottom: 28% !important
    }

    .md-mb-29 {
        margin-bottom: 29% !important
    }

    .md-mb-30 {
        margin-bottom: 30% !important
    }

    .md-ms-5px {
        margin-left: 5px !important
    }

    .md-ms-10px {
        margin-left: 10px !important
    }

    .md-ms-15px {
        margin-left: 15px !important
    }

    .md-ms-20px {
        margin-left: 20px !important
    }

    .md-ms-25px {
        margin-left: 25px !important
    }

    .md-ms-30px {
        margin-left: 30px !important
    }

    .md-ms-35px {
        margin-left: 35px !important
    }

    .md-ms-40px {
        margin-left: 40px !important
    }

    .md-ms-45px {
        margin-left: 45px !important
    }

    .md-ms-50px {
        margin-left: 50px !important
    }

    .md-ms-55px {
        margin-left: 55px !important
    }

    .md-ms-60px {
        margin-left: 60px !important
    }

    .md-ms-65px {
        margin-left: 65px !important
    }

    .md-ms-70px {
        margin-left: 70px !important
    }

    .md-ms-1 {
        margin-left: 1% !important
    }

    .md-ms-2 {
        margin-left: 2% !important
    }

    .md-ms-3 {
        margin-left: 3% !important
    }

    .md-ms-4 {
        margin-left: 4% !important
    }

    .md-ms-5 {
        margin-left: 5% !important
    }

    .md-ms-6 {
        margin-left: 6% !important
    }

    .md-ms-7 {
        margin-left: 7% !important
    }

    .md-ms-8 {
        margin-left: 8% !important
    }

    .md-ms-9 {
        margin-left: 9% !important
    }

    .md-ms-10 {
        margin-left: 10% !important
    }

    .md-ms-11 {
        margin-left: 11% !important
    }

    .md-ms-12 {
        margin-left: 12% !important
    }

    .md-ms-13 {
        margin-left: 13% !important
    }

    .md-ms-14 {
        margin-left: 14% !important
    }

    .md-ms-15 {
        margin-left: 15% !important
    }

    .md-ms-16 {
        margin-left: 16% !important
    }

    .md-ms-17 {
        margin-left: 17% !important
    }

    .md-ms-18 {
        margin-left: 18% !important
    }

    .md-ms-19 {
        margin-left: 19% !important
    }

    .md-ms-20 {
        margin-left: 20% !important
    }

    .md-ms-21 {
        margin-left: 21% !important
    }

    .md-ms-22 {
        margin-left: 22% !important
    }

    .md-ms-23 {
        margin-left: 23% !important
    }

    .md-ms-24 {
        margin-left: 24% !important
    }

    .md-ms-25 {
        margin-left: 25% !important
    }

    .md-ms-26 {
        margin-left: 26% !important
    }

    .md-ms-27 {
        margin-left: 27% !important
    }

    .md-ms-28 {
        margin-left: 28% !important
    }

    .md-ms-29 {
        margin-left: 29% !important
    }

    .md-ms-30 {
        margin-left: 30% !important
    }

    .md-top-0px {
        top: 0 !important
    }

    .md-top-1px {
        top: 1px !important
    }

    .md-top-2px {
        top: 2px !important
    }

    .md-top-3px {
        top: 3px !important
    }

    .md-top-4px {
        top: 4px !important
    }

    .md-top-5px {
        top: 5px !important
    }

    .md-top-6px {
        top: 6px !important
    }

    .md-top-7px {
        top: 7px !important
    }

    .md-top-8px {
        top: 8px !important
    }

    .md-top-9px {
        top: 9px !important
    }

    .md-top-10px {
        top: 10px !important
    }

    .md-top-11px {
        top: 11px !important
    }

    .md-top-12px {
        top: 12px !important
    }

    .md-top-13px {
        top: 13px !important
    }

    .md-top-14px {
        top: 14px !important
    }

    .md-top-15px {
        top: 15px !important
    }

    .md-top-16px {
        top: 16px !important
    }

    .md-top-17px {
        top: 17px !important
    }

    .md-top-18px {
        top: 18px !important
    }

    .md-top-19px {
        top: 19px !important
    }

    .md-top-20px {
        top: 20px !important
    }

    .md-top-21px {
        top: 21px !important
    }

    .md-top-22px {
        top: 22px !important
    }

    .md-top-23px {
        top: 23px !important
    }

    .md-top-24px {
        top: 24px !important
    }

    .md-top-25px {
        top: 25px !important
    }

    .md-top-26px {
        top: 26px !important
    }

    .md-top-27px {
        top: 27px !important
    }

    .md-top-28px {
        top: 28px !important
    }

    .md-top-29px {
        top: 29px !important
    }

    .md-top-30px {
        top: 30px !important
    }

    .md-top-40px {
        top: 40px !important
    }

    .md-top-50px {
        top: 50px !important
    }

    .md-top-60px {
        top: 60px !important
    }

    .md-top-70px {
        top: 70px !important
    }

    .md-top-80px {
        top: 80px !important
    }

    .md-top-90px {
        top: 90px !important
    }

    .md-top-100px {
        top: 100px !important
    }

    .md-top-110px {
        top: 110px !important
    }

    .md-top-120px {
        top: 120px !important
    }

    .md-top-130px {
        top: 130px !important
    }

    .md-top-140px {
        top: 140px !important
    }

    .md-top-150px {
        top: 150px !important
    }

    .md-right-0px {
        right: 0 !important
    }

    .md-right-1px {
        right: 1px !important
    }

    .md-right-2px {
        right: 2px !important
    }

    .md-right-3px {
        right: 3px !important
    }

    .md-right-4px {
        right: 4px !important
    }

    .md-right-5px {
        right: 5px !important
    }

    .md-right-6px {
        right: 6px !important
    }

    .md-right-7px {
        right: 7px !important
    }

    .md-right-8px {
        right: 8px !important
    }

    .md-right-9px {
        right: 9px !important
    }

    .md-right-10px {
        right: 10px !important
    }

    .md-right-11px {
        right: 11px !important
    }

    .md-right-12px {
        right: 12px !important
    }

    .md-right-13px {
        right: 13px !important
    }

    .md-right-14px {
        right: 14px !important
    }

    .md-right-15px {
        right: 15px !important
    }

    .md-right-16px {
        right: 16px !important
    }

    .md-right-17px {
        right: 17px !important
    }

    .md-right-18px {
        right: 18px !important
    }

    .md-right-19px {
        right: 19px !important
    }

    .md-right-20px {
        right: 20px !important
    }

    .md-right-21px {
        right: 21px !important
    }

    .md-right-22px {
        right: 22px !important
    }

    .md-right-23px {
        right: 23px !important
    }

    .md-right-24px {
        right: 24px !important
    }

    .md-right-25px {
        right: 25px !important
    }

    .md-right-26px {
        right: 26px !important
    }

    .md-right-27px {
        right: 27px !important
    }

    .md-right-28px {
        right: 28px !important
    }

    .md-right-29px {
        right: 29px !important
    }

    .md-right-30px {
        right: 30px !important
    }

    .md-right-40px {
        right: 40px !important
    }

    .md-right-50px {
        right: 50px !important
    }

    .md-right-60px {
        right: 60px !important
    }

    .md-right-70px {
        right: 70px !important
    }

    .md-right-80px {
        right: 80px !important
    }

    .md-right-90px {
        right: 90px !important
    }

    .md-right-100px {
        right: 100px !important
    }

    .md-right-110px {
        right: 110px !important
    }

    .md-right-120px {
        right: 120px !important
    }

    .md-right-130px {
        right: 130px !important
    }

    .md-right-140px {
        right: 140px !important
    }

    .md-right-150px {
        right: 150px !important
    }

    .md-bottom-0px {
        bottom: 0 !important
    }

    .md-bottom-1px {
        bottom: 1px !important
    }

    .md-bottom-2px {
        bottom: 2px !important
    }

    .md-bottom-3px {
        bottom: 3px !important
    }

    .md-bottom-4px {
        bottom: 4px !important
    }

    .md-bottom-5px {
        bottom: 5px !important
    }

    .md-bottom-6px {
        bottom: 6px !important
    }

    .md-bottom-7px {
        bottom: 7px !important
    }

    .md-bottom-8px {
        bottom: 8px !important
    }

    .md-bottom-9px {
        bottom: 9px !important
    }

    .md-bottom-10px {
        bottom: 10px !important
    }

    .md-bottom-11px {
        bottom: 11px !important
    }

    .md-bottom-12px {
        bottom: 12px !important
    }

    .md-bottom-13px {
        bottom: 13px !important
    }

    .md-bottom-14px {
        bottom: 14px !important
    }

    .md-bottom-15px {
        bottom: 15px !important
    }

    .md-bottom-16px {
        bottom: 16px !important
    }

    .md-bottom-17px {
        bottom: 17px !important
    }

    .md-bottom-18px {
        bottom: 18px !important
    }

    .md-bottom-19px {
        bottom: 19px !important
    }

    .md-bottom-20px {
        bottom: 20px !important
    }

    .md-bottom-21px {
        bottom: 21px !important
    }

    .md-bottom-22px {
        bottom: 22px !important
    }

    .md-bottom-23px {
        bottom: 23px !important
    }

    .md-bottom-24px {
        bottom: 24px !important
    }

    .md-bottom-25px {
        bottom: 25px !important
    }

    .md-bottom-26px {
        bottom: 26px !important
    }

    .md-bottom-27px {
        bottom: 27px !important
    }

    .md-bottom-28px {
        bottom: 28px !important
    }

    .md-bottom-29px {
        bottom: 29px !important
    }

    .md-bottom-30px {
        bottom: 30px !important
    }

    .md-bottom-40px {
        bottom: 40px !important
    }

    .md-bottom-50px {
        bottom: 50px !important
    }

    .md-bottom-60px {
        bottom: 60px !important
    }

    .md-bottom-70px {
        bottom: 70px !important
    }

    .md-bottom-80px {
        bottom: 80px !important
    }

    .md-bottom-90px {
        bottom: 90px !important
    }

    .md-bottom-100px {
        bottom: 100px !important
    }

    .md-bottom-110px {
        bottom: 110px !important
    }

    .md-bottom-120px {
        bottom: 120px !important
    }

    .md-bottom-130px {
        bottom: 130px !important
    }

    .md-bottom-140px {
        bottom: 140px !important
    }

    .md-bottom-150px {
        bottom: 150px !important
    }

    .md-left-0px {
        left: 0 !important
    }

    .md-left-1px {
        left: 1px !important
    }

    .md-left-2px {
        left: 2px !important
    }

    .md-left-3px {
        left: 3px !important
    }

    .md-left-4px {
        left: 4px !important
    }

    .md-left-5px {
        left: 5px !important
    }

    .md-left-6px {
        left: 6px !important
    }

    .md-left-7px {
        left: 7px !important
    }

    .md-left-8px {
        left: 8px !important
    }

    .md-left-9px {
        left: 9px !important
    }

    .md-left-10px {
        left: 10px !important
    }

    .md-left-11px {
        left: 11px !important
    }

    .md-left-12px {
        left: 12px !important
    }

    .md-left-13px {
        left: 13px !important
    }

    .md-left-14px {
        left: 14px !important
    }

    .md-left-15px {
        left: 15px !important
    }

    .md-left-16px {
        left: 16px !important
    }

    .md-left-17px {
        left: 17px !important
    }

    .md-left-18px {
        left: 18px !important
    }

    .md-left-19px {
        left: 19px !important
    }

    .md-left-20px {
        left: 20px !important
    }

    .md-left-21px {
        left: 21px !important
    }

    .md-left-22px {
        left: 22px !important
    }

    .md-left-23px {
        left: 23px !important
    }

    .md-left-24px {
        left: 24px !important
    }

    .md-left-25px {
        left: 25px !important
    }

    .md-left-26px {
        left: 26px !important
    }

    .md-left-27px {
        left: 27px !important
    }

    .md-left-28px {
        left: 28px !important
    }

    .md-left-29px {
        left: 29px !important
    }

    .md-left-30px {
        left: 30px !important
    }

    .md-left-40px {
        left: 40px !important
    }

    .md-left-50px {
        left: 50px !important
    }

    .md-left-60px {
        left: 60px !important
    }

    .md-left-70px {
        left: 70px !important
    }

    .md-left-80px {
        left: 80px !important
    }

    .md-left-90px {
        left: 90px !important
    }

    .md-left-100px {
        left: 100px !important
    }

    .md-left-110px {
        left: 110px !important
    }

    .md-left-120px {
        left: 120px !important
    }

    .md-left-130px {
        left: 130px !important
    }

    .md-left-140px {
        left: 140px !important
    }

    .md-left-150px {
        left: 150px !important
    }

    .md-top-minus-0px {
        top: 0 !important
    }

    .md-top-minus-1px {
        top: -1px !important
    }

    .md-top-minus-2px {
        top: -2px !important
    }

    .md-top-minus-3px {
        top: -3px !important
    }

    .md-top-minus-4px {
        top: -4px !important
    }

    .md-top-minus-5px {
        top: -5px !important
    }

    .md-top-minus-6px {
        top: -6px !important
    }

    .md-top-minus-7px {
        top: -7px !important
    }

    .md-top-minus-8px {
        top: -8px !important
    }

    .md-top-minus-9px {
        top: -9px !important
    }

    .md-top-minus-10px {
        top: -10px !important
    }

    .md-top-minus-11px {
        top: -11px !important
    }

    .md-top-minus-12px {
        top: -12px !important
    }

    .md-top-minus-13px {
        top: -13px !important
    }

    .md-top-minus-14px {
        top: -14px !important
    }

    .md-top-minus-15px {
        top: -15px !important
    }

    .md-top-minus-16px {
        top: -16px !important
    }

    .md-top-minus-17px {
        top: -17px !important
    }

    .md-top-minus-18px {
        top: -18px !important
    }

    .md-top-minus-19px {
        top: -19px !important
    }

    .md-top-minus-20px {
        top: -20px !important
    }

    .md-top-minus-21px {
        top: -21px !important
    }

    .md-top-minus-22px {
        top: -22px !important
    }

    .md-top-minus-23px {
        top: -23px !important
    }

    .md-top-minus-24px {
        top: -24px !important
    }

    .md-top-minus-25px {
        top: -25px !important
    }

    .md-top-minus-26px {
        top: -26px !important
    }

    .md-top-minus-27px {
        top: -27px !important
    }

    .md-top-minus-28px {
        top: -28px !important
    }

    .md-top-minus-29px {
        top: -29px !important
    }

    .md-top-minus-30px {
        top: -30px !important
    }

    .md-top-minus-40px {
        top: -40px !important
    }

    .md-top-minus-50px {
        top: -50px !important
    }

    .md-top-minus-60px {
        top: -60px !important
    }

    .md-top-minus-70px {
        top: -70px !important
    }

    .md-top-minus-80px {
        top: -80px !important
    }

    .md-top-minus-90px {
        top: -90px !important
    }

    .md-top-minus-100px {
        top: -100px !important
    }

    .md-top-minus-110px {
        top: -110px !important
    }

    .md-top-minus-120px {
        top: -120px !important
    }

    .md-top-minus-130px {
        top: -130px !important
    }

    .md-top-minus-140px {
        top: -140px !important
    }

    .md-top-minus-150px {
        top: -150px !important
    }

    .md-right-minus-0px {
        right: 0 !important
    }

    .md-right-minus-1px {
        right: -1px !important
    }

    .md-right-minus-2px {
        right: -2px !important
    }

    .md-right-minus-3px {
        right: -3px !important
    }

    .md-right-minus-4px {
        right: -4px !important
    }

    .md-right-minus-5px {
        right: -5px !important
    }

    .md-right-minus-6px {
        right: -6px !important
    }

    .md-right-minus-7px {
        right: -7px !important
    }

    .md-right-minus-8px {
        right: -8px !important
    }

    .md-right-minus-9px {
        right: -9px !important
    }

    .md-right-minus-10px {
        right: -10px !important
    }

    .md-right-minus-11px {
        right: -11px !important
    }

    .md-right-minus-12px {
        right: -12px !important
    }

    .md-right-minus-13px {
        right: -13px !important
    }

    .md-right-minus-14px {
        right: -14px !important
    }

    .md-right-minus-15px {
        right: -15px !important
    }

    .md-right-minus-16px {
        right: -16px !important
    }

    .md-right-minus-17px {
        right: -17px !important
    }

    .md-right-minus-18px {
        right: -18px !important
    }

    .md-right-minus-19px {
        right: -19px !important
    }

    .md-right-minus-20px {
        right: -20px !important
    }

    .md-right-minus-21px {
        right: -21px !important
    }

    .md-right-minus-22px {
        right: -22px !important
    }

    .md-right-minus-23px {
        right: -23px !important
    }

    .md-right-minus-24px {
        right: -24px !important
    }

    .md-right-minus-25px {
        right: -25px !important
    }

    .md-right-minus-26px {
        right: -26px !important
    }

    .md-right-minus-27px {
        right: -27px !important
    }

    .md-right-minus-28px {
        right: -28px !important
    }

    .md-right-minus-29px {
        right: -29px !important
    }

    .md-right-minus-30px {
        right: -30px !important
    }

    .md-right-minus-40px {
        right: -40px !important
    }

    .md-right-minus-50px {
        right: -50px !important
    }

    .md-right-minus-60px {
        right: -60px !important
    }

    .md-right-minus-70px {
        right: -70px !important
    }

    .md-right-minus-80px {
        right: -80px !important
    }

    .md-right-minus-90px {
        right: -90px !important
    }

    .md-right-minus-100px {
        right: -100px !important
    }

    .md-right-minus-110px {
        right: -110px !important
    }

    .md-right-minus-120px {
        right: -120px !important
    }

    .md-right-minus-130px {
        right: -130px !important
    }

    .md-right-minus-140px {
        right: -140px !important
    }

    .md-right-minus-150px {
        right: -150px !important
    }

    .md-bottom-minus-0px {
        bottom: 0 !important
    }

    .md-bottom-minus-1px {
        bottom: -1px !important
    }

    .md-bottom-minus-2px {
        bottom: -2px !important
    }

    .md-bottom-minus-3px {
        bottom: -3px !important
    }

    .md-bottom-minus-4px {
        bottom: -4px !important
    }

    .md-bottom-minus-5px {
        bottom: -5px !important
    }

    .md-bottom-minus-6px {
        bottom: -6px !important
    }

    .md-bottom-minus-7px {
        bottom: -7px !important
    }

    .md-bottom-minus-8px {
        bottom: -8px !important
    }

    .md-bottom-minus-9px {
        bottom: -9px !important
    }

    .md-bottom-minus-10px {
        bottom: -10px !important
    }

    .md-bottom-minus-11px {
        bottom: -11px !important
    }

    .md-bottom-minus-12px {
        bottom: -12px !important
    }

    .md-bottom-minus-13px {
        bottom: -13px !important
    }

    .md-bottom-minus-14px {
        bottom: -14px !important
    }

    .md-bottom-minus-15px {
        bottom: -15px !important
    }

    .md-bottom-minus-16px {
        bottom: -16px !important
    }

    .md-bottom-minus-17px {
        bottom: -17px !important
    }

    .md-bottom-minus-18px {
        bottom: -18px !important
    }

    .md-bottom-minus-19px {
        bottom: -19px !important
    }

    .md-bottom-minus-20px {
        bottom: -20px !important
    }

    .md-bottom-minus-21px {
        bottom: -21px !important
    }

    .md-bottom-minus-22px {
        bottom: -22px !important
    }

    .md-bottom-minus-23px {
        bottom: -23px !important
    }

    .md-bottom-minus-24px {
        bottom: -24px !important
    }

    .md-bottom-minus-25px {
        bottom: -25px !important
    }

    .md-bottom-minus-26px {
        bottom: -26px !important
    }

    .md-bottom-minus-27px {
        bottom: -27px !important
    }

    .md-bottom-minus-28px {
        bottom: -28px !important
    }

    .md-bottom-minus-29px {
        bottom: -29px !important
    }

    .md-bottom-minus-30px {
        bottom: -30px !important
    }

    .md-bottom-minus-40px {
        bottom: -40px !important
    }

    .md-bottom-minus-50px {
        bottom: -50px !important
    }

    .md-bottom-minus-60px {
        bottom: -60px !important
    }

    .md-bottom-minus-70px {
        bottom: -70px !important
    }

    .md-bottom-minus-80px {
        bottom: -80px !important
    }

    .md-bottom-minus-90px {
        bottom: -90px !important
    }

    .md-bottom-minus-100px {
        bottom: -100px !important
    }

    .md-bottom-minus-110px {
        bottom: -110px !important
    }

    .md-bottom-minus-120px {
        bottom: -120px !important
    }

    .md-bottom-minus-130px {
        bottom: -130px !important
    }

    .md-bottom-minus-140px {
        bottom: -140px !important
    }

    .md-bottom-minus-150px {
        bottom: -150px !important
    }

    .md-left-minus-0px {
        left: 0 !important
    }

    .md-left-minus-1px {
        left: -1px !important
    }

    .md-left-minus-2px {
        left: -2px !important
    }

    .md-left-minus-3px {
        left: -3px !important
    }

    .md-left-minus-4px {
        left: -4px !important
    }

    .md-left-minus-5px {
        left: -5px !important
    }

    .md-left-minus-6px {
        left: -6px !important
    }

    .md-left-minus-7px {
        left: -7px !important
    }

    .md-left-minus-8px {
        left: -8px !important
    }

    .md-left-minus-9px {
        left: -9px !important
    }

    .md-left-minus-10px {
        left: -10px !important
    }

    .md-left-minus-11px {
        left: -11px !important
    }

    .md-left-minus-12px {
        left: -12px !important
    }

    .md-left-minus-13px {
        left: -13px !important
    }

    .md-left-minus-14px {
        left: -14px !important
    }

    .md-left-minus-15px {
        left: -15px !important
    }

    .md-left-minus-16px {
        left: -16px !important
    }

    .md-left-minus-17px {
        left: -17px !important
    }

    .md-left-minus-18px {
        left: -18px !important
    }

    .md-left-minus-19px {
        left: -19px !important
    }

    .md-left-minus-20px {
        left: -20px !important
    }

    .md-left-minus-21px {
        left: -21px !important
    }

    .md-left-minus-22px {
        left: -22px !important
    }

    .md-left-minus-23px {
        left: -23px !important
    }

    .md-left-minus-24px {
        left: -24px !important
    }

    .md-left-minus-25px {
        left: -25px !important
    }

    .md-left-minus-26px {
        left: -26px !important
    }

    .md-left-minus-27px {
        left: -27px !important
    }

    .md-left-minus-28px {
        left: -28px !important
    }

    .md-left-minus-29px {
        left: -29px !important
    }

    .md-left-minus-30px {
        left: -30px !important
    }

    .md-left-minus-40px {
        left: -40px !important
    }

    .md-left-minus-50px {
        left: -50px !important
    }

    .md-left-minus-60px {
        left: -60px !important
    }

    .md-left-minus-70px {
        left: -70px !important
    }

    .md-left-minus-80px {
        left: -80px !important
    }

    .md-left-minus-90px {
        left: -90px !important
    }

    .md-left-minus-100px {
        left: -100px !important
    }

    .md-left-minus-110px {
        left: -110px !important
    }

    .md-left-minus-120px {
        left: -120px !important
    }

    .md-left-minus-130px {
        left: -130px !important
    }

    .md-left-minus-140px {
        left: -140px !important
    }

    .md-left-minus-150px {
        left: -150px !important
    }

    .md-border-width-0px {
        border-width: 0 !important
    }

    .md-border-width-1px {
        border-width: 1px !important
    }

    .md-border-width-2px {
        border-width: 2px !important
    }

    .md-border-width-3px {
        border-width: 3px !important
    }

    .md-border-width-4px {
        border-width: 4px !important
    }

    .md-border-width-5px {
        border-width: 5px !important
    }

    .md-border-width-6px {
        border-width: 6px !important
    }

    .md-border-width-7px {
        border-width: 7px !important
    }

    .md-border-width-8px {
        border-width: 8px !important
    }

    .md-border-width-9px {
        border-width: 9px !important
    }

    .md-border-width-10px {
        border-width: 10px !important
    }
}

@media (max-width:767px) {
    .sm-p-5px {
        padding: 5px !important
    }

    .sm-p-10px {
        padding: 10px !important
    }

    .sm-p-15px {
        padding: 15px !important
    }

    .sm-p-20px {
        padding: 20px !important
    }

    .sm-p-25px {
        padding: 25px !important
    }

    .sm-p-30px {
        padding: 30px !important
    }

    .sm-p-35px {
        padding: 35px !important
    }

    .sm-p-40px {
        padding: 40px !important
    }

    .sm-p-45px {
        padding: 45px !important
    }

    .sm-p-50px {
        padding: 50px !important
    }

    .sm-p-55px {
        padding: 55px !important
    }

    .sm-p-60px {
        padding: 60px !important
    }

    .sm-p-65px {
        padding: 65px !important
    }

    .sm-p-70px {
        padding: 70px !important
    }

    .sm-p-1 {
        padding: 1% !important
    }

    .sm-p-2 {
        padding: 2% !important
    }

    .sm-p-3 {
        padding: 3% !important
    }

    .sm-p-4 {
        padding: 4% !important
    }

    .sm-p-5 {
        padding: 5% !important
    }

    .sm-p-6 {
        padding: 6% !important
    }

    .sm-p-7 {
        padding: 7% !important
    }

    .sm-p-8 {
        padding: 8% !important
    }

    .sm-p-9 {
        padding: 9% !important
    }

    .sm-p-10 {
        padding: 10% !important
    }

    .sm-p-11 {
        padding: 11% !important
    }

    .sm-p-12 {
        padding: 12% !important
    }

    .sm-p-13 {
        padding: 13% !important
    }

    .sm-p-14 {
        padding: 14% !important
    }

    .sm-p-15 {
        padding: 15% !important
    }

    .sm-p-16 {
        padding: 16% !important
    }

    .sm-p-17 {
        padding: 17% !important
    }

    .sm-p-18 {
        padding: 18% !important
    }

    .sm-p-19 {
        padding: 19% !important
    }

    .sm-p-20 {
        padding: 20% !important
    }

    .sm-p-21 {
        padding: 21% !important
    }

    .sm-p-22 {
        padding: 22% !important
    }

    .sm-p-23 {
        padding: 23% !important
    }

    .sm-p-24 {
        padding: 24% !important
    }

    .sm-p-25 {
        padding: 25% !important
    }

    .sm-p-26 {
        padding: 26% !important
    }

    .sm-p-27 {
        padding: 27% !important
    }

    .sm-p-28 {
        padding: 28% !important
    }

    .sm-p-29 {
        padding: 29% !important
    }

    .sm-p-30 {
        padding: 30% !important
    }

    .sm-pt-5px {
        padding-top: 5px !important
    }

    .sm-pt-10px {
        padding-top: 10px !important
    }

    .sm-pt-15px {
        padding-top: 15px !important
    }

    .sm-pt-20px {
        padding-top: 20px !important
    }

    .sm-pt-25px {
        padding-top: 25px !important
    }

    .sm-pt-30px {
        padding-top: 30px !important
    }

    .sm-pt-35px {
        padding-top: 35px !important
    }

    .sm-pt-40px {
        padding-top: 40px !important
    }

    .sm-pt-45px {
        padding-top: 45px !important
    }

    .sm-pt-50px {
        padding-top: 50px !important
    }

    .sm-pt-55px {
        padding-top: 55px !important
    }

    .sm-pt-60px {
        padding-top: 60px !important
    }

    .sm-pt-65px {
        padding-top: 65px !important
    }

    .sm-pt-70px {
        padding-top: 70px !important
    }

    .sm-pt-1 {
        padding-top: 1% !important
    }

    .sm-pt-2 {
        padding-top: 2% !important
    }

    .sm-pt-3 {
        padding-top: 3% !important
    }

    .sm-pt-4 {
        padding-top: 4% !important
    }

    .sm-pt-5 {
        padding-top: 5% !important
    }

    .sm-pt-6 {
        padding-top: 6% !important
    }

    .sm-pt-7 {
        padding-top: 7% !important
    }

    .sm-pt-8 {
        padding-top: 8% !important
    }

    .sm-pt-9 {
        padding-top: 9% !important
    }

    .sm-pt-10 {
        padding-top: 10% !important
    }

    .sm-pt-11 {
        padding-top: 11% !important
    }

    .sm-pt-12 {
        padding-top: 12% !important
    }

    .sm-pt-13 {
        padding-top: 13% !important
    }

    .sm-pt-14 {
        padding-top: 14% !important
    }

    .sm-pt-15 {
        padding-top: 15% !important
    }

    .sm-pt-16 {
        padding-top: 16% !important
    }

    .sm-pt-17 {
        padding-top: 17% !important
    }

    .sm-pt-18 {
        padding-top: 18% !important
    }

    .sm-pt-19 {
        padding-top: 19% !important
    }

    .sm-pt-20 {
        padding-top: 20% !important
    }

    .sm-pt-21 {
        padding-top: 21% !important
    }

    .sm-pt-22 {
        padding-top: 22% !important
    }

    .sm-pt-23 {
        padding-top: 23% !important
    }

    .sm-pt-24 {
        padding-top: 24% !important
    }

    .sm-pt-25 {
        padding-top: 25% !important
    }

    .sm-pt-26 {
        padding-top: 26% !important
    }

    .sm-pt-27 {
        padding-top: 27% !important
    }

    .sm-pt-28 {
        padding-top: 28% !important
    }

    .sm-pt-29 {
        padding-top: 29% !important
    }

    .sm-pt-30 {
        padding-top: 30% !important
    }

    .sm-pe-5px {
        padding-right: 5px !important
    }

    .sm-pe-10px {
        padding-right: 10px !important
    }

    .sm-pe-15px {
        padding-right: 15px !important
    }

    .sm-pe-20px {
        padding-right: 20px !important
    }

    .sm-pe-25px {
        padding-right: 25px !important
    }

    .sm-pe-30px {
        padding-right: 30px !important
    }

    .sm-pe-35px {
        padding-right: 35px !important
    }

    .sm-pe-40px {
        padding-right: 40px !important
    }

    .sm-pe-45px {
        padding-right: 45px !important
    }

    .sm-pe-50px {
        padding-right: 50px !important
    }

    .sm-pe-55px {
        padding-right: 55px !important
    }

    .sm-pe-60px {
        padding-right: 60px !important
    }

    .sm-pe-65px {
        padding-right: 65px !important
    }

    .sm-pe-70px {
        padding-right: 70px !important
    }

    .sm-pe-1 {
        padding-right: 1% !important
    }

    .sm-pe-2 {
        padding-right: 2% !important
    }

    .sm-pe-3 {
        padding-right: 3% !important
    }

    .sm-pe-4 {
        padding-right: 4% !important
    }

    .sm-pe-5 {
        padding-right: 5% !important
    }

    .sm-pe-6 {
        padding-right: 6% !important
    }

    .sm-pe-7 {
        padding-right: 7% !important
    }

    .sm-pe-8 {
        padding-right: 8% !important
    }

    .sm-pe-9 {
        padding-right: 9% !important
    }

    .sm-pe-10 {
        padding-right: 10% !important
    }

    .sm-pe-11 {
        padding-right: 11% !important
    }

    .sm-pe-12 {
        padding-right: 12% !important
    }

    .sm-pe-13 {
        padding-right: 13% !important
    }

    .sm-pe-14 {
        padding-right: 14% !important
    }

    .sm-pe-15 {
        padding-right: 15% !important
    }

    .sm-pe-16 {
        padding-right: 16% !important
    }

    .sm-pe-17 {
        padding-right: 17% !important
    }

    .sm-pe-18 {
        padding-right: 18% !important
    }

    .sm-pe-19 {
        padding-right: 19% !important
    }

    .sm-pe-20 {
        padding-right: 20% !important
    }

    .sm-pe-21 {
        padding-right: 21% !important
    }

    .sm-pe-22 {
        padding-right: 22% !important
    }

    .sm-pe-23 {
        padding-right: 23% !important
    }

    .sm-pe-24 {
        padding-right: 24% !important
    }

    .sm-pe-25 {
        padding-right: 25% !important
    }

    .sm-pe-26 {
        padding-right: 26% !important
    }

    .sm-pe-27 {
        padding-right: 27% !important
    }

    .sm-pe-28 {
        padding-right: 28% !important
    }

    .sm-pe-29 {
        padding-right: 29% !important
    }

    .sm-pe-30 {
        padding-right: 30% !important
    }

    .sm-pb-5px {
        padding-bottom: 5px !important
    }

    .sm-pb-10px {
        padding-bottom: 10px !important
    }

    .sm-pb-15px {
        padding-bottom: 15px !important
    }

    .sm-pb-20px {
        padding-bottom: 20px !important
    }

    .sm-pb-25px {
        padding-bottom: 25px !important
    }

    .sm-pb-30px {
        padding-bottom: 30px !important
    }

    .sm-pb-35px {
        padding-bottom: 35px !important
    }

    .sm-pb-40px {
        padding-bottom: 40px !important
    }

    .sm-pb-45px {
        padding-bottom: 45px !important
    }

    .sm-pb-50px {
        padding-bottom: 50px !important
    }

    .sm-pb-55px {
        padding-bottom: 55px !important
    }

    .sm-pb-60px {
        padding-bottom: 60px !important
    }

    .sm-pb-65px {
        padding-bottom: 65px !important
    }

    .sm-pb-70px {
        padding-bottom: 70px !important
    }

    .sm-pb-1 {
        padding-bottom: 1% !important
    }

    .sm-pb-2 {
        padding-bottom: 2% !important
    }

    .sm-pb-3 {
        padding-bottom: 3% !important
    }

    .sm-pb-4 {
        padding-bottom: 4% !important
    }

    .sm-pb-5 {
        padding-bottom: 5% !important
    }

    .sm-pb-6 {
        padding-bottom: 6% !important
    }

    .sm-pb-7 {
        padding-bottom: 7% !important
    }

    .sm-pb-8 {
        padding-bottom: 8% !important
    }

    .sm-pb-9 {
        padding-bottom: 9% !important
    }

    .sm-pb-10 {
        padding-bottom: 10% !important
    }

    .sm-pb-11 {
        padding-bottom: 11% !important
    }

    .sm-pb-12 {
        padding-bottom: 12% !important
    }

    .sm-pb-13 {
        padding-bottom: 13% !important
    }

    .sm-pb-14 {
        padding-bottom: 14% !important
    }

    .sm-pb-15 {
        padding-bottom: 15% !important
    }

    .sm-pb-16 {
        padding-bottom: 16% !important
    }

    .sm-pb-17 {
        padding-bottom: 17% !important
    }

    .sm-pb-18 {
        padding-bottom: 18% !important
    }

    .sm-pb-19 {
        padding-bottom: 19% !important
    }

    .sm-pb-20 {
        padding-bottom: 20% !important
    }

    .sm-pb-21 {
        padding-bottom: 21% !important
    }

    .sm-pb-22 {
        padding-bottom: 22% !important
    }

    .sm-pb-23 {
        padding-bottom: 23% !important
    }

    .sm-pb-24 {
        padding-bottom: 24% !important
    }

    .sm-pb-25 {
        padding-bottom: 25% !important
    }

    .sm-pb-26 {
        padding-bottom: 26% !important
    }

    .sm-pb-27 {
        padding-bottom: 27% !important
    }

    .sm-pb-28 {
        padding-bottom: 28% !important
    }

    .sm-pb-29 {
        padding-bottom: 29% !important
    }

    .sm-pb-30 {
        padding-bottom: 30% !important
    }

    .sm-ps-5px {
        padding-left: 5px !important
    }

    .sm-ps-10px {
        padding-left: 10px !important
    }

    .sm-ps-15px {
        padding-left: 15px !important
    }

    .sm-ps-20px {
        padding-left: 20px !important
    }

    .sm-ps-25px {
        padding-left: 25px !important
    }

    .sm-ps-30px {
        padding-left: 30px !important
    }

    .sm-ps-35px {
        padding-left: 35px !important
    }

    .sm-ps-40px {
        padding-left: 40px !important
    }

    .sm-ps-45px {
        padding-left: 45px !important
    }

    .sm-ps-50px {
        padding-left: 50px !important
    }

    .sm-ps-55px {
        padding-left: 55px !important
    }

    .sm-ps-60px {
        padding-left: 60px !important
    }

    .sm-ps-65px {
        padding-left: 65px !important
    }

    .sm-ps-70px {
        padding-left: 70px !important
    }

    .sm-ps-1 {
        padding-left: 1% !important
    }

    .sm-ps-2 {
        padding-left: 2% !important
    }

    .sm-ps-3 {
        padding-left: 3% !important
    }

    .sm-ps-4 {
        padding-left: 4% !important
    }

    .sm-ps-5 {
        padding-left: 5% !important
    }

    .sm-ps-6 {
        padding-left: 6% !important
    }

    .sm-ps-7 {
        padding-left: 7% !important
    }

    .sm-ps-8 {
        padding-left: 8% !important
    }

    .sm-ps-9 {
        padding-left: 9% !important
    }

    .sm-ps-10 {
        padding-left: 10% !important
    }

    .sm-ps-11 {
        padding-left: 11% !important
    }

    .sm-ps-12 {
        padding-left: 12% !important
    }

    .sm-ps-13 {
        padding-left: 13% !important
    }

    .sm-ps-14 {
        padding-left: 14% !important
    }

    .sm-ps-15 {
        padding-left: 15% !important
    }

    .sm-ps-16 {
        padding-left: 16% !important
    }

    .sm-ps-17 {
        padding-left: 17% !important
    }

    .sm-ps-18 {
        padding-left: 18% !important
    }

    .sm-ps-19 {
        padding-left: 19% !important
    }

    .sm-ps-20 {
        padding-left: 20% !important
    }

    .sm-ps-21 {
        padding-left: 21% !important
    }

    .sm-ps-22 {
        padding-left: 22% !important
    }

    .sm-ps-23 {
        padding-left: 23% !important
    }

    .sm-ps-24 {
        padding-left: 24% !important
    }

    .sm-ps-25 {
        padding-left: 25% !important
    }

    .sm-ps-26 {
        padding-left: 26% !important
    }

    .sm-ps-27 {
        padding-left: 27% !important
    }

    .sm-ps-28 {
        padding-left: 28% !important
    }

    .sm-ps-29 {
        padding-left: 29% !important
    }

    .sm-ps-30 {
        padding-left: 30% !important
    }

    .sm-m-5px {
        margin: 5px !important
    }

    .sm-m-10px {
        margin: 10px !important
    }

    .sm-m-15px {
        margin: 15px !important
    }

    .sm-m-20px {
        margin: 20px !important
    }

    .sm-m-25px {
        margin: 25px !important
    }

    .sm-m-30px {
        margin: 30px !important
    }

    .sm-m-35px {
        margin: 35px !important
    }

    .sm-m-40px {
        margin: 40px !important
    }

    .sm-m-45px {
        margin: 45px !important
    }

    .sm-m-50px {
        margin: 50px !important
    }

    .sm-m-55px {
        margin: 55px !important
    }

    .sm-m-60px {
        margin: 60px !important
    }

    .sm-m-65px {
        margin: 65px !important
    }

    .sm-m-70px {
        margin: 70px !important
    }

    .sm-m-1 {
        margin: 1% !important
    }

    .sm-m-2 {
        margin: 2% !important
    }

    .sm-m-3 {
        margin: 3% !important
    }

    .sm-m-4 {
        margin: 4% !important
    }

    .sm-m-5 {
        margin: 5% !important
    }

    .sm-m-6 {
        margin: 6% !important
    }

    .sm-m-7 {
        margin: 7% !important
    }

    .sm-m-8 {
        margin: 8% !important
    }

    .sm-m-9 {
        margin: 9% !important
    }

    .sm-m-10 {
        margin: 10% !important
    }

    .sm-m-11 {
        margin: 11% !important
    }

    .sm-m-12 {
        margin: 12% !important
    }

    .sm-m-13 {
        margin: 13% !important
    }

    .sm-m-14 {
        margin: 14% !important
    }

    .sm-m-15 {
        margin: 15% !important
    }

    .sm-m-16 {
        margin: 16% !important
    }

    .sm-m-17 {
        margin: 17% !important
    }

    .sm-m-18 {
        margin: 18% !important
    }

    .sm-m-19 {
        margin: 19% !important
    }

    .sm-m-20 {
        margin: 20% !important
    }

    .sm-m-21 {
        margin: 21% !important
    }

    .sm-m-22 {
        margin: 22% !important
    }

    .sm-m-23 {
        margin: 23% !important
    }

    .sm-m-24 {
        margin: 24% !important
    }

    .sm-m-25 {
        margin: 25% !important
    }

    .sm-m-26 {
        margin: 26% !important
    }

    .sm-m-27 {
        margin: 27% !important
    }

    .sm-m-28 {
        margin: 28% !important
    }

    .sm-m-29 {
        margin: 29% !important
    }

    .sm-m-30 {
        margin: 30% !important
    }

    .sm-mt-5px {
        margin-top: 5px !important
    }

    .sm-mt-10px {
        margin-top: 10px !important
    }

    .sm-mt-15px {
        margin-top: 15px !important
    }

    .sm-mt-20px {
        margin-top: 20px !important
    }

    .sm-mt-25px {
        margin-top: 25px !important
    }

    .sm-mt-30px {
        margin-top: 30px !important
    }

    .sm-mt-35px {
        margin-top: 35px !important
    }

    .sm-mt-40px {
        margin-top: 40px !important
    }

    .sm-mt-45px {
        margin-top: 45px !important
    }

    .sm-mt-50px {
        margin-top: 50px !important
    }

    .sm-mt-55px {
        margin-top: 55px !important
    }

    .sm-mt-60px {
        margin-top: 60px !important
    }

    .sm-mt-65px {
        margin-top: 65px !important
    }

    .sm-mt-70px {
        margin-top: 70px !important
    }

    .sm-mt-1 {
        margin-top: 1% !important
    }

    .sm-mt-2 {
        margin-top: 2% !important
    }

    .sm-mt-3 {
        margin-top: 3% !important
    }

    .sm-mt-4 {
        margin-top: 4% !important
    }

    .sm-mt-5 {
        margin-top: 5% !important
    }

    .sm-mt-6 {
        margin-top: 6% !important
    }

    .sm-mt-7 {
        margin-top: 7% !important
    }

    .sm-mt-8 {
        margin-top: 8% !important
    }

    .sm-mt-9 {
        margin-top: 9% !important
    }

    .sm-mt-10 {
        margin-top: 10% !important
    }

    .sm-mt-11 {
        margin-top: 11% !important
    }

    .sm-mt-12 {
        margin-top: 12% !important
    }

    .sm-mt-13 {
        margin-top: 13% !important
    }

    .sm-mt-14 {
        margin-top: 14% !important
    }

    .sm-mt-15 {
        margin-top: 15% !important
    }

    .sm-mt-16 {
        margin-top: 16% !important
    }

    .sm-mt-17 {
        margin-top: 17% !important
    }

    .sm-mt-18 {
        margin-top: 18% !important
    }

    .sm-mt-19 {
        margin-top: 19% !important
    }

    .sm-mt-20 {
        margin-top: 20% !important
    }

    .sm-mt-21 {
        margin-top: 21% !important
    }

    .sm-mt-22 {
        margin-top: 22% !important
    }

    .sm-mt-23 {
        margin-top: 23% !important
    }

    .sm-mt-24 {
        margin-top: 24% !important
    }

    .sm-mt-25 {
        margin-top: 25% !important
    }

    .sm-mt-26 {
        margin-top: 26% !important
    }

    .sm-mt-27 {
        margin-top: 27% !important
    }

    .sm-mt-28 {
        margin-top: 28% !important
    }

    .sm-mt-29 {
        margin-top: 29% !important
    }

    .sm-mt-30 {
        margin-top: 30% !important
    }

    .sm-me-5px {
        margin-right: 5px !important
    }

    .sm-me-10px {
        margin-right: 10px !important
    }

    .sm-me-15px {
        margin-right: 15px !important
    }

    .sm-me-20px {
        margin-right: 20px !important
    }

    .sm-me-25px {
        margin-right: 25px !important
    }

    .sm-me-30px {
        margin-right: 30px !important
    }

    .sm-me-35px {
        margin-right: 35px !important
    }

    .sm-me-40px {
        margin-right: 40px !important
    }

    .sm-me-45px {
        margin-right: 45px !important
    }

    .sm-me-50px {
        margin-right: 50px !important
    }

    .sm-me-55px {
        margin-right: 55px !important
    }

    .sm-me-60px {
        margin-right: 60px !important
    }

    .sm-me-65px {
        margin-right: 65px !important
    }

    .sm-me-70px {
        margin-right: 70px !important
    }

    .sm-me-1 {
        margin-right: 1% !important
    }

    .sm-me-2 {
        margin-right: 2% !important
    }

    .sm-me-3 {
        margin-right: 3% !important
    }

    .sm-me-4 {
        margin-right: 4% !important
    }

    .sm-me-5 {
        margin-right: 5% !important
    }

    .sm-me-6 {
        margin-right: 6% !important
    }

    .sm-me-7 {
        margin-right: 7% !important
    }

    .sm-me-8 {
        margin-right: 8% !important
    }

    .sm-me-9 {
        margin-right: 9% !important
    }

    .sm-me-10 {
        margin-right: 10% !important
    }

    .sm-me-11 {
        margin-right: 11% !important
    }

    .sm-me-12 {
        margin-right: 12% !important
    }

    .sm-me-13 {
        margin-right: 13% !important
    }

    .sm-me-14 {
        margin-right: 14% !important
    }

    .sm-me-15 {
        margin-right: 15% !important
    }

    .sm-me-16 {
        margin-right: 16% !important
    }

    .sm-me-17 {
        margin-right: 17% !important
    }

    .sm-me-18 {
        margin-right: 18% !important
    }

    .sm-me-19 {
        margin-right: 19% !important
    }

    .sm-me-20 {
        margin-right: 20% !important
    }

    .sm-me-21 {
        margin-right: 21% !important
    }

    .sm-me-22 {
        margin-right: 22% !important
    }

    .sm-me-23 {
        margin-right: 23% !important
    }

    .sm-me-24 {
        margin-right: 24% !important
    }

    .sm-me-25 {
        margin-right: 25% !important
    }

    .sm-me-26 {
        margin-right: 26% !important
    }

    .sm-me-27 {
        margin-right: 27% !important
    }

    .sm-me-28 {
        margin-right: 28% !important
    }

    .sm-me-29 {
        margin-right: 29% !important
    }

    .sm-me-30 {
        margin-right: 30% !important
    }

    .sm-mb-5px {
        margin-bottom: 5px !important
    }

    .sm-mb-10px {
        margin-bottom: 10px !important
    }

    .sm-mb-15px {
        margin-bottom: 15px !important
    }

    .sm-mb-20px {
        margin-bottom: 20px !important
    }

    .sm-mb-25px {
        margin-bottom: 25px !important
    }

    .sm-mb-30px {
        margin-bottom: 30px !important
    }

    .sm-mb-35px {
        margin-bottom: 35px !important
    }

    .sm-mb-40px {
        margin-bottom: 40px !important
    }

    .sm-mb-45px {
        margin-bottom: 45px !important
    }

    .sm-mb-50px {
        margin-bottom: 50px !important
    }

    .sm-mb-55px {
        margin-bottom: 55px !important
    }

    .sm-mb-60px {
        margin-bottom: 60px !important
    }

    .sm-mb-65px {
        margin-bottom: 65px !important
    }

    .sm-mb-70px {
        margin-bottom: 70px !important
    }

    .sm-mb-1 {
        margin-bottom: 1% !important
    }

    .sm-mb-2 {
        margin-bottom: 2% !important
    }

    .sm-mb-3 {
        margin-bottom: 3% !important
    }

    .sm-mb-4 {
        margin-bottom: 4% !important
    }

    .sm-mb-5 {
        margin-bottom: 5% !important
    }

    .sm-mb-6 {
        margin-bottom: 6% !important
    }

    .sm-mb-7 {
        margin-bottom: 7% !important
    }

    .sm-mb-8 {
        margin-bottom: 8% !important
    }

    .sm-mb-9 {
        margin-bottom: 9% !important
    }

    .sm-mb-10 {
        margin-bottom: 10% !important
    }

    .sm-mb-11 {
        margin-bottom: 11% !important
    }

    .sm-mb-12 {
        margin-bottom: 12% !important
    }

    .sm-mb-13 {
        margin-bottom: 13% !important
    }

    .sm-mb-14 {
        margin-bottom: 14% !important
    }

    .sm-mb-15 {
        margin-bottom: 15% !important
    }

    .sm-mb-16 {
        margin-bottom: 16% !important
    }

    .sm-mb-17 {
        margin-bottom: 17% !important
    }

    .sm-mb-18 {
        margin-bottom: 18% !important
    }

    .sm-mb-19 {
        margin-bottom: 19% !important
    }

    .sm-mb-20 {
        margin-bottom: 20% !important
    }

    .sm-mb-21 {
        margin-bottom: 21% !important
    }

    .sm-mb-22 {
        margin-bottom: 22% !important
    }

    .sm-mb-23 {
        margin-bottom: 23% !important
    }

    .sm-mb-24 {
        margin-bottom: 24% !important
    }

    .sm-mb-25 {
        margin-bottom: 25% !important
    }

    .sm-mb-26 {
        margin-bottom: 26% !important
    }

    .sm-mb-27 {
        margin-bottom: 27% !important
    }

    .sm-mb-28 {
        margin-bottom: 28% !important
    }

    .sm-mb-29 {
        margin-bottom: 29% !important
    }

    .sm-mb-30 {
        margin-bottom: 30% !important
    }

    .sm-ms-5px {
        margin-left: 5px !important
    }

    .sm-ms-10px {
        margin-left: 10px !important
    }

    .sm-ms-15px {
        margin-left: 15px !important
    }

    .sm-ms-20px {
        margin-left: 20px !important
    }

    .sm-ms-25px {
        margin-left: 25px !important
    }

    .sm-ms-30px {
        margin-left: 30px !important
    }

    .sm-ms-35px {
        margin-left: 35px !important
    }

    .sm-ms-40px {
        margin-left: 40px !important
    }

    .sm-ms-45px {
        margin-left: 45px !important
    }

    .sm-ms-50px {
        margin-left: 50px !important
    }

    .sm-ms-55px {
        margin-left: 55px !important
    }

    .sm-ms-60px {
        margin-left: 60px !important
    }

    .sm-ms-65px {
        margin-left: 65px !important
    }

    .sm-ms-70px {
        margin-left: 70px !important
    }

    .sm-ms-1 {
        margin-left: 1% !important
    }

    .sm-ms-2 {
        margin-left: 2% !important
    }

    .sm-ms-3 {
        margin-left: 3% !important
    }

    .sm-ms-4 {
        margin-left: 4% !important
    }

    .sm-ms-5 {
        margin-left: 5% !important
    }

    .sm-ms-6 {
        margin-left: 6% !important
    }

    .sm-ms-7 {
        margin-left: 7% !important
    }

    .sm-ms-8 {
        margin-left: 8% !important
    }

    .sm-ms-9 {
        margin-left: 9% !important
    }

    .sm-ms-10 {
        margin-left: 10% !important
    }

    .sm-ms-11 {
        margin-left: 11% !important
    }

    .sm-ms-12 {
        margin-left: 12% !important
    }

    .sm-ms-13 {
        margin-left: 13% !important
    }

    .sm-ms-14 {
        margin-left: 14% !important
    }

    .sm-ms-15 {
        margin-left: 15% !important
    }

    .sm-ms-16 {
        margin-left: 16% !important
    }

    .sm-ms-17 {
        margin-left: 17% !important
    }

    .sm-ms-18 {
        margin-left: 18% !important
    }

    .sm-ms-19 {
        margin-left: 19% !important
    }

    .sm-ms-20 {
        margin-left: 20% !important
    }

    .sm-ms-21 {
        margin-left: 21% !important
    }

    .sm-ms-22 {
        margin-left: 22% !important
    }

    .sm-ms-23 {
        margin-left: 23% !important
    }

    .sm-ms-24 {
        margin-left: 24% !important
    }

    .sm-ms-25 {
        margin-left: 25% !important
    }

    .sm-ms-26 {
        margin-left: 26% !important
    }

    .sm-ms-27 {
        margin-left: 27% !important
    }

    .sm-ms-28 {
        margin-left: 28% !important
    }

    .sm-ms-29 {
        margin-left: 29% !important
    }

    .sm-ms-30 {
        margin-left: 30% !important
    }

    .sm-top-0px {
        top: 0 !important
    }

    .sm-top-1px {
        top: 1px !important
    }

    .sm-top-2px {
        top: 2px !important
    }

    .sm-top-3px {
        top: 3px !important
    }

    .sm-top-4px {
        top: 4px !important
    }

    .sm-top-5px {
        top: 5px !important
    }

    .sm-top-6px {
        top: 6px !important
    }

    .sm-top-7px {
        top: 7px !important
    }

    .sm-top-8px {
        top: 8px !important
    }

    .sm-top-9px {
        top: 9px !important
    }

    .sm-top-10px {
        top: 10px !important
    }

    .sm-top-11px {
        top: 11px !important
    }

    .sm-top-12px {
        top: 12px !important
    }

    .sm-top-13px {
        top: 13px !important
    }

    .sm-top-14px {
        top: 14px !important
    }

    .sm-top-15px {
        top: 15px !important
    }

    .sm-top-16px {
        top: 16px !important
    }

    .sm-top-17px {
        top: 17px !important
    }

    .sm-top-18px {
        top: 18px !important
    }

    .sm-top-19px {
        top: 19px !important
    }

    .sm-top-20px {
        top: 20px !important
    }

    .sm-top-21px {
        top: 21px !important
    }

    .sm-top-22px {
        top: 22px !important
    }

    .sm-top-23px {
        top: 23px !important
    }

    .sm-top-24px {
        top: 24px !important
    }

    .sm-top-25px {
        top: 25px !important
    }

    .sm-top-26px {
        top: 26px !important
    }

    .sm-top-27px {
        top: 27px !important
    }

    .sm-top-28px {
        top: 28px !important
    }

    .sm-top-29px {
        top: 29px !important
    }

    .sm-top-30px {
        top: 30px !important
    }

    .sm-top-40px {
        top: 40px !important
    }

    .sm-top-50px {
        top: 50px !important
    }

    .sm-top-60px {
        top: 60px !important
    }

    .sm-top-70px {
        top: 70px !important
    }

    .sm-top-80px {
        top: 80px !important
    }

    .sm-top-90px {
        top: 90px !important
    }

    .sm-top-100px {
        top: 100px !important
    }

    .sm-top-110px {
        top: 110px !important
    }

    .sm-top-120px {
        top: 120px !important
    }

    .sm-top-130px {
        top: 130px !important
    }

    .sm-top-140px {
        top: 140px !important
    }

    .sm-top-150px {
        top: 150px !important
    }

    .sm-right-0px {
        right: 0 !important
    }

    .sm-right-1px {
        right: 1px !important
    }

    .sm-right-2px {
        right: 2px !important
    }

    .sm-right-3px {
        right: 3px !important
    }

    .sm-right-4px {
        right: 4px !important
    }

    .sm-right-5px {
        right: 5px !important
    }

    .sm-right-6px {
        right: 6px !important
    }

    .sm-right-7px {
        right: 7px !important
    }

    .sm-right-8px {
        right: 8px !important
    }

    .sm-right-9px {
        right: 9px !important
    }

    .sm-right-10px {
        right: 10px !important
    }

    .sm-right-11px {
        right: 11px !important
    }

    .sm-right-12px {
        right: 12px !important
    }

    .sm-right-13px {
        right: 13px !important
    }

    .sm-right-14px {
        right: 14px !important
    }

    .sm-right-15px {
        right: 15px !important
    }

    .sm-right-16px {
        right: 16px !important
    }

    .sm-right-17px {
        right: 17px !important
    }

    .sm-right-18px {
        right: 18px !important
    }

    .sm-right-19px {
        right: 19px !important
    }

    .sm-right-20px {
        right: 20px !important
    }

    .sm-right-21px {
        right: 21px !important
    }

    .sm-right-22px {
        right: 22px !important
    }

    .sm-right-23px {
        right: 23px !important
    }

    .sm-right-24px {
        right: 24px !important
    }

    .sm-right-25px {
        right: 25px !important
    }

    .sm-right-26px {
        right: 26px !important
    }

    .sm-right-27px {
        right: 27px !important
    }

    .sm-right-28px {
        right: 28px !important
    }

    .sm-right-29px {
        right: 29px !important
    }

    .sm-right-30px {
        right: 30px !important
    }

    .sm-right-40px {
        right: 40px !important
    }

    .sm-right-50px {
        right: 50px !important
    }

    .sm-right-60px {
        right: 60px !important
    }

    .sm-right-70px {
        right: 70px !important
    }

    .sm-right-80px {
        right: 80px !important
    }

    .sm-right-90px {
        right: 90px !important
    }

    .sm-right-100px {
        right: 100px !important
    }

    .sm-right-110px {
        right: 110px !important
    }

    .sm-right-120px {
        right: 120px !important
    }

    .sm-right-130px {
        right: 130px !important
    }

    .sm-right-140px {
        right: 140px !important
    }

    .sm-right-150px {
        right: 150px !important
    }

    .sm-bottom-0px {
        bottom: 0 !important
    }

    .sm-bottom-1px {
        bottom: 1px !important
    }

    .sm-bottom-2px {
        bottom: 2px !important
    }

    .sm-bottom-3px {
        bottom: 3px !important
    }

    .sm-bottom-4px {
        bottom: 4px !important
    }

    .sm-bottom-5px {
        bottom: 5px !important
    }

    .sm-bottom-6px {
        bottom: 6px !important
    }

    .sm-bottom-7px {
        bottom: 7px !important
    }

    .sm-bottom-8px {
        bottom: 8px !important
    }

    .sm-bottom-9px {
        bottom: 9px !important
    }

    .sm-bottom-10px {
        bottom: 10px !important
    }

    .sm-bottom-11px {
        bottom: 11px !important
    }

    .sm-bottom-12px {
        bottom: 12px !important
    }

    .sm-bottom-13px {
        bottom: 13px !important
    }

    .sm-bottom-14px {
        bottom: 14px !important
    }

    .sm-bottom-15px {
        bottom: 15px !important
    }

    .sm-bottom-16px {
        bottom: 16px !important
    }

    .sm-bottom-17px {
        bottom: 17px !important
    }

    .sm-bottom-18px {
        bottom: 18px !important
    }

    .sm-bottom-19px {
        bottom: 19px !important
    }

    .sm-bottom-20px {
        bottom: 20px !important
    }

    .sm-bottom-21px {
        bottom: 21px !important
    }

    .sm-bottom-22px {
        bottom: 22px !important
    }

    .sm-bottom-23px {
        bottom: 23px !important
    }

    .sm-bottom-24px {
        bottom: 24px !important
    }

    .sm-bottom-25px {
        bottom: 25px !important
    }

    .sm-bottom-26px {
        bottom: 26px !important
    }

    .sm-bottom-27px {
        bottom: 27px !important
    }

    .sm-bottom-28px {
        bottom: 28px !important
    }

    .sm-bottom-29px {
        bottom: 29px !important
    }

    .sm-bottom-30px {
        bottom: 30px !important
    }

    .sm-bottom-40px {
        bottom: 40px !important
    }

    .sm-bottom-50px {
        bottom: 50px !important
    }

    .sm-bottom-60px {
        bottom: 60px !important
    }

    .sm-bottom-70px {
        bottom: 70px !important
    }

    .sm-bottom-80px {
        bottom: 80px !important
    }

    .sm-bottom-90px {
        bottom: 90px !important
    }

    .sm-bottom-100px {
        bottom: 100px !important
    }

    .sm-bottom-110px {
        bottom: 110px !important
    }

    .sm-bottom-120px {
        bottom: 120px !important
    }

    .sm-bottom-130px {
        bottom: 130px !important
    }

    .sm-bottom-140px {
        bottom: 140px !important
    }

    .sm-bottom-150px {
        bottom: 150px !important
    }

    .sm-left-0px {
        left: 0 !important
    }

    .sm-left-1px {
        left: 1px !important
    }

    .sm-left-2px {
        left: 2px !important
    }

    .sm-left-3px {
        left: 3px !important
    }

    .sm-left-4px {
        left: 4px !important
    }

    .sm-left-5px {
        left: 5px !important
    }

    .sm-left-6px {
        left: 6px !important
    }

    .sm-left-7px {
        left: 7px !important
    }

    .sm-left-8px {
        left: 8px !important
    }

    .sm-left-9px {
        left: 9px !important
    }

    .sm-left-10px {
        left: 10px !important
    }

    .sm-left-11px {
        left: 11px !important
    }

    .sm-left-12px {
        left: 12px !important
    }

    .sm-left-13px {
        left: 13px !important
    }

    .sm-left-14px {
        left: 14px !important
    }

    .sm-left-15px {
        left: 15px !important
    }

    .sm-left-16px {
        left: 16px !important
    }

    .sm-left-17px {
        left: 17px !important
    }

    .sm-left-18px {
        left: 18px !important
    }

    .sm-left-19px {
        left: 19px !important
    }

    .sm-left-20px {
        left: 20px !important
    }

    .sm-left-21px {
        left: 21px !important
    }

    .sm-left-22px {
        left: 22px !important
    }

    .sm-left-23px {
        left: 23px !important
    }

    .sm-left-24px {
        left: 24px !important
    }

    .sm-left-25px {
        left: 25px !important
    }

    .sm-left-26px {
        left: 26px !important
    }

    .sm-left-27px {
        left: 27px !important
    }

    .sm-left-28px {
        left: 28px !important
    }

    .sm-left-29px {
        left: 29px !important
    }

    .sm-left-30px {
        left: 30px !important
    }

    .sm-left-40px {
        left: 40px !important
    }

    .sm-left-50px {
        left: 50px !important
    }

    .sm-left-60px {
        left: 60px !important
    }

    .sm-left-70px {
        left: 70px !important
    }

    .sm-left-80px {
        left: 80px !important
    }

    .sm-left-90px {
        left: 90px !important
    }

    .sm-left-100px {
        left: 100px !important
    }

    .sm-left-110px {
        left: 110px !important
    }

    .sm-left-120px {
        left: 120px !important
    }

    .sm-left-130px {
        left: 130px !important
    }

    .sm-left-140px {
        left: 140px !important
    }

    .sm-left-150px {
        left: 150px !important
    }

    .sm-top-minus-0px {
        top: 0 !important
    }

    .sm-top-minus-1px {
        top: -1px !important
    }

    .sm-top-minus-2px {
        top: -2px !important
    }

    .sm-top-minus-3px {
        top: -3px !important
    }

    .sm-top-minus-4px {
        top: -4px !important
    }

    .sm-top-minus-5px {
        top: -5px !important
    }

    .sm-top-minus-6px {
        top: -6px !important
    }

    .sm-top-minus-7px {
        top: -7px !important
    }

    .sm-top-minus-8px {
        top: -8px !important
    }

    .sm-top-minus-9px {
        top: -9px !important
    }

    .sm-top-minus-10px {
        top: -10px !important
    }

    .sm-top-minus-11px {
        top: -11px !important
    }

    .sm-top-minus-12px {
        top: -12px !important
    }

    .sm-top-minus-13px {
        top: -13px !important
    }

    .sm-top-minus-14px {
        top: -14px !important
    }

    .sm-top-minus-15px {
        top: -15px !important
    }

    .sm-top-minus-16px {
        top: -16px !important
    }

    .sm-top-minus-17px {
        top: -17px !important
    }

    .sm-top-minus-18px {
        top: -18px !important
    }

    .sm-top-minus-19px {
        top: -19px !important
    }

    .sm-top-minus-20px {
        top: -20px !important
    }

    .sm-top-minus-21px {
        top: -21px !important
    }

    .sm-top-minus-22px {
        top: -22px !important
    }

    .sm-top-minus-23px {
        top: -23px !important
    }

    .sm-top-minus-24px {
        top: -24px !important
    }

    .sm-top-minus-25px {
        top: -25px !important
    }

    .sm-top-minus-26px {
        top: -26px !important
    }

    .sm-top-minus-27px {
        top: -27px !important
    }

    .sm-top-minus-28px {
        top: -28px !important
    }

    .sm-top-minus-29px {
        top: -29px !important
    }

    .sm-top-minus-30px {
        top: -30px !important
    }

    .sm-top-minus-40px {
        top: -40px !important
    }

    .sm-top-minus-50px {
        top: -50px !important
    }

    .sm-top-minus-60px {
        top: -60px !important
    }

    .sm-top-minus-70px {
        top: -70px !important
    }

    .sm-top-minus-80px {
        top: -80px !important
    }

    .sm-top-minus-90px {
        top: -90px !important
    }

    .sm-top-minus-100px {
        top: -100px !important
    }

    .sm-top-minus-110px {
        top: -110px !important
    }

    .sm-top-minus-120px {
        top: -120px !important
    }

    .sm-top-minus-130px {
        top: -130px !important
    }

    .sm-top-minus-140px {
        top: -140px !important
    }

    .sm-top-minus-150px {
        top: -150px !important
    }

    .sm-right-minus-0px {
        right: 0 !important
    }

    .sm-right-minus-1px {
        right: -1px !important
    }

    .sm-right-minus-2px {
        right: -2px !important
    }

    .sm-right-minus-3px {
        right: -3px !important
    }

    .sm-right-minus-4px {
        right: -4px !important
    }

    .sm-right-minus-5px {
        right: -5px !important
    }

    .sm-right-minus-6px {
        right: -6px !important
    }

    .sm-right-minus-7px {
        right: -7px !important
    }

    .sm-right-minus-8px {
        right: -8px !important
    }

    .sm-right-minus-9px {
        right: -9px !important
    }

    .sm-right-minus-10px {
        right: -10px !important
    }

    .sm-right-minus-11px {
        right: -11px !important
    }

    .sm-right-minus-12px {
        right: -12px !important
    }

    .sm-right-minus-13px {
        right: -13px !important
    }

    .sm-right-minus-14px {
        right: -14px !important
    }

    .sm-right-minus-15px {
        right: -15px !important
    }

    .sm-right-minus-16px {
        right: -16px !important
    }

    .sm-right-minus-17px {
        right: -17px !important
    }

    .sm-right-minus-18px {
        right: -18px !important
    }

    .sm-right-minus-19px {
        right: -19px !important
    }

    .sm-right-minus-20px {
        right: -20px !important
    }

    .sm-right-minus-21px {
        right: -21px !important
    }

    .sm-right-minus-22px {
        right: -22px !important
    }

    .sm-right-minus-23px {
        right: -23px !important
    }

    .sm-right-minus-24px {
        right: -24px !important
    }

    .sm-right-minus-25px {
        right: -25px !important
    }

    .sm-right-minus-26px {
        right: -26px !important
    }

    .sm-right-minus-27px {
        right: -27px !important
    }

    .sm-right-minus-28px {
        right: -28px !important
    }

    .sm-right-minus-29px {
        right: -29px !important
    }

    .sm-right-minus-30px {
        right: -30px !important
    }

    .sm-right-minus-40px {
        right: -40px !important
    }

    .sm-right-minus-50px {
        right: -50px !important
    }

    .sm-right-minus-60px {
        right: -60px !important
    }

    .sm-right-minus-70px {
        right: -70px !important
    }

    .sm-right-minus-80px {
        right: -80px !important
    }

    .sm-right-minus-90px {
        right: -90px !important
    }

    .sm-right-minus-100px {
        right: -100px !important
    }

    .sm-right-minus-110px {
        right: -110px !important
    }

    .sm-right-minus-120px {
        right: -120px !important
    }

    .sm-right-minus-130px {
        right: -130px !important
    }

    .sm-right-minus-140px {
        right: -140px !important
    }

    .sm-right-minus-150px {
        right: -150px !important
    }

    .sm-bottom-minus-0px {
        bottom: 0 !important
    }

    .sm-bottom-minus-1px {
        bottom: -1px !important
    }

    .sm-bottom-minus-2px {
        bottom: -2px !important
    }

    .sm-bottom-minus-3px {
        bottom: -3px !important
    }

    .sm-bottom-minus-4px {
        bottom: -4px !important
    }

    .sm-bottom-minus-5px {
        bottom: -5px !important
    }

    .sm-bottom-minus-6px {
        bottom: -6px !important
    }

    .sm-bottom-minus-7px {
        bottom: -7px !important
    }

    .sm-bottom-minus-8px {
        bottom: -8px !important
    }

    .sm-bottom-minus-9px {
        bottom: -9px !important
    }

    .sm-bottom-minus-10px {
        bottom: -10px !important
    }

    .sm-bottom-minus-11px {
        bottom: -11px !important
    }

    .sm-bottom-minus-12px {
        bottom: -12px !important
    }

    .sm-bottom-minus-13px {
        bottom: -13px !important
    }

    .sm-bottom-minus-14px {
        bottom: -14px !important
    }

    .sm-bottom-minus-15px {
        bottom: -15px !important
    }

    .sm-bottom-minus-16px {
        bottom: -16px !important
    }

    .sm-bottom-minus-17px {
        bottom: -17px !important
    }

    .sm-bottom-minus-18px {
        bottom: -18px !important
    }

    .sm-bottom-minus-19px {
        bottom: -19px !important
    }

    .sm-bottom-minus-20px {
        bottom: -20px !important
    }

    .sm-bottom-minus-21px {
        bottom: -21px !important
    }

    .sm-bottom-minus-22px {
        bottom: -22px !important
    }

    .sm-bottom-minus-23px {
        bottom: -23px !important
    }

    .sm-bottom-minus-24px {
        bottom: -24px !important
    }

    .sm-bottom-minus-25px {
        bottom: -25px !important
    }

    .sm-bottom-minus-26px {
        bottom: -26px !important
    }

    .sm-bottom-minus-27px {
        bottom: -27px !important
    }

    .sm-bottom-minus-28px {
        bottom: -28px !important
    }

    .sm-bottom-minus-29px {
        bottom: -29px !important
    }

    .sm-bottom-minus-30px {
        bottom: -30px !important
    }

    .sm-bottom-minus-40px {
        bottom: -40px !important
    }

    .sm-bottom-minus-50px {
        bottom: -50px !important
    }

    .sm-bottom-minus-60px {
        bottom: -60px !important
    }

    .sm-bottom-minus-70px {
        bottom: -70px !important
    }

    .sm-bottom-minus-80px {
        bottom: -80px !important
    }

    .sm-bottom-minus-90px {
        bottom: -90px !important
    }

    .sm-bottom-minus-100px {
        bottom: -100px !important
    }

    .sm-bottom-minus-110px {
        bottom: -110px !important
    }

    .sm-bottom-minus-120px {
        bottom: -120px !important
    }

    .sm-bottom-minus-130px {
        bottom: -130px !important
    }

    .sm-bottom-minus-140px {
        bottom: -140px !important
    }

    .sm-bottom-minus-150px {
        bottom: -150px !important
    }

    .sm-left-minus-0px {
        left: 0 !important
    }

    .sm-left-minus-1px {
        left: -1px !important
    }

    .sm-left-minus-2px {
        left: -2px !important
    }

    .sm-left-minus-3px {
        left: -3px !important
    }

    .sm-left-minus-4px {
        left: -4px !important
    }

    .sm-left-minus-5px {
        left: -5px !important
    }

    .sm-left-minus-6px {
        left: -6px !important
    }

    .sm-left-minus-7px {
        left: -7px !important
    }

    .sm-left-minus-8px {
        left: -8px !important
    }

    .sm-left-minus-9px {
        left: -9px !important
    }

    .sm-left-minus-10px {
        left: -10px !important
    }

    .sm-left-minus-11px {
        left: -11px !important
    }

    .sm-left-minus-12px {
        left: -12px !important
    }

    .sm-left-minus-13px {
        left: -13px !important
    }

    .sm-left-minus-14px {
        left: -14px !important
    }

    .sm-left-minus-15px {
        left: -15px !important
    }

    .sm-left-minus-16px {
        left: -16px !important
    }

    .sm-left-minus-17px {
        left: -17px !important
    }

    .sm-left-minus-18px {
        left: -18px !important
    }

    .sm-left-minus-19px {
        left: -19px !important
    }

    .sm-left-minus-20px {
        left: -20px !important
    }

    .sm-left-minus-21px {
        left: -21px !important
    }

    .sm-left-minus-22px {
        left: -22px !important
    }

    .sm-left-minus-23px {
        left: -23px !important
    }

    .sm-left-minus-24px {
        left: -24px !important
    }

    .sm-left-minus-25px {
        left: -25px !important
    }

    .sm-left-minus-26px {
        left: -26px !important
    }

    .sm-left-minus-27px {
        left: -27px !important
    }

    .sm-left-minus-28px {
        left: -28px !important
    }

    .sm-left-minus-29px {
        left: -29px !important
    }

    .sm-left-minus-30px {
        left: -30px !important
    }

    .sm-left-minus-40px {
        left: -40px !important
    }

    .sm-left-minus-50px {
        left: -50px !important
    }

    .sm-left-minus-60px {
        left: -60px !important
    }

    .sm-left-minus-70px {
        left: -70px !important
    }

    .sm-left-minus-80px {
        left: -80px !important
    }

    .sm-left-minus-90px {
        left: -90px !important
    }

    .sm-left-minus-100px {
        left: -100px !important
    }

    .sm-left-minus-110px {
        left: -110px !important
    }

    .sm-left-minus-120px {
        left: -120px !important
    }

    .sm-left-minus-130px {
        left: -130px !important
    }

    .sm-left-minus-140px {
        left: -140px !important
    }

    .sm-left-minus-150px {
        left: -150px !important
    }

    .sm-border-width-0px {
        border-width: 0 !important
    }

    .sm-border-width-1px {
        border-width: 1px !important
    }

    .sm-border-width-2px {
        border-width: 2px !important
    }

    .sm-border-width-3px {
        border-width: 3px !important
    }

    .sm-border-width-4px {
        border-width: 4px !important
    }

    .sm-border-width-5px {
        border-width: 5px !important
    }

    .sm-border-width-6px {
        border-width: 6px !important
    }

    .sm-border-width-7px {
        border-width: 7px !important
    }

    .sm-border-width-8px {
        border-width: 8px !important
    }

    .sm-border-width-9px {
        border-width: 9px !important
    }

    .sm-border-width-10px {
        border-width: 10px !important
    }
}

@media (max-width:575px) {
    .xs-p-5px {
        padding: 5px !important
    }

    .xs-p-10px {
        padding: 10px !important
    }

    .xs-p-15px {
        padding: 15px !important
    }

    .xs-p-20px {
        padding: 20px !important
    }

    .xs-p-25px {
        padding: 25px !important
    }

    .xs-p-30px {
        padding: 30px !important
    }

    .xs-p-35px {
        padding: 35px !important
    }

    .xs-p-40px {
        padding: 40px !important
    }

    .xs-p-45px {
        padding: 45px !important
    }

    .xs-p-50px {
        padding: 50px !important
    }

    .xs-p-55px {
        padding: 55px !important
    }

    .xs-p-60px {
        padding: 60px !important
    }

    .xs-p-65px {
        padding: 65px !important
    }

    .xs-p-70px {
        padding: 70px !important
    }

    .xs-p-1 {
        padding: 1% !important
    }

    .xs-p-2 {
        padding: 2% !important
    }

    .xs-p-3 {
        padding: 3% !important
    }

    .xs-p-4 {
        padding: 4% !important
    }

    .xs-p-5 {
        padding: 5% !important
    }

    .xs-p-6 {
        padding: 6% !important
    }

    .xs-p-7 {
        padding: 7% !important
    }

    .xs-p-8 {
        padding: 8% !important
    }

    .xs-p-9 {
        padding: 9% !important
    }

    .xs-p-10 {
        padding: 10% !important
    }

    .xs-p-11 {
        padding: 11% !important
    }

    .xs-p-12 {
        padding: 12% !important
    }

    .xs-p-13 {
        padding: 13% !important
    }

    .xs-p-14 {
        padding: 14% !important
    }

    .xs-p-15 {
        padding: 15% !important
    }

    .xs-p-16 {
        padding: 16% !important
    }

    .xs-p-17 {
        padding: 17% !important
    }

    .xs-p-18 {
        padding: 18% !important
    }

    .xs-p-19 {
        padding: 19% !important
    }

    .xs-p-20 {
        padding: 20% !important
    }

    .xs-p-21 {
        padding: 21% !important
    }

    .xs-p-22 {
        padding: 22% !important
    }

    .xs-p-23 {
        padding: 23% !important
    }

    .xs-p-24 {
        padding: 24% !important
    }

    .xs-p-25 {
        padding: 25% !important
    }

    .xs-p-26 {
        padding: 26% !important
    }

    .xs-p-27 {
        padding: 27% !important
    }

    .xs-p-28 {
        padding: 28% !important
    }

    .xs-p-29 {
        padding: 29% !important
    }

    .xs-p-30 {
        padding: 30% !important
    }

    .xs-pt-5px {
        padding-top: 5px !important
    }

    .xs-pt-10px {
        padding-top: 10px !important
    }

    .xs-pt-15px {
        padding-top: 15px !important
    }

    .xs-pt-20px {
        padding-top: 20px !important
    }

    .xs-pt-25px {
        padding-top: 25px !important
    }

    .xs-pt-30px {
        padding-top: 30px !important
    }

    .xs-pt-35px {
        padding-top: 35px !important
    }

    .xs-pt-40px {
        padding-top: 40px !important
    }

    .xs-pt-45px {
        padding-top: 45px !important
    }

    .xs-pt-50px {
        padding-top: 50px !important
    }

    .xs-pt-55px {
        padding-top: 55px !important
    }

    .xs-pt-60px {
        padding-top: 60px !important
    }

    .xs-pt-65px {
        padding-top: 65px !important
    }

    .xs-pt-70px {
        padding-top: 70px !important
    }

    .xs-pt-1 {
        padding-top: 1% !important
    }

    .xs-pt-2 {
        padding-top: 2% !important
    }

    .xs-pt-3 {
        padding-top: 3% !important
    }

    .xs-pt-4 {
        padding-top: 4% !important
    }

    .xs-pt-5 {
        padding-top: 5% !important
    }

    .xs-pt-6 {
        padding-top: 6% !important
    }

    .xs-pt-7 {
        padding-top: 7% !important
    }

    .xs-pt-8 {
        padding-top: 8% !important
    }

    .xs-pt-9 {
        padding-top: 9% !important
    }

    .xs-pt-10 {
        padding-top: 10% !important
    }

    .xs-pt-11 {
        padding-top: 11% !important
    }

    .xs-pt-12 {
        padding-top: 12% !important
    }

    .xs-pt-13 {
        padding-top: 13% !important
    }

    .xs-pt-14 {
        padding-top: 14% !important
    }

    .xs-pt-15 {
        padding-top: 15% !important
    }

    .xs-pt-16 {
        padding-top: 16% !important
    }

    .xs-pt-17 {
        padding-top: 17% !important
    }

    .xs-pt-18 {
        padding-top: 18% !important
    }

    .xs-pt-19 {
        padding-top: 19% !important
    }

    .xs-pt-20 {
        padding-top: 20% !important
    }

    .xs-pt-21 {
        padding-top: 21% !important
    }

    .xs-pt-22 {
        padding-top: 22% !important
    }

    .xs-pt-23 {
        padding-top: 23% !important
    }

    .xs-pt-24 {
        padding-top: 24% !important
    }

    .xs-pt-25 {
        padding-top: 25% !important
    }

    .xs-pt-26 {
        padding-top: 26% !important
    }

    .xs-pt-27 {
        padding-top: 27% !important
    }

    .xs-pt-28 {
        padding-top: 28% !important
    }

    .xs-pt-29 {
        padding-top: 29% !important
    }

    .xs-pt-30 {
        padding-top: 30% !important
    }

    .xs-pe-5px {
        padding-right: 5px !important
    }

    .xs-pe-10px {
        padding-right: 10px !important
    }

    .xs-pe-15px {
        padding-right: 15px !important
    }

    .xs-pe-20px {
        padding-right: 20px !important
    }

    .xs-pe-25px {
        padding-right: 25px !important
    }

    .xs-pe-30px {
        padding-right: 30px !important
    }

    .xs-pe-35px {
        padding-right: 35px !important
    }

    .xs-pe-40px {
        padding-right: 40px !important
    }

    .xs-pe-45px {
        padding-right: 45px !important
    }

    .xs-pe-50px {
        padding-right: 50px !important
    }

    .xs-pe-55px {
        padding-right: 55px !important
    }

    .xs-pe-60px {
        padding-right: 60px !important
    }

    .xs-pe-65px {
        padding-right: 65px !important
    }

    .xs-pe-70px {
        padding-right: 70px !important
    }

    .xs-pe-1 {
        padding-right: 1% !important
    }

    .xs-pe-2 {
        padding-right: 2% !important
    }

    .xs-pe-3 {
        padding-right: 3% !important
    }

    .xs-pe-4 {
        padding-right: 4% !important
    }

    .xs-pe-5 {
        padding-right: 5% !important
    }

    .xs-pe-6 {
        padding-right: 6% !important
    }

    .xs-pe-7 {
        padding-right: 7% !important
    }

    .xs-pe-8 {
        padding-right: 8% !important
    }

    .xs-pe-9 {
        padding-right: 9% !important
    }

    .xs-pe-10 {
        padding-right: 10% !important
    }

    .xs-pe-11 {
        padding-right: 11% !important
    }

    .xs-pe-12 {
        padding-right: 12% !important
    }

    .xs-pe-13 {
        padding-right: 13% !important
    }

    .xs-pe-14 {
        padding-right: 14% !important
    }

    .xs-pe-15 {
        padding-right: 15% !important
    }

    .xs-pe-16 {
        padding-right: 16% !important
    }

    .xs-pe-17 {
        padding-right: 17% !important
    }

    .xs-pe-18 {
        padding-right: 18% !important
    }

    .xs-pe-19 {
        padding-right: 19% !important
    }

    .xs-pe-20 {
        padding-right: 20% !important
    }

    .xs-pe-21 {
        padding-right: 21% !important
    }

    .xs-pe-22 {
        padding-right: 22% !important
    }

    .xs-pe-23 {
        padding-right: 23% !important
    }

    .xs-pe-24 {
        padding-right: 24% !important
    }

    .xs-pe-25 {
        padding-right: 25% !important
    }

    .xs-pe-26 {
        padding-right: 26% !important
    }

    .xs-pe-27 {
        padding-right: 27% !important
    }

    .xs-pe-28 {
        padding-right: 28% !important
    }

    .xs-pe-29 {
        padding-right: 29% !important
    }

    .xs-pe-30 {
        padding-right: 30% !important
    }

    .xs-pb-5px {
        padding-bottom: 5px !important
    }

    .xs-pb-10px {
        padding-bottom: 10px !important
    }

    .xs-pb-15px {
        padding-bottom: 15px !important
    }

    .xs-pb-20px {
        padding-bottom: 20px !important
    }

    .xs-pb-25px {
        padding-bottom: 25px !important
    }

    .xs-pb-30px {
        padding-bottom: 30px !important
    }

    .xs-pb-35px {
        padding-bottom: 35px !important
    }

    .xs-pb-40px {
        padding-bottom: 40px !important
    }

    .xs-pb-45px {
        padding-bottom: 45px !important
    }

    .xs-pb-50px {
        padding-bottom: 50px !important
    }

    .xs-pb-55px {
        padding-bottom: 55px !important
    }

    .xs-pb-60px {
        padding-bottom: 60px !important
    }

    .xs-pb-65px {
        padding-bottom: 65px !important
    }

    .xs-pb-70px {
        padding-bottom: 70px !important
    }

    .xs-pb-1 {
        padding-bottom: 1% !important
    }

    .xs-pb-2 {
        padding-bottom: 2% !important
    }

    .xs-pb-3 {
        padding-bottom: 3% !important
    }

    .xs-pb-4 {
        padding-bottom: 4% !important
    }

    .xs-pb-5 {
        padding-bottom: 5% !important
    }

    .xs-pb-6 {
        padding-bottom: 6% !important
    }

    .xs-pb-7 {
        padding-bottom: 7% !important
    }

    .xs-pb-8 {
        padding-bottom: 8% !important
    }

    .xs-pb-9 {
        padding-bottom: 9% !important
    }

    .xs-pb-10 {
        padding-bottom: 10% !important
    }

    .xs-pb-11 {
        padding-bottom: 11% !important
    }

    .xs-pb-12 {
        padding-bottom: 12% !important
    }

    .xs-pb-13 {
        padding-bottom: 13% !important
    }

    .xs-pb-14 {
        padding-bottom: 14% !important
    }

    .xs-pb-15 {
        padding-bottom: 15% !important
    }

    .xs-pb-16 {
        padding-bottom: 16% !important
    }

    .xs-pb-17 {
        padding-bottom: 17% !important
    }

    .xs-pb-18 {
        padding-bottom: 18% !important
    }

    .xs-pb-19 {
        padding-bottom: 19% !important
    }

    .xs-pb-20 {
        padding-bottom: 20% !important
    }

    .xs-pb-21 {
        padding-bottom: 21% !important
    }

    .xs-pb-22 {
        padding-bottom: 22% !important
    }

    .xs-pb-23 {
        padding-bottom: 23% !important
    }

    .xs-pb-24 {
        padding-bottom: 24% !important
    }

    .xs-pb-25 {
        padding-bottom: 25% !important
    }

    .xs-pb-26 {
        padding-bottom: 26% !important
    }

    .xs-pb-27 {
        padding-bottom: 27% !important
    }

    .xs-pb-28 {
        padding-bottom: 28% !important
    }

    .xs-pb-29 {
        padding-bottom: 29% !important
    }

    .xs-pb-30 {
        padding-bottom: 30% !important
    }

    .xs-ps-5px {
        padding-left: 5px !important
    }

    .xs-ps-10px {
        padding-left: 10px !important
    }

    .xs-ps-15px {
        padding-left: 15px !important
    }

    .xs-ps-20px {
        padding-left: 20px !important
    }

    .xs-ps-25px {
        padding-left: 25px !important
    }

    .xs-ps-30px {
        padding-left: 30px !important
    }

    .xs-ps-35px {
        padding-left: 35px !important
    }

    .xs-ps-40px {
        padding-left: 40px !important
    }

    .xs-ps-45px {
        padding-left: 45px !important
    }

    .xs-ps-50px {
        padding-left: 50px !important
    }

    .xs-ps-55px {
        padding-left: 55px !important
    }

    .xs-ps-60px {
        padding-left: 60px !important
    }

    .xs-ps-65px {
        padding-left: 65px !important
    }

    .xs-ps-70px {
        padding-left: 70px !important
    }

    .xs-ps-1 {
        padding-left: 1% !important
    }

    .xs-ps-2 {
        padding-left: 2% !important
    }

    .xs-ps-3 {
        padding-left: 3% !important
    }

    .xs-ps-4 {
        padding-left: 4% !important
    }

    .xs-ps-5 {
        padding-left: 5% !important
    }

    .xs-ps-6 {
        padding-left: 6% !important
    }

    .xs-ps-7 {
        padding-left: 7% !important
    }

    .xs-ps-8 {
        padding-left: 8% !important
    }

    .xs-ps-9 {
        padding-left: 9% !important
    }

    .xs-ps-10 {
        padding-left: 10% !important
    }

    .xs-ps-11 {
        padding-left: 11% !important
    }

    .xs-ps-12 {
        padding-left: 12% !important
    }

    .xs-ps-13 {
        padding-left: 13% !important
    }

    .xs-ps-14 {
        padding-left: 14% !important
    }

    .xs-ps-15 {
        padding-left: 15% !important
    }

    .xs-ps-16 {
        padding-left: 16% !important
    }

    .xs-ps-17 {
        padding-left: 17% !important
    }

    .xs-ps-18 {
        padding-left: 18% !important
    }

    .xs-ps-19 {
        padding-left: 19% !important
    }

    .xs-ps-20 {
        padding-left: 20% !important
    }

    .xs-ps-21 {
        padding-left: 21% !important
    }

    .xs-ps-22 {
        padding-left: 22% !important
    }

    .xs-ps-23 {
        padding-left: 23% !important
    }

    .xs-ps-24 {
        padding-left: 24% !important
    }

    .xs-ps-25 {
        padding-left: 25% !important
    }

    .xs-ps-26 {
        padding-left: 26% !important
    }

    .xs-ps-27 {
        padding-left: 27% !important
    }

    .xs-ps-28 {
        padding-left: 28% !important
    }

    .xs-ps-29 {
        padding-left: 29% !important
    }

    .xs-ps-30 {
        padding-left: 30% !important
    }

    .xs-m-5px {
        margin: 5px !important
    }

    .xs-m-10px {
        margin: 10px !important
    }

    .xs-m-15px {
        margin: 15px !important
    }

    .xs-m-20px {
        margin: 20px !important
    }

    .xs-m-25px {
        margin: 25px !important
    }

    .xs-m-30px {
        margin: 30px !important
    }

    .xs-m-35px {
        margin: 35px !important
    }

    .xs-m-40px {
        margin: 40px !important
    }

    .xs-m-45px {
        margin: 45px !important
    }

    .xs-m-50px {
        margin: 50px !important
    }

    .xs-m-55px {
        margin: 55px !important
    }

    .xs-m-60px {
        margin: 60px !important
    }

    .xs-m-65px {
        margin: 65px !important
    }

    .xs-m-70px {
        margin: 70px !important
    }

    .xs-m-1 {
        margin: 1% !important
    }

    .xs-m-2 {
        margin: 2% !important
    }

    .xs-m-3 {
        margin: 3% !important
    }

    .xs-m-4 {
        margin: 4% !important
    }

    .xs-m-5 {
        margin: 5% !important
    }

    .xs-m-6 {
        margin: 6% !important
    }

    .xs-m-7 {
        margin: 7% !important
    }

    .xs-m-8 {
        margin: 8% !important
    }

    .xs-m-9 {
        margin: 9% !important
    }

    .xs-m-10 {
        margin: 10% !important
    }

    .xs-m-11 {
        margin: 11% !important
    }

    .xs-m-12 {
        margin: 12% !important
    }

    .xs-m-13 {
        margin: 13% !important
    }

    .xs-m-14 {
        margin: 14% !important
    }

    .xs-m-15 {
        margin: 15% !important
    }

    .xs-m-16 {
        margin: 16% !important
    }

    .xs-m-17 {
        margin: 17% !important
    }

    .xs-m-18 {
        margin: 18% !important
    }

    .xs-m-19 {
        margin: 19% !important
    }

    .xs-m-20 {
        margin: 20% !important
    }

    .xs-m-21 {
        margin: 21% !important
    }

    .xs-m-22 {
        margin: 22% !important
    }

    .xs-m-23 {
        margin: 23% !important
    }

    .xs-m-24 {
        margin: 24% !important
    }

    .xs-m-25 {
        margin: 25% !important
    }

    .xs-m-26 {
        margin: 26% !important
    }

    .xs-m-27 {
        margin: 27% !important
    }

    .xs-m-28 {
        margin: 28% !important
    }

    .xs-m-29 {
        margin: 29% !important
    }

    .xs-m-30 {
        margin: 30% !important
    }

    .xs-mt-5px {
        margin-top: 5px !important
    }

    .xs-mt-10px {
        margin-top: 10px !important
    }

    .xs-mt-15px {
        margin-top: 15px !important
    }

    .xs-mt-20px {
        margin-top: 20px !important
    }

    .xs-mt-25px {
        margin-top: 25px !important
    }

    .xs-mt-30px {
        margin-top: 30px !important
    }

    .xs-mt-35px {
        margin-top: 35px !important
    }

    .xs-mt-40px {
        margin-top: 40px !important
    }

    .xs-mt-45px {
        margin-top: 45px !important
    }

    .xs-mt-50px {
        margin-top: 50px !important
    }

    .xs-mt-55px {
        margin-top: 55px !important
    }

    .xs-mt-60px {
        margin-top: 60px !important
    }

    .xs-mt-65px {
        margin-top: 65px !important
    }

    .xs-mt-70px {
        margin-top: 70px !important
    }

    .xs-mt-1 {
        margin-top: 1% !important
    }

    .xs-mt-2 {
        margin-top: 2% !important
    }

    .xs-mt-3 {
        margin-top: 3% !important
    }

    .xs-mt-4 {
        margin-top: 4% !important
    }

    .xs-mt-5 {
        margin-top: 5% !important
    }

    .xs-mt-6 {
        margin-top: 6% !important
    }

    .xs-mt-7 {
        margin-top: 7% !important
    }

    .xs-mt-8 {
        margin-top: 8% !important
    }

    .xs-mt-9 {
        margin-top: 9% !important
    }

    .xs-mt-10 {
        margin-top: 10% !important
    }

    .xs-mt-11 {
        margin-top: 11% !important
    }

    .xs-mt-12 {
        margin-top: 12% !important
    }

    .xs-mt-13 {
        margin-top: 13% !important
    }

    .xs-mt-14 {
        margin-top: 14% !important
    }

    .xs-mt-15 {
        margin-top: 15% !important
    }

    .xs-mt-16 {
        margin-top: 16% !important
    }

    .xs-mt-17 {
        margin-top: 17% !important
    }

    .xs-mt-18 {
        margin-top: 18% !important
    }

    .xs-mt-19 {
        margin-top: 19% !important
    }

    .xs-mt-20 {
        margin-top: 20% !important
    }

    .xs-mt-21 {
        margin-top: 21% !important
    }

    .xs-mt-22 {
        margin-top: 22% !important
    }

    .xs-mt-23 {
        margin-top: 23% !important
    }

    .xs-mt-24 {
        margin-top: 24% !important
    }

    .xs-mt-25 {
        margin-top: 25% !important
    }

    .xs-mt-26 {
        margin-top: 26% !important
    }

    .xs-mt-27 {
        margin-top: 27% !important
    }

    .xs-mt-28 {
        margin-top: 28% !important
    }

    .xs-mt-29 {
        margin-top: 29% !important
    }

    .xs-mt-30 {
        margin-top: 30% !important
    }

    .xs-me-5px {
        margin-right: 5px !important
    }

    .xs-me-10px {
        margin-right: 10px !important
    }

    .xs-me-15px {
        margin-right: 15px !important
    }

    .xs-me-20px {
        margin-right: 20px !important
    }

    .xs-me-25px {
        margin-right: 25px !important
    }

    .xs-me-30px {
        margin-right: 30px !important
    }

    .xs-me-35px {
        margin-right: 35px !important
    }

    .xs-me-40px {
        margin-right: 40px !important
    }

    .xs-me-45px {
        margin-right: 45px !important
    }

    .xs-me-50px {
        margin-right: 50px !important
    }

    .xs-me-55px {
        margin-right: 55px !important
    }

    .xs-me-60px {
        margin-right: 60px !important
    }

    .xs-me-65px {
        margin-right: 65px !important
    }

    .xs-me-70px {
        margin-right: 70px !important
    }

    .xs-me-1 {
        margin-right: 1% !important
    }

    .xs-me-2 {
        margin-right: 2% !important
    }

    .xs-me-3 {
        margin-right: 3% !important
    }

    .xs-me-4 {
        margin-right: 4% !important
    }

    .xs-me-5 {
        margin-right: 5% !important
    }

    .xs-me-6 {
        margin-right: 6% !important
    }

    .xs-me-7 {
        margin-right: 7% !important
    }

    .xs-me-8 {
        margin-right: 8% !important
    }

    .xs-me-9 {
        margin-right: 9% !important
    }

    .xs-me-10 {
        margin-right: 10% !important
    }

    .xs-me-11 {
        margin-right: 11% !important
    }

    .xs-me-12 {
        margin-right: 12% !important
    }

    .xs-me-13 {
        margin-right: 13% !important
    }

    .xs-me-14 {
        margin-right: 14% !important
    }

    .xs-me-15 {
        margin-right: 15% !important
    }

    .xs-me-16 {
        margin-right: 16% !important
    }

    .xs-me-17 {
        margin-right: 17% !important
    }

    .xs-me-18 {
        margin-right: 18% !important
    }

    .xs-me-19 {
        margin-right: 19% !important
    }

    .xs-me-20 {
        margin-right: 20% !important
    }

    .xs-me-21 {
        margin-right: 21% !important
    }

    .xs-me-22 {
        margin-right: 22% !important
    }

    .xs-me-23 {
        margin-right: 23% !important
    }

    .xs-me-24 {
        margin-right: 24% !important
    }

    .xs-me-25 {
        margin-right: 25% !important
    }

    .xs-me-26 {
        margin-right: 26% !important
    }

    .xs-me-27 {
        margin-right: 27% !important
    }

    .xs-me-28 {
        margin-right: 28% !important
    }

    .xs-me-29 {
        margin-right: 29% !important
    }

    .xs-me-30 {
        margin-right: 30% !important
    }

    .xs-mb-5px {
        margin-bottom: 5px !important
    }

    .xs-mb-10px {
        margin-bottom: 10px !important
    }

    .xs-mb-15px {
        margin-bottom: 15px !important
    }

    .xs-mb-20px {
        margin-bottom: 20px !important
    }

    .xs-mb-25px {
        margin-bottom: 25px !important
    }

    .xs-mb-30px {
        margin-bottom: 30px !important
    }

    .xs-mb-35px {
        margin-bottom: 35px !important
    }

    .xs-mb-40px {
        margin-bottom: 40px !important
    }

    .xs-mb-45px {
        margin-bottom: 45px !important
    }

    .xs-mb-50px {
        margin-bottom: 50px !important
    }

    .xs-mb-55px {
        margin-bottom: 55px !important
    }

    .xs-mb-60px {
        margin-bottom: 60px !important
    }

    .xs-mb-65px {
        margin-bottom: 65px !important
    }

    .xs-mb-70px {
        margin-bottom: 70px !important
    }

    .xs-mb-1 {
        margin-bottom: 1% !important
    }

    .xs-mb-2 {
        margin-bottom: 2% !important
    }

    .xs-mb-3 {
        margin-bottom: 3% !important
    }

    .xs-mb-4 {
        margin-bottom: 4% !important
    }

    .xs-mb-5 {
        margin-bottom: 5% !important
    }

    .xs-mb-6 {
        margin-bottom: 6% !important
    }

    .xs-mb-7 {
        margin-bottom: 7% !important
    }

    .xs-mb-8 {
        margin-bottom: 8% !important
    }

    .xs-mb-9 {
        margin-bottom: 9% !important
    }

    .xs-mb-10 {
        margin-bottom: 10% !important
    }

    .xs-mb-11 {
        margin-bottom: 11% !important
    }

    .xs-mb-12 {
        margin-bottom: 12% !important
    }

    .xs-mb-13 {
        margin-bottom: 13% !important
    }

    .xs-mb-14 {
        margin-bottom: 14% !important
    }

    .xs-mb-15 {
        margin-bottom: 15% !important
    }

    .xs-mb-16 {
        margin-bottom: 16% !important
    }

    .xs-mb-17 {
        margin-bottom: 17% !important
    }

    .xs-mb-18 {
        margin-bottom: 18% !important
    }

    .xs-mb-19 {
        margin-bottom: 19% !important
    }

    .xs-mb-20 {
        margin-bottom: 20% !important
    }

    .xs-mb-21 {
        margin-bottom: 21% !important
    }

    .xs-mb-22 {
        margin-bottom: 22% !important
    }

    .xs-mb-23 {
        margin-bottom: 23% !important
    }

    .xs-mb-24 {
        margin-bottom: 24% !important
    }

    .xs-mb-25 {
        margin-bottom: 25% !important
    }

    .xs-mb-26 {
        margin-bottom: 26% !important
    }

    .xs-mb-27 {
        margin-bottom: 27% !important
    }

    .xs-mb-28 {
        margin-bottom: 28% !important
    }

    .xs-mb-29 {
        margin-bottom: 29% !important
    }

    .xs-mb-30 {
        margin-bottom: 30% !important
    }

    .xs-ms-5px {
        margin-left: 5px !important
    }

    .xs-ms-10px {
        margin-left: 10px !important
    }

    .xs-ms-15px {
        margin-left: 15px !important
    }

    .xs-ms-20px {
        margin-left: 20px !important
    }

    .xs-ms-25px {
        margin-left: 25px !important
    }

    .xs-ms-30px {
        margin-left: 30px !important
    }

    .xs-ms-35px {
        margin-left: 35px !important
    }

    .xs-ms-40px {
        margin-left: 40px !important
    }

    .xs-ms-45px {
        margin-left: 45px !important
    }

    .xs-ms-50px {
        margin-left: 50px !important
    }

    .xs-ms-55px {
        margin-left: 55px !important
    }

    .xs-ms-60px {
        margin-left: 60px !important
    }

    .xs-ms-65px {
        margin-left: 65px !important
    }

    .xs-ms-70px {
        margin-left: 70px !important
    }

    .xs-ms-1 {
        margin-left: 1% !important
    }

    .xs-ms-2 {
        margin-left: 2% !important
    }

    .xs-ms-3 {
        margin-left: 3% !important
    }

    .xs-ms-4 {
        margin-left: 4% !important
    }

    .xs-ms-5 {
        margin-left: 5% !important
    }

    .xs-ms-6 {
        margin-left: 6% !important
    }

    .xs-ms-7 {
        margin-left: 7% !important
    }

    .xs-ms-8 {
        margin-left: 8% !important
    }

    .xs-ms-9 {
        margin-left: 9% !important
    }

    .xs-ms-10 {
        margin-left: 10% !important
    }

    .xs-ms-11 {
        margin-left: 11% !important
    }

    .xs-ms-12 {
        margin-left: 12% !important
    }

    .xs-ms-13 {
        margin-left: 13% !important
    }

    .xs-ms-14 {
        margin-left: 14% !important
    }

    .xs-ms-15 {
        margin-left: 15% !important
    }

    .xs-ms-16 {
        margin-left: 16% !important
    }

    .xs-ms-17 {
        margin-left: 17% !important
    }

    .xs-ms-18 {
        margin-left: 18% !important
    }

    .xs-ms-19 {
        margin-left: 19% !important
    }

    .xs-ms-20 {
        margin-left: 20% !important
    }

    .xs-ms-21 {
        margin-left: 21% !important
    }

    .xs-ms-22 {
        margin-left: 22% !important
    }

    .xs-ms-23 {
        margin-left: 23% !important
    }

    .xs-ms-24 {
        margin-left: 24% !important
    }

    .xs-ms-25 {
        margin-left: 25% !important
    }

    .xs-ms-26 {
        margin-left: 26% !important
    }

    .xs-ms-27 {
        margin-left: 27% !important
    }

    .xs-ms-28 {
        margin-left: 28% !important
    }

    .xs-ms-29 {
        margin-left: 29% !important
    }

    .xs-ms-30 {
        margin-left: 30% !important
    }

    .xs-top-0px {
        top: 0 !important
    }

    .xs-top-1px {
        top: 1px !important
    }

    .xs-top-2px {
        top: 2px !important
    }

    .xs-top-3px {
        top: 3px !important
    }

    .xs-top-4px {
        top: 4px !important
    }

    .xs-top-5px {
        top: 5px !important
    }

    .xs-top-6px {
        top: 6px !important
    }

    .xs-top-7px {
        top: 7px !important
    }

    .xs-top-8px {
        top: 8px !important
    }

    .xs-top-9px {
        top: 9px !important
    }

    .xs-top-10px {
        top: 10px !important
    }

    .xs-top-11px {
        top: 11px !important
    }

    .xs-top-12px {
        top: 12px !important
    }

    .xs-top-13px {
        top: 13px !important
    }

    .xs-top-14px {
        top: 14px !important
    }

    .xs-top-15px {
        top: 15px !important
    }

    .xs-top-16px {
        top: 16px !important
    }

    .xs-top-17px {
        top: 17px !important
    }

    .xs-top-18px {
        top: 18px !important
    }

    .xs-top-19px {
        top: 19px !important
    }

    .xs-top-20px {
        top: 20px !important
    }

    .xs-top-21px {
        top: 21px !important
    }

    .xs-top-22px {
        top: 22px !important
    }

    .xs-top-23px {
        top: 23px !important
    }

    .xs-top-24px {
        top: 24px !important
    }

    .xs-top-25px {
        top: 25px !important
    }

    .xs-top-26px {
        top: 26px !important
    }

    .xs-top-27px {
        top: 27px !important
    }

    .xs-top-28px {
        top: 28px !important
    }

    .xs-top-29px {
        top: 29px !important
    }

    .xs-top-30px {
        top: 30px !important
    }

    .xs-top-40px {
        top: 40px !important
    }

    .xs-top-50px {
        top: 50px !important
    }

    .xs-top-60px {
        top: 60px !important
    }

    .xs-top-70px {
        top: 70px !important
    }

    .xs-top-80px {
        top: 80px !important
    }

    .xs-top-90px {
        top: 90px !important
    }

    .xs-top-100px {
        top: 100px !important
    }

    .xs-top-110px {
        top: 110px !important
    }

    .xs-top-120px {
        top: 120px !important
    }

    .xs-top-130px {
        top: 130px !important
    }

    .xs-top-140px {
        top: 140px !important
    }

    .xs-top-150px {
        top: 150px !important
    }

    .xs-right-0px {
        right: 0 !important
    }

    .xs-right-1px {
        right: 1px !important
    }

    .xs-right-2px {
        right: 2px !important
    }

    .xs-right-3px {
        right: 3px !important
    }

    .xs-right-4px {
        right: 4px !important
    }

    .xs-right-5px {
        right: 5px !important
    }

    .xs-right-6px {
        right: 6px !important
    }

    .xs-right-7px {
        right: 7px !important
    }

    .xs-right-8px {
        right: 8px !important
    }

    .xs-right-9px {
        right: 9px !important
    }

    .xs-right-10px {
        right: 10px !important
    }

    .xs-right-11px {
        right: 11px !important
    }

    .xs-right-12px {
        right: 12px !important
    }

    .xs-right-13px {
        right: 13px !important
    }

    .xs-right-14px {
        right: 14px !important
    }

    .xs-right-15px {
        right: 15px !important
    }

    .xs-right-16px {
        right: 16px !important
    }

    .xs-right-17px {
        right: 17px !important
    }

    .xs-right-18px {
        right: 18px !important
    }

    .xs-right-19px {
        right: 19px !important
    }

    .xs-right-20px {
        right: 20px !important
    }

    .xs-right-21px {
        right: 21px !important
    }

    .xs-right-22px {
        right: 22px !important
    }

    .xs-right-23px {
        right: 23px !important
    }

    .xs-right-24px {
        right: 24px !important
    }

    .xs-right-25px {
        right: 25px !important
    }

    .xs-right-26px {
        right: 26px !important
    }

    .xs-right-27px {
        right: 27px !important
    }

    .xs-right-28px {
        right: 28px !important
    }

    .xs-right-29px {
        right: 29px !important
    }

    .xs-right-30px {
        right: 30px !important
    }

    .xs-right-40px {
        right: 40px !important
    }

    .xs-right-50px {
        right: 50px !important
    }

    .xs-right-60px {
        right: 60px !important
    }

    .xs-right-70px {
        right: 70px !important
    }

    .xs-right-80px {
        right: 80px !important
    }

    .xs-right-90px {
        right: 90px !important
    }

    .xs-right-100px {
        right: 100px !important
    }

    .xs-right-110px {
        right: 110px !important
    }

    .xs-right-120px {
        right: 120px !important
    }

    .xs-right-130px {
        right: 130px !important
    }

    .xs-right-140px {
        right: 140px !important
    }

    .xs-right-150px {
        right: 150px !important
    }

    .xs-bottom-0px {
        bottom: 0 !important
    }

    .xs-bottom-1px {
        bottom: 1px !important
    }

    .xs-bottom-2px {
        bottom: 2px !important
    }

    .xs-bottom-3px {
        bottom: 3px !important
    }

    .xs-bottom-4px {
        bottom: 4px !important
    }

    .xs-bottom-5px {
        bottom: 5px !important
    }

    .xs-bottom-6px {
        bottom: 6px !important
    }

    .xs-bottom-7px {
        bottom: 7px !important
    }

    .xs-bottom-8px {
        bottom: 8px !important
    }

    .xs-bottom-9px {
        bottom: 9px !important
    }

    .xs-bottom-10px {
        bottom: 10px !important
    }

    .xs-bottom-11px {
        bottom: 11px !important
    }

    .xs-bottom-12px {
        bottom: 12px !important
    }

    .xs-bottom-13px {
        bottom: 13px !important
    }

    .xs-bottom-14px {
        bottom: 14px !important
    }

    .xs-bottom-15px {
        bottom: 15px !important
    }

    .xs-bottom-16px {
        bottom: 16px !important
    }

    .xs-bottom-17px {
        bottom: 17px !important
    }

    .xs-bottom-18px {
        bottom: 18px !important
    }

    .xs-bottom-19px {
        bottom: 19px !important
    }

    .xs-bottom-20px {
        bottom: 20px !important
    }

    .xs-bottom-21px {
        bottom: 21px !important
    }

    .xs-bottom-22px {
        bottom: 22px !important
    }

    .xs-bottom-23px {
        bottom: 23px !important
    }

    .xs-bottom-24px {
        bottom: 24px !important
    }

    .xs-bottom-25px {
        bottom: 25px !important
    }

    .xs-bottom-26px {
        bottom: 26px !important
    }

    .xs-bottom-27px {
        bottom: 27px !important
    }

    .xs-bottom-28px {
        bottom: 28px !important
    }

    .xs-bottom-29px {
        bottom: 29px !important
    }

    .xs-bottom-30px {
        bottom: 30px !important
    }

    .xs-bottom-40px {
        bottom: 40px !important
    }

    .xs-bottom-50px {
        bottom: 50px !important
    }

    .xs-bottom-60px {
        bottom: 60px !important
    }

    .xs-bottom-70px {
        bottom: 70px !important
    }

    .xs-bottom-80px {
        bottom: 80px !important
    }

    .xs-bottom-90px {
        bottom: 90px !important
    }

    .xs-bottom-100px {
        bottom: 100px !important
    }

    .xs-bottom-110px {
        bottom: 110px !important
    }

    .xs-bottom-120px {
        bottom: 120px !important
    }

    .xs-bottom-130px {
        bottom: 130px !important
    }

    .xs-bottom-140px {
        bottom: 140px !important
    }

    .xs-bottom-150px {
        bottom: 150px !important
    }

    .xs-left-0px {
        left: 0 !important
    }

    .xs-left-1px {
        left: 1px !important
    }

    .xs-left-2px {
        left: 2px !important
    }

    .xs-left-3px {
        left: 3px !important
    }

    .xs-left-4px {
        left: 4px !important
    }

    .xs-left-5px {
        left: 5px !important
    }

    .xs-left-6px {
        left: 6px !important
    }

    .xs-left-7px {
        left: 7px !important
    }

    .xs-left-8px {
        left: 8px !important
    }

    .xs-left-9px {
        left: 9px !important
    }

    .xs-left-10px {
        left: 10px !important
    }

    .xs-left-11px {
        left: 11px !important
    }

    .xs-left-12px {
        left: 12px !important
    }

    .xs-left-13px {
        left: 13px !important
    }

    .xs-left-14px {
        left: 14px !important
    }

    .xs-left-15px {
        left: 15px !important
    }

    .xs-left-16px {
        left: 16px !important
    }

    .xs-left-17px {
        left: 17px !important
    }

    .xs-left-18px {
        left: 18px !important
    }

    .xs-left-19px {
        left: 19px !important
    }

    .xs-left-20px {
        left: 20px !important
    }

    .xs-left-21px {
        left: 21px !important
    }

    .xs-left-22px {
        left: 22px !important
    }

    .xs-left-23px {
        left: 23px !important
    }

    .xs-left-24px {
        left: 24px !important
    }

    .xs-left-25px {
        left: 25px !important
    }

    .xs-left-26px {
        left: 26px !important
    }

    .xs-left-27px {
        left: 27px !important
    }

    .xs-left-28px {
        left: 28px !important
    }

    .xs-left-29px {
        left: 29px !important
    }

    .xs-left-30px {
        left: 30px !important
    }

    .xs-left-40px {
        left: 40px !important
    }

    .xs-left-50px {
        left: 50px !important
    }

    .xs-left-60px {
        left: 60px !important
    }

    .xs-left-70px {
        left: 70px !important
    }

    .xs-left-80px {
        left: 80px !important
    }

    .xs-left-90px {
        left: 90px !important
    }

    .xs-left-100px {
        left: 100px !important
    }

    .xs-left-110px {
        left: 110px !important
    }

    .xs-left-120px {
        left: 120px !important
    }

    .xs-left-130px {
        left: 130px !important
    }

    .xs-left-140px {
        left: 140px !important
    }

    .xs-left-150px {
        left: 150px !important
    }

    .xs-top-minus-0px {
        top: 0 !important
    }

    .xs-top-minus-1px {
        top: -1px !important
    }

    .xs-top-minus-2px {
        top: -2px !important
    }

    .xs-top-minus-3px {
        top: -3px !important
    }

    .xs-top-minus-4px {
        top: -4px !important
    }

    .xs-top-minus-5px {
        top: -5px !important
    }

    .xs-top-minus-6px {
        top: -6px !important
    }

    .xs-top-minus-7px {
        top: -7px !important
    }

    .xs-top-minus-8px {
        top: -8px !important
    }

    .xs-top-minus-9px {
        top: -9px !important
    }

    .xs-top-minus-10px {
        top: -10px !important
    }

    .xs-top-minus-11px {
        top: -11px !important
    }

    .xs-top-minus-12px {
        top: -12px !important
    }

    .xs-top-minus-13px {
        top: -13px !important
    }

    .xs-top-minus-14px {
        top: -14px !important
    }

    .xs-top-minus-15px {
        top: -15px !important
    }

    .xs-top-minus-16px {
        top: -16px !important
    }

    .xs-top-minus-17px {
        top: -17px !important
    }

    .xs-top-minus-18px {
        top: -18px !important
    }

    .xs-top-minus-19px {
        top: -19px !important
    }

    .xs-top-minus-20px {
        top: -20px !important
    }

    .xs-top-minus-21px {
        top: -21px !important
    }

    .xs-top-minus-22px {
        top: -22px !important
    }

    .xs-top-minus-23px {
        top: -23px !important
    }

    .xs-top-minus-24px {
        top: -24px !important
    }

    .xs-top-minus-25px {
        top: -25px !important
    }

    .xs-top-minus-26px {
        top: -26px !important
    }

    .xs-top-minus-27px {
        top: -27px !important
    }

    .xs-top-minus-28px {
        top: -28px !important
    }

    .xs-top-minus-29px {
        top: -29px !important
    }

    .xs-top-minus-30px {
        top: -30px !important
    }

    .xs-top-minus-40px {
        top: -40px !important
    }

    .xs-top-minus-50px {
        top: -50px !important
    }

    .xs-top-minus-60px {
        top: -60px !important
    }

    .xs-top-minus-70px {
        top: -70px !important
    }

    .xs-top-minus-80px {
        top: -80px !important
    }

    .xs-top-minus-90px {
        top: -90px !important
    }

    .xs-top-minus-100px {
        top: -100px !important
    }

    .xs-top-minus-110px {
        top: -110px !important
    }

    .xs-top-minus-120px {
        top: -120px !important
    }

    .xs-top-minus-130px {
        top: -130px !important
    }

    .xs-top-minus-140px {
        top: -140px !important
    }

    .xs-top-minus-150px {
        top: -150px !important
    }

    .xs-right-minus-0px {
        right: 0 !important
    }

    .xs-right-minus-1px {
        right: -1px !important
    }

    .xs-right-minus-2px {
        right: -2px !important
    }

    .xs-right-minus-3px {
        right: -3px !important
    }

    .xs-right-minus-4px {
        right: -4px !important
    }

    .xs-right-minus-5px {
        right: -5px !important
    }

    .xs-right-minus-6px {
        right: -6px !important
    }

    .xs-right-minus-7px {
        right: -7px !important
    }

    .xs-right-minus-8px {
        right: -8px !important
    }

    .xs-right-minus-9px {
        right: -9px !important
    }

    .xs-right-minus-10px {
        right: -10px !important
    }

    .xs-right-minus-11px {
        right: -11px !important
    }

    .xs-right-minus-12px {
        right: -12px !important
    }

    .xs-right-minus-13px {
        right: -13px !important
    }

    .xs-right-minus-14px {
        right: -14px !important
    }

    .xs-right-minus-15px {
        right: -15px !important
    }

    .xs-right-minus-16px {
        right: -16px !important
    }

    .xs-right-minus-17px {
        right: -17px !important
    }

    .xs-right-minus-18px {
        right: -18px !important
    }

    .xs-right-minus-19px {
        right: -19px !important
    }

    .xs-right-minus-20px {
        right: -20px !important
    }

    .xs-right-minus-21px {
        right: -21px !important
    }

    .xs-right-minus-22px {
        right: -22px !important
    }

    .xs-right-minus-23px {
        right: -23px !important
    }

    .xs-right-minus-24px {
        right: -24px !important
    }

    .xs-right-minus-25px {
        right: -25px !important
    }

    .xs-right-minus-26px {
        right: -26px !important
    }

    .xs-right-minus-27px {
        right: -27px !important
    }

    .xs-right-minus-28px {
        right: -28px !important
    }

    .xs-right-minus-29px {
        right: -29px !important
    }

    .xs-right-minus-30px {
        right: -30px !important
    }

    .xs-right-minus-40px {
        right: -40px !important
    }

    .xs-right-minus-50px {
        right: -50px !important
    }

    .xs-right-minus-60px {
        right: -60px !important
    }

    .xs-right-minus-70px {
        right: -70px !important
    }

    .xs-right-minus-80px {
        right: -80px !important
    }

    .xs-right-minus-90px {
        right: -90px !important
    }

    .xs-right-minus-100px {
        right: -100px !important
    }

    .xs-right-minus-110px {
        right: -110px !important
    }

    .xs-right-minus-120px {
        right: -120px !important
    }

    .xs-right-minus-130px {
        right: -130px !important
    }

    .xs-right-minus-140px {
        right: -140px !important
    }

    .xs-right-minus-150px {
        right: -150px !important
    }

    .xs-bottom-minus-0px {
        bottom: 0 !important
    }

    .xs-bottom-minus-1px {
        bottom: -1px !important
    }

    .xs-bottom-minus-2px {
        bottom: -2px !important
    }

    .xs-bottom-minus-3px {
        bottom: -3px !important
    }

    .xs-bottom-minus-4px {
        bottom: -4px !important
    }

    .xs-bottom-minus-5px {
        bottom: -5px !important
    }

    .xs-bottom-minus-6px {
        bottom: -6px !important
    }

    .xs-bottom-minus-7px {
        bottom: -7px !important
    }

    .xs-bottom-minus-8px {
        bottom: -8px !important
    }

    .xs-bottom-minus-9px {
        bottom: -9px !important
    }

    .xs-bottom-minus-10px {
        bottom: -10px !important
    }

    .xs-bottom-minus-11px {
        bottom: -11px !important
    }

    .xs-bottom-minus-12px {
        bottom: -12px !important
    }

    .xs-bottom-minus-13px {
        bottom: -13px !important
    }

    .xs-bottom-minus-14px {
        bottom: -14px !important
    }

    .xs-bottom-minus-15px {
        bottom: -15px !important
    }

    .xs-bottom-minus-16px {
        bottom: -16px !important
    }

    .xs-bottom-minus-17px {
        bottom: -17px !important
    }

    .xs-bottom-minus-18px {
        bottom: -18px !important
    }

    .xs-bottom-minus-19px {
        bottom: -19px !important
    }

    .xs-bottom-minus-20px {
        bottom: -20px !important
    }

    .xs-bottom-minus-21px {
        bottom: -21px !important
    }

    .xs-bottom-minus-22px {
        bottom: -22px !important
    }

    .xs-bottom-minus-23px {
        bottom: -23px !important
    }

    .xs-bottom-minus-24px {
        bottom: -24px !important
    }

    .xs-bottom-minus-25px {
        bottom: -25px !important
    }

    .xs-bottom-minus-26px {
        bottom: -26px !important
    }

    .xs-bottom-minus-27px {
        bottom: -27px !important
    }

    .xs-bottom-minus-28px {
        bottom: -28px !important
    }

    .xs-bottom-minus-29px {
        bottom: -29px !important
    }

    .xs-bottom-minus-30px {
        bottom: -30px !important
    }

    .xs-bottom-minus-40px {
        bottom: -40px !important
    }

    .xs-bottom-minus-50px {
        bottom: -50px !important
    }

    .xs-bottom-minus-60px {
        bottom: -60px !important
    }

    .xs-bottom-minus-70px {
        bottom: -70px !important
    }

    .xs-bottom-minus-80px {
        bottom: -80px !important
    }

    .xs-bottom-minus-90px {
        bottom: -90px !important
    }

    .xs-bottom-minus-100px {
        bottom: -100px !important
    }

    .xs-bottom-minus-110px {
        bottom: -110px !important
    }

    .xs-bottom-minus-120px {
        bottom: -120px !important
    }

    .xs-bottom-minus-130px {
        bottom: -130px !important
    }

    .xs-bottom-minus-140px {
        bottom: -140px !important
    }

    .xs-bottom-minus-150px {
        bottom: -150px !important
    }

    .xs-left-minus-0px {
        left: 0 !important
    }

    .xs-left-minus-1px {
        left: -1px !important
    }

    .xs-left-minus-2px {
        left: -2px !important
    }

    .xs-left-minus-3px {
        left: -3px !important
    }

    .xs-left-minus-4px {
        left: -4px !important
    }

    .xs-left-minus-5px {
        left: -5px !important
    }

    .xs-left-minus-6px {
        left: -6px !important
    }

    .xs-left-minus-7px {
        left: -7px !important
    }

    .xs-left-minus-8px {
        left: -8px !important
    }

    .xs-left-minus-9px {
        left: -9px !important
    }

    .xs-left-minus-10px {
        left: -10px !important
    }

    .xs-left-minus-11px {
        left: -11px !important
    }

    .xs-left-minus-12px {
        left: -12px !important
    }

    .xs-left-minus-13px {
        left: -13px !important
    }

    .xs-left-minus-14px {
        left: -14px !important
    }

    .xs-left-minus-15px {
        left: -15px !important
    }

    .xs-left-minus-16px {
        left: -16px !important
    }

    .xs-left-minus-17px {
        left: -17px !important
    }

    .xs-left-minus-18px {
        left: -18px !important
    }

    .xs-left-minus-19px {
        left: -19px !important
    }

    .xs-left-minus-20px {
        left: -20px !important
    }

    .xs-left-minus-21px {
        left: -21px !important
    }

    .xs-left-minus-22px {
        left: -22px !important
    }

    .xs-left-minus-23px {
        left: -23px !important
    }

    .xs-left-minus-24px {
        left: -24px !important
    }

    .xs-left-minus-25px {
        left: -25px !important
    }

    .xs-left-minus-26px {
        left: -26px !important
    }

    .xs-left-minus-27px {
        left: -27px !important
    }

    .xs-left-minus-28px {
        left: -28px !important
    }

    .xs-left-minus-29px {
        left: -29px !important
    }

    .xs-left-minus-30px {
        left: -30px !important
    }

    .xs-left-minus-40px {
        left: -40px !important
    }

    .xs-left-minus-50px {
        left: -50px !important
    }

    .xs-left-minus-60px {
        left: -60px !important
    }

    .xs-left-minus-70px {
        left: -70px !important
    }

    .xs-left-minus-80px {
        left: -80px !important
    }

    .xs-left-minus-90px {
        left: -90px !important
    }

    .xs-left-minus-100px {
        left: -100px !important
    }

    .xs-left-minus-110px {
        left: -110px !important
    }

    .xs-left-minus-120px {
        left: -120px !important
    }

    .xs-left-minus-130px {
        left: -130px !important
    }

    .xs-left-minus-140px {
        left: -140px !important
    }

    .xs-left-minus-150px {
        left: -150px !important
    }

    .xs-border-width-0px {
        border-width: 0 !important
    }

    .xs-border-width-1px {
        border-width: 1px !important
    }

    .xs-border-width-2px {
        border-width: 2px !important
    }

    .xs-border-width-3px {
        border-width: 3px !important
    }

    .xs-border-width-4px {
        border-width: 4px !important
    }

    .xs-border-width-5px {
        border-width: 5px !important
    }

    .xs-border-width-6px {
        border-width: 6px !important
    }

    .xs-border-width-7px {
        border-width: 7px !important
    }

    .xs-border-width-8px {
        border-width: 8px !important
    }

    .xs-border-width-9px {
        border-width: 9px !important
    }

    .xs-border-width-10px {
        border-width: 10px !important
    }
}

@media (max-width:1600px) {

    .xxl-me-0,
    .xxl-mx-0 {
        margin-right: 0 !important
    }

    .xxl-mt-0,
    .xxl-my-0 {
        margin-top: 0 !important
    }

    .xxl-mb-0,
    .xxl-my-0 {
        margin-bottom: 0 !important
    }

    .xxl-ps-0,
    .xxl-px-0 {
        padding-left: 0 !important
    }

    .xxl-pe-0,
    .xxl-px-0 {
        padding-right: 0 !important
    }

    .xxl-pt-0,
    .xxl-py-0 {
        padding-top: 0 !important
    }

    .xxl-pb-0,
    .xxl-py-0 {
        padding-bottom: 0 !important
    }

    .xxl-ms-0 {
        margin-left: 0 !important
    }

    .xxl-mx-0 {
        margin-left: 0 !important
    }

    .xxl-mx-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .xxl-m-0 {
        margin: 0 !important
    }

    .xxl-p-0 {
        padding: 0 !important
    }

    .xxl-border-top-0 {
        border-top: 0 !important
    }

    .xxl-border-bottom-0 {
        border-bottom: 0 !important
    }

    .xxl-border-end-0 {
        border-right: 0 !important
    }

    .xxl-border-start-0 {
        border-left: 0 !important
    }
}

@media (max-width:1399px) {

    .xl-me-0,
    .xl-mx-0 {
        margin-right: 0 !important
    }

    .xl-mt-0,
    .xl-my-0 {
        margin-top: 0 !important
    }

    .xl-mb-0,
    .xl-my-0 {
        margin-bottom: 0 !important
    }

    .xl-ps-0,
    .xl-px-0 {
        padding-left: 0 !important
    }

    .xl-pe-0,
    .xl-px-0 {
        padding-right: 0 !important
    }

    .xl-pt-0,
    .xl-py-0 {
        padding-top: 0 !important
    }

    .xl-pb-0,
    .xl-py-0 {
        padding-bottom: 0 !important
    }

    .xl-ms-0 {
        margin-left: 0 !important
    }

    .xl-mx-0 {
        margin-left: 0 !important
    }

    .xl-mx-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .xl-m-0 {
        margin: 0 !important
    }

    .xl-p-0 {
        padding: 0 !important
    }
}

@media (max-width:1199px) {

    .lg-me-0,
    .lg-mx-0 {
        margin-right: 0 !important
    }

    .lg-mt-0,
    .lg-my-0 {
        margin-top: 0 !important
    }

    .lg-mb-0,
    .lg-my-0 {
        margin-bottom: 0 !important
    }

    .lg-ps-0,
    .lg-px-0 {
        padding-left: 0 !important
    }

    .lg-pe-0,
    .lg-px-0 {
        padding-right: 0 !important
    }

    .lg-pt-0,
    .lg-py-0 {
        padding-top: 0 !important
    }

    .lg-pb-0,
    .lg-py-0 {
        padding-bottom: 0 !important
    }

    .lg-ms-0 {
        margin-left: 0 !important
    }

    .lg-mx-0 {
        margin-left: 0 !important
    }

    .lg-mx-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .lg-m-0 {
        margin: 0 !important
    }

    .lg-p-0 {
        padding: 0 !important
    }

    .lg-right-auto {
        right: auto !important
    }

    .lg-left-auto {
        left: auto !important
    }
}

@media (max-width:991px) {

    .md-me-0,
    .md-mx-0 {
        margin-right: 0 !important
    }

    .md-ps-0,
    .md-px-0 {
        padding-left: 0 !important
    }

    .md-pe-0,
    .md-px-0 {
        padding-right: 0 !important
    }

    .md-pt-0,
    .md-py-0 {
        padding-top: 0 !important
    }

    .md-pb-0,
    .md-py-0 {
        padding-bottom: 0 !important
    }

    .md-ms-0 {
        margin-left: 0 !important
    }

    .md-mt-0 {
        margin-top: 0 !important
    }

    .md-mb-0 {
        margin-bottom: 0 !important
    }

    .md-mt-auto {
        margin-top: auto !important
    }

    .md-mb-auto {
        margin-bottom: auto !important
    }

    .md-mx-0 {
        margin-left: 0 !important
    }

    .md-mx-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .md-my-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .md-m-0 {
        margin: 0 !important
    }

    .md-p-0 {
        padding: 0 !important
    }

    .md-right-auto {
        right: auto !important
    }

    .md-left-auto {
        left: auto !important
    }
}

@media (max-width:767px) {

    .sm-me-0,
    .sm-mx-0 {
        margin-right: 0 !important
    }

    .sm-mt-0,
    .sm-my-0 {
        margin-top: 0 !important
    }

    .sm-mb-0,
    .sm-my-0 {
        margin-bottom: 0 !important
    }

    .sm-ps-0,
    .sm-px-0 {
        padding-left: 0 !important
    }

    .sm-pe-0,
    .sm-px-0 {
        padding-right: 0 !important
    }

    .sm-pt-0,
    .sm-py-0 {
        padding-top: 0 !important
    }

    .sm-pb-0,
    .sm-py-0 {
        padding-bottom: 0 !important
    }

    .sm-ms-0 {
        margin-left: 0 !important
    }

    .sm-mx-0 {
        margin-left: 0 !important
    }

    .sm-mx-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .sm-m-0 {
        margin: 0 !important
    }

    .sm-mt-auto {
        margin-top: auto !important
    }

    .sm-mb-auto {
        margin-bottom: auto !important
    }

    .sm-p-0 {
        padding: 0 !important
    }

    .sm-right-auto {
        right: auto !important
    }

    .sm-left-auto {
        left: auto !important
    }

    .sm-min-h-100px {
        min-height: 100px !important
    }
}

@media (max-width:575px) {

    .xs-me-0,
    .xs-mx-0 {
        margin-right: 0 !important
    }

    .xs-mt-0,
    .xs-my-0 {
        margin-top: 0 !important
    }

    .xs-mb-0,
    .xs-my-0 {
        margin-bottom: 0 !important
    }

    .xs-ps-0,
    .xs-px-0 {
        padding-left: 0 !important
    }

    .xs-pe-0,
    .xs-px-0 {
        padding-right: 0 !important
    }

    .xs-pt-0,
    .xs-py-0 {
        padding-top: 0 !important
    }

    .xs-pb-0,
    .xs-py-0 {
        padding-bottom: 0 !important
    }

    .xs-ms-0 {
        margin-left: 0 !important
    }

    .xs-mx-0 {
        margin-left: 0 !important
    }

    .xs-mx-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .xs-m-0 {
        margin: 0 !important
    }

    .xs-ms-auto {
        margin-left: auto !important
    }

    .xs-me-auto {
        margin-right: auto !important
    }

    .xs-p-0 {
        padding: 0 !important
    }

    .xs-right-auto {
        right: auto !important
    }

    .xs-left-auto {
        left: auto !important
    }
}

@media (max-width:1399px) {

    .center-logo .navbar-nav .nav-link,
    header.sticky .center-logo .navbar-nav .nav-link {
        padding-left: 12px;
        padding-right: 12px
    }
}

@media (max-width:1199px) {
    header .container-fluid {
        padding-left: 35px;
        padding-right: 35px
    }

    header.sticky .center-logo .navbar-nav .nav-link {
        padding: 26px 15px
    }

    .navbar>.container-fluid {
        padding-left: 35px;
        padding-right: 35px
    }

    .navbar .navbar-nav .nav-link {
        padding: 10px 15px
    }

    .navbar .navbar-nav .submenu-content .mega-menu {
        width: 100%;
        padding: 0 60px
    }

    .navbar .navbar-nav .submenu-content ul {
        padding: 0 30px 0 0
    }

    .navbar.center-logo .navbar-nav .nav-link {
        padding: 38px 15px
    }

    .navbar.center-logo .navbar-left,
    .navbar.center-logo .navbar-right {
        width: 40%
    }

    .navbar.top-logo .navbar-nav .nav-link {
        padding: 25px 15px !important
    }

    .navbar.mini-header .navbar-nav .nav-link {
        padding: 20px 15px !important
    }

    .navbar .navbar-nav .dropdown .dropdown-menu {
        padding: 35px 0
    }

    .navbar .navbar-nav .dropdown .dropdown-menu a {
        padding-bottom: 8px
    }

    .navbar .navbar-nav .submenu-content ul .sub-title {
        margin-bottom: 8px
    }

    .navbar .navbar-nav .simple-dropdown .dropdown-menu li a {
        padding-bottom: 10px
    }

    .header-demo .container-fluid {
        padding-left: 15px;
        padding-right: 15px
    }

    .header-demo.navbar .navbar-nav .nav-link {
        padding-left: 8px;
        padding-right: 8px
    }

    .header-demo.navbar .navbar-nav .submenu-content .mega-menu {
        padding: 0 30px
    }

    .header-demo.navbar .header-button .purchase-envato>span {
        padding-left: 10px;
        padding-right: 10px
    }

    .header-demo.navbar.center-logo .navbar-nav .nav-link {
        padding-left: 15px;
        padding-right: 15px
    }

    .push-menu .left-circle {
        width: 460px !important;
        height: 430px
    }

    .header-search-popup .search-form {
        width: 60%
    }
}

@media (max-width:991px) {

    .header-demo.navbar .navbar-nav .premium-element,
    .navbar.header-demo .navbar-nav .nav-item:first-child .dropdown-menu,
    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav>.nav-item:first-child .dropdown-menu,
    [data-mobile-nav-style=full-screen-menu] .navbar-nav .premium-element,
    [data-mobile-nav-style=modern] .navbar-nav .premium-element {
        background-image: none !important
    }

    .header-demo.navbar .navbar-nav .premium-element .sub-title {
        font-size: 16px
    }

    .header-demo.navbar .navbar-nav .dropdown .dropdown-menu a .label {
        display: none
    }

    [data-mobile-nav-style=classic] .navbar-nav,
    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .dropdown-menu ul li {
        display: block
    }

    .navbar.header-demo .header-button .purchase-envato>span {
        padding-left: 20px;
        padding-right: 20px
    }

    [data-mobile-nav-style=full-screen-menu] header .navbar-collapse {
        display: none !important
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner {
        background-image: linear-gradient(to right top, #0039e3, #4132e0, #5e28dd, #741bd9, #8600d4);
        visibility: hidden;
        overflow: hidden !important;
        width: 100vw;
        height: 100vh !important;
        position: fixed;
        top: -100vh;
        left: 0;
        z-index: 9999;
        display: flex !important;
        transition: all .4s ease-out;
        transition-delay: .6s
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-collapse {
        position: inherit;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 100px 0;
        max-height: 100%;
        box-shadow: none;
        background: 0 0;
        display: flex !important;
        justify-content: center !important
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .mCustomScrollBox {
        height: auto;
        width: 85%;
        margin: 0 auto
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .simple-dropdown .dropdown-menu .dropdown:last-child,
    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .simple-dropdown .dropdown-menu .dropdown:last-child {
        margin-bottom: 20px
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .mCustomScrollBox>.mCSB_container {
        margin-right: 0;
        padding: 0 20px
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .nav-item .nav-link {
        display: inline-block;
        line-height: 25px;
        font-size: 17px;
        font-weight: 500;
        color: var(--white);
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .nav-item.active .nav-link {
        opacity: .6;
        color: var(--white)
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .nav-item.active .dropdown-toggle,
    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .nav-item.active:hover .nav-link {
        color: var(--white)
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .nav-item>.dropdown-menu {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .nav-item .dropdown-toggle {
        color: var(--white);
        top: 10px;
        right: 0
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .nav-item .dropdown-menu .mega-menu ul:last-child {
        padding-bottom: 20px
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .nav-item .dropdown-menu .sub-title {
        color: var(--white) !important
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .nav-item .dropdown-menu a {
        color: var(--white);
        opacity: .6
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .nav-item .dropdown-menu a .label {
        display: none
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .nav-item .dropdown-menu .dropdown>a,
    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .nav-item .dropdown-menu li.active>a {
        color: var(--white);
        opacity: 1
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .nav-item:hover .nav-link {
        color: var(--white)
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav>.nav-item {
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        padding: 5px 0;
        transform: scale(1.15) translateY(-30px);
        opacity: 0;
        transition: transform .5s cubic-bezier(.4, .01, .165, .99), opacity .6s cubic-bezier(.4, .01, .165, .99)
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav>.nav-item:nth-child(1) {
        transition-delay: .49s
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav>.nav-item:nth-child(2) {
        transition-delay: .42s
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav>.nav-item:nth-child(3) {
        transition-delay: .35s
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav>.nav-item:nth-child(4) {
        transition-delay: .28s
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav>.nav-item:nth-child(5) {
        transition-delay: .21s
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav>.nav-item:nth-child(6) {
        transition-delay: .14s
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav>.nav-item:nth-child(7) {
        transition-delay: 70ms
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav>.nav-item:nth-child(8) {
        transition-delay: 0s
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav>.nav-item:nth-child(9) {
        transition-delay: -70ms
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav>.nav-item:nth-child(10) {
        transition-delay: -.14s
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav>.nav-item:nth-child(11) {
        transition-delay: -.21s
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav>.nav-item:nth-child(12) {
        transition-delay: -.28s
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav>.nav-item:nth-child(13) {
        transition-delay: -.35s
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav>.nav-item:nth-child(14) {
        transition-delay: -.42s
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav>.nav-item:nth-child(15) {
        transition-delay: -.49s
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav>.nav-item:last-child {
        border-bottom: 0
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .dropdown-menu {
        position: static !important;
        transform: translate3d(0, 0, 0) !important;
        -webkit-transform: translate3d(0, 0, 0) !important;
        padding: 0 !important;
        border-radius: 0 !important;
        border: 0 !important
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .dropdown-menu ul li a i {
        width: 25px;
        display: inline-block;
        vertical-align: middle
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .simple-dropdown .dropdown-menu .dropdown>a {
        display: block
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-toggler {
        position: absolute;
        right: 30px;
        top: 35px;
        margin: 0
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-toggler .navbar-toggler-line {
        background-color: var(--white)
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu {
        padding-top: 5px !important;
        padding-bottom: 20px !important
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu li a {
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        padding: 15px 0;
        font-size: 15px;
        line-height: normal;
        display: block
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu li i {
        margin-right: 10px
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu li:last-child a {
        border-bottom: 0
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu {
        padding-top: 10px !important;
        padding-bottom: 25px !important
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li {
        justify-content: center;
        display: flex;
        border-bottom: 0;
        padding: 0 15px
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li:last-child a {
        border-bottom: 0
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a {
        margin: 0;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        padding: 15px 0;
        font-size: 16px;
        line-height: 28px;
        font-weight: 500;
        width: 100%;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-item-align: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        opacity: 1
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a i {
        width: 35px;
        font-size: 32px;
        margin-right: 15px;
        color: var(--white);
        flex-shrink: 0;
        text-align: left;
        opacity: .6
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a span {
        color: var(--white);
        font-weight: 600
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a p {
        margin: 0;
        font-size: 13px;
        color: var(--white);
        opacity: .6
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a img {
        border-radius: 100%;
        width: 55px;
        margin-right: 15px
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a.btn {
        display: inline-block;
        border: 0;
        margin-top: 15px
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a:before {
        content: "\f135";
        color: var(--white);
        font-family: bootstrap-icons;
        font-weight: 400;
        font-size: 23px;
        position: absolute;
        right: 6px;
        opacity: 0;
        -webkit-transition: all .3s cubic-bezier(.61, 1, .88, 1);
        transition: all .3s cubic-bezier(.61, 1, .88, 1)
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a:hover:before {
        right: 0;
        opacity: 1
    }

    .navbar-collapse-show[data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner {
        top: 0;
        visibility: visible !important;
        transition: all .2s ease-in;
        transition-delay: .2s
    }

    .navbar-collapse-show[data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-toggler {
        transition: all .5s ease-in;
        transition-delay: .8s
    }

    .navbar-collapse-show[data-mobile-nav-style=full-screen-menu] .navbar-nav>.nav-item {
        transform: scale(1) translateY(0);
        -webkit-transform: scale(1) translateY(0);
        opacity: 1
    }

    .navbar-collapse-show[data-mobile-nav-style=full-screen-menu] .navbar-nav>.nav-item:nth-child(1) {
        transition-delay: .27s
    }

    .navbar-collapse-show[data-mobile-nav-style=full-screen-menu] .navbar-nav>.nav-item:nth-child(2) {
        transition-delay: .34s
    }

    .navbar-collapse-show[data-mobile-nav-style=full-screen-menu] .navbar-nav>.nav-item:nth-child(3) {
        transition-delay: .41s
    }

    .navbar-collapse-show[data-mobile-nav-style=full-screen-menu] .navbar-nav>.nav-item:nth-child(4) {
        transition-delay: .48s
    }

    .navbar-collapse-show[data-mobile-nav-style=full-screen-menu] .navbar-nav>.nav-item:nth-child(5) {
        transition-delay: .55s
    }

    .navbar-collapse-show[data-mobile-nav-style=full-screen-menu] .navbar-nav>.nav-item:nth-child(6) {
        transition-delay: .62s
    }

    .navbar-collapse-show[data-mobile-nav-style=full-screen-menu] .navbar-nav>.nav-item:nth-child(7) {
        transition-delay: .69s
    }

    .navbar-collapse-show[data-mobile-nav-style=full-screen-menu] .navbar-nav>.nav-item:nth-child(8) {
        transition-delay: .76s
    }

    .navbar-collapse-show[data-mobile-nav-style=full-screen-menu] .navbar-nav>.nav-item:nth-child(9) {
        transition-delay: .83s
    }

    .navbar-collapse-show[data-mobile-nav-style=full-screen-menu] .navbar-nav>.nav-item:nth-child(10) {
        transition-delay: .9s
    }

    .navbar-collapse-show[data-mobile-nav-style=full-screen-menu] .navbar-nav>.nav-item:nth-child(11) {
        transition-delay: .97s
    }

    .navbar-collapse-show[data-mobile-nav-style=full-screen-menu] .navbar-nav>.nav-item:nth-child(12) {
        transition-delay: 1.04s
    }

    .navbar-collapse-show[data-mobile-nav-style=full-screen-menu] .navbar-nav>.nav-item:nth-child(13) {
        transition-delay: 1.11s
    }

    .navbar-collapse-show[data-mobile-nav-style=full-screen-menu] .navbar-nav>.nav-item:nth-child(14) {
        transition-delay: 1.18s
    }

    .navbar-collapse-show[data-mobile-nav-style=full-screen-menu] .navbar-nav>.nav-item:nth-child(15) {
        transition-delay: 1.25s
    }

    [data-mobile-nav-style=modern] header .navbar-collapse {
        display: none !important
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner {
        opacity: 0;
        visibility: hidden;
        overflow: visible !important;
        width: 70vw;
        height: 100vh !important;
        position: fixed;
        top: 0;
        right: -70vw;
        z-index: 90;
        display: flex !important;
        justify-content: center;
        transition-duration: .75s;
        transform: translate3d(30vw, 0, 0);
        -webkit-transform: translate3d(30vw, 0, 0)
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-collapse {
        position: static;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: 0 0;
        padding: 100px 50px;
        box-shadow: none;
        max-height: 100%;
        display: flex !important;
        justify-content: center !important
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .mCustomScrollBox {
        height: auto;
        width: 100%
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .nav-item .nav-link {
        display: inline-block;
        font-size: 17px;
        line-height: 24px;
        font-weight: 500;
        color: var(--white);
        padding: 10px 0 !important
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .nav-item.active .nav-link {
        opacity: .6;
        color: var(--white)
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .nav-item.active .dropdown-toggle,
    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .nav-item.active:hover .nav-link {
        color: var(--white)
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .nav-item .dropdown-toggle {
        color: var(--white);
        top: 10px;
        right: 0
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .nav-item .dropdown-menu .sub-title {
        color: var(--white) !important;
        font-size: 14px
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .nav-item .dropdown-menu a {
        color: var(--white);
        opacity: .6;
        font-size: 13.5px;
        display: block
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .nav-item .dropdown-menu .dropdown>a,
    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .nav-item .dropdown-menu li.active>a {
        color: var(--white);
        opacity: 1
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .nav-item .dropdown-menu .mega-menu ul:last-child {
        padding-bottom: 20px
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .nav-item:hover .nav-link {
        color: var(--white)
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav>.nav-item {
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        padding: 10px 0
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav>.nav-item:last-child {
        border-bottom: 0
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav>.nav-item:first-child .dropdown-menu {
        background-image: none !important
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown-menu {
        position: static !important;
        transform: translate3d(0, 0, 0) !important;
        -webkit-transform: translate3d(0, 0, 0) !important;
        padding: 0 !important;
        border-radius: 0 !important;
        border: 0 !important
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown-menu ul li {
        display: block
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown-menu ul li a i {
        width: 25px;
        display: inline-block;
        vertical-align: middle
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown-menu ul li a .label {
        display: none
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .simple-dropdown .dropdown-menu .dropdown>a {
        display: block
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .mCSB_inside>.mCSB_container {
        margin-right: 0;
        padding: 0 20px
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu {
        padding-top: 10px !important;
        padding-bottom: 15px !important
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu li a {
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        padding: 15px 0;
        font-size: 15px;
        line-height: normal
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu li i {
        margin-right: 10px
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu li:last-child a {
        border-bottom: 0
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu {
        padding-top: 10px !important;
        padding-bottom: 20px !important
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li {
        justify-content: center;
        display: flex;
        border-bottom: 0;
        padding: 0 15px
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li:last-child a {
        border-bottom: 0
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a {
        margin: 0;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        padding: 15px 0;
        font-size: 16px;
        line-height: 28px;
        font-weight: 500;
        width: 100%;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-item-align: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        opacity: 1
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a i {
        width: 35px;
        font-size: 32px;
        margin-right: 15px;
        color: var(--white);
        flex-shrink: 0;
        text-align: left;
        opacity: .6
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a span {
        color: var(--white);
        font-weight: 600
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a p {
        margin: 0;
        font-size: 13px;
        color: var(--white);
        opacity: .6
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a img {
        border-radius: 100%;
        width: 55px;
        margin-right: 15px
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a.btn {
        display: inline-block;
        border: 0;
        margin-top: 15px
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a:before {
        content: "\f135";
        color: var(--white);
        font-family: bootstrap-icons;
        font-weight: 400;
        font-size: 23px;
        position: absolute;
        right: 6px;
        opacity: 0;
        -webkit-transition: all .3s cubic-bezier(.61, 1, .88, 1);
        transition: all .3s cubic-bezier(.61, 1, .88, 1)
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a:hover:before {
        right: 0;
        opacity: 1
    }

    [data-mobile-nav-style=modern] .navbar {
        transition: transform .75s
    }

    [data-mobile-nav-style=modern] .page-layout {
        transition: transform .75s;
        background-color: var(--white)
    }

    [data-mobile-nav-style=modern] .navbar-show-modern-bg {
        display: inline-block;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        background-image: linear-gradient(to right top, #0039e3, #4132e0, #5e28dd, #741bd9, #8600d4);
        transform: scale(1.75);
        -webkit-transform: scale(1.75);
        transition: opacity .3s, transform .3s;
        -webkit-transition: opacity .3s, transform .3s;
        transition-delay: .75s;
        -webkit-transition-delay: .75s
    }

    [data-mobile-nav-trigger-alignment=left][data-mobile-nav-style=modern] .navbar-modern-inner {
        transform: translate3d(-30vw, 0, 0);
        -webkit-transform: translate3d(-30vw, 0, 0);
        left: -70vw;
        right: auto
    }

    [data-mobile-nav-trigger-alignment=left][data-mobile-nav-style=modern] .navbar-modern-inner .navbar-toggler {
        display: block;
        position: absolute;
        right: 30px;
        top: 35px;
        margin: 0
    }

    [data-mobile-nav-trigger-alignment=left][data-mobile-nav-style=modern] .navbar-modern-inner .navbar-toggler .navbar-toggler-line {
        background-color: var(--white)
    }

    .navbar-collapse-show[data-mobile-nav-style=modern] {
        overflow: hidden
    }

    .navbar-collapse-show[data-mobile-nav-style=modern] .navbar,
    .navbar-collapse-show[data-mobile-nav-style=modern] .page-layout {
        transform: translate3d(-70vw, 0, 0) !important;
        -webkit-transform: translate3d(-70vw, 0, 0) !important
    }

    .navbar-collapse-show[data-mobile-nav-style=modern] .navbar-modern-inner {
        right: 0;
        opacity: 1;
        visibility: visible !important;
        transition-delay: .1s;
        transform: translate3d(0, 0, 0)
    }

    .navbar-collapse-show[data-mobile-nav-style=modern] .navbar-modern-inner .collapse {
        overflow-y: auto !important;
        -webkit-overflow-scrolling: touch;
        height: 100%
    }

    .navbar-collapse-show[data-mobile-nav-style=modern] .navbar-show-modern-bg {
        transform: scale(1);
        opacity: 1;
        transition-delay: 0s
    }

    .navbar-collapse-show[data-mobile-nav-trigger-alignment=left][data-mobile-nav-style=modern] .navbar-modern-inner {
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        left: 0;
        right: auto
    }

    .navbar-collapse-show[data-mobile-nav-trigger-alignment=left][data-mobile-nav-style=modern] .navbar,
    .navbar-collapse-show[data-mobile-nav-trigger-alignment=left][data-mobile-nav-style=modern] .page-layout {
        transform: translate3d(70vw, 0, 0) !important;
        -webkit-transform: translate3d(70vw, 0, 0) !important
    }

    .navbar-expand-lg .navbar-toggler {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center
    }

    .navbar-expand-lg .navbar-toggler:focus {
        box-shadow: none
    }

    .navbar-expand-lg .navbar-collapse {
        display: block !important
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: static
    }

    .navbar-expand-lg .container-lg,
    .navbar-expand-lg>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }

    header .container-fluid,
    header .navbar-expand-lg {
        padding-left: 15px;
        padding-right: 15px
    }

    .collapse:not(.show) {
        display: none !important
    }

    header .navbar [class*=col-] .navbar-nav .nav-item,
    header .navbar [class*=col-] .navbar-nav .nav-item a {
        display: block
    }

    header .header-push-button.icon,
    header .left-nav .navbar-nav:before,
    header .push-menu {
        display: none
    }

    header .md-border-none {
        border: 0 !important
    }

    header .center-logo {
        padding-top: 0;
        padding-bottom: 0
    }

    header .navbar-brand,
    header.sticky .disable-fixed .navbar-brand,
    header.sticky .fixed-header .navbar-brand,
    header.sticky .navbar-brand {
        padding: 22px 0
    }

    header .navbar-brand .alt-logo,
    header .navbar-brand .default-logo {
        visibility: hidden !important;
        opacity: 0 !important;
        width: 0 !important
    }
  
    
    header .navbar-brand .Firstlogo 
    {
        display: none;
    }

    header .navbar-brand .mobile-logo {
        visibility: visible !important;
        opacity: 1 !important;
        width: auto !important;
    }

    header .navbar-brand .mobile-logo:focus,
    header .navbar-brand:focus {
        outline: 0
    }

    header.sticky .navbar-nav .nav-link {
        color: var(--dark-gray)
    }

    header.sticky.sticky-active .navbar-brand .alt-logo,
    header.sticky.sticky-active .navbar-brand .default-logo {
        visibility: hidden;
        opacity: 0;
        width: 0
    }

    header.sticky .header-icon .header-language a {
        padding-top: 0;
        padding-bottom: 0
    }

    header.sticky .top-logo.responsive-sticky .navbar-nav .nav-link {
        padding-top: 14px !important;
        padding-bottom: 14px !important
    }

    header.sticky .responsive-sticky .navbar-brand {
        padding: 14px 0
    }

    header.sticky .responsive-sticky .navbar-toggler {
        margin: 20px 0
    }

    header .header-top-bar .row {
        margin: 0
    }

    .navbar-full-screen-menu-inner.bg-transparent,
    .navbar-modern-inner.bg-transparent,
    .navbar.bg-transparent {
        background-color: var(--white) !important
    }

    .navbar .navbar-collapse.show,
    .navbar-full-screen-menu-inner .navbar-collapse.show,
    .navbar-modern-inner .navbar-collapse.show {
        overflow-y: auto !important;
        -webkit-overflow-scrolling: touch
    }

    .navbar .navbar-nav,
    .navbar-full-screen-menu-inner .navbar-nav,
    .navbar-modern-inner .navbar-nav {
        padding: 15px 15px 28px
    }

    .navbar .navbar-nav .nav-link,
    .navbar-full-screen-menu-inner .navbar-nav .nav-link,
    .navbar-modern-inner .navbar-nav .nav-link {
        padding: 10px 15px !important;
        color: var(--dark-gray)
    }

    .navbar .navbar-nav .nav-link .label,
    .navbar-full-screen-menu-inner .navbar-nav .nav-link .label,
    .navbar-modern-inner .navbar-nav .nav-link .label {
        margin-top: -3px;
        display: inline-block;
        padding: 1px 11px;
        font-size: 12px;
        margin-left: 8px;
        vertical-align: middle;
        line-height: 21px
    }

    .navbar .navbar-nav .nav-item.active .nav-link,
    .navbar-full-screen-menu-inner .navbar-nav .nav-item.active .nav-link,
    .navbar-modern-inner .navbar-nav .nav-item.active .nav-link {
        color: var(--dark-gray);
        opacity: .6
    }

    .navbar .navbar-nav .nav-item.active .dropdown-toggle,
    .navbar-full-screen-menu-inner .navbar-nav .nav-item.active .dropdown-toggle,
    .navbar-modern-inner .navbar-nav .nav-item.active .dropdown-toggle {
        color: var(--dark-gray)
    }

    .navbar .navbar-nav .nav-item.active:hover .nav-link,
    .navbar-full-screen-menu-inner .navbar-nav .nav-item.active:hover .nav-link,
    .navbar-modern-inner .navbar-nav .nav-item.active:hover .nav-link {
        color: var(--dark-gray);
        opacity: .6
    }

    .navbar .navbar-nav .nav-item .dropdown-toggle,
    .navbar-full-screen-menu-inner .navbar-nav .nav-item .dropdown-toggle,
    .navbar-modern-inner .navbar-nav .nav-item .dropdown-toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        right: 0;
        position: absolute;
        top: 0;
        text-align: center;
        color: var(--dark-gray)
    }

    .navbar .navbar-nav .dropdown.open .dropdown-menu,
    .navbar .navbar-nav .nav-item .dropdown-toggle:after,
    .navbar-full-screen-menu-inner .navbar-nav .dropdown.open .dropdown-menu,
    .navbar-full-screen-menu-inner .navbar-nav .nav-item .dropdown-toggle:after,
    .navbar-modern-inner .navbar-nav .dropdown.open .dropdown-menu,
    .navbar-modern-inner .navbar-nav .nav-item .dropdown-toggle:after {
        display: none
    }

    .navbar .navbar-nav .nav-item:hover .nav-link,
    .navbar-full-screen-menu-inner .navbar-nav .nav-item:hover .nav-link,
    .navbar-modern-inner .navbar-nav .nav-item:hover .nav-link {
        color: var(--dark-gray);
        opacity: 1
    }

    .navbar .navbar-nav .dropdown.submenu,
    .navbar-full-screen-menu-inner .navbar-nav .dropdown.submenu,
    .navbar-modern-inner .navbar-nav .dropdown.submenu {
        position: relative
    }

    .navbar .navbar-nav .dropdown .dropdown-menu.show,
    .navbar-full-screen-menu-inner .navbar-nav .dropdown .dropdown-menu.show,
    .navbar-modern-inner .navbar-nav .dropdown .dropdown-menu.show {
        display: block
    }

    .navbar .navbar-nav .dropdown .dropdown-toggle.show,
    .navbar-full-screen-menu-inner .navbar-nav .dropdown .dropdown-toggle.show,
    .navbar-modern-inner .navbar-nav .dropdown .dropdown-toggle.show {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg)
    }

    .navbar .navbar-nav .dropdown .dropdown-menu,
    .navbar-full-screen-menu-inner .navbar-nav .dropdown .dropdown-menu,
    .navbar-modern-inner .navbar-nav .dropdown .dropdown-menu {
        top: 0;
        animation: none !important;
        background-color: transparent;
        box-shadow: none;
        border-top: 0 !important;
        padding: 0 15px 15px;
        width: 100%;
        display: none;
        border-radius: 0 !important
    }

    .navbar .navbar-nav .dropdown .dropdown-menu .mega-menu,
    .navbar-full-screen-menu-inner .navbar-nav .dropdown .dropdown-menu .mega-menu,
    .navbar-modern-inner .navbar-nav .dropdown .dropdown-menu .mega-menu {
        padding: 0;
        display: block !important
    }

    .navbar .navbar-nav .dropdown .dropdown-menu .mega-menu ul,
    .navbar-full-screen-menu-inner .navbar-nav .dropdown .dropdown-menu .mega-menu ul,
    .navbar-modern-inner .navbar-nav .dropdown .dropdown-menu .mega-menu ul {
        padding: 15px 0 0
    }

    .navbar .navbar-nav .dropdown .dropdown-menu .mega-menu ul:first-child .sub-title,
    .navbar-full-screen-menu-inner .navbar-nav .dropdown .dropdown-menu .mega-menu ul:first-child .sub-title,
    .navbar-modern-inner .navbar-nav .dropdown .dropdown-menu .mega-menu ul:first-child .sub-title {
        margin-top: 0
    }

    .navbar .navbar-nav .dropdown .dropdown-menu li,
    .navbar-full-screen-menu-inner .navbar-nav .dropdown .dropdown-menu li,
    .navbar-modern-inner .navbar-nav .dropdown .dropdown-menu li {
        padding: 0 15px
    }

    .navbar .navbar-nav .dropdown .dropdown-menu li.sub-title,
    .navbar-full-screen-menu-inner .navbar-nav .dropdown .dropdown-menu li.sub-title,
    .navbar-modern-inner .navbar-nav .dropdown .dropdown-menu li.sub-title {
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 16px
    }

    .navbar .navbar-nav .dropdown .dropdown-menu li:last-child a,
    .navbar-full-screen-menu-inner .navbar-nav .dropdown .dropdown-menu li:last-child a,
    .navbar-modern-inner .navbar-nav .dropdown .dropdown-menu li:last-child a {
        padding-bottom: 5px
    }

    .navbar .navbar-nav .dropdown .dropdown-menu li.active>a,
    .navbar-full-screen-menu-inner .navbar-nav .dropdown .dropdown-menu li.active>a,
    .navbar-modern-inner .navbar-nav .dropdown .dropdown-menu li.active>a {
        color: var(--dark-gray)
    }

    .navbar .navbar-nav .dropdown .dropdown-menu a,
    .navbar-full-screen-menu-inner .navbar-nav .dropdown .dropdown-menu a,
    .navbar-modern-inner .navbar-nav .dropdown .dropdown-menu a {
        color: var(--medium-gray);
        padding: 6px 0;
        line-height: 16px
    }

    .navbar .navbar-nav .simple-dropdown .dropdown-menu li,
    .navbar-full-screen-menu-inner .navbar-nav .simple-dropdown .dropdown-menu li,
    .navbar-modern-inner .navbar-nav .simple-dropdown .dropdown-menu li {
        padding: 0 15px
    }

    .navbar .navbar-nav .simple-dropdown .dropdown-menu li a,
    .navbar-full-screen-menu-inner .navbar-nav .simple-dropdown .dropdown-menu li a,
    .navbar-modern-inner .navbar-nav .simple-dropdown .dropdown-menu li a {
        color: var(--medium-gray);
        padding: 6px 0;
        margin-bottom: 0
    }

    .navbar .navbar-nav .simple-dropdown .dropdown-menu>.dropdown,
    .navbar-full-screen-menu-inner .navbar-nav .simple-dropdown .dropdown-menu>.dropdown,
    .navbar-modern-inner .navbar-nav .simple-dropdown .dropdown-menu>.dropdown {
        margin-bottom: 25px
    }

    .navbar .navbar-nav .simple-dropdown .dropdown-menu .dropdown:last-child,
    .navbar-full-screen-menu-inner .navbar-nav .simple-dropdown .dropdown-menu .dropdown:last-child,
    .navbar-modern-inner .navbar-nav .simple-dropdown .dropdown-menu .dropdown:last-child {
        margin-bottom: 0
    }

    .navbar .navbar-nav .simple-dropdown .dropdown-menu .dropdown i,
    .navbar-full-screen-menu-inner .navbar-nav .simple-dropdown .dropdown-menu .dropdown i,
    .navbar-modern-inner .navbar-nav .simple-dropdown .dropdown-menu .dropdown i {
        display: none
    }

    .navbar .navbar-nav .simple-dropdown .dropdown-menu .dropdown>a,
    .navbar-full-screen-menu-inner .navbar-nav .simple-dropdown .dropdown-menu .dropdown>a,
    .navbar-modern-inner .navbar-nav .simple-dropdown .dropdown-menu .dropdown>a {
        color: var(--dark-gray);
        padding: 0 !important;
        margin-top: 15px;
        font-weight: 500;
        margin-bottom: 6px
    }

    .navbar .navbar-nav .simple-dropdown .dropdown-menu .dropdown-menu,
    .navbar-full-screen-menu-inner .navbar-nav .simple-dropdown .dropdown-menu .dropdown-menu,
    .navbar-modern-inner .navbar-nav .simple-dropdown .dropdown-menu .dropdown-menu {
        display: block;
        position: relative;
        left: 0 !important;
        padding: 0;
        top: 0 !important
    }

    .navbar .navbar-nav .simple-dropdown .dropdown-menu .dropdown-menu li,
    .navbar-full-screen-menu-inner .navbar-nav .simple-dropdown .dropdown-menu .dropdown-menu li,
    .navbar-modern-inner .navbar-nav .simple-dropdown .dropdown-menu .dropdown-menu li {
        padding: 0
    }

    .navbar .navbar-nav .simple-dropdown .dropdown-menu .dropdown-menu>.dropdown>a,
    .navbar-full-screen-menu-inner .navbar-nav .simple-dropdown .dropdown-menu .dropdown-menu>.dropdown>a,
    .navbar-modern-inner .navbar-nav .simple-dropdown .dropdown-menu .dropdown-menu>.dropdown>a {
        margin-top: 25px
    }

    .navbar .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu,
    .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu,
    .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu {
        border-radius: 0;
        padding: 0 30px;
        width: 100% !important;
        margin-bottom: 15px
    }

    .navbar .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu li a,
    .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu li a,
    .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu li a {
        padding-left: 0;
        padding-right: 0
    }

    .navbar .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu,
    .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu,
    .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu {
        box-shadow: none;
        border-radius: 0 !important;
        width: 100% !important;
        padding: 0 15px 15px
    }

    .navbar .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li,
    .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li,
    .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li {
        padding: 0 15px
    }

    .navbar .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a span,
    .navbar-full-screen-menu-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a span,
    .navbar-modern-inner .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a span {
        color: var(--dark-gray)
    }

    .navbar-full-screen-menu-inner.center-logo .navbar-nav,
    .navbar-modern-inner.center-logo .navbar-nav,
    .navbar.center-logo .navbar-nav {
        margin: 0 !important
    }

    .navbar-full-screen-menu-inner.center-logo .navbar-nav .nav-link,
    .navbar-modern-inner.center-logo .navbar-nav .nav-link,
    .navbar.center-logo .navbar-nav .nav-link {
        padding: 14px 15px !important
    }

    .navbar-full-screen-menu-inner.center-logo .navbar-nav:first-child,
    .navbar-modern-inner.center-logo .navbar-nav:first-child,
    .navbar.center-logo .navbar-nav:first-child {
        padding-bottom: 0
    }

    .navbar-full-screen-menu-inner.center-logo .navbar-nav:last-child,
    .navbar-modern-inner.center-logo .navbar-nav:last-child,
    .navbar.center-logo .navbar-nav:last-child {
        padding-top: 0
    }

    .navbar-full-screen-menu-inner.center-logo .navbar-left,
    .navbar-full-screen-menu-inner.center-logo .navbar-right,
    .navbar-modern-inner.center-logo .navbar-left,
    .navbar-modern-inner.center-logo .navbar-right,
    .navbar.center-logo .navbar-left,
    .navbar.center-logo .navbar-right {
        width: 100%
    }

    .navbar-full-screen-menu-inner.header-dark .navbar-toggler-line,
    .navbar-modern-inner.header-dark .navbar-toggler-line,
    .navbar.header-dark .navbar-toggler-line {
        background-color: var(--white)
    }

    .navbar-full-screen-menu-inner.header-dark .header-icon .icon .header-cart>a,
    .navbar-full-screen-menu-inner.header-dark .header-icon .icon .header-language>a,
    .navbar-full-screen-menu-inner.header-dark .header-icon .icon>a,
    .navbar-modern-inner.header-dark .header-icon .icon .header-cart>a,
    .navbar-modern-inner.header-dark .header-icon .icon .header-language>a,
    .navbar-modern-inner.header-dark .header-icon .icon>a,
    .navbar.header-dark .header-icon .icon .header-cart>a,
    .navbar.header-dark .header-icon .icon .header-language>a,
    .navbar.header-dark .header-icon .icon>a {
        color: var(--white)
    }

    .navbar-full-screen-menu-inner.header-dark .header-language-icon .header-language .language-dropdown a,
    .navbar-modern-inner.header-dark .header-language-icon .header-language .language-dropdown a,
    .navbar.header-dark .header-language-icon .header-language .language-dropdown a {
        color: var(--light-gray)
    }

    .navbar-full-screen-menu-inner.header-dark .navbar-collapse,
    .navbar-modern-inner.header-dark .navbar-collapse,
    .navbar.header-dark .navbar-collapse {
        background-color: var(--dark-gray)
    }

    .left-sidebar-header.header-dark .navbar-toggler-line,
    .navbar-collapse {
        background: var(--white)
    }

    .navbar-full-screen-menu-inner.header-dark .navbar-nav .nav-item .dropdown-toggle,
    .navbar-full-screen-menu-inner.header-dark .navbar-nav .nav-link,
    .navbar-modern-inner.header-dark .navbar-nav .nav-item .dropdown-toggle,
    .navbar-modern-inner.header-dark .navbar-nav .nav-link,
    .navbar.header-dark .navbar-nav .nav-item .dropdown-toggle,
    .navbar.header-dark .navbar-nav .nav-link {
        color: var(--white)
    }

    .navbar-full-screen-menu-inner.header-dark .navbar-nav .nav-item.active .dropdown-toggle,
    .navbar-full-screen-menu-inner.header-dark .navbar-nav .nav-item.active .nav-link,
    .navbar-full-screen-menu-inner.header-dark .navbar-nav .nav-item.active:hover .nav-link,
    .navbar-modern-inner.header-dark .navbar-nav .nav-item.active .dropdown-toggle,
    .navbar-modern-inner.header-dark .navbar-nav .nav-item.active .nav-link,
    .navbar-modern-inner.header-dark .navbar-nav .nav-item.active:hover .nav-link,
    .navbar.header-dark .navbar-nav .nav-item.active .dropdown-toggle,
    .navbar.header-dark .navbar-nav .nav-item.active .nav-link,
    .navbar.header-dark .navbar-nav .nav-item.active:hover .nav-link {
        color: var(--white);
        opacity: .6
    }

    .navbar-full-screen-menu-inner.header-dark .navbar-nav .nav-item:hover .nav-link,
    .navbar-modern-inner.header-dark .navbar-nav .nav-item:hover .nav-link,
    .navbar.header-dark .navbar-nav .nav-item:hover .nav-link {
        opacity: 1;
        color: var(--white)
    }

    .header-top-bar.top-bar-dark .header-icon .icon>a,
    .navbar-full-screen-menu-inner.header-dark .navbar-nav .dropdown .dropdown-menu li.active>a,
    .navbar-full-screen-menu-inner.header-dark .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a span,
    .navbar-full-screen-menu-inner.header-dark .navbar-nav .simple-dropdown .dropdown-menu .dropdown>a,
    .navbar-modern-inner.header-dark .navbar-nav .dropdown .dropdown-menu li.active>a,
    .navbar-modern-inner.header-dark .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a span,
    .navbar-modern-inner.header-dark .navbar-nav .simple-dropdown .dropdown-menu .dropdown>a,
    .navbar.header-dark .navbar-nav .dropdown .dropdown-menu li.active>a,
    .navbar.header-dark .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a span,
    .navbar.header-dark .navbar-nav .simple-dropdown .dropdown-menu .dropdown>a {
        color: var(--white)
    }

    .menu-order {
        order: 5
    }

    .navbar-collapse {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        overflow: hidden;
        box-shadow: 0 20px 15px 0 rgba(23, 23, 23, .05);
        max-height: calc(100vh - 72px)
    }

    .header-icon .icon>a {
        color: var(--dark-gray);
        padding-left: 12px
    }

    .header-icon .icon>a:hover {
        color: var(--dark-gray);
        opacity: 1
    }

    .header-icon .icon .header-cart>a,
    .header-icon .icon .header-language>a {
        color: var(--dark-gray);
        padding-left: 12px
    }

    .header-icon .icon .header-cart>a:hover,
    .header-icon .icon .header-language>a:hover {
        color: var(--dark-gray);
        opacity: 1
    }

    .center-logo .menu-logo {
        margin-right: auto
    }

    .center-logo .navbar-brand {
        position: relative;
        left: auto;
        top: auto;
        transform: none;
        float: left
    }

    .center-logo .navbar-toggler {
        margin: 31px 0
    }

    .navbar.mini-header .navbar-nav .nav-link,
    .navbar.top-logo .navbar-nav .nav-link {
        padding: 14px 15px !important
    }

    .header-with-topbar+.top-space-margin {
        margin-top: 116px
    }

    .header-transparent[data-header-hover=dark] {
        background-color: var(--dark-gray) !important
    }

    .header-transparent[data-header-hover=dark] .hamburger-push-button.icon span,
    .header-transparent[data-header-hover=dark] .navbar-toggler-line,
    header .header-dark .hamburger-push-button.icon span {
        background-color: var(--white)
    }

    .header-transparent[data-header-hover=dark] .header-icon .icon .header-cart>a,
    .header-transparent[data-header-hover=dark] .header-icon .icon .header-language>a,
    .header-transparent[data-header-hover=dark] .header-icon .icon>a {
        color: var(--white)
    }

    .header-transparent[data-header-hover=dark] .header-language-icon .header-language .language-dropdown a {
        color: var(--light-gray)
    }

    .header-transparent[data-header-hover=dark] .navbar-collapse {
        background-color: var(--dark-gray)
    }

    .header-transparent[data-header-hover=dark] .navbar-nav .nav-item .dropdown-toggle,
    .header-transparent[data-header-hover=dark] .navbar-nav .nav-link {
        color: var(--white)
    }

    .header-transparent[data-header-hover=dark] .navbar-nav .nav-item.active .dropdown-toggle,
    .header-transparent[data-header-hover=dark] .navbar-nav .nav-item.active .nav-link,
    .header-transparent[data-header-hover=dark] .navbar-nav .nav-item.active:hover .nav-link {
        color: var(--medium-gray)
    }

    .header-transparent[data-header-hover=dark] .navbar-nav .nav-item:hover .nav-link {
        opacity: 1;
        color: var(--white)
    }

    .header-transparent[data-header-hover=dark] .navbar-nav .dropdown .dropdown-menu li.active>a,
    .header-transparent[data-header-hover=dark] .navbar-nav .simple-dropdown .dropdown-menu .dropdown>a,
    .header-transparent[data-header-hover=dark] .navbar-nav .submenu-content ul .sub-title {
        color: var(--white)
    }

    .left-sidebar-wrapper {
        padding-left: 0
    }

    .left-sidebar-wrapper .left-sidebar-header.collapsing {
        overflow: visible !important
    }

    .left-sidebar-wrapper .left-sidebar-nav .navbar-brand {
        padding: 22px 0 !important
    }

    .side-menu-top {
        position: fixed;
        left: 0;
        top: 0;
        text-align: left !important;
        width: 100%;
        padding: 0 30px;
        z-index: 9
    }

    .left-sidebar-header {
        left: -300px;
        transition-duration: .3s;
        height: 100% !important
    }

    .left-sidebar-header.show {
        left: 0;
        transition-duration: .3s
    }

    .left-sidebar-header.show .left-sidebar-nav {
        left: 0
    }

    .left-sidebar-header .side-menu-bottom {
        padding: 50px 15px 0;
        height: 100%;
        overflow: auto;
        border-top: 1px solid var(--very-light-gray)
    }

    .left-sidebar-header .side-menu-bottom .left-menu-bottom {
        margin-top: 50px
    }

    .left-sidebar-header .side-menu-bottom .header-icon .icon a>i {
        padding: 0
    }

    .left-sidebar-header .left-sidebar-nav {
        left: -300px;
        transition-duration: .3s;
        overflow: visible;
        height: 100%;
        top: 0 !important;
        position: fixed !important;
        padding: 72px 15px
    }

    .left-sidebar-header .left-sidebar-nav .navbar-toggler {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 30px;
        margin: 0;
        transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%)
    }

    .left-sidebar-header.show .navbar-toggler-line:nth-child(1),
    .left-sidebar-header.show .navbar-toggler-line:nth-child(4) {
        top: 7px;
        width: 0%
    }

    .left-sidebar-header .left-sidebar-nav .side-menu {
        margin-top: 0;
        margin-bottom: 0
    }

    .left-sidebar-header.show .navbar-toggler-line:nth-child(2) {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg)
    }

    .left-sidebar-header.show .navbar-toggler-line:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg)
    }

    .left-sidebar-header.header-dark .header-icon .icon>a {
        color: var(--white)
    }

    .left-sidebar-header.header-light .side-menu-top {
        border-bottom: 1px solid var(--very-light-gray)
    }

    .left-modern-menu {
        padding-left: 0
    }

    .left-modern-sidebar {
        height: auto;
        width: 100%;
        padding: 0 30px;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between
    }

    .left-modern-sidebar .vertical-title {
        transform: none;
        height: auto;
        display: none;
        flex: inherit
    }

    .left-modern-sidebar .vertical-title .title {
        transform: none
    }

    .left-modern-sidebar .toggle-icon {
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        right: 30px;
        left: auto
    }

    .left-modern-menu .left-modern-header .navbar-brand {
        width: auto;
        padding: 22px 0 !important
    }

    .left-modern-menu .left-modern-header .modern-sidebar-nav {
        padding-top: 72px;
        width: 380px;
        left: -400px
    }

    .left-modern-menu .left-modern-header .modern-side-menu {
        padding: 50px 20px
    }

    .left-modern-menu .left-modern-header.show .modern-sidebar-nav {
        left: 0
    }

    header .header-dark .hamburger-push-button .push-button-text {
        color: var(--white) !important
    }

    header .hamburger-push-button.icon {
        display: flex
    }

    header .hamburger-push-button.icon span {
        background-color: var(--dark-gray)
    }

    header .hamburger-push-button .push-button-text {
        color: var(--dark-gray) !important
    }

    header .push-menu.hamburger-nav {
        display: block
    }

    header .push-menu .close-menu {
        top: 30px;
        right: 30px
    }

    .navbar-modern-inner .navbar-nav {
        padding: 15px
    }

    .hamburger-menu-simple .menu-item-list {
        width: 100%
    }

    .search-form-wrapper .search-form-box {
        width: 75%
    }

    .ipad-top-space-margin,
    .top-space-margin {
        margin-top: 76px
    }

    .full-screen.ipad-top-space-margin,
    .full-screen.top-space-margin {
        height: calc(100vh - 76px)
    }

    .navbar .navbar-nav .nav-link[href="javascript:void(0);
 "] {
 z-index:1 !important
}
}

@media (max-width:767px) {
    header .navbar-expand-lg {
        padding-left: 0;
        padding-right: 0
    }

    header .header-top-bar {
        display: none;
        height: 0
    }

    header .header-top-bar+.navbar {
        top: 0
    }

    header .push-menu .close-menu {
        top: 15px;
        right: 15px
    }

    .navbar .navbar-nav {
        padding: 15px 0
    }

    .header-search-popup .search-form {
        width: 85%
    }

    .header-search-popup .mb-50px {
        margin-bottom: 30px
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner {
        width: 85vw;
        right: -80vw;
        transform: translate3d(15vw, 0, 0);
        -webkit-transform: translate3d(15vw, 0, 0)
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-collapse {
        padding: 70px 0
    }

    .navbar-collapse-show[data-mobile-nav-style=modern] .navbar-modern-inner {
        width: 85vw;
        right: 0;
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0)
    }

    .navbar-collapse-show[data-mobile-nav-style=modern] .navbar,
    .navbar-collapse-show[data-mobile-nav-style=modern] .page-layout {
        transform: translate3d(-85vw, 0, 0) !important;
        -webkit-transform: translate3d(-85vw, 0, 0) !important
    }

    .navbar-collapse-show[data-mobile-nav-trigger-alignment=left][data-mobile-nav-style=modern] .navbar,
    .navbar-collapse-show[data-mobile-nav-trigger-alignment=left][data-mobile-nav-style=modern] .page-layout {
        transform: translate3d(85vw, 0, 0) !important;
        -webkit-transform: translate3d(85vw, 0, 0) !important
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .navbar-collapse {
        padding: 60px 0
    }

    [data-mobile-nav-style=full-screen-menu] .navbar-full-screen-menu-inner .mCustomScrollBox {
        width: 100%
    }

    .search-form-wrapper .search-form-box {
        width: 85%
    }

    .search-form-wrapper .search-close {
        top: 15px;
        right: 15px
    }

    .header-with-topbar+.top-space-margin {
        margin-top: 76px
    }

    .left-sidebar-header .side-menu-bottom {
        padding-top: 30px
    }

    .left-modern-sidebar,
    .side-menu-top {
        padding: 0 15px
    }

    .left-sidebar-header .left-sidebar-nav .navbar-toggler {
        right: 15px
    }

    .left-modern-menu .left-modern-header .modern-side-menu {
        padding: 50px 0
    }

    .left-modern-menu .left-modern-header .modern-side-menu .mCustomScrollBox .mCSB_container {
        padding: 0 20px
    }

    .search-form-wrapper .search-form {
        height: 450px !important
    }

    .menu-list-wrapper {
        height: calc(100vh - 120px);
        padding: 20px 0
    }
}

@media (max-width:575px) {
    .left-modern-menu .left-modern-header .modern-sidebar-nav {
        width: 100%;
        left: -100%
    }

    .left-modern-menu .left-modern-header.show .modern-sidebar-nav {
        left: 0
    }

    [data-mobile-nav-style=modern] .navbar-modern-inner .navbar-nav>.nav-item {
        padding: 7px 0
    }

    .search-form-wrapper .search-form {
        height: 380px !important
    }

    .search-form-wrapper .search-form h6 {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 10px !important
    }

    .search-form-wrapper .search-form .search-input {
        font-size: 15px;
        height: 50px
    }

    .search-form-wrapper .search-form .search-button {
        bottom: 11px;
        font-size: 20px
    }
}

@media (max-height:380px) {
    .search-form-wrapper .search-form {
        height: 85vh !important
    }

    .search-form-wrapper .search-form h6 {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 10px !important
    }

    .search-form-wrapper .search-form .search-input {
        font-size: 14px;
        height: 50px
    }

    .search-form-wrapper .search-form .search-button {
        bottom: 10px;
        font-size: 18px
    }

    .header-cart-icon .header-cart .cart-item-list .cart-item {
        padding: 12px
    }

    .header-cart-icon .header-cart .cart-item-list .cart-item.cart-total .mb-15px {
        margin-bottom: 0 !important
    }

    .header-cart-icon .header-cart .cart-item-list .cart-item .product-image {
        max-width: 40px
    }
}

@media (max-width:991px) {
    .down-section {
        bottom: 35px
    }
}

@media (max-width:575px) {
    .breadcrumb ul li:after {
        padding: 0 6px 0 8px
    }

    .down-section {
        bottom: 20px
    }
}

@media (max-width:1600px) {
    .grid.xxl-grid-6col li {
        width: 16.67%
    }

    .grid.xxl-grid-6col li.grid-item-double {
        width: 33.33%
    }

    .grid.xxl-grid-5col li {
        width: 20%
    }

    .grid.xxl-grid-5col li.grid-item-double {
        width: 40%
    }

    .grid.xxl-grid-4col li {
        width: 25%
    }

    .grid.xxl-grid-4col li.grid-item-double {
        width: 50%
    }

    .grid.xxl-grid-3col li {
        width: 33.33%
    }

    .grid.xxl-grid-3col li.grid-item-double {
        width: 66.67%
    }

    .grid.xxl-grid-2col li {
        width: 50%
    }

    .grid.xxl-grid-1col li,
    .grid.xxl-grid-1col li.grid-item-double,
    .grid.xxl-grid-2col li.grid-item-double {
        width: 100%
    }
}

@media (max-width:1399px) {
    .grid.xl-grid-6col li {
        width: 16.67%
    }

    .grid.xl-grid-6col li.grid-item-double {
        width: 33.33%
    }

    .grid.xl-grid-5col li {
        width: 20%
    }

    .grid.xl-grid-5col li.grid-item-double {
        width: 40%
    }

    .grid.xl-grid-4col li {
        width: 25%
    }

    .grid.xl-grid-4col li.grid-item-double {
        width: 50%
    }

    .grid.xl-grid-3col li {
        width: 33.33%
    }

    .grid.xl-grid-3col li.grid-item-double {
        width: 66.67%
    }

    .grid.xl-grid-2col li {
        width: 50%
    }

    .grid.xl-grid-1col li,
    .grid.xl-grid-1col li.grid-item-double,
    .grid.xl-grid-2col li.grid-item-double {
        width: 100%
    }
}

@media (max-width:1199px) {
    .grid.lg-grid-6col li {
        width: 16.67%
    }

    .grid.lg-grid-6col li.grid-item-double {
        width: 33.33%
    }

    .grid.lg-grid-5col li {
        width: 20%
    }

    .grid.lg-grid-5col li.grid-item-double {
        width: 40%
    }

    .grid.lg-grid-4col li {
        width: 25%
    }

    .grid.lg-grid-4col li.grid-item-double {
        width: 50%
    }

    .grid.lg-grid-3col li {
        width: 33.33%
    }

    .grid.lg-grid-3col li.grid-item-double {
        width: 66.67%
    }

    .grid.lg-grid-2col li {
        width: 50%
    }

    .grid.lg-grid-1col li,
    .grid.lg-grid-1col li.grid-item-double,
    .grid.lg-grid-2col li.grid-item-double {
        width: 100%
    }
}

@media (max-width:991px) {
    .grid.md-grid-6col li {
        width: 16.67%
    }

    .grid.md-grid-6col li.grid-item-double {
        width: 33.33%
    }

    .grid.md-grid-5col li {
        width: 20%
    }

    .grid.md-grid-5col li.grid-item-double {
        width: 40%
    }

    .grid.md-grid-4col li {
        width: 25%
    }

    .grid.md-grid-4col li.grid-item-double {
        width: 50%
    }

    .grid.md-grid-3col li {
        width: 33.33%
    }

    .grid.md-grid-3col li.grid-item-double {
        width: 66.67%
    }

    .grid.md-grid-2col li {
        width: 50%
    }

    .grid.md-grid-1col li,
    .grid.md-grid-1col li.grid-item-double,
    .grid.md-grid-2col li.grid-item-double {
        width: 100%
    }
}

@media (max-width:767px) {

    .page-title-extra-large h1,
    .page-title-extra-small h2,
    .page-title-large h1 {
        font-size: 36px;
        line-height: 44px
    }

    .grid.sm-grid-6col li {
        width: 16.67%
    }

    .grid.sm-grid-6col li.grid-item-double {
        width: 33.33%
    }

    .grid.sm-grid-5col li {
        width: 20%
    }

    .grid.sm-grid-5col li.grid-item-double {
        width: 40%
    }

    .grid.sm-grid-4col li {
        width: 25%
    }

    .grid.sm-grid-4col li.grid-item-double {
        width: 50%
    }

    .grid.sm-grid-3col li {
        width: 33.33%
    }

    .grid.sm-grid-3col li.grid-item-double {
        width: 66.67%
    }

    .grid.sm-grid-2col li {
        width: 50%
    }

    .grid.sm-grid-1col li,
    .grid.sm-grid-1col li.grid-item-double,
    .grid.sm-grid-2col li.grid-item-double {
        width: 100%
    }

    .grid.gutter-small {
        margin: 0 -7px
    }

    .grid.gutter-small li {
        padding: 7px
    }

    .grid.gutter-double-extra-large li,
    .grid.gutter-extra-large li {
        padding: 15px
    }

    .grid.gutter-extra-large {
        margin: 0 -15px
    }

    .grid.gutter-medium {
        margin: 0 -7px
    }

    .grid.gutter-medium li {
        padding: 7px
    }

    .grid.gutter-large {
        margin: 0 -10px
    }

    .grid.gutter-large li {
        padding: 10px
    }

    .accordion-style-04 .accordion-item .accordion-body,
    .accordion-style-04 .accordion-item .accordion-header {
        padding-left: 25px;
        padding-right: 25px
    }

    .accordion-style-02 .accordion-item .accordion-body {
        padding-bottom: 25px
    }

    .accordion-style-05 .accordion-item {
        padding: 20px 35px 22px 55px
    }

    .accordion-style-05 .accordion-item .number {
        top: 17px
    }
}

@media (max-width:575px) {
    .grid.xs-grid-6col li {
        width: 16.67%
    }

    .grid.xs-grid-6col li.grid-item-double {
        width: 33.33%
    }

    .grid.xs-grid-5col li {
        width: 20%
    }

    .grid.xs-grid-5col li.grid-item-double {
        width: 40%
    }

    .grid.xs-grid-4col li {
        width: 25%
    }

    .grid.xs-grid-4col li.grid-item-double {
        width: 50%
    }

    .grid.xs-grid-3col li {
        width: 33.33%
    }

    .grid.xs-grid-3col li.grid-item-double {
        width: 66.67%
    }

    .grid.xs-grid-2col li {
        width: 50%
    }

    .grid.xs-grid-1col li,
    .grid.xs-grid-2col li.grid-item-double {
        width: 100%
    }

    .accordion-style-05 .accordion-item {
        padding: 18px 20px 20px 55px
    }

    .accordion-style-05 .accordion-item .number {
        top: 15px
    }

    .accordion-style-05 .accordion-item .accordion-body {
        padding-right: 25px
    }

    .accordion-style-06 .accordion-item {
        padding: 15px 70px 15px 30px
    }
}

@media (max-width:1399px) {
    .blockquote-style-04 .blockquote-img img {
        margin-top: -106px
    }

    .blockquote-style-04 .blockquote-content {
        padding-left: 23%
    }
}

@media (max-width:1199px) {
    .blockquote-style-04 .blockquote-img img {
        margin-top: -89px
    }

    .blockquote-style-04 .blockquote-content {
        padding-left: 20%;
        border-bottom-right-radius: 89px
    }

    .clients-style-02 .swiper-button-prev {
        left: -30px
    }

    .clients-style-02 .swiper-button-next {
        right: -30px
    }
}

@media (max-width:991px) {
    .blockquote-style-04 .blockquote-img img {
        margin-top: -72px
    }

    .blockquote-style-04 .blockquote-content {
        border-bottom-right-radius: 64px
    }

    .clients-style-02 .swiper-button-prev {
        left: -15px
    }

    .clients-style-02 .swiper-button-next {
        right: -15px
    }

    .countdown-style-02 .countdown-box {
        width: 145px
    }

    .countdown-style-04 .countdown-box {
        width: 150px
    }
}

@media (max-width:767px) {
    .blockquote-style-04 .blockquote-content {
        margin-left: 0;
        padding: 8% 10%
    }

    .btn.btn-extra-large {
        font-size: 15px;
        padding: 20px 40px
    }

    .btn.btn-large,
    .btn.btn-rounded.btn-extra-large {
        padding: 16px 36px
    }

    .btn.btn-large {
        font-size: 13px
    }

    .btn.btn-rounded.btn-large {
        padding: 12px 30px
    }

    .btn.with-rounded.btn-extra-large {
        padding-right: 70px
    }

    .btn.with-rounded.btn-large {
        padding-right: 55px
    }

    .btn.btn-link {
        padding: 0 0 2px
    }

    .btn.btn-link-gradient {
        padding: 0 0 3px
    }

    .btn.btn-switch-text.btn-extra-large,
    .btn.btn-switch-text.btn-large,
    .btn.btn-switch-text.btn-medium,
    .btn.btn-switch-text.btn-small,
    .btn.btn-switch-text.btn-very-small {
        padding: 0
    }

    .clients-style-02 .swiper-button-prev {
        left: 0
    }

    .clients-style-02 .swiper-button-next {
        right: 0
    }

    .text-slider-style-05 .swiper-slide {
        -webkit-filter: grayscale(0);
        filter: grayscale(0);
        opacity: 1
    }

    .text-slider-style-05 .swiper-slide .services-box-style-01 .icon-box {
        opacity: 1
    }

    .countdown-style-01 .countdown {
        width: 100%
    }

    .countdown-style-01 .counter-container {
        display: block;
        width: 100%
    }

    .countdown-style-01 .counter-container .countdown-box {
        margin: 5%;
        width: 40%;
        float: left
    }

    .countdown-style-02 .countdown-box,
    .countdown-style-03 .countdown-box,
    .countdown-style-04 .countdown-box {
        width: 50%;
        margin: 0 0 30px
    }

    .countdown-style-02 .countdown-box span,
    .countdown-style-04 .countdown-box span {
        position: relative;
        top: 0
    }

    .countdown-style-02 .countdown-box:before,
    .countdown-style-04 .countdown-box:before {
        transform: none;
        top: 15px
    }

    .countdown-style-02 .countdown-box:nth-child(2):before,
    .countdown-style-04 .countdown-box:nth-child(2):before {
        display: none
    }
}

@media (max-width:991px) {
    .counter-style-06 .feature-box:nth-last-child(2n+1) .feature-box-content:after {
        display: none
    }

    .event-style-03 .accordion .trainer-box {
        margin-bottom: 25px;
        margin-top: 15px;
        margin-right: 0
    }

    .event-style-03 .nav-tabs .nav-link {
        padding-right: 5px;
        padding-left: 5px
    }

    .event-style-03 .accordion .accordion-title .event-time {
        min-width: 290px
    }

    .time-schedule-scroll {
        overflow-x: auto;
        overflow-y: hidden
    }

    .time-schedule-scroll .time-schedule-min-width {
        min-width: 1050px
    }

    #map {
        height: 450px
    }
}

@media (max-width:767px) {
    .time-schedule-scroll {
        overflow-x: auto;
        overflow-y: hidden
    }

    .time-schedule-scroll .time-schedule-min-width {
        min-width: 980px
    }

    .time-table-box.day {
        padding: 15px 10px
    }

    .event-style-03 .accordion .accordion-title .event-time {
        min-width: 1px;
        margin-right: 20px
    }

    .event-style-03 .accordion .accordion-item .accordion-body {
        padding-right: 0
    }

    .event-style-03 .nav-tabs>li.nav-item:not(:last-child) {
        border-right: 0;
        border-bottom: 1px solid rgba(255, 255, 255, .5)
    }

    .shadow-in .separator-animation {
        width: calc(100% + 5px) !important
    }

    .text-highlight .separator-animation {
        left: -1px
    }
}

@media (max-width:575px) {
    .counter-style-06 .feature-box .feature-box-content:after {
        display: none
    }

    .fancy-text-box-style-01 {
        border-right: 0 solid
    }

    #map {
        height: 420px
    }

    .infowindow {
        padding: 20px !important
    }
}

@media (max-width:1199px) {
    .fancy-text-style-3 {
        transform: rotate(0) !important
    }

    .feature-box:hover .content-slide-up.content-scale .feature-box-overlay {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    .icon-with-text-style-05 .feature-box {
        overflow: hidden
    }

    .icon-with-text-style-05 .feature-box .content-slide-up {
        width: 100%
    }
}

@media (max-width:767px) {

    .feature-box.feature-box-left-icon .feature-box-icon,
    .feature-box.feature-box-left-icon-middle .feature-box-icon {
        margin-right: 20px
    }

    .feature-box:hover .content-slide-up.content-scale .feature-box-overlay {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@media (max-width:991px) {
    .html-video {
        height: 100%
    }

    .mfp-container {
        padding-left: 25px;
        padding-right: 25px
    }
}

@media (max-width:767px) {
    .mfp-container {
        padding-left: 15px;
        padding-right: 15px
    }

    .mfp-arrow-right {
        right: 10px
    }

    .mfp-arrow-left {
        left: 10px
    }
}

@media (max-width:1199px) {
    .interactive-banner-style-06 .interactive-banners-content {
        transform: translateY(calc(100% - 110px))
    }

    .mfp-gallery button.mfp-close,
    .mfp-iframe-holder button.mfp-close,
    .mfp-iframe-holder~button.mfp-close,
    .mfp-image-holder button.mfp-close,
    .subscribe-popup .mfp-close,
    .subscribe-popup .mfp-close:active,
    button.mfp-close,
    button.mfp-close:active {
        right: 10px;
        top: 10px
    }

    .fix-background,
    [data-parallax-background-ratio] {
        background-attachment: inherit !important;
        background-position: center !important
    }
}

.is-touchable [data-parallax-background-ratio] {
    background-attachment: inherit !important;
    background-position: center !important;
    background-repeat: no-repeat !important
}

@media (max-width:991px) {

    .pricing-table-style-01.popular-item,
    .pricing-table-style-02.popular-item {
        margin-top: 0
    }

    .pricing-table-style-02 .popular-item {
        left: 0;
        width: 100%
    }
}

@media (max-width:575px) {
    .pricing-table-style-04 .accordion-item {
        padding-left: 30px;
        padding-right: 30px
    }

    .pricing-table-style-04 .accordion-item.active-accordion {
        padding-left: 35px;
        padding-right: 35px
    }

    .pricing-table-style-08 .nav-tabs>li.nav-item>a.nav-link .tab-nav-text {
        margin: 0 12px
    }

    .pricing-table-style-12 li {
        display: block
    }

    .pricing-table-style-12 li:hover {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    .pricing-table-style-12 li img {
        margin-bottom: 10px
    }
}

@media (max-width:767px) {

    .process-step-style-02 .progress-step-separator,
    .process-step-style-03 .progress-step-separator,
    .process-step-style-04 .progress-step-separator {
        display: none
    }

    .process-step-style-01 .progress-step-separator {
        position: absolute;
        top: 50%;
        left: 0;
        transform: none;
        right: 0;
        margin: 0 auto
    }
}

@media (max-width:1199px) {
    .sliding-box .sliding-box-item {
        width: 33.33% !important;
        margin-left: 0;
        margin-right: 0;
        padding: 0 15px;
        box-shadow: none
    }

    .sliding-box .sliding-box-item .sliding-box-content {
        width: 100% !important;
        left: 0 !important;
        position: relative;
        height: auto
    }

    .sliding-box .sliding-box-item.active .sliding-box-content,
    .sliding-box .sliding-box-item.active .sliding-box-img {
        border-radius: 0
    }

    .sliding-box .sliding-box-img {
        width: 100% !important;
        border-radius: 0 !important
    }

    .sliding-box-style-01 .sliding-box-item .sliding-box-content:after {
        top: 0;
        opacity: 1;
        right: 50%;
        margin-top: -23px;
        border: solid transparent;
        border-bottom-color: var(--white);
        border-width: 12px;
        margin-right: -10px
    }

    .sliding-box-style-02 .sliding-box-item.active .content-hover {
        animation: fade 0s
    }
}

@media (max-width:991px) {

    .process-step-style-02:nth-child(2) .progress-step-separator,
    .process-step-style-03:nth-child(2) .progress-step-separator,
    .process-step-style-04:nth-child(2) .progress-step-separator {
        display: none
    }

    .sliding-box {
        justify-content: center
    }

    .sliding-box .sliding-box-item {
        width: 50% !important
    }
}

@media (max-width:767px) {
    .sliding-box .sliding-box-item {
        width: 100% !important;
        padding: 0
    }

    .sliding-box-style-03 .number {
        bottom: -22px
    }

    .sliding-box-style-03 .sliding-box-item {
        padding-right: 15px;
        padding-left: 15px
    }

    .elements-social ul li {
        margin-bottom: 20px
    }
}

@media (max-width:991px) {
    .social-icon-style-06 ul li a {
        padding: 0 20px
    }
}

@media (max-width:575px) {

    .extra-large-icon li,
    .large-icon li,
    .medium-icon li {
        margin: 0 6px
    }
}

@media (max-width:767px) {
    .newsletter-style-01.box-shadow {
        box-shadow: none
    }

    .newsletter-style-01 input {
        padding-right: 25px
    }

    .newsletter-style-01 input.is-invalid {
        padding-right: 30px
    }

    .newsletter-style-01 .btn {
        position: relative;
        margin-top: 15px;
        width: 100%;
        border-radius: 4px
    }

    .newsletter-style-03 input {
        padding-right: 90px !important;
        padding-left: 30px !important
    }

    .newsletter-style-03 .btn {
        padding: 12px 30px
    }

    .newsletter-style-03 .btn i {
        margin-left: 0;
        margin-right: 0
    }

    .newsletter-style-03 .btn span {
        display: none
    }
}

@media (max-width:1399px) {
    .tab-style-05 .nav-tabs .nav-item .nav-link {
        padding: 18px 20px 20px
    }
}

@media (max-width:1199px) {
    .tab-style-05 .nav-tabs .nav-item .nav-link {
        padding: 16px 20px
    }

    .tab-style-08 ul .nav-item .nav-link {
        padding: 25px 10px
    }
}

@media (max-width:991px) {
    .tab-style-02 .nav-tabs>li.nav-item {
        margin: 0 10px
    }

    .tab-style-03 .nav-tabs .nav-link {
        padding: 10px 28px;
        margin-bottom: 0
    }

    .tab-style-03 .nav-tabs>li.nav-item {
        padding: 0 5px
    }

    .tab-style-04 .nav-tabs .nav-item {
        padding: 0 20px
    }

    .tab-style-05 .nav-tabs>li.nav-item {
        width: auto;
        padding: 0 3px
    }

    .tab-style-05 .nav-tabs>li.nav-item .nav-link {
        padding: 12px 20px
    }

    .tab-style-07 .nav-tabs .nav-item .nav-link {
        padding-left: 50px;
        padding-right: 50px
    }
}

@media (max-width:767px) {
    .tab-style-01 .nav-tabs {
        border-bottom: 0
    }

    .tab-style-01 .nav-tabs>li.nav-item .nav-link {
        border-bottom: 1px solid var(--light-medium-gray);
        border-radius: 6px
    }

    .tab-style-02 .nav-tabs>li.nav-item {
        margin-bottom: 30px
    }

    .tab-style-01 .nav-tabs>li.nav-item {
        margin-bottom: 15px
    }

    .tab-style-04 .nav-tabs {
        display: inline-block;
        width: 100%
    }

    .tab-style-04 .nav-tabs .nav-item {
        width: auto;
        display: table;
        margin: 0 auto 10px
    }

    .tab-style-05 .nav-tabs {
        display: inline-block;
        width: 100%
    }

    .tab-style-05 .nav-tabs .nav-item {
        display: table;
        margin: 0 auto;
        width: auto
    }

    .tab-style-05 .nav-tabs .nav-item .nav-link {
        padding: 12px 30px
    }

    .tab-style-07 .nav-tabs .nav-item .nav-link {
        padding: 25px
    }

    .tab-style-07 .nav-tabs .nav-item .nav-link .number-box {
        right: 40px
    }

    .tab-style-08 ul .nav-item {
        border-right: 0;
        border-bottom: 1px solid var(--light-medium-gray);
        flex-grow: inherit;
        flex-basis: auto;
        width: 100%
    }

    .tab-style-08 ul .nav-item .nav-link {
        padding: 18px 25px
    }

    .swiper-horizontal-3d .swiper-slide.swiper-slide-active {
        box-shadow: none
    }

    .testimonials-style-04 {
        padding: 0 15px
    }

    .testimonials-style-04.swiper-horizontal-3d .swiper-slide.swiper-slide-next,
    .testimonials-style-04.swiper-horizontal-3d .swiper-slide.swiper-slide-prev {
        opacity: 0
    }

    .testimonials-style-04.swiper-horizontal-3d .swiper-slide.swiper-slide-active {
        box-shadow: 0 0 10px rgba(0, 0, 0, .1)
    }

    .testimonials-style-11 .swiper-button-next.slider-custom-text-next,
    .testimonials-style-11 .swiper-button-prev.slider-custom-text-prev {
        min-height: 1px;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        top: initial;
        right: auto;
        left: auto;
        padding-left: 20px;
        padding-right: 20px;
        position: relative;
        margin: 30px -3px 0;
        display: inline-block
    }
}

@media (max-width:1600px) {
    .blog-simple figure figcaption .hover-text {
        display: block !important;
        height: auto !important;
        margin-bottom: -50px !important;
        -webkit-transition: .3s;
        transition: .3s
    }

    .blog-simple figure:hover figcaption .hover-text {
        margin-bottom: 0 !important
    }

    .blog-modern figure .post-content-wrapper .post-content .hover-text {
        display: block !important
    }
}

@media (max-width:991px) {
    .blog-date .feature-box .feature-box-icon {
        margin-right: 35px !important
    }
}

@media (max-width:767px) {
    .blog-standard .entry-meta span {
        margin-right: 20px
    }

    .blog-comment li {
        padding-bottom: 50px
    }

    .blog-comment li ul.child-comment li {
        margin-top: 45px
    }
}

@media (max-width:575px) {
    .testimonials-style-12 .swiper-button-previous-nav {
        left: 25px
    }

    .testimonials-style-12 .swiper-button-next-nav {
        right: 25px
    }

    .testimonials-style-13 .swiper-slide,
    .testimonials-style-13 .swiper-slide.swiper-slide-active,
    .testimonials-style-13 .swiper-slide.swiper-slide-next {
        opacity: 1
    }

    .blog-date .feature-box .feature-box-icon {
        margin-right: 25px !important
    }

    .blog-date .feature-box .post-date {
        min-width: 65px;
        padding: 15px 0
    }

    .blog-standard .entry-meta span {
        display: block
    }

    .blog-standard .blog-details .entry-meta span {
        display: inline-block
    }

    .blog-modern figure .post-content-wrapper {
        width: 90%
    }

    .blog-comment li ul.child-comment {
        margin-left: 20px;
        padding-left: 20px
    }

    .pagination-style-01 .page-item .page-link {
        margin: 0 5px
    }
}

@media (max-width:1600px) {
    .sticky-image-distortion-wrapper .menu {
        padding-left: 30px
    }

    .sticky-image-title a {
        padding-left: 50px
    }

    .sticky-category {
        left: 35px
    }

    .sticky-image-distortion-wrapper .menu .menu__link.active .sticky-image-title a {
        padding-left: 70px
    }

    .sticky-image-distortion-wrapper .menu .menu__link.active .sticky-category {
        left: 20px
    }
}

@media (max-width:1399px) {
    .threeD-letter-menu .menu-item .hover-reveal {
        width: 320px;
        height: 320px
    }

    .sticky-image-distortion-wrapper .menu .menu__link.active .sticky-image-title .title {
        left: 65px
    }

    .sticky-image-title .title {
        left: 50px
    }

    .sticky-category,
    .sticky-image-distortion-wrapper .menu .menu__link.active .sticky-category {
        left: 0
    }

    .sticky-image-distortion-wrapper .menu .menu__link.active .sticky-image-title a {
        padding-left: 55px
    }
}

@media (max-width:1199px) {
    .portfolio-creative .portfolio-box .portfolio-title:after {
        font-size: 80px
    }

    .threeD-letter-menu .menu-item .hover-reveal {
        width: 320px;
        height: 320px
    }

    .sticky-image-distortion-wrapper .menu {
        padding-top: 160px
    }

    .sticky-image-title a {
        padding-left: 15px
    }
}

@media (max-width:991px) {
    .portfolio-filter.filter-btn li {
        padding: 0 6px
    }

    .portfolio-filter.filter-btn li a {
        padding: 6px 25px
    }

    .mousetip-wrapper .caption {
        display: none !important
    }

    .portfolio-creative .portfolio-box .portfolio-title:after {
        font-size: 60px
    }

    .sticky-image-distortion-wrapper .menu .menu__link.active .svg-wrapper {
        margin-top: 50px
    }
}

@media (max-width:767px) {

    .nav-tabs li,
    .portfolio-filter.filter-btn li a {
        display: block
    }

    .portfolio-filter.filter-btn li {
        padding: 0;
        margin-bottom: 10px
    }

    .nav-tabs li {
        padding: 0;
        margin-bottom: 5px;
        width: 100%
    }

    .sticky-image-distortion-wrapper .menu {
        padding: 130px 30px 30px;
        width: 100%
    }

    .sticky-image-distortion-wrapper .menu .menu__link {
        flex-direction: column
    }

    .sticky-image-distortion-wrapper .menu .menu__link.active .svg-wrapper {
        height: auto;
        margin-top: 0
    }

    .sticky-image-distortion-wrapper .menu .svg-wrapper {
        position: relative;
        right: 22px;
        width: 100%;
        height: 0
    }

    .sticky-image-distortion-wrapper .sticky-image-title {
        width: 100%
    }

    .sticky-image-distortion-wrapper .sticky-image-title a {
        padding: 30px 35px
    }
}

@media (max-width:575px) {
    .portfolio-attractive .portfolio-box:hover .portfolio-hover .icon-box {
        margin-top: -10px
    }

    .sticky-image-distortion-wrapper .menu {
        padding: 130px 0 0
    }

    .sticky-image-distortion-wrapper .menu .svg-wrapper {
        right: 20px
    }

    .sticky-image-title .title {
        display: none
    }
}

@media (max-width:1199px) {
    .btn-cart.btn.btn-switch-text.btn-extra-large>span {
        padding: 13px 20px
    }
}

@media (max-width:991px) {
    .btn-cart.btn.btn-switch-text.btn-extra-large>span {
        padding: 13px 48px
    }

    .single-product-thumb .slider-vertical {
        position: relative;
        left: 0;
        touch-action: pan-y;
        overflow: hidden;
        margin-top: 15px
    }

    .single-product-thumb .slider-vertical .swiper-wrapper {
        flex-direction: row
    }

    .product-image-thumb .swiper-slide.swiper-slide-thumb-active {
        border-color: transparent
    }

    .product-image-thumb .swiper-slide img {
        border: 1px solid transparent
    }

    .product-image-thumb .swiper-slide.swiper-slide-thumb-active img {
        border-color: var(--light-gray)
    }

    .single-product-thumb .slider-vertical .swiper-slide {
        width: 25%
    }
}

@media (max-width:767px) {
    .cart-products thead {
        display: none
    }

    .cart-products tbody tr td {
        padding: 0;
        border: 0;
        clear: both;
        margin: 0 0 15px;
        line-height: 1.5em;
        display: block !important;
        text-align: right !important
    }

    .cart-products tbody tr td:last-child {
        margin-bottom: 0
    }

    .cart-products tbody tr td::before {
        content: attr(data-title) !important;
        font-weight: 500 !important;
        float: left !important;
        display: block !important;
        color: var(--dark-gray);
        font-size: 15px
    }

    .cart-products tbody tr {
        border-bottom: 1px solid var(--extra-medium-gray);
        position: relative;
        padding-left: 95px;
        padding-bottom: 30px;
        background: 0 0;
        margin-bottom: 30px;
        overflow: hidden;
        display: block !important;
        min-height: 100px
    }

    .cart-products tbody tr:last-child {
        margin-bottom: 0
    }

    .cart-products tbody tr .product-remove {
        width: 20px;
        position: absolute;
        right: 0;
        margin: 0;
        padding: 0;
        text-align: right !important;
        top: 5px;
        z-index: 11
    }

    .cart-products tbody tr .product-remove a {
        line-height: 13px;
        width: 20px;
        height: 20px;
        display: block;
        text-align: center
    }

    .cart-products tbody tr .product-thumbnail {
        position: absolute;
        left: 0;
        width: 80px;
        display: inline-block;
        top: 0;
        overflow: hidden;
        text-align: left !important
    }

    .cart-products tbody tr .product-name {
        text-align: left !important;
        padding-right: 25px;
        margin-bottom: 15px
    }

    .cart-products tbody tr td.product-quantity:before {
        margin-top: 8px
    }

    .cart-products tbody tr .product-name::before,
    .cart-products tbody tr .product-remove::before,
    .cart-products tbody tr .product-thumbnail::before {
        display: none
    }
}

@media (max-width:575px) {
    .product-info .quantity {
        margin-right: auto !important
    }

    .product-info .wishlist {
        position: absolute;
        top: 0;
        right: 0
    }

    .product-info .btn-cart {
        width: 100%
    }
}

@media (max-width:1600px) {
    footer {
        padding-top: 90px;
        padding-bottom: 90px
    }
}

@media (max-width:991px) {
    footer {
        padding-top: 75px;
        padding-bottom: 75px
    }

    footer.half-footer {
        padding: 65px 0
    }

    .footer-sticky {
        position: relative !important
    }
}

@media (max-width:767px) {
    footer {
        padding-top: 50px;
        padding-bottom: 50px
    }

    footer.half-footer {
        padding: 50px 0
    }

    footer .elements-social li {
        margin-bottom: 0
    }

    .footer-navbar li .nav-link {
        padding-left: .8rem;
        padding-right: .8rem
    }
}

/*# sourceMappingURL=responsive.min.css.map */